import React from 'react'
import Signup from './Signup'
import Signin from './Signin'
import Dashboard from './Dashboard'
import About from './About'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import TopbarOut from './Parts/TopbarOut'
import Correspondence from './AppRoutes/Correspondence/Correspondence'
import Defendants from './AppRoutes/Matters/Defendants/Defendants'
import Intake from './AppRoutes/Intake/Intake'
import Matters from './AppRoutes/Matters/Matters'
import EditMatter from './AppRoutes/Matters/EditMatter'
import Plaintiffs from './AppRoutes/Matters/Plaintiffs/Plaintiffs'
import Demands from './AppRoutes/Demands/Demands'
import Billing from './AppRoutes/Billing/Billing'
import Account from './AppRoutes/Account/Account'
import Firms from './AppRoutes/Matters/Firms/Firms'
import CaseAccess from './AppRoutes/Matters/CaseAccess/CaseAccess'
import ProtectedMatter from './AppRoutes/Matters/ProtectedMatter'
import './App.css'
import CaseInfo from './AppRoutes/Matters/CaseInfo/CaseInfo'
import Attorneys from './AppRoutes/Matters/Attorneys/Attorneys'
import FirmSettings from './AppRoutes/FirmSettings/FirmSettings'
import NotApproved from './AppRoutes/NotApproved/NotApproved'
import RemovedFromFirm from './AppRoutes/RemovedFromFirm/RemovedFromFirm'
import MyMatters from './AppRoutes/Matters/MyMatters'
import SharedMatters from './AppRoutes/Matters/SharedMatters'
import FirmMatters from './AppRoutes/Matters/FirmMatters'
import PitchDeck from './PitchDeck'
import Notes from './AppRoutes/Matters/Notes/Notes'
import Apps from './AppRoutes/Matters/Apps/Apps'
import FormRogsP from './AppRoutes/Matters/Apps/Discovery/Propounding/FormRogs/FormRogsP'
import FormRogsR from './AppRoutes/Matters/Apps/Discovery/Response/FormRogs/FormRogsR'
import Discovery from './AppRoutes/Matters/Apps/Discovery/Discovery'

function App() {
  return (
    <div className='App'>
      {/* <Beforeunload onBeforeunload={() => 'You will be signed out if you leave'} /> */}
      <Routes>
        <Route
          path='/'
          element={
            <div>
              <TopbarOut></TopbarOut>
              <About />
            </div>
          }
        />

        <Route
          path='/pitchdeck'
          element={
            <div>
              <PitchDeck />
            </div>
          }
        />

        <Route
          path='/login'
          element={
            <div>
              <TopbarOut></TopbarOut>
              <Signin />
            </div>
          }
        />

        <Route
          path='/register'
          element={
            <div>
              <TopbarOut></TopbarOut>
              <Signup />
            </div>
          }
        />

        <Route
          path='/pending_user'
          element={
            <div>
              <NotApproved></NotApproved>
            </div>
          }
        />

        <Route
          path='/user_removed_from_firm_msg'
          element={
            <div>
              <TopbarOut></TopbarOut>
              <RemovedFromFirm></RemovedFromFirm>
            </div>
          }
        />

        <Route path='/client'>
          <Route path=':m_id'>
            <Route path=':p_id'>
              <Route path='intake' element={<Intake />} />
              <Route path='discovery' />
            </Route>
          </Route>
        </Route>

        <Route
          path='/app'
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route path='intake' element={<Intake />} />
          <Route path='firmsettings' element={<FirmSettings />} />

          <Route path='matters' element={<Matters />}>
            <Route
              path=':id'
              element={
                <ProtectedMatter>
                  <EditMatter />
                </ProtectedMatter>
              }
            >
              <Route path='' element={<Apps />} />
              <Route path='discovery' element={<Discovery />}>
                <Route path='propounding'>
                  <Route path='formrog' element={<FormRogsP />} />
                </Route>
                <Route path='response'>
                  <Route path='formrog' element={<FormRogsR />} />
                </Route>
              </Route>
              <Route path='caseinfo' element={<CaseInfo />} />
              <Route path='access' element={<CaseAccess />} />
              <Route path='plaintiffs'>
                <Route path='' element={<Plaintiffs />} />
              </Route>
              <Route path='defendants' element={<Defendants />} />
              <Route path='attorneys' element={<Attorneys />} />
              <Route path='firms' element={<Firms />} />
              <Route path='notes' element={<Notes />} />
              <Route
                path='na'
                element={<div>These pages aren't built yet</div>}
              />
            </Route>
            <Route path='' element={<MyMatters />} />
            <Route path='shared_matters' element={<SharedMatters />} />
            <Route path='firm_matters' element={<FirmMatters />} />
          </Route>

          <Route path='correspondence' element={<Correspondence />} />
          <Route path='demands' element={<Demands />} />
          <Route path='billing' element={<Billing />} />
          <Route path='account' element={<Account />} />
        </Route>

        <Route
          path='*'
          element={
            <div>
              <TopbarOut></TopbarOut>
              <Signin />
            </div>
          }
        />
      </Routes>
    </div>
  )
}

export default App
