import React from 'react'
import { Container } from 'react-bootstrap'
import { Navbar, Nav } from 'react-bootstrap'
import LoginIcon from '@mui/icons-material/Login'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import './TopbarOut.css'
import { useNavigate } from 'react-router'
import logo from '../../images/tl_black.png'

export default function TopbarOut() {
  const navigate = useNavigate()

  return (
    <Navbar
      bg='white'
      sticky='top'
      style={{
        minHeight: '70px',
        padding: '0 14%'
      }}
    >
      <Container>
        <Navbar.Brand href='/'>
          <div className='Logo'>
            <img className='TLLogo' src={logo} alt='logo' />
            <h3>TAP</h3>
            <h3 style={{ color: 'red' }}>LAW</h3>
          </div>
        </Navbar.Brand>
        <div className='TopbarLinksOut' style={{ paddingTop: '4px' }}>
          <Nav className='me-auto gap-4'>
            <div
              className={`gap-1 ${
                window.location.pathname === '/signin'
                  ? 'LogInButtonSelected'
                  : 'LogInButton'
              }`}
              onClick={() => navigate('/signin')}
            >
              <LoginIcon /> Log In
            </div>
            <div
              className={`gap-1 ${
                window.location.pathname === '/register'
                  ? 'RegisterButtonSelected'
                  : 'RegisterButton'
              }`}
              onClick={() => navigate('/register')}
            >
              <AppRegistrationIcon /> Register
            </div>
          </Nav>
        </div>
      </Container>
    </Navbar>
  )
}
