import React from 'react'
import './NotApproved.css'
import RefreshIcon from '@mui/icons-material/Refresh'
import LogoutIcon from '@mui/icons-material/Logout'
import { UserAuth } from '../../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export default function NotApproved() {
  const { user, logout } = UserAuth()
  const navigate = useNavigate()
  // Logout function
  const handleLogout = async () => {
    try {
      await logout()
      navigate('/')
    } catch (e) {}
  }

  function refreshPage() {
    navigate('/app')
  }

  return (
    <div className='NotApproved'>
      {user.email}
      <br></br>
      <br></br>
      <br></br>
      The firm under which you signed up is private.
      <br></br>A firm admin must first accept your request.
      <br></br>
      <br></br>
      <button onClick={() => refreshPage()}>
        Refresh &nbsp;<RefreshIcon></RefreshIcon>{' '}
      </button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button onClick={() => handleLogout()}>
        Logout &nbsp;<LogoutIcon></LogoutIcon>{' '}
      </button>
    </div>
  )
}
