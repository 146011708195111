import React, { useState, useEffect } from 'react'
import './Account.css'
import { UserAuth } from '../../../contexts/AuthContext'
import {
  doc,
  onSnapshot,
  getDoc,
  addDoc,
  collection,
  updateDoc
} from 'firebase/firestore'
import { db } from '../../../firebase'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { Form, Button, Card, FormControl, InputGroup } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import { formatPhoneNumber } from 'react-phone-number-input'
import 'react-phone-input-2/lib/bootstrap.css'
import { updateEmail } from 'firebase/auth'
import { getAuth } from 'firebase/auth'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Fade } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DoneIcon from '@mui/icons-material/Done'
import { PulseLoader } from 'react-spinners'

export default function Account() {
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [firmData, setFirmData] = useState([])
  const [accountCreated, setAccountCreated] = useState('')
  const [accountVisited, setAccountVisited] = useState('')
  const [userError, setUserError] = useState('')
  const [myPhone, setMyPhone] = useState('')
  const [myEmail, setMyEmail] = useState('')
  const [myName, setMyName] = useState('')
  const [, setUserID] = useState('')
  const auth = getAuth()
  const { user } = UserAuth()

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()
    return cMonth + '/' + cDay + '/' + cYear
  }

  useEffect(() => {
    onSnapshot(doc(db, 'firmUsers', user.uid), () => {
      getAndSetUserData(user)
    })
  }, [user])

  async function getAndSetUserData(user) {
    // Access firm ID from custom claims
    const uid = user.uid
    const ct = user.metadata.creationTime
    const lt = user.metadata.lastSignInTime

    const userRef = doc(db, 'firmUsers', uid)
    const userSnap = await getDoc(userRef)
    const userData = userSnap.data()
    let user_data_retrieve = {
      fullName: userData.fullName,
      email: userData.email,
      phone: userData.phone
    }

    const firmRef = doc(db, 'firms', userData.firmID)
    const firmSnap = await getDoc(firmRef)
    const firmData = firmSnap.data()
    let firm_data_retrieve = {
      name: firmData.name,
      street: firmData.street,
      location: firmData.location,
      zip: firmData.zip,
      fax: firmData.fax
    }

    setAccountCreated(ct)
    setAccountVisited(lt)

    setUserID(uid)
    setUserData(user_data_retrieve)
    setFirmData(firm_data_retrieve)
    setLoading(false)
  }

  // Update user values
  async function changeUserVal(property, data, func) {
    if (data === '') {
      setUserError('')
    } else if (property !== 'email' && property !== 'phone') {
      await updateDoc(doc(db, 'firmUsers', user.uid), {
        [property]: data
      })
      func('')
      setUserError('')
    } else if (property === 'email' && property !== 'phone') {
      updateEmail(auth.currentUser, data)
        .then(async () => {
          await updateDoc(doc(db, 'firmUsers', user.uid), {
            email: data
          })
          func('')
          setUserError('')
        })
        .catch(() => {
          setUserError('Email already in use')
        })
    } else if (property === 'phone') {
      if (data.length === 14) {
        await updateDoc(doc(db, 'firmUsers', user.uid), {
          [property]: formatPhoneNumber('+1' + data)
        })
        func('')
        setUserError('')
      } else {
        setUserError('Invalid Phone Number')
      }
    }

    let updateVal = property
    if (property === 'fullName') {
      updateVal = 'full name'
    }
    const myCreateContent = 'Updated your ' + updateVal
    // Add to my activities collection
    await addDoc(collection(db, 'firmUsers', user.uid, 'activities'), {
      type: 'account',
      date: getTime(),
      content: myCreateContent
    })
  }

  return (
    <Fade in={true} appear timeout={700}>
      <div className='AccountPage'>
        <br></br>
        <div className='AccountContainer'>
          <div className='AccountEditors'>
            <Card style={{ width: '500px' }}>
              <Card.Body>
                <div className='AEditTop'>
                  <h5>User</h5>&nbsp;&nbsp;
                  <div className='AccountEdit'>
                    {isEditing ? (
                      <DoneIcon onClick={() => setIsEditing(false)}></DoneIcon>
                    ) : (
                      <EditIcon onClick={() => setIsEditing(true)}></EditIcon>
                    )}
                  </div>
                </div>
                {isEditing ? (
                  <Form>
                    <Form.Label>Name</Form.Label>
                    <InputGroup className='mb-3'>
                      <FormControl
                        value={myName}
                        placeholder={userData.fullName}
                        onChange={e => setMyName(e.target.value)}
                      />
                      <Button
                        variant='outline-secondary'
                        id='button-addon2'
                        onClick={() =>
                          changeUserVal('fullName', myName, setMyName)
                        }
                      >
                        Save
                      </Button>
                    </InputGroup>

                    <Form.Label>Email</Form.Label>
                    <InputGroup className='mb-3'>
                      <FormControl
                        value={myEmail}
                        placeholder={userData.email}
                        onChange={e => setMyEmail(e.target.value)}
                      />
                      <Button
                        variant='outline-secondary'
                        id='button-addon2'
                        onClick={() =>
                          changeUserVal('email', myEmail, setMyEmail)
                        }
                      >
                        Save
                      </Button>
                    </InputGroup>

                    <Form.Label>Phone Number</Form.Label>
                    <InputGroup className='mb-3'>
                      <div className='PhoneUpdate'>
                        <PhoneInput
                          enableAreaCodes={false}
                          inputStyle={{
                            height: '40px',
                            borderTopRightRadius: '0px',
                            borderBottomRightRadius: '0px',
                            width: '406px'
                          }}
                          disableCountryCode
                          country={'us'}
                          onlyCountries={['us']}
                          placeholder={formatPhoneNumber('+1' + userData.phone)}
                          value={myPhone}
                          onChange={setMyPhone}
                        />
                      </div>
                      <Button
                        variant='outline-secondary'
                        id='button-addon2'
                        onClick={() =>
                          changeUserVal(
                            'phone',
                            formatPhoneNumber('+1' + myPhone),
                            setMyPhone
                          )
                        }
                      >
                        Save
                      </Button>
                    </InputGroup>
                  </Form>
                ) : (
                  <div>
                    <br></br>
                    <div className='infoRow'>
                      <div style={{ color: '#000000' }}>Name:</div>&nbsp;{' '}
                      {userData.fullName}
                    </div>
                    <div className='infoRow'>
                      <div style={{ color: '#000000' }}>Email:</div>&nbsp;{' '}
                      {userData.email}
                    </div>
                    <div className='infoRowL'>
                      <div style={{ color: '#000000' }}>Phone:</div>&nbsp;{' '}
                      {userData.phone}
                    </div>
                  </div>
                )}
              </Card.Body>
              <div className='errorMsg'>{userError}</div>
            </Card>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Card style={{ width: '500px' }}>
              <Card.Body>
                <div className='AEditTop'>
                  <h5>Firm</h5>
                </div>
                <br></br>
                <div className='infoRow'>
                  <div style={{ color: '#000000' }}>Firm Name:</div>&nbsp;{' '}
                  {firmData.name}
                </div>
                <div className='infoRow'>
                  <div style={{ color: '#000000' }}>Firm Street:</div>&nbsp;{' '}
                  {firmData.street}
                </div>
                <div className='infoRow'>
                  <div style={{ color: '#000000' }}>Firm City, State:</div>
                  &nbsp; {firmData.location}
                </div>
                <div className='infoRow'>
                  <div style={{ color: '#000000' }}>Firm Zip:</div>&nbsp;{' '}
                  {firmData.zip}
                </div>
                <div className='infoRowL'>
                  <div style={{ color: '#000000' }}>Firm Fax:</div>&nbsp;
                  {firmData.fax}
                </div>
              </Card.Body>
            </Card>
          </div>
          <br />
          <Card style={{ padding: '20px' }}>
            <br></br>
            <h5 className='text-center mb-4'>My Data</h5>
            <hr></hr>
            <div className='rowData'>
              &nbsp;&nbsp;<AdminPanelSettingsIcon></AdminPanelSettingsIcon>{' '}
              &nbsp;Account Created:&nbsp;
              <div style={{ color: '#7f7f7f' }}>{accountCreated}</div>
            </div>
            <hr></hr>
            <div className='rowData'>
              &nbsp;&nbsp;<AccessTimeIcon></AccessTimeIcon> &nbsp;Last Login:
              &nbsp;<div style={{ color: '#7f7f7f' }}>{accountVisited}</div>
            </div>
            <br></br>
          </Card>
        </div>
        <br></br>
        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto' }}>
            {loading ? <PulseLoader width={200} color={'#6aa4e6'} /> : ''}
          </div>
        </div>
        <br></br>
        <br></br>
      </div>
    </Fade>
  )
}
