import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const Q6_3 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q6_3, setQ6_3] = useState([])

  const [q6_3description, setQ6_3description] = useState('')
  const [q6_3status, setQ6_3status] = useState('')
  const [q6_3duration, setQ6_3duration] = useState('')

  const [hasComplaints, setHasComplaints] = useState(false)

  async function saveQuestion() {
    if (q6_3.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_3: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_3: q6_3
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q6_3) {
      if (docSnap.data().q6_3 !== 'N/A') {
        setHasComplaints(true)
        setQ6_3(docSnap.data().q6_3)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addComplaint() {
    if (q6_3description !== '') {
      let currList = q6_3
      currList.push({
        Description: q6_3description,
        Status: q6_3status,
        Duration: q6_3duration
      })

      setQ6_3(currList)
      setQ6_3description('')
      setQ6_3status('')
      setQ6_3duration('')
    }
  }

  function remComplaint(index) {
    let newList = []
    for (let i = 0; i < q6_3.length; i++) {
      if (i !== index) {
        newList.push(q6_3[i])
      }
    }
    setQ6_3(newList)
  }

  function noComplaints() {
    setHasComplaints(false)
    setQ6_3([])
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Do you still have any complaints that you attribute to <br></br>
          the incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasComplaints}
                onClick={() => setHasComplaints(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasComplaints}
                onClick={() => noComplaints()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasComplaints ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each complaint, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_3description}
                  required
                  onChange={e => setQ6_3description(e.target.value)}
                />
                <label className={q6_3description && 'filled'}>
                  {'A description'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_3duration}
                  required
                  onChange={e => setQ6_3duration(e.target.value)}
                />
                <label className={q6_3duration && 'filled'}>
                  {'The frequency and duration'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <Form.Select
                aria-label='Default select example'
                style={{ height: '43px' }}
                onChange={e => setQ6_3status(e.target.value)}
                value={q6_3status}
              >
                <option value=''>Complaint Status</option>
                <option value='Subsiding'>Subsiding</option>
                <option value='Remaining the same'>Remaining the same</option>
                <option value='Becoming worse'>Becoming worse</option>
              </Form.Select>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto' }}
                  onClick={() => addComplaint()}
                >
                  <div>&nbsp;Add&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q6_3.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q6_3.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q6_3[i]['Description']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remComplaint(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ maxWidth: '400px' }}>
                          <div
                            style={{
                              display: 'flex',
                              padding: '10px',
                              borderBottomStyle: 'solid',
                              borderWidth: '1px',
                              borderBottomColor: '#dddddd'
                            }}
                          >
                            Description: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q6_3[i]['Description']}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              padding: '10px',
                              borderBottomStyle: 'solid',
                              borderWidth: '1px',
                              borderBottomColor: '#dddddd'
                            }}
                          >
                            Status: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q6_3[i]['Status']}
                            </div>
                          </div>
                          <div style={{ display: 'flex', padding: '10px' }}>
                            Frequency & Duration: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q6_3[i]['Duration']}
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q6_3
