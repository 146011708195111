import './SidebarHome.css'
import React, { useCallback, useEffect, useState } from 'react'
import { UserAuth } from '../../../../contexts/AuthContext'
import { SidebarHomeData } from './SidebarHomeData'
import { useNavigate } from 'react-router-dom'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot
} from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CreateMatter from '../CreateMatter'
import { Fade } from '@mui/material'
import { Container } from 'react-bootstrap'
import ClearIcon from '@mui/icons-material/Clear'

const SidebarHome = () => {
  const [isFirmAdmin, setIsFirmAdmin] = useState(false)
  const [createNewMatter, setCreateNewMatter] = useState(false)
  const { user } = UserAuth()
  const navigate = useNavigate()
  const [, setUserObj] = useState({})

  const getAndSetUserData = useCallback(async () => {
    //Get the user's data, so we can get their firm
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    setUserObj(userSnap.data())

    const firmUsersRef = collection(
      db,
      'firms',
      userSnap.data().firmID,
      'users'
    )
    const firmUsersSnap = await getDocs(firmUsersRef)
    let fUsers = {}
    firmUsersSnap.forEach(doc => {
      fUsers[doc.id] = doc.data().isAdmin
    })

    setIsFirmAdmin(fUsers[user.uid])
  }, [user.uid])

  const checkForUpdates = useCallback(async () => {
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    const userData = userSnap.data()
    const firmID = userData.firmID
    onSnapshot(doc(db, 'firms', firmID, 'users', user.uid), () => {
      getAndSetUserData()
    })
  }, [getAndSetUserData, user.uid])

  useEffect(() => {
    checkForUpdates()
  }, [checkForUpdates])

  function getCurrentLocation() {
    const currPath = window.location.pathname

    const mIndex = currPath.indexOf('matters')
    return currPath.slice(mIndex + 8, currPath.length)
  }

  // // Retrieve case info constantly
  // useEffect(() => {
  //     getLocTest();
  // }, );

  return (
    <div>
      <div className='SidebarHome'>
        <div className='Top'>
          <div className='NewButton' onClick={() => setCreateNewMatter(true)}>
            <AddOutlinedIcon></AddOutlinedIcon>
            &nbsp; New Matter
          </div>
        </div>

        {createNewMatter ? (
          <div
            className='overlayP'
            onClick={() => setCreateNewMatter(false)}
          ></div>
        ) : (
          ''
        )}

        {createNewMatter ? (
          <Fade in={true} appear timeout={500}>
            <div className='SPopContent'>
              <div className='Close' onClick={() => setCreateNewMatter(false)}>
                <ClearIcon></ClearIcon>
              </div>
              <Container
                className='d-flex justify-content-center'
                style={{ minHeight: '90vh' }}
              >
                <div className='SPopCard'>
                  <div
                    className='w-100'
                    style={{ maxWidth: '600px', marginTop: '-10px' }}
                  >
                    <CreateMatter></CreateMatter>
                  </div>
                </div>
              </Container>
            </div>
          </Fade>
        ) : (
          ''
        )}

        <br></br>
        <ul className='SidebarListHome'>
          {/* eslint-disable-next-line array-callback-return */}
          {SidebarHomeData.map((val, key) => {
            if (val.title === 'Firm matters' && isFirmAdmin) {
              return (
                <li
                  className='row'
                  id={getCurrentLocation() === val.link ? 'active' : ''}
                  key={key}
                  onClick={() => navigate(val.link)}
                >
                  <div style={{ display: 'flex', 'align-items': 'center' }}>
                    {val.icon} &nbsp; {val.title}
                    <div
                      style={{ 'margin-left': 'auto', 'margin-right': '7px' }}
                    >
                      <div style={{ display: 'flex' }}></div>
                    </div>
                  </div>
                </li>
              )
            } else if (val.title !== 'Firm matters') {
              return (
                <li
                  className='row'
                  id={getCurrentLocation() === val.link ? 'active' : ''}
                  key={key}
                  onClick={() => navigate(val.link)}
                >
                  <div style={{ display: 'flex', 'align-items': 'center' }}>
                    {val.icon} &nbsp; {val.title}
                    <div
                      style={{ 'margin-left': 'auto', 'margin-right': '7px' }}
                    >
                      <div style={{ display: 'flex' }}></div>
                    </div>
                  </div>
                </li>
              )
            }
          })}
        </ul>
      </div>
      <div className='PlaceHolder1'></div>
    </div>
  )
}

export default SidebarHome
