import React, { useState, useEffect, useCallback } from 'react'
import './Apps.css'
import { UserAuth } from '../../../../contexts/AuthContext'
import {
  doc,
  addDoc,
  getDoc,
  collection,
  onSnapshot,
  updateDoc
} from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Card } from 'react-bootstrap'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import 'reactjs-popup/dist/index.css'
import CheckIcon from '@mui/icons-material/Check'
import { InputGroup, Form, Button } from 'react-bootstrap'
import RemoveIcon from '@mui/icons-material/Remove'
import { useNavigate, useParams } from 'react-router'
import { useRef } from 'react'
import { Fade } from '@mui/material'
import '../../Account/Account.css'
import CircleIcon from '@mui/icons-material/Circle'
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import GavelIcon from '@mui/icons-material/Gavel';
import MediationIcon from '@mui/icons-material/Mediation';
import NextWeekIcon from '@mui/icons-material/NextWeek';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';

export default function Apps() {
  const [caseName, setCaseName] = useState('')
  const navigate = useNavigate()
  //make the app render a description underneath. add it to the props so it can be passed in
  function appIcon(icon, description, slug){
    return(
      <div style={{width:"200px"}} onClick={() => navigate(slug)}>
        <div style={{textAlign:"center"}}>
          <div className="appIcon">
            <div style={{scale:"2"}}>{icon}</div>
          </div>
          <div style={{marginTop:"-15px"}}>{description}</div>
        </div>
      </div>
    )
  }
  const { id } = useParams()

  const retrieveCaseinfo = useCallback(async () => {
    const myMatterRef = doc(db, 'matters', id)
    const myMattersSnap = await getDoc(myMatterRef)
    const obj = myMattersSnap.data()
    setCaseName(obj.caseName)
  }, )

  // Retrieve case info on page load
  useEffect(() => {
    onSnapshot(doc(db, 'matters', id), () => {
      retrieveCaseinfo()
    })
  }, [id, retrieveCaseinfo])

  const appList = [[<TroubleshootIcon />, "Discovery", "discovery"],
                    [<HistoryEduIcon />, "Demands", "demands"],
                    [<DesignServicesIcon />, "Depositions", "depositions"],
                    [<NextWeekIcon />, "File a lawsuit", "lawsuit"],
                    [<MediationIcon />, "Mediation", "mediation"],
                    [<GavelIcon />, "Trial", "trial"]];

  return(<div>
    <div style={{textAlign:"center", fontSize:"25px", marginTop:"30px"}}>Apps for: {caseName}</div>

    <div style={{display:"flex", marginTop:"50px"}}>
      <div style={{margin:"auto"}}>
        <div style={{display:"flex", marginBottom:"60px"}}>
          {appList.splice(0,3).map((app, key) => 
            appIcon(app[0], app[1], app[2])
          )}
        </div>

        <div style={{display:"flex", marginBottom:"60px"}}>
          {appList.splice(0,3).map((app, key) => 
            appIcon(app[0], app[1], app[2])
          )}
        </div>
        <div style={{display:"flex"}}>
          {appList.splice(0,3).map((app, key) => 
            appIcon(app[0], app[1], app[2])
          )}
        </div>
      </div>
    </div>

  </div>)
}
