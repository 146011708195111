import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import './Firms.css'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Button, Card, Form } from 'react-bootstrap'
import 'reactjs-popup/dist/index.css'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import { Fade } from '@mui/material'
import { UserAuth } from '../../../../contexts/AuthContext'
import { Container } from '@mui/system'
import PhoneInput from 'react-phone-input-2'
import EditIcon from '@mui/icons-material/Edit'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import Select from 'react-select'
import { formatPhoneNumber } from 'react-phone-number-input'
import BusinessIcon from '@mui/icons-material/Business'

export default function Firms() {
  const { user } = UserAuth()
  const { id } = useParams()
  const [caseName, setCaseName] = useState('')
  const [, setLoadedFirms] = useState(false)
  const [firmDB, setFirmDB] = useState([])
  const [searchInput, setSearchInput] = useState(false)
  const [firmList, setFirmList] = useState([])
  const [firmNames, setFirmNames] = useState({})
  const [firmErr, setFirmErr] = useState('')

  const [firmObj, setFirmObj] = useState({})

  const [editFirmModal, setEditFirmModal] = useState(false)
  const [overlay, setOverlay] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editingSlug, setEditingSlug] = useState('')

  const [firmName, setFirmName] = useState('')
  const [firmSt, setFirmSt] = useState('')
  const [firmLoc, setFirmLoc] = useState('')
  const [firmZip, setFirmZip] = useState('')
  const [firmFax, setFirmFax] = useState('')
  const [firmNameDisabled, setFirmNameDisabled] = useState(false)

  const [addFirmID, setAddFirmID] = useState('')

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()
    return cMonth + '/' + cDay + '/' + cYear
  }

  function firmIsValid() {
    return firmName !== ''
  }

  const toggleModal = () => {
    setModal(!modal)
    setOverlay(!modal)

    setFirmName('')
    setFirmSt('')
    setFirmLoc('')
    setFirmZip('')
    setFirmFax('')
    setFirmNameDisabled(false)

    setFirmErr('')
  }

  async function getAndSetFirm(slug) {
    const firmRef = doc(db, 'matters', id, 'firms', slug)
    const firmsnap = await getDoc(firmRef)
    const fData = firmsnap.data()

    setFirmName(fData.name)
    setFirmSt(fData.street)
    setFirmLoc(fData.location)
    setFirmZip(fData.zip)
    setFirmFax(fData.fax)

    setEditingSlug(slug)
    setFirmObj(fData)
  }

  const toggleEditModal = slug => {
    if (!editFirmModal) {
      getAndSetFirm(slug)
    }
    setOverlay(!editFirmModal)
    setEditFirmModal(!editFirmModal)
    setIsEditing(false)
  }

  const loadFirms = async () => {
    setLoadedFirms(true)
    let firm_list = []
    const querySnapshot = await getDocs(collection(db, 'firms'))
    querySnapshot.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      firm_list.push({ label: doc.data().name, value: doc.id })
    })
    setFirmDB(firm_list)
  }

  const getAndSetData = useCallback(async () => {
    const myMattersRef = collection(db, 'matters', id, 'firms')
    const myMattersSnap = await getDocs(myMattersRef)
    let tempList = []
    let tempDict = {}
    myMattersSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      tempList.push(doc.id)
      tempDict[doc.id] = doc.data().name + ' - ' + doc.data().zip
    })

    setFirmList(tempList)
    setFirmNames(tempDict)

    const matterSnap = await getDoc(doc(db, 'matters', id))
    setCaseName(matterSnap.data().caseName)
  }, [id])

  useEffect(() => {
    onSnapshot(collection(db, 'matters', id, 'firms'), () => {
      getAndSetData()
    })
    onSnapshot(collection(db, 'firms'), () => {
      loadFirms()
    })
  }, [getAndSetData, id])

  async function updateFirm() {
    await updateDoc(doc(db, 'matters', id, 'firms', editingSlug), {
      name: firmName,
      fax: formatPhoneNumber('+1' + firmFax),
      location: firmLoc,
      street: firmSt,
      zip: firmZip
    })
  }

  // If there's text in the dropdown, open it. Otherwise, keep it hidden
  function dropIsOpen(input) {
    if (input === '') {
      setSearchInput(false)
    } else if (searchInput === false && input !== '') {
      setSearchInput(true)
    }
  }

  async function addFirm() {
    // user is adding a firm from database:
    if (addFirmID !== '') {
      if (firmList.indexOf(addFirmID) !== -1) {
        setFirmErr('Firm already added!')
        return -1
      }
      setFirmErr('')
      await setDoc(doc(db, 'matters', id, 'firms', addFirmID), {
        name: firmName,
        fax: formatPhoneNumber('+1' + firmFax),
        location: firmLoc,
        street: firmSt,
        zip: firmZip,
        firmID: addFirmID,
        inDB: true
      })
    }
    // user is adding nonDB firm:
    else {
      const docRef = await addDoc(collection(db, 'matters', id, 'firms'), {
        name: firmName,
        fax: formatPhoneNumber('+1' + firmFax),
        location: firmLoc,
        street: firmSt,
        zip: firmZip,
        inDB: false
      })

      await updateDoc(doc(db, 'matters', id, 'firms', docRef.id), {
        firmID: docRef.id
      })
    }

    let createContent = user.email + ' added firm: ' + firmName
    // Create the activities collection
    await addDoc(collection(db, 'matters', id, 'activities'), {
      user: user.uid,
      date: getTime(),
      content: createContent
    })

    let myCreateContent = 'Added firm: ' + firmName + ', to matter: ' + caseName

    // Add to my activities collection
    await addDoc(collection(db, 'firmUsers', user.uid, 'activities'), {
      type: 'firm',
      date: getTime(),
      content: myCreateContent
    })

    loadFirms()
    toggleModal()
  }

  function cancelEdit() {
    getAndSetFirm(editingSlug)
    setIsEditing(false)
  }

  async function saveEdit() {
    await updateFirm()
    getAndSetFirm(editingSlug)
    setIsEditing(false)
  }

  async function deleteFirm() {
    await deleteDoc(doc(db, 'matters', id, 'firms', editingSlug))
    toggleEditModal()
  }

  async function processFirm(opt, meta) {
    const action = meta.action
    if (action === 'select-option') {
      const firmRef = doc(db, 'firms', opt['value'])
      const firmsnap = await getDoc(firmRef)
      const fData = firmsnap.data()

      setFirmName(fData.name)
      setAddFirmID(firmsnap.id)
      setFirmSt(fData.street)
      setFirmLoc(fData.location)
      setFirmZip(fData.zip)
      setFirmFax(fData.fax)
      setFirmNameDisabled(true)
    } else {
      setFirmName('')
      setFirmSt('')
      setFirmLoc('')
      setFirmZip('')
      setFirmFax('')
      setAddFirmID('')
      setFirmNameDisabled(false)
    }
  }

  return (
    <div>
      <Fade in={true} appear timeout={300}>
        <div className='PlaintiffPage'>
          <br></br>
          <Card>
            <br></br>
            <div className='PlaintiffsTop'>
              <h5 className='text-center mb-4'>Firms</h5>
              <div className='Add' onClick={toggleModal}>
                <AddIcon style={{ color: 'white' }}></AddIcon>
              </div>
            </div>
            <div className='SharedListFinal'>
              {firmList.map(val => {
                return (
                  <div className='PlaintiffRow'>
                    &nbsp; <BusinessIcon></BusinessIcon>
                    &nbsp; {firmNames[val]}
                    <div className='PlaintiffView'>
                      <OpenInFullIcon
                        onClick={() => toggleEditModal(val)}
                      ></OpenInFullIcon>
                    </div>
                  </div>
                )
              })}
            </div>
            <br></br>
          </Card>
        </div>
      </Fade>

      {overlay ? <div className='overlayP'></div> : <div></div>}

      {modal ? (
        <Fade in={true} appear timeout={500}>
          <div className='FPopContent'>
            <div className='Close' onClick={toggleModal}>
              <ClearIcon></ClearIcon>
            </div>
            {/* Popup content here: */}
            <Container
              className='d-flex
                justify-content-center'
              style={{ minHeight: '90vh' }}
            >
              <div className='APopCard'>
                <div className='w-100' style={{ maxWidth: '600px' }}>
                  <Card style={{ border: 'white' }}>
                    <Card.Body>
                      <h2 className='text-center mb-4'>Add Firm</h2>
                      <Form>
                        <Form.Group id='type'>
                          <Form.Label>Select Firm From Dropdown</Form.Label>
                          <Select
                            options={firmDB}
                            placeholder='Select a firm'
                            menuIsOpen={searchInput}
                            isClearable
                            onInputChange={e => dropIsOpen(e)}
                            onChange={(opt, meta) => processFirm(opt, meta)}
                          />
                        </Form.Group>
                        <br></br>
                        <div
                          className='text-center mb-4'
                          style={{ color: 'gray' }}
                        >
                          -- Or Manually Input Firm Information --
                        </div>
                        <Form.Group id='pst'>
                          <Form.Label>Firm Name</Form.Label>
                          <Form.Control
                            type='text'
                            onChange={e => setFirmName(e.target.value)}
                            value={firmName}
                            disabled={firmNameDisabled}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <Form.Group id='dst'>
                          <Form.Label>Street Address</Form.Label>
                          <Form.Control
                            type='text'
                            onChange={e => setFirmSt(e.target.value)}
                            value={firmSt}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <Form.Group id='dloc'>
                          <Form.Label>City, State</Form.Label>
                          <Form.Control
                            type='text'
                            onChange={e => setFirmLoc(e.target.value)}
                            value={firmLoc}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <Form.Group id='attorneyname'>
                          <Form.Label>Zip</Form.Label>
                          <Form.Control
                            type='text'
                            onChange={e => setFirmZip(e.target.value)}
                            value={firmZip}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <Form.Group id='dphone'>
                          <Form.Label>Fax</Form.Label>
                          <div className='PhoneArea'>
                            <PhoneInput
                              enableAreaCodes={false}
                              inputStyle={{ height: '40px' }}
                              disableCountryCode
                              country={'us'}
                              onlyCountries={['us']}
                              placeholder='(123) 456-7890'
                              value={firmFax}
                              onChange={setFirmFax}
                            />
                          </div>
                        </Form.Group>
                        <br></br>
                        <br></br>
                        <Button
                          className='w-100'
                          variant='success'
                          disabled={!firmIsValid()}
                          onClick={() => addFirm()}
                        >
                          Add Firm
                        </Button>
                        <br></br>
                        <br></br>
                        <div style={{ color: 'gray' }}>
                          You can change these values later
                        </div>
                        <div style={{ color: 'red' }}>{firmErr}</div>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Container>
            {/* End Popup Content */}
          </div>
        </Fade>
      ) : (
        <div></div>
      )}
      {editFirmModal ? (
        <Fade in={true} appear timeout={500}>
          <div
            className='PopContent'
            style={isEditing ? { height: '660px' } : { height: '340px' }}
          >
            <div className='Close' onClick={toggleEditModal}>
              <ClearIcon></ClearIcon>
            </div>
            {/* Popup content here: */}
            <Container
              className='d-flex
                justify-content-center'
              style={{ minHeight: '90vh' }}
            >
              <div className='PopCard'>
                <div className='w-100' style={{ maxWidth: '600px' }}>
                  <Card style={{ border: 'white' }}>
                    {isEditing ? (
                      <div className='ModifyOpts'>
                        {firmIsValid() ? (
                          <div className='SaveBtn1' onClick={() => saveEdit()}>
                            <CheckIcon></CheckIcon>&nbsp;&nbsp;Save
                          </div>
                        ) : (
                          <div className='SaveBtnDisabled'>
                            <CheckIcon></CheckIcon>&nbsp;&nbsp;Save
                          </div>
                        )}
                        &nbsp;&nbsp;
                        <div
                          className='CancelBtn1'
                          onClick={() => cancelEdit()}
                        >
                          <CloseIcon></CloseIcon>&nbsp;Cancel
                        </div>
                        {/* &nbsp;&nbsp; */}
                        {/* <div className="DelBtn"
                        onClick={()=>(deletePlaintiff())}><DeleteIcon></DeleteIcon>&nbsp;Delete Plaintiff</div> */}
                      </div>
                    ) : (
                      <div className='PEditTop'>
                        <h5>{firmNames[editingSlug]}</h5>
                        <div className='PlaintiffEdit'>
                          <EditIcon
                            onClick={() => setIsEditing(true)}
                          ></EditIcon>
                        </div>
                      </div>
                    )}
                    {isEditing ? (
                      <Card.Body>
                        {isEditing ? (
                          <div></div>
                        ) : (
                          <div className='PEditTop'>
                            <h5>{firmNames[editingSlug]}</h5>
                            <div className='PlaintiffEdit'>
                              <EditIcon
                                onClick={() => setIsEditing(true)}
                              ></EditIcon>
                            </div>
                          </div>
                        )}
                        <Form>
                          <Form.Group id='pst'>
                            <Form.Label>Firm Name</Form.Label>
                            <Form.Control
                              type='text'
                              onChange={e => setFirmName(e.target.value)}
                              disabled={!isEditing}
                              value={firmName}
                              required
                            />
                          </Form.Group>
                          <br></br>
                          <Form.Group id='dst'>
                            <Form.Label>Street Address</Form.Label>
                            <Form.Control
                              type='text'
                              onChange={e => setFirmSt(e.target.value)}
                              disabled={!isEditing}
                              value={firmSt}
                              required
                            />
                          </Form.Group>
                          <br></br>
                          <Form.Group id='dloc'>
                            <Form.Label>City, State</Form.Label>
                            <Form.Control
                              type='text'
                              onChange={e => setFirmLoc(e.target.value)}
                              disabled={!isEditing}
                              value={firmLoc}
                              required
                            />
                          </Form.Group>
                          <br></br>
                          <Form.Group id='attorneyname'>
                            <Form.Label>Zip</Form.Label>
                            <Form.Control
                              type='text'
                              onChange={e => setFirmZip(e.target.value)}
                              disabled={!isEditing}
                              value={firmZip}
                              required
                            />
                          </Form.Group>
                          <br></br>
                          <Form.Group id='dphone'>
                            <Form.Label>Fax</Form.Label>
                            <div className='PhoneArea'>
                              <PhoneInput
                                enableAreaCodes={false}
                                inputStyle={{ height: '40px' }}
                                disableCountryCode
                                country={'us'}
                                onlyCountries={['us']}
                                disabled={!isEditing}
                                value={firmFax}
                                onChange={setFirmFax}
                              />
                            </div>
                          </Form.Group>
                          <br></br>
                          <br></br>
                          <div className='DelBtn' onClick={() => deleteFirm()}>
                            <DeleteIcon></DeleteIcon>&nbsp;Remove Firm
                          </div>
                        </Form>
                      </Card.Body>
                    ) : (
                      <Card.Body>
                        <div className='infoRow'>
                          Firm Name: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{firmObj.name}</div>
                        </div>
                        <div className='infoRow'>
                          Street: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{firmObj.street}</div>
                        </div>
                        <div className='infoRow'>
                          City, State: &nbsp;{' '}
                          <div style={{ color: 'black' }}>
                            {firmObj.location}
                          </div>
                        </div>
                        <div className='infoRow'>
                          Zip: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{firmObj.zip}</div>
                        </div>
                        <div className='infoRowL'>
                          Fax: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{firmObj.fax}</div>
                        </div>
                      </Card.Body>
                    )}
                  </Card>
                </div>
              </div>
            </Container>
            {/* End Popup Content */}
          </div>
        </Fade>
      ) : (
        <div></div>
      )}
    </div>
  )
}
