import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form, InputGroup } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CurrencyInput from 'react-currency-input-fix'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q7_2 = forwardRef((props, ref) => {
  const addressRef1 = useRef()
  const addressRef2 = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q7_2, setQ7_2] = useState([])

  const [q7_2prepared_name, setQ7_2prepared_name] = useState('')
  const [q7_2prepared_address, setQ7_2prepared_address] = useState('')
  const [q7_2prepared_phone, setQ7_2prepared_phone] = useState('')
  const [q7_2prepared_date, setQ7_2prepared_date] = useState('')
  const [q7_2damage, setQ7_2damage] = useState('')

  const [q7_2has_copies, setQ7_2has_copies] = useState(false)
  const [q7_2copies, setQ7_2copies] = useState([])
  const [q7_2copy_name, setQ7_2copy_name] = useState('')
  const [q7_2copy_address, setQ7_2copy_address] = useState('')
  const [q7_2copy_phone, setQ7_2copy_phone] = useState('')

  const [hasEvaluation, setHasEvaluation] = useState(false)

  async function saveQuestion() {
    if (q7_2.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q7_2: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q7_2: q7_2
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q7_2) {
      if (docSnap.data().q7_2 !== 'N/A') {
        setHasEvaluation(true)
        setQ7_2(docSnap.data().q7_2)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addEvaluation() {
    if (q7_2prepared_name !== '') {
      let currList = q7_2
      currList.push({
        PreparedName: q7_2prepared_name,
        PreparedAddress: q7_2prepared_address,
        PreparedPhone: q7_2prepared_phone,
        PreparedDate: q7_2prepared_date,
        Damage: q7_2damage,
        Copies: q7_2copies
      })

      setQ7_2(currList)

      setQ7_2prepared_name('')
      setQ7_2prepared_address('')
      setQ7_2prepared_phone('')
      setQ7_2prepared_date('')
      setQ7_2damage('')

      setQ7_2has_copies(false)
      setQ7_2copies([])
      setQ7_2copy_name('')
      setQ7_2copy_address('')
      setQ7_2copy_phone('')

      addressRef1.current.clear()
      addressRef2.current.clear()
    }
  }

  function remEvaluation(index) {
    let newList = []
    for (let i = 0; i < q7_2.length; i++) {
      if (i !== index) {
        newList.push(q7_2[i])
      }
    }
    setQ7_2(newList)
  }

  function noEvaluations() {
    setHasEvaluation(false)
    setQ7_2([])
  }

  function addCopy() {
    if (q7_2copy_name !== '') {
      let currList = q7_2copies
      currList.push({
        Name: q7_2copy_name,
        Address: q7_2copy_address,
        Phone: q7_2copy_phone
      })

      setQ7_2copies(currList)
      setQ7_2copy_name('')
      setQ7_2copy_address('')
      setQ7_2copy_phone('')

      addressRef2.current.clear()
    }
  }

  function remCopy(index) {
    let newList = []
    for (let i = 0; i < q7_2copies.length; i++) {
      if (i !== index) {
        newList.push(q7_2copies[i])
      }
    }
    setQ7_2copies(newList)
  }

  function noCopies() {
    setQ7_2has_copies(false)
    setQ7_2copies([])
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Damage: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Damage']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Prepared by: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['PreparedName']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['PreparedAddress']}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['PreparedPhone']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date Prepared: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['PreparedDate']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Copies: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['Copies'].map((key, j) => {
                  return <div>{props.dict['Copies'][j]['Name']}</div>
                })}
                {props.dict['Copies'].length === 0 ? 'None' : ''}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Damage: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Damage']}
                  onChange={e => (props.dict['Damage'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Prepared By: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['PreparedName']}
                  onChange={e => (props.dict['PreparedName'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['PreparedAddress']}
                  onChange={e =>
                    (props.dict['PreparedAddress'] = e.target.value)
                  }
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['PreparedPhone']}
                  onChange={e => (props.dict['PreparedPhone'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Date Prepared: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['PreparedDate']}
                  onChange={e => (props.dict['PreparedDate'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Has a written estimate or evaluation been made <br></br>
          for any item of property referred to in the <br></br>
          previous question? <br></br>
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasEvaluation}
                onClick={() => setHasEvaluation(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasEvaluation}
                onClick={() => noEvaluations()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasEvaluation ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each estimate or evaluation, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q7_2prepared_name}
                  required
                  onChange={e => setQ7_2prepared_name(e.target.value)}
                />
                <label className={q7_2prepared_name && 'filled'}>
                  {'Name of person who prepared it'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <AddressInput
                passData={data => setQ7_2prepared_address(data)}
                ref={addressRef1}
                ph={'Their address'}
              />
              <div style={{ marginTop: '15px' }}></div>
              <div className='phone-input-container'>
                <PhoneInput
                  enableAreaCodes={false}
                  inputStyle={{
                    height: '44px',
                    width: 'inherit',
                    borderColor: 'rgb(214, 214, 214)'
                  }}
                  disableCountryCode
                  country={'us'}
                  onlyCountries={['us']}
                  value={q7_2prepared_phone}
                  placeholder=''
                  onChange={setQ7_2prepared_phone}
                />

                <label className={q7_2prepared_phone && 'filled'}>
                  {'Phone of person who prepared it'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='date'
                  value={q7_2prepared_date}
                  required
                  onChange={e => setQ7_2prepared_date(e.target.value)}
                />
                <label className={'filled'}>{'Date prepared'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <div className='input-container'>
                  <CurrencyInput
                    id='input-example'
                    name='input-name'
                    value={q7_2damage}
                    decimalsLimit={2}
                    style={{ width: '363px' }}
                    onValueChange={value => setQ7_2damage(value)}
                  />
                  <label className={q7_2damage && 'filled'}>
                    {'Amount of damage stated'}
                  </label>
                </div>
              </InputGroup>
              <div style={{ marginTop: '15px' }}></div>

              <div style={{ textAlign: 'center', width: '400px' }}>
                Does anyone else have a copy of this evaluation?<br></br>
                <div style={{ marginTop: '15px' }}></div>
              </div>
              <div style={{ textAlign: 'center' }}>
                {['radio'].map(type => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Yes'
                      name='copy1'
                      type={type}
                      checked={q7_2has_copies}
                      onClick={() => setQ7_2has_copies(true)}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name='copy1'
                      type={type}
                      checked={!q7_2has_copies}
                      onClick={() => noCopies()}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </div>
              {q7_2has_copies ? (
                <Card style={{ padding: '20px', textAlign: 'center' }}>
                  <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                    For each person who has a copy of it, state:<br></br>
                  </div>
                  <div className='input-container'>
                    <input
                      type='text'
                      value={q7_2copy_name}
                      required
                      onChange={e => setQ7_2copy_name(e.target.value)}
                    />
                    <label className={q7_2copy_name && 'filled'}>
                      {'Their Name'}
                    </label>
                  </div>
                  <div style={{ marginTop: '15px' }}></div>
                  <AddressInput
                    passData={data => setQ7_2copy_address(data)}
                    ref={addressRef2}
                  />
                  <div style={{ marginTop: '15px' }}></div>
                  <div className='phone-input-container'>
                    <PhoneInput
                      enableAreaCodes={false}
                      inputStyle={{
                        height: '44px',
                        width: 'inherit',
                        borderColor: 'rgb(214, 214, 214)'
                      }}
                      disableCountryCode
                      country={'us'}
                      onlyCountries={['us']}
                      value={q7_2copy_phone}
                      placeholder=''
                      onChange={setQ7_2copy_phone}
                    />

                    <label className={q7_2copy_phone && 'filled'}>
                      {'Phone Number'}
                    </label>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '15px',
                      marginBottom: '10px'
                    }}
                  >
                    <div
                      className='AddPerson'
                      style={{ color: 'white', margin: 'auto' }}
                      onClick={() => addCopy()}
                    >
                      <div>&nbsp;Add&nbsp;</div>
                      <AddIcon style={{ color: 'white' }}></AddIcon>
                    </div>
                  </div>
                  {q7_2copies.length > 0 ? <hr></hr> : ''}
                  <div style={{ marginBottom: '-15px' }}>
                    {q7_2copies.map((key, i) => {
                      return (
                        <Accordion
                          style={{
                            backgroundColor: 'white',
                            boxShadow: '0px 1px 3px #cccccc',
                            marginBottom: '4px'
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {q7_2copies[i]['Name']}

                            <div style={{ marginLeft: 'auto' }}>
                              <div
                                className='RemButton'
                                onClick={() => remCopy(i)}
                              >
                                &nbsp;
                                <RemoveIcon></RemoveIcon>
                                Remove&nbsp;&nbsp;&nbsp;
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ maxWidth: '400px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '10px',
                                  borderBottomStyle: 'solid',
                                  borderWidth: '1px',
                                  borderBottomColor: '#dddddd'
                                }}
                              >
                                Name: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q7_2copies[i]['Name']}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '10px',
                                  borderBottomStyle: 'solid',
                                  borderWidth: '1px',
                                  borderBottomColor: '#dddddd'
                                }}
                              >
                                Address: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q7_2copies[i]['Address']}
                                </div>
                              </div>
                              <div style={{ display: 'flex', padding: '10px' }}>
                                Phone: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q7_2copies[i]['Phone']}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                  </div>
                </Card>
              ) : (
                ''
              )}

              <div style={{ display: 'flex', marginTop: '35px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '180px' }}
                  onClick={() => addEvaluation()}
                >
                  <div>&nbsp;Add Evaluation&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q7_2.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q7_2.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q7_2[i]['PreparedName']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remEvaluation(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q7_2[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q7_2
