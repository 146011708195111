import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q2_8 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  // Question 2_1
  const [q2_8, setQ2_8] = useState([])

  const [q2_8city, setQ2_8city] = useState('')
  const [q2_8state, setQ2_8state] = useState('')
  const [q2_8date, setQ2_8date] = useState('')
  const [q2_8offense, setQ2_8offense] = useState('')
  const [q2_8cnum, setQ2_8cnum] = useState('')

  const [hasConvicted, setHasConvicted] = useState(false)

  async function saveQuestion() {
    if (q2_8.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_8: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_8: q2_8
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q2_8) {
      if (docSnap.data().q2_8 !== 'N/A') {
        setHasConvicted(true)
        setQ2_8(docSnap.data().q2_8)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addFelony() {
    if (q2_8offense !== '') {
      q2_8.push({
        City: q2_8city,
        State: q2_8state,
        Date: q2_8date,
        Offense: q2_8offense,
        Cnum: q2_8cnum
      })

      setQ2_8city('')
      setQ2_8state('')
      setQ2_8date('')
      setQ2_8offense('')
      setQ2_8cnum('')
    }
  }

  function remFelony(index) {
    let newList = []
    for (let i = 0; i < q2_8.length; i++) {
      if (i !== index) {
        newList.push(q2_8[i])
      }
    }
    setQ2_8(newList)
  }

  function noFelonies() {
    setHasConvicted(false)
    setQ2_8([])
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              City: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['City']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              State: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['State']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date:&nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Date']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Offense: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Offense']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Case Num: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Cnum']}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              City: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['City']}
                  onChange={e => (props.dict['City'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              State: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['State']}
                  onChange={e => (props.dict['State'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Date']}
                  onChange={e => (props.dict['Date'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Offense: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Offense']}
                  onChange={e => (props.dict['Offense'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Case Num: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Cnum']}
                  onChange={e => (props.dict['Cnum'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Have you ever been convicted of a felony?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasConvicted}
                onClick={() => setHasConvicted(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasConvicted}
                onClick={() => noFelonies()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasConvicted ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each conviction, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_8city}
                  required
                  onChange={e => setQ2_8city(e.target.value)}
                />
                <label className={q2_8city && 'filled'}>
                  {'City you were convicted'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_8state}
                  required
                  onChange={e => setQ2_8state(e.target.value)}
                />
                <label className={q2_8state && 'filled'}>{'State'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_8offense}
                  required
                  onChange={e => setQ2_8offense(e.target.value)}
                />
                <label className={q2_8offense && 'filled'}>
                  {'The offense'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_8cnum}
                  required
                  onChange={e => setQ2_8cnum(e.target.value)}
                />
                <label className={q2_8cnum && 'filled'}>
                  {'Court & case number'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div>Date of conviction:</div>
              <div className='input-container'>
                <input
                  type='date'
                  value={q2_8date}
                  required
                  onChange={e => setQ2_8date(e.target.value)}
                />
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto' }}
                  onClick={() => addFelony()}
                >
                  <div>&nbsp;Add&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q2_8.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q2_8.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q2_8[i]['Offense']}, &nbsp;
                        {q2_8[i]['Date'].slice(0, 4)}
                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remFelony(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q2_8[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q2_8
