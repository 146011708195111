import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'

export const Q3_4 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()
  const addressRef1 = useRef()
  const addressRef2 = useRef()

  const [q3_4name, setQ3_4name] = useState('')
  const [q3_4address, setQ3_4address] = useState('')

  const [q3_4jvs, setQ3_4jvs] = useState([])
  const [q3_4jv_name, setQ3_4jv_name] = useState('')
  const [q3_4jv_address, setQ3_4jv_address] = useState('')

  const [q3_4prev, setQ3_4prev] = useState([])
  const [q3_4has_prev, setQ3_4has_prev] = useState(false)
  const [q3_4prev_name, setQ3_4prev_name] = useState('')
  const [q3_4prev_from, setQ3_4prev_from] = useState('')
  const [q3_4prev_to, setQ3_4prev_to] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [filled, setFilled] = useState(false)

  const [isJointVenture, setIsJointVenture] = useState(false)

  async function saveQuestion() {
    if (!isJointVenture) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q3_4: 'N/A'
        }
      )
    } else if (isJointVenture && q3_4name !== '') {
      if (q3_4has_prev) {
        await updateDoc(
          doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
          {
            q3_4: {
              Name: q3_4name,
              Address: q3_4address,
              PrevNames: q3_4prev,
              Venturers: q3_4jvs
            }
          }
        )
      } else {
        await updateDoc(
          doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
          {
            q3_4: {
              Name: q3_4name,
              Address: q3_4address,
              PrevNames: [],
              Venturers: q3_4jvs
            }
          }
        )
      }
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    setLoaded(true)
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q3_4) {
      if (docSnap.data().q3_4 !== 'N/A') {
        setIsJointVenture(true)

        const docData = docSnap.data().q3_4
        setQ3_4name(docData['Name'])
        setQ3_4address(docData['Address'])
        setQ3_4jvs(docData['Venturers'])
        setQ3_4prev(docData['PrevNames'])
        if (docData['PrevNames'].length > 0) {
          setQ3_4has_prev(true)
        }
      }
    }
  }, [m_id, p_id])

  const fillAddress = useCallback(async () => {
    setFilled(true)
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    addressRef1.current.set(docSnap.data().q3_4['Address'])
  }, [m_id, p_id])

  useEffect(() => {
    if (!loaded) {
      getAndSetIntakeData()
    }
    if (addressRef1.current && !filled) {
      fillAddress()
    }
  }, [fillAddress, filled, getAndSetIntakeData, loaded])

  function addPrev() {
    if (q3_4prev_name !== '') {
      let newList = []
      for (let i = 0; i < q3_4prev.length; i++) {
        newList.push(q3_4prev[i])
      }
      newList.push({
        Name: q3_4prev_name,
        FromDate: q3_4prev_from,
        ToDate: q3_4prev_to
      })

      setQ3_4prev(newList)
      setQ3_4prev_name('')
      setQ3_4prev_to('')
      setQ3_4prev_from('')
    }
  }

  function remPrev(index) {
    let newList = []
    for (let i = 0; i < q3_4prev.length; i++) {
      if (i !== index) {
        newList.push(q3_4prev[i])
      }
    }
    setQ3_4prev(newList)
  }

  function addPartner() {
    if (q3_4jv_name !== '') {
      let newList = []
      for (let i = 0; i < q3_4jvs.length; i++) {
        newList.push(q3_4jvs[i])
      }
      newList.push({ Name: q3_4jv_name, Address: q3_4jv_address })
      setQ3_4jv_name('')
      addressRef2.current.clear()
      setQ3_4jvs(newList)
    }
  }

  function remPartner(index) {
    let newList = []
    for (let i = 0; i < q3_4jvs.length; i++) {
      if (i !== index) {
        newList.push(q3_4jvs[i])
      }
    }
    setQ3_4jvs(newList)
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Are you a joint venture?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='ispart'
                type={type}
                checked={isJointVenture}
                onClick={() => setIsJointVenture(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='ispart'
                type={type}
                checked={!isJointVenture}
                onClick={() => setIsJointVenture(false)}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {isJointVenture ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                If so, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q3_4name}
                  required
                  onChange={e => setQ3_4name(e.target.value)}
                />
                <label className={q3_4name && 'filled'}>
                  {'Current joint venture name'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>

              <AddressInput
                passData={data => setQ3_4address(data)}
                ref={addressRef1}
                ph={'Address of business'}
              />

              <div style={{ marginTop: '15px' }}></div>

              <Card style={{ padding: '20px', textAlign: 'center' }}>
                State the following info for each <br></br>
                joint venturer
                <div style={{ marginTop: '15px' }}></div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q3_4jv_name}
                    required
                    onChange={e => setQ3_4jv_name(e.target.value)}
                  />
                  <label className={q3_4jv_name && 'filled'}>
                    {'Partner Name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ3_4jv_address(data)}
                  ref={addressRef2}
                  ph={'Partner Address'}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div style={{ display: 'flex', marginTop: '15px' }}>
                  <div
                    className='AddPerson'
                    style={{ color: 'white', margin: 'auto' }}
                    onClick={() => addPartner()}
                  >
                    <div>&nbsp;Add&nbsp;</div>
                    <AddIcon style={{ color: 'white' }}></AddIcon>
                  </div>
                </div>
                {q3_4jvs.length > 0 ? <hr></hr> : ''}
                {q3_4jvs.length > 0 ? (
                  <div>
                    {q3_4jvs.map((key, i) => {
                      return (
                        <Accordion
                          style={{
                            backgroundColor: 'white',
                            boxShadow: '0px 1px 3px #cccccc',
                            marginBottom: '4px'
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {q3_4jvs[i]['Name']}

                            <div style={{ marginLeft: 'auto' }}>
                              <div
                                className='RemButton'
                                onClick={() => remPartner(i)}
                              >
                                &nbsp;
                                <RemoveIcon></RemoveIcon>
                                Remove&nbsp;&nbsp;&nbsp;
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ maxWidth: '400px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '10px',
                                  borderBottomStyle: 'solid',
                                  borderWidth: '1px',
                                  borderBottomColor: '#dddddd'
                                }}
                              >
                                Name: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q3_4jvs[i]['Name']}
                                </div>
                              </div>
                              <div style={{ display: 'flex', padding: '10px' }}>
                                Address: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q3_4jvs[i]['Address']}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                  </div>
                ) : (
                  ''
                )}
              </Card>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ textAlign: 'center', width: '400px' }}>
                Has your joint venture used other names<br></br>
                within the past 10 years?
                <div style={{ marginTop: '15px' }}></div>
                {['radio'].map(type => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Yes'
                      name='hasprev'
                      type={type}
                      checked={q3_4has_prev}
                      onClick={() => setQ3_4has_prev(true)}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name='hasprev'
                      type={type}
                      checked={!q3_4has_prev}
                      onClick={() => setQ3_4has_prev(false)}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </div>
              {q3_4has_prev ? (
                <Card style={{ padding: '20px', textAlign: 'center' }}>
                  State each name your joint venture has used <br></br>
                  over the past 10 years. Add one at a time.
                  <div style={{ marginTop: '15px' }}></div>
                  <div className='input-container'>
                    <input
                      type='text'
                      value={q3_4prev_name}
                      required
                      onChange={e => setQ3_4prev_name(e.target.value)}
                    />
                    <label className={q3_4prev_name && 'filled'}>
                      {'Previous Name'}
                    </label>
                  </div>
                  <div style={{ marginTop: '15px' }}></div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ textAlign: 'center' }}>
                      From:
                      <div className='input-container'>
                        <input
                          type='date'
                          value={q3_4prev_from}
                          required
                          onChange={e => setQ3_4prev_from(e.target.value)}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: 'auto', textAlign: 'center' }}>
                      To:
                      <div className='input-container'>
                        <input
                          type='date'
                          value={q3_4prev_to}
                          required
                          onChange={e => setQ3_4prev_to(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '15px' }}></div>
                  <div style={{ display: 'flex', marginTop: '15px' }}>
                    <div
                      className='AddPerson'
                      style={{ color: 'white', margin: 'auto' }}
                      onClick={() => addPrev()}
                    >
                      <div>&nbsp;Add&nbsp;</div>
                      <AddIcon style={{ color: 'white' }}></AddIcon>
                    </div>
                  </div>
                  {q3_4prev.length > 0 ? <hr></hr> : ''}
                  {q3_4prev.length > 0 ? (
                    <div>
                      {q3_4prev.map((key, i) => {
                        return (
                          <Accordion
                            style={{
                              backgroundColor: 'white',
                              boxShadow: '0px 1px 3px #cccccc',
                              marginBottom: '4px'
                            }}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              {q3_4prev[i]['Name']}

                              <div style={{ marginLeft: 'auto' }}>
                                <div
                                  className='RemButton'
                                  onClick={() => remPrev(i)}
                                >
                                  &nbsp;
                                  <RemoveIcon></RemoveIcon>
                                  Remove&nbsp;&nbsp;&nbsp;
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div style={{ maxWidth: '400px' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    padding: '10px',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '1px',
                                    borderBottomColor: '#dddddd'
                                  }}
                                >
                                  Name: &nbsp;
                                  <div style={{ color: 'gray' }}>
                                    {q3_4prev[i]['Name']}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    padding: '10px',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '1px',
                                    borderBottomColor: '#dddddd'
                                  }}
                                >
                                  From: &nbsp;
                                  <div style={{ color: 'gray' }}>
                                    {q3_4prev[i]['FromDate']}
                                  </div>
                                </div>
                                <div
                                  style={{ display: 'flex', padding: '10px' }}
                                >
                                  To: &nbsp;
                                  <div style={{ color: 'gray' }}>
                                    {q3_4prev[i]['ToDate']}
                                  </div>
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        )
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </Card>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q3_4
