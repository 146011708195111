import React, { useState, useEffect } from 'react'
import { Form, Button, Card, Alert } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { UserAuth } from '../contexts/AuthContext'
import { db, setCustomUserClaims } from '../firebase'
import {
  doc,
  setDoc,
  getDocs,
  addDoc,
  getDoc,
  collection,
  updateDoc
} from 'firebase/firestore'
import PhoneInput from 'react-phone-input-2'
import { formatPhoneNumber } from 'react-phone-number-input'
import 'react-phone-input-2/lib/bootstrap.css'
import './Signup.css'
import './Signin.css'
import Creatable from 'react-select/creatable'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import { Fade } from '@mui/material'
import { PulseLoader } from 'react-spinners'
import '../Inputs/Inputs.css'
import ClearIcon from '@mui/icons-material/Clear'

export default function Signup() {
  const [loadedFirms, setLoadedFirms] = useState(false)
  const [dropInputVal, setDropInputVal] = useState('')
  const [firmPlaceholder, setfirmPlaceHolder] = useState('')
  const [firmList, setFirmList] = useState([])
  const [firmPrivateDict, setFirmPrivateDict] = useState({})
  const [selectedPrivateFirm, setSelectedPrivateFirm] = useState(false)
  const [disableSearch, setDisableSearch] = useState(false)
  const [searchInput, setSearchInput] = useState(false)
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [phone, setPhone] = useState('')
  const [fax, setFax] = useState('')
  const [firmName, setFirmName] = useState('')
  const [firmID, setFirmID] = useState('')
  const [firmSt, setFirmSt] = useState('')
  const [firmLoc, setFirmLoc] = useState('')
  const [firmZip, setFirmZip] = useState('')
  const [firmError, setFirmError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [error, setError] = useState('')
  const [passError, setPassError] = useState('')
  const [passConfErr, setPassConfErr] = useState('')
  const [createFirm, setCreateFirm] = useState(false)
  const [userCreatedNewFirm, setUserCreatedNewFirm] = useState(false)
  const [, setSuccesstext] = useState('')
  const [createLoading, setCreateLoading] = useState(false)
  const { createUser, logout } = UserAuth()
  const navigate = useNavigate()

  // Logout function
  const handleLogout = async () => {
    try {
      await logout()
      navigate('/signin')
    } catch (e) {}
  }

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()
    return cMonth + '/' + cDay + '/' + cYear
  }

  //Retrieve the firms in the database
  const loadFirms = async () => {
    let temp_dict = {}
    let temp_list = []
    const querySnapshot = await getDocs(collection(db, 'firms'))
    querySnapshot.forEach(doc => {
      let format_name = doc.data().name + ' - ' + doc.data().zip
      if (doc.data().isPrivate) {
        format_name = format_name + ' (private)'
      }
      temp_dict[doc.id] = doc.data().isPrivate
      temp_list.push({ value: doc.id, label: format_name })
    })

    setFirmList(temp_list)
    setFirmPrivateDict(temp_dict)
    setLoadedFirms(true)
  }
  //Load the firms on page load
  useEffect(() => {
    if (!loadedFirms) {
      loadFirms()
    }
    //Make sure password length is at least 6
    if (password.length > 5 && passError !== '') {
      setPassError('')
    } else if (passError === '' && password.length < 6 && password.length > 0) {
      setPassError('Must be at least 6 characters')
      setPasswordConfirm('')
    } else if (passError === '' && password.length === 0) {
      // console.log("X");
    } else if (password === '') {
      setPassError('')
    }

    if (passwordConfirm !== '' && password !== passwordConfirm) {
      setPassConfErr("Passwords don't match")
    } else {
      setPassConfErr('')
    }
  }, [loadedFirms, password, passError, passwordConfirm])

  //Process the input for the firm
  async function processFirm(opt, meta) {
    const action = meta.action
    if (action === 'create-option') {
      setFirmName(opt.label)
      setCreateFirm(true)
      setSelectedPrivateFirm('')
    } else if (action === 'select-option') {
      setFirmID(opt.value)
      if (firmPrivateDict[opt.value]) {
        setSelectedPrivateFirm(true)
      } else {
        setSelectedPrivateFirm(false)
      }
      setCreateFirm(false)
      setUserCreatedNewFirm(false)
    } else {
      setFirmID('')
      setCreateFirm('')
      setSelectedPrivateFirm('')
      setUserCreatedNewFirm(false)
    }
  }

  // If there's text in the dropdown, open it. Otherwise, keep it hidden
  function dropIsOpen(input) {
    setDropInputVal(input)
    if (input === '') {
      setSearchInput(false)
    } else if (searchInput === false && input !== '') {
      setSearchInput(true)
    }
  }

  function closeModal() {
    const val = ' '
    setDropInputVal(val)
    setCreateFirm(false)
    setFirmName('')
    setFirmSt('')
    setFirmZip('')
    setFirmLoc('')
    setFax('')
  }

  //If the firm is new, add it and set the parameters
  async function addFirm() {
    // Prevent creation of duplicate firms
    let firmExists = false
    const firmSnap = await getDocs(collection(db, 'firms'))
    firmSnap.forEach(doc => {
      let firmData = doc.data()
      if (firmData.name === firmName && firmData.zip === firmZip) {
        firmExists = true
      }
    })

    // Also check to make sure all fields were entered
    if (
      firmName === '' ||
      firmSt === '' ||
      firmLoc === '' ||
      fax === '' ||
      firmZip === ''
    ) {
      setFirmError('Please Enter Values in all Fields')
    } else if (firmExists) {
      setFirmError('Firm exists')
    } else {
      let formatted_name = firmName + ' - ' + firmZip
      setfirmPlaceHolder(formatted_name)

      setCreateFirm(false)
      setUserCreatedNewFirm(true)
      setDisableSearch(true)
      setSuccesstext('Firm Successfully Created')

      // fix autofill bug
      setFullName('')
      setEmail('')
      setPassword('')
      setPasswordConfirm('')
    }
  }
  //Handle the submission of the form
  async function handleSubmit(e) {
    e.preventDefault()
    setError('')
    try {
      if (password === passwordConfirm) {
        setCreateLoading(true)
        // Account creation based off of if the firm is new or not
        if (userCreatedNewFirm) {
          const docRef = await addDoc(collection(db, 'firms'), {
            name: firmName,
            street: firmSt,
            location: firmLoc,
            zip: firmZip,
            fax: formatPhoneNumber('+1' + fax),
            isPrivate: false
          })

          const newFirmID = docRef.id

          await createUser(email, password).then(async userdata => {
            let userid = userdata.user.uid
            await setDoc(doc(db, 'firmUsers', userid), {
              fullName: fullName,
              email: email,
              phone: formatPhoneNumber('+1' + phone),
              firmID: newFirmID
            })

            //link the user to the firm
            await setDoc(doc(db, 'firms', newFirmID, 'users', userid), {
              isAdmin: true
            })

            //set the user as firm creator
            await updateDoc(doc(db, 'firms', newFirmID), {
              creatorID: userid
            })

            // Set custom claims
            setCustomUserClaims({
              uid: userid,
              accountType: 'firmUser'
            })

            // Add to my activities collection
            await addDoc(collection(db, 'firmUsers', userid, 'activities'), {
              type: 'create',
              date: getTime(),
              content: 'Created Account'
            })
          })
        } else {
          await createUser(email, password).then(async userdata => {
            let userid = userdata.user.uid
            const firmSnap = await getDoc(doc(db, 'firms', firmID))
            const firmUsersSnap = await getDocs(
              collection(db, 'firms', firmID, 'users')
            )
            let firmAdmins = []
            firmUsersSnap.forEach(doc => {
              if (doc.data().isAdmin) {
                firmAdmins.push(doc.id)
              }
            })

            //link the user to the firm depending on privacy
            if (firmSnap.data().isPrivate) {
              await setDoc(doc(db, 'firmUsers', userid), {
                fullName: fullName,
                email: email,
                phone: formatPhoneNumber('+1' + phone),
                firmID: 'awaiting'
              })

              await setDoc(
                doc(db, 'firms', firmID, 'pending_users', userid),
                {}
              )

              // Notify the firm admins
              const nContent = 'A new user has requested to join your firm'
              firmAdmins.forEach(async adminUID => {
                await addDoc(
                  collection(db, 'firmUsers', adminUID, 'notifications'),
                  {
                    content: nContent,
                    time: getTime(),
                    link: 'firmsettings',
                    type: 'firm'
                  }
                )
              })
            } else {
              await setDoc(doc(db, 'firmUsers', userid), {
                fullName: fullName,
                email: email,
                phone: formatPhoneNumber('+1' + phone),
                firmID: firmID
              })

              await setDoc(doc(db, 'firms', firmID, 'users', userid), {
                isAdmin: false
              })

              // Notify the firm admins
              const nContent = 'A new user has joined your firm'
              firmAdmins.forEach(async adminUID => {
                await addDoc(
                  collection(db, 'firmUsers', adminUID, 'notifications'),
                  {
                    content: nContent,
                    time: getTime(),
                    link: 'firmsettings',
                    type: 'firm'
                  }
                )
              })
            }

            // Set custom claims
            setCustomUserClaims({
              uid: userid,
              accountType: 'firmUser'
            })

            // Add to my activities collection
            await addDoc(collection(db, 'firmUsers', userid, 'activities'), {
              type: 'account',
              date: getTime(),
              content: 'Created Account'
            })
          })
        }
        handleLogout()
      } else {
        setError('Passwords do not match')
      }
    } catch (e) {
      setError(e.message)
    }
  }

  if (!createLoading) {
    return (
      <Fade in={true} appear timeout={800}>
        <div>
          <Container
            className='d-flex
                justify-content-center'
            style={{ minHeight: '93vh' }}
          >
            <div className='w-100' style={{ maxWidth: '500px' }}>
              <Card border='white'>
                <Card.Body>
                  <h2 className='text-center mb-4'>Register</h2>
                  {error && <Alert variant='danger'>{error}</Alert>}
                  <Form onSubmit={handleSubmit}>
                    <div className='drop-input-container'>
                      <Creatable
                        isDisabled={disableSearch}
                        options={firmList}
                        inputValue={dropInputVal}
                        isClearable={true}
                        styles={{
                          control: base => ({ ...base, height: '42px' })
                        }}
                        placeholder={firmPlaceholder}
                        menuIsOpen={searchInput}
                        onInputChange={e => dropIsOpen(e)}
                        onChange={(opt, meta) => processFirm(opt, meta)}
                      />
                      <label className={firmID && 'filled'}>
                        {!userCreatedNewFirm ? 'Firm Name' : ''}
                      </label>
                      <Form.Text className='text-muted'>
                        {firmID === '' && !userCreatedNewFirm
                          ? ' If your firm is not registered within our system, type to create it.'
                          : ''}
                      </Form.Text>
                      {selectedPrivateFirm ? (
                        <div
                          style={{
                            'font-size': '18px',
                            color: '#c47a7b',
                            'text-align': 'center'
                          }}
                        >
                          <br></br>Since this firm is private, you will need to
                          await approval after you sign up
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <br></br>
                    <div className='input-container'>
                      <input
                        type='text'
                        value={fullName}
                        required
                        onChange={e => setFullName(e.target.value)}
                      />
                      <label className={fullName && 'filled'}>
                        {'First & Last Name'}
                      </label>
                    </div>
                    <br></br>
                    <div className='input-container'>
                      <input
                        type='email'
                        value={email}
                        required
                        onChange={e => setEmail(e.target.value)}
                      />
                      <label className={email && 'filled'}>{'Email'}</label>
                    </div>
                    <br></br>
                    <div className='input-container'>
                      <input
                        type='password'
                        value={password}
                        required
                        onChange={e => setPassword(e.target.value)}
                      />
                      <label className={password && 'filled'}>
                        {'Password'}
                      </label>
                    </div>
                    <div style={{ color: 'red' }}>{passError}</div>
                    <br></br>
                    <div className='input-container'>
                      <input
                        type='password'
                        value={passwordConfirm}
                        required
                        onChange={e => setPasswordConfirm(e.target.value)}
                      />
                      <label className={passwordConfirm && 'filled'}>
                        {'Confirm Password'}
                      </label>
                    </div>
                    <div style={{ color: 'red' }}>{passConfErr}</div>
                    <br></br>
                    <div className='phone-input-container'>
                      <PhoneInput
                        enableAreaCodes={false}
                        inputStyle={{
                          height: '44px',
                          width: '466px',
                          borderColor: 'rgb(214, 214, 214)'
                        }}
                        disableCountryCode
                        country={'us'}
                        onlyCountries={['us']}
                        value={phone}
                        placeholder=''
                        onChange={setPhone}
                      />

                      <label className={phone && 'filled'}>
                        {'Phone Number'}
                      </label>
                    </div>
                    <br></br>

                    <Button
                      className='w-100'
                      type='submit'
                      disabled={
                        (firmID === '' && !userCreatedNewFirm) ||
                        password.length < 6
                      }
                      variant='outline-success'
                      style={{
                        color: '#2CA874',
                        height: '42px',
                        background: 'white'
                      }}
                    >
                      <AppRegistrationIcon></AppRegistrationIcon> &nbsp;
                      Register
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
              <div className='w-100 text-center mt-2'>
                Already have an account?{' '}
                <Link to='/signin' className='underline'>
                  Log In
                </Link>
              </div>
            </div>
          </Container>
          {createFirm ? <div className='overlayP'></div> : ''}
          {createFirm ? (
            <Fade in={true} appear timeout={500}>
              <div
                className='PopContent'
                style={{ height: '540px', width: '500px' }}
              >
                <div className='Close' onClick={() => closeModal()}>
                  <ClearIcon></ClearIcon>
                </div>
                <Container
                  className='d-flex
                        justify-content-center'
                  style={{ minHeight: '90vh' }}
                >
                  <div className='PopCard'>
                    <div className='w-100' style={{ maxWidth: '600px' }}>
                      <Card style={{ borderColor: 'white' }}>
                        <Card.Body>
                          <h2 className='text-center mb-4'>Firm Info</h2>
                          {error && <Alert variant='danger'>{error}</Alert>}
                          <Form onSubmit={handleSubmit}>
                            <div className='input-container'>
                              <input
                                type='text'
                                value={firmName}
                                required
                                onChange={e => setFirmName(e.target.value)}
                              />
                              <label className={firmName && 'filled'}>
                                {'Firm name'}
                              </label>
                            </div>
                            <br></br>
                            <div className='input-container'>
                              <input
                                type='text'
                                value={firmSt}
                                required
                                onChange={e => setFirmSt(e.target.value)}
                              />
                              <label className={firmSt && 'filled'}>
                                {'Firm Street Address'}
                              </label>
                            </div>
                            <br></br>
                            <div className='input-container'>
                              <input
                                type='text'
                                value={firmLoc}
                                required
                                onChange={e => setFirmLoc(e.target.value)}
                              />
                              <label className={firmLoc && 'filled'}>
                                {'Firm City, State'}
                              </label>
                            </div>
                            <br></br>
                            <div className='input-container'>
                              <input
                                type='text'
                                value={firmZip}
                                required
                                onChange={e => setFirmZip(e.target.value)}
                              />
                              <label className={firmZip && 'filled'}>
                                {'Firm ZIP'}
                              </label>
                            </div>
                            <br></br>
                            <div className='phone-input-container'>
                              <PhoneInput
                                enableAreaCodes={false}
                                inputStyle={{
                                  height: '44px',
                                  width: '422px',
                                  borderColor: 'rgb(214, 214, 214)'
                                }}
                                disableCountryCode
                                country={'us'}
                                onlyCountries={['us']}
                                value={fax}
                                placeholder=''
                                onChange={setFax}
                              />

                              <label className={fax && 'filled'}>
                                {'Firm Fax'}
                              </label>
                            </div>
                            <br></br>
                            <Button
                              className='w-100'
                              variant='success'
                              style={{ height: '42px' }}
                              disabled={
                                firmName === '' ||
                                firmSt === '' ||
                                firmLoc === '' ||
                                fax === '' ||
                                firmZip === ''
                              }
                              onClick={() => addFirm()}
                            >
                              Add Firm and Continue Signup
                            </Button>
                            <Form.Text style={{ color: 'red' }}>
                              {firmError}
                            </Form.Text>
                          </Form>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Container>
              </div>
            </Fade>
          ) : (
            ''
          )}
        </div>
      </Fade>
    )
  } else if (createLoading) {
    return (
      <div>
        <div className='FirmLoading'>
          <PulseLoader width={200} color={'#3B8FF0'} />
        </div>
      </div>
    )
  }
}
