import React, { useState, useEffect, useCallback } from 'react'
import { Navigate } from 'react-router-dom'
import { UserAuth } from '../contexts/AuthContext'
import { doc, getDoc, collection, onSnapshot } from 'firebase/firestore'
import { db } from '../firebase'
import { useNavigate } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {
  const { user, logout } = UserAuth()
  const [myFirmName, setMyFirmName] = useState('')
  const navigate = useNavigate()

  const retrieveFirm = useCallback(async () => {
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    const firmName = userSnap.data().firmID
    setMyFirmName(firmName)
    onSnapshot(collection(db, 'firms', firmName, 'users'), async docs => {
      let firmUsersTemp = []
      docs.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        firmUsersTemp.push(doc.id)
      })

      if (firmUsersTemp.indexOf(user.uid) < 0 && firmName !== 'awaiting') {
        await logout()
        navigate('/')
      } else if (firmName === 'awaiting') {
        navigate('/pending_user')
      }
    })
  }, [logout, navigate, user.uid])

  const checkForUpdates = useCallback(async () => {
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    const userData = userSnap.data()
    const firmID = userData.firmID
    onSnapshot(doc(db, 'firms', firmID, 'users', user.uid), () => {
      retrieveFirm()
    })
  }, [retrieveFirm, user.uid])

  useEffect(() => {
    checkForUpdates()
  }, [checkForUpdates])

  if (!user) {
    return <Navigate to='/' />
  } else if (myFirmName !== 'awaiting' && myFirmName !== '') {
    return children
  }
}

export default ProtectedRoute
