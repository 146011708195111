import React, { useState, useEffect, useCallback } from 'react'
import './FirmSettings.css'
import {
  doc,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  collection,
  updateDoc,
  addDoc,
  onSnapshot
} from 'firebase/firestore'
import { db, deleteUser } from '../../../firebase'
import { Card } from 'react-bootstrap'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Form } from 'react-bootstrap'
import { Fade } from '@mui/material'
import { UserAuth } from '../../../contexts/AuthContext'
import { Dropdown } from 'react-bootstrap'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import PhoneInput from 'react-phone-input-2'
import { formatPhoneNumber } from 'react-phone-number-input'
import { PulseLoader } from 'react-spinners'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'

export default function FirmSettings() {
  const [displayState, setDisplayState] = useState(0)
  const [viewPending, setViewPending] = useState(false)

  const [firmData, setFirmData] = useState({})
  const [myFirmID, setMyFirmID] = useState('')

  const [userList, setUserList] = useState([])
  const [userInfoList, setUserInfoList] = useState({})

  const [pendingCount, setPendingCount] = useState(0)
  const [pendingList, setPendingList] = useState([])
  const [pendingInfoList, setPendingInfoList] = useState({})

  const [myEmail, setMyEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [creatorID, setCreatorID] = useState('')
  const [firmAdminList, setFirmAdminList] = useState([])
  const [editingFirmInfo, setEditingFirmInfo] = useState(false)

  const [firmName, setFirmName] = useState('')
  const [firmSt, setFirmSt] = useState('')
  const [firmLoc, setFirmLoc] = useState('')
  const [firmZip, setFirmZip] = useState('')
  const [firmFax, setFirmFax] = useState('')
  const [isPrivate, setIsPrivate] = useState('')

  const { user } = UserAuth()

  const getAndSetFirmData = useCallback(async () => {
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    const thisFirmID = userSnap.data().firmID
    setMyFirmID(thisFirmID)

    const firmSnap = await getDoc(doc(db, 'firms', thisFirmID))

    setFirmName(firmSnap.data().name)
    setFirmSt(firmSnap.data().street)
    setFirmLoc(firmSnap.data().location)
    setFirmZip(firmSnap.data().zip)
    setFirmFax(firmSnap.data().fax)
    setIsPrivate(firmSnap.data().isPrivate)

    setFirmData(firmSnap.data())
    setCreatorID(firmSnap.data().creatorID)
    setMyEmail(user.email)

    const sharedUsersRef = collection(db, 'firms', thisFirmID, 'users')
    const sharedUsersSnap = await getDocs(sharedUsersRef)
    let uidList = []
    let adminList = []
    sharedUsersSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      if (doc.id !== user.uid) {
        uidList.push(doc.id)
      } else {
        uidList = [user.uid].concat(uidList)
      }
      if (doc.data().isAdmin) {
        adminList.push(doc.id)
      }
    })

    if (firmSnap.data().isPrivate) {
      const pendingSnap = await getDocs(
        collection(db, 'firms', thisFirmID, 'pending_users')
      )
      const pendingList = []
      const pendingDict = {}
      pendingSnap.forEach(doc => {
        // doc.data() is never undefined for query doc snapshots
        pendingList.push(doc.id)
      })

      await Promise.all(
        pendingList.map(async uid => {
          const userRef = doc(db, 'firmUsers', uid)
          const myRef = await getDoc(userRef)
          const email = myRef.data().email
          const name = myRef.data().fullName

          pendingDict[uid] = [name, email]
        })
      )

      setPendingList(pendingList)
      setPendingCount(pendingList.length)
      setPendingInfoList(pendingDict)
    }

    let infoList = {}
    await Promise.all(
      uidList.map(async uid => {
        const userRef = doc(db, 'firmUsers', uid)
        const myRef = await getDoc(userRef)
        const email = myRef.data().email
        const name = myRef.data().fullName

        infoList[uid] = [name, email]
      })
    )

    setUserInfoList(infoList)
    setUserList(uidList)
    setFirmAdminList(adminList)

    setFirmData(firmSnap.data())
    setCreatorID(firmSnap.data().creatorID)

    if (adminList.indexOf(user.uid) !== -1) {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
      setDisplayState(1)
      return 1
    }

    setMyEmail(user.email)

    if (firmSnap.data().isPrivate) {
      setDisplayState(3)
    } else if (!firmSnap.data().isPrivate) {
      setDisplayState(2)
    }
  }, [user.email, user.uid])

  const checkForUpdates = useCallback(async () => {
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    const userData = userSnap.data()
    onSnapshot(doc(db, 'firms', userData.firmID), () => {
      getAndSetFirmData()
    })
    onSnapshot(collection(db, 'firms', userData.firmID, 'users'), () => {
      getAndSetFirmData()
    })
    onSnapshot(
      collection(db, 'firms', userData.firmID, 'pending_users'),
      () => {
        getAndSetFirmData()
      }
    )
  }, [getAndSetFirmData, user.uid])

  useEffect(() => {
    checkForUpdates()
  }, [checkForUpdates])

  async function removeUser(data) {
    const oldUserSnap = await getDoc(doc(db, 'firmUsers', data))
    const myOldContent = 'Removed ' + oldUserSnap.data().email + ' from firm'
    // Add to my activities collection
    await addDoc(collection(db, 'firmUsers', user.uid, 'activities'), {
      type: 'rem_party',
      date: getTime(),
      content: myOldContent
    })

    await deleteUser({
      uid: data
    })

    // then remove the user from the firm
    await deleteDoc(doc(db, 'firms', myFirmID, 'users', data))

    // then remove the user from all the matters which they are shared on
    const sharedMattersSnap = await getDocs(
      collection(db, 'firmUsers', data, 'shared_matters')
    )
    sharedMattersSnap.forEach(async matter => {
      await deleteDoc(doc(db, 'matters', matter.id, 'shared_users', data))
    })

    // finally delete the user from the firmUsers in firestore
    await deleteDoc(doc(db, 'firmUsers', data))

    //perhaps send an email that they were removed
    //for the future
  }

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()
    return cMonth + '/' + cDay + '/' + cYear
  }

  async function demote(data) {
    //remove the user from admin list
    await updateDoc(doc(db, 'firms', myFirmID, 'users', data), {
      isAdmin: false
    })
  }

  async function makeAdmin(data) {
    await updateDoc(doc(db, 'firms', myFirmID, 'users', data), {
      isAdmin: true
    })
  }

  async function updateFirm() {
    await updateDoc(doc(db, 'firms', myFirmID), {
      name: firmName,
      street: firmSt,
      location: firmLoc,
      zip: firmZip,
      fax: formatPhoneNumber('+1' + firmFax),
      isPrivate: isPrivate
    })
    setEditingFirmInfo(false)

    if (isPrivate === false) {
      const pendingSnap = await getDocs(
        collection(db, 'firms', myFirmID, 'pending_users')
      )
      const tempList = []

      pendingSnap.forEach(doc => {
        tempList.push(doc.id)
      })

      await Promise.all(
        tempList.map(async uid => {
          await acceptUser(uid)
        })
      )
    }

    const myCreateContent = 'Modified firm data'
    // Add to my activities collection
    await addDoc(collection(db, 'firmUsers', user.uid, 'activities'), {
      type: 'firm',
      date: getTime(),
      content: myCreateContent
    })
  }

  async function cancelEdit() {
    setEditingFirmInfo(false)
  }

  function togglePrivacy() {
    setIsPrivate(!isPrivate)
  }

  async function acceptUser(data) {
    // remove the user from pending list
    await deleteDoc(doc(db, 'firms', myFirmID, 'pending_users', data))

    // add the user to the firm
    await setDoc(doc(db, 'firms', myFirmID, 'users', data), {
      isAdmin: false
    })

    // update the user's firmID reference
    await updateDoc(doc(db, 'firmUsers', data), {
      firmID: myFirmID
    })

    const newUserSnap = await getDoc(doc(db, 'firmUsers', data))
    const myNewContent = 'Added ' + newUserSnap.data().email + ' to firm'
    // Add to my activities collection
    await addDoc(collection(db, 'firmUsers', user.uid, 'activities'), {
      type: 'add_party',
      date: getTime(),
      content: myNewContent
    })
  }

  async function denyUser(data) {
    const newUserSnap = await getDoc(doc(db, 'firmUsers', data))
    const myNewContent = 'Denied ' + newUserSnap.data().email + ' from firm'
    // Add to my activities collection
    await addDoc(collection(db, 'firmUsers', user.uid, 'activities'), {
      type: 'add_party',
      date: getTime(),
      content: myNewContent
    })

    await deleteUser({
      uid: data
    })

    await deleteDoc(doc(db, 'firms', myFirmID, 'pending_users', data))
    await deleteDoc(doc(db, 'firmUsers', data))
  }

  if (displayState === 0) {
    //loading
    return (
      <div>
        <div className='SignupLoading'>
          <PulseLoader width={200} color={'#6aa4e6'} />
        </div>
      </div>
    )
  } else if (displayState === 1) {
    //no access
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ margin: 'auto', paddingTop: '50px' }}>
          You must be a firm admin to access this page
        </div>
      </div>
    )
  } else if (displayState === 2) {
    //public firm
    return (
      <Fade in={true} appear timeout={300}>
        <div className='FirmSettingsPage'>
          <div className='EditFirm'>
            {!editingFirmInfo ? (
              <div style={{ display: 'flex' }}>
                <Card className='btmCard' style={{ height: '400px' }}>
                  <div className='AllTop'>
                    <div className='btmTop'>
                      <h5 className='text-center mb-4'>Firm Information</h5>
                      <div className='InfoEdit'>
                        <EditIcon
                          style={{ color: 'black' }}
                          onClick={() => setEditingFirmInfo(true)}
                        ></EditIcon>
                      </div>
                    </div>
                  </div>

                  <div className='infoRow'>
                    <div style={{ color: '#000000' }}>Firm Name:</div>&nbsp;{' '}
                    {firmData.name}
                  </div>
                  <div className='infoRow'>
                    <div style={{ color: '#000000' }}>Privacy:</div>&nbsp;{' '}
                    {firmData.isPrivate ? 'Private' : 'Public'}
                  </div>
                  <div className='infoRow'>
                    <div style={{ color: '#000000' }}>Street Address:</div>
                    &nbsp; {firmData.street}
                  </div>
                  <div className='infoRow'>
                    <div style={{ color: '#000000' }}>City, State:</div>&nbsp;{' '}
                    {firmData.location}
                  </div>
                  <div className='infoRow'>
                    <div style={{ color: '#000000' }}>Zip:</div>&nbsp;{' '}
                    {firmData.zip}
                  </div>
                  <div className='infoRowL'>
                    <div style={{ color: '#000000' }}>Fax:</div>&nbsp;{' '}
                    {firmData.fax}
                  </div>
                </Card>
                &nbsp;&nbsp;&nbsp;
                <Card style={{ margin: 'auto' }}>
                  <br></br>
                  <div className='topTop'>
                    <h5 className='text-center mb-4'>Current Firm Users</h5>
                  </div>
                  <div className='SharedList'>
                    {userList.map(val => {
                      return (
                        <Fade in={true} appear timeout={800}>
                          <div
                            className='SharedRowF'
                            style={
                              userInfoList[val][1] === myEmail
                                ? { backgroundColor: 'rgb(222, 239, 255)' }
                                : { backgroundColor: 'white' }
                            }
                          >
                            &nbsp;{' '}
                            {val === creatorID ? (
                              <AdminPanelSettingsIcon></AdminPanelSettingsIcon>
                            ) : (
                              <AccountCircleIcon></AccountCircleIcon>
                            )}
                            &nbsp; {userInfoList[val][0]}
                            &nbsp;
                            <t style={{ color: 'gray' }}>
                              {userInfoList[val][1]}
                            </t>
                            <t style={{ color: 'gray' }}>
                              {val === creatorID ? (
                                <t>&nbsp;{'(creator)'}</t>
                              ) : (
                                ''
                              )}
                            </t>
                            <t style={{ color: 'gray' }}>
                              {firmAdminList.indexOf(val) !== -1 &&
                              val !== creatorID ? (
                                <t>&nbsp;{'(admin)'}</t>
                              ) : (
                                ''
                              )}
                            </t>
                            {isAdmin &&
                            firmAdminList.indexOf(val) !== -1 &&
                            userInfoList[val][1] !== myEmail &&
                            val !== creatorID ? (
                              <div className='actions'>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    size='sm'
                                    id='dropdown-basic-button'
                                  >
                                    Actions
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => demote(val)}>
                                      Demote Admin
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            ) : isAdmin &&
                              userInfoList[val][1] !== myEmail &&
                              val !== creatorID ? (
                              <div className='actions'>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    size='sm'
                                    id='dropdown-basic-button'
                                  >
                                    Actions
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => removeUser(val)}
                                    >
                                      Remove User
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => makeAdmin(val)}
                                    >
                                      Make Admin
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </Fade>
                      )
                    })}
                  </div>
                  <br></br>
                </Card>
              </div>
            ) : (
              <Card className='editCard'>
                <br></br>
                <Form>
                  <Form.Label style={{ color: 'black' }}>Firm Name:</Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder={firmData.name}
                      type='text'
                      value={firmName}
                      onChange={e => setFirmName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Privacy:
                    <t style={{ color: 'gray' }}>
                      &nbsp;{'('}users will need to be approved into the firm
                      {')'}
                    </t>
                  </Form.Label>
                  <Form.Check
                    type='switch'
                    id='custom-switch'
                    label={isPrivate ? 'Private' : 'Public'}
                    onChange={() => togglePrivacy()}
                    defaultChecked={isPrivate}
                  />
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Street Address:
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder={firmData.street}
                      type='text'
                      value={firmSt}
                      onChange={e => setFirmSt(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    City, State:
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder={firmData.location}
                      type='text'
                      value={firmLoc}
                      onChange={e => setFirmLoc(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>Zip:</Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder={firmData.zip}
                      type='text'
                      value={firmZip}
                      onChange={e => setFirmZip(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>Fax:</Form.Label>
                  <Form.Group id='type'>
                    <PhoneInput
                      enableAreaCodes={false}
                      inputStyle={{
                        height: '40px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                        width: '406px'
                      }}
                      disableCountryCode
                      country={'us'}
                      onlyCountries={['us']}
                      placeholder={firmData.fax}
                      value={firmFax}
                      onChange={setFirmFax}
                    />
                  </Form.Group>
                  <br></br>
                  <div className='AllTop' style={{ display: 'flex' }}>
                    <div className='ModifyOpts' style={{ margin: 'auto' }}>
                      <div className='SaveBtn1' onClick={() => updateFirm()}>
                        <CheckIcon></CheckIcon>&nbsp;&nbsp;Save
                      </div>
                      &nbsp;&nbsp;
                      <div className='CancelBtn1' onClick={() => cancelEdit()}>
                        <CloseIcon></CloseIcon>&nbsp;Cancel
                      </div>
                    </div>
                  </div>
                  <br></br>
                </Form>
              </Card>
            )}
          </div>
        </div>
      </Fade>
    )
  } else if (displayState === 3) {
    // private firm
    return (
      <Fade in={true} appear timeout={300}>
        <div className='FirmSettingsPage'>
          <div className='EditFirm'>
            {!editingFirmInfo ? (
              <div style={{ display: 'flex' }}>
                <Card className='btmCard' style={{ height: '423px' }}>
                  <div className='AllTop'>
                    <div className='btmTop'>
                      <h5 className='text-center mb-4'>Firm Information</h5>
                      <div className='InfoEdit'>
                        <EditIcon
                          style={{ color: 'black' }}
                          onClick={() => setEditingFirmInfo(true)}
                        ></EditIcon>
                      </div>
                    </div>
                  </div>

                  <div className='infoRow'>
                    <div style={{ color: '#000000' }}>Firm Name:</div>&nbsp;{' '}
                    {firmData.name}
                  </div>
                  <div className='infoRow'>
                    <div style={{ color: '#000000' }}>Privacy:</div>&nbsp;{' '}
                    {firmData.isPrivate ? 'Private' : 'Public'}
                  </div>
                  <div className='infoRow'>
                    <div style={{ color: '#000000' }}>Street Address:</div>
                    &nbsp; {firmData.street}
                  </div>
                  <div className='infoRow'>
                    <div style={{ color: '#000000' }}>City, State:</div>&nbsp;{' '}
                    {firmData.location}
                  </div>
                  <div className='infoRow'>
                    <div style={{ color: '#000000' }}>Zip:</div>&nbsp;{' '}
                    {firmData.zip}
                  </div>
                  <div className='infoRowL'>
                    <div style={{ color: '#000000' }}>Fax:</div>&nbsp;{' '}
                    {firmData.fax}
                  </div>
                </Card>
                &nbsp;&nbsp;&nbsp;
                {viewPending ? (
                  <Card className='topCard' style={{ margin: 'auto' }}>
                    <br></br>
                    <div className='topTop'>
                      <div className='Priv'>
                        <h5 className='text-center mb-4'>Pending Firm Users</h5>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div
                          className='PendingButton'
                          onClick={() => setViewPending(false)}
                        >
                          <CheckIcon></CheckIcon>
                          &nbsp; Done
                        </div>
                      </div>
                    </div>
                    <div className='SharedList'>
                      {pendingList.map(val => {
                        return (
                          <Fade in={true} appear timeout={800}>
                            <div className='SharedRowF'>
                              &nbsp;{' '}
                              {val === creatorID ? (
                                <AdminPanelSettingsIcon></AdminPanelSettingsIcon>
                              ) : (
                                <AccountCircleIcon></AccountCircleIcon>
                              )}
                              &nbsp; {pendingInfoList[val][0]}
                              &nbsp;
                              <t style={{ color: 'gray' }}>
                                {pendingInfoList[val][1]}
                              </t>
                              <div className='actions'>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    size='sm'
                                    id='dropdown-basic-button'
                                  >
                                    Actions
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => acceptUser(val)}
                                    >
                                      Accept
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => denyUser(val)}
                                    >
                                      Deny
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Fade>
                        )
                      })}
                    </div>
                    <br></br>
                  </Card>
                ) : (
                  <Card className='topCard' style={{ margin: 'auto' }}>
                    <br></br>
                    <div className='topTop'>
                      <div className='Priv'>
                        <h5 className='text-center mb-4'>Current Firm Users</h5>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div
                          className='PendingButton'
                          onClick={() => setViewPending(true)}
                        >
                          <NotificationsNoneOutlinedIcon></NotificationsNoneOutlinedIcon>
                          &nbsp; Pending ({pendingCount}) &nbsp;
                        </div>
                      </div>
                    </div>
                    <div className='SharedList'>
                      {userList.map(val => {
                        return (
                          <Fade in={true} appear timeout={800}>
                            <div
                              className='SharedRowF'
                              style={
                                userInfoList[val][1] === myEmail
                                  ? { backgroundColor: 'rgb(222, 239, 255)' }
                                  : { backgroundColor: 'white' }
                              }
                            >
                              &nbsp;{' '}
                              {val === creatorID ? (
                                <AdminPanelSettingsIcon></AdminPanelSettingsIcon>
                              ) : (
                                <AccountCircleIcon></AccountCircleIcon>
                              )}
                              &nbsp; {userInfoList[val][0]}
                              &nbsp;
                              <t style={{ color: 'gray' }}>
                                {userInfoList[val][1]}
                              </t>
                              <t style={{ color: 'gray' }}>
                                {val === creatorID ? (
                                  <t>&nbsp;{'(creator)'}</t>
                                ) : (
                                  ''
                                )}
                              </t>
                              <t style={{ color: 'gray' }}>
                                {firmAdminList.indexOf(val) !== -1 &&
                                val !== creatorID ? (
                                  <t>&nbsp;{'(admin)'}</t>
                                ) : (
                                  ''
                                )}
                              </t>
                              {isAdmin &&
                              firmAdminList.indexOf(val) !== -1 &&
                              userInfoList[val][1] !== myEmail &&
                              val !== creatorID ? (
                                <div className='actions'>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      size='sm'
                                      id='dropdown-basic-button'
                                    >
                                      Actions
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => demote(val)}
                                      >
                                        Demote Admin
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              ) : isAdmin &&
                                userInfoList[val][1] !== myEmail &&
                                val !== creatorID ? (
                                <div className='actions'>
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      size='sm'
                                      id='dropdown-basic-button'
                                    >
                                      Actions
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        onClick={() => removeUser(val)}
                                      >
                                        Remove User
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => makeAdmin(val)}
                                      >
                                        Make Admin
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </Fade>
                        )
                      })}
                    </div>
                    <br></br>
                  </Card>
                )}
              </div>
            ) : (
              <Card className='editCard'>
                <div className='AllTop'>
                  <div className='ModifyOpts'>
                    <div className='InfoEdit' onClick={() => updateFirm()}>
                      &nbsp;&nbsp;Done&nbsp;&nbsp;<CheckIcon></CheckIcon>&nbsp;
                    </div>
                  </div>
                </div>
                <br></br>
                <Form>
                  <Form.Label style={{ color: 'black' }}>Firm Name:</Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder={firmData.name}
                      type='text'
                      value={firmName}
                      onChange={e => setFirmName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Privacy:
                    <t style={{ color: 'gray' }}>
                      &nbsp;{'('}users will need to be approved into the firm
                      {')'}
                    </t>
                  </Form.Label>
                  <Form.Check
                    type='switch'
                    id='custom-switch'
                    label={isPrivate ? 'Private' : 'Public'}
                    onChange={() => togglePrivacy()}
                    defaultChecked={isPrivate}
                  />
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Street Address:
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder={firmData.street}
                      type='text'
                      value={firmSt}
                      onChange={e => setFirmSt(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    City, State:
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder={firmData.location}
                      type='text'
                      value={firmLoc}
                      onChange={e => setFirmLoc(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>Zip:</Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder={firmData.zip}
                      type='text'
                      value={firmZip}
                      onChange={e => setFirmZip(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>Fax:</Form.Label>
                  <Form.Group id='type'>
                    <PhoneInput
                      enableAreaCodes={false}
                      inputStyle={{
                        height: '40px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                        width: '406px'
                      }}
                      disableCountryCode
                      country={'us'}
                      onlyCountries={['us']}
                      placeholder={firmData.fax}
                      value={firmFax}
                      onChange={setFirmFax}
                    />
                  </Form.Group>
                  {!isPrivate ? (
                    <div style={{ color: 'gray' }}>
                      <br></br>If you switch to public, all currently pending
                      users will be auto-accepted
                    </div>
                  ) : (
                    ''
                  )}
                  <br></br>
                </Form>
              </Card>
            )}
          </div>
        </div>
      </Fade>
    )
  }
}
