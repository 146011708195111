import React, { useState, useEffect } from 'react'
import pitchdeck from '../images/PITCHQ1_2023.pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import './PitchDeck.css'
import { Helmet } from 'react-helmet'
import '../Inputs/Inputs.css'
import { Button, FormControl, InputGroup } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { PulseLoader } from 'react-spinners'
import { Fade } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

export default function PitchDeck() {
  const [loading, setLoading] = useState(false)
  const [pageNum, setPageNum] = useState(1)
  const [unlocked, setUnlocked] = useState(false)
  const [password, setPassword] = useState('')
  const [err, setErr] = useState('')

  async function submitPass(password) {
    setLoading(true)
    if (password === '') {
      setErr('Enter a password')
    } else {
      const docRef = doc(db, 'pitchdeck', password)
      const docSnap = await getDoc(docRef)

      // user entered correct password
      if (docSnap.exists()) {
        localStorage.setItem('pitchPass', password)
        setUnlocked(true)
        setPassword('')
        const newUse = docSnap.data().useCount + 1
        await updateDoc(docRef, {
          useCount: newUse
        })
      } else {
        setErr('Invalid Password')
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (localStorage.getItem('pitchPass')) {
      submitPass(localStorage.getItem('pitchPass'))
    }
  }, [])

  function nextPage() {
    const newNum = pageNum + 1
    if (newNum < 28) {
      setPageNum(newNum)
    }
  }

  function backPage() {
    const newNum = pageNum + -1
    if (newNum > 0) {
      setPageNum(newNum)
    }
  }

  if (!unlocked) {
    return (
      <div style={{ display: 'flex' }}>
        <Helmet className='bg'>
          <style>{'body { background-color: black; }'}</style>
        </Helmet>

        <div className='passPrompt'>
          <div style={{ marginBottom: '30px' }}>Enter password to proceed</div>
          <InputGroup className='mb-3'>
            <FormControl
              value={password}
              placeholder={'Password'}
              type='password'
              onChange={e => setPassword(e.target.value)}
              style={{
                borderColor: 'rgb(110, 110, 110)',
                backgroundColor: 'inherit',
                height: '42px'
              }}
            />
            <Button
              variant='outline-secondary'
              id='button-addon2'
              onClick={() => submitPass(password)}
              style={{ borderColor: 'rgb(110, 110, 110)' }}
            >
              {loading ? <PulseLoader color='white' size='10' /> : 'Submit'}
            </Button>
          </InputGroup>
          <div style={{ color: 'red' }}>{err}</div>
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ display: 'flex' }}>
        <Helmet className='bg'>
          <style>{'body { background-color: black; }'}</style>
        </Helmet>
        <Fade in={true} appear timeout={1800}>
          <div style={{ margin: 'auto' }}>
            <Document
              className='PdfDoc'
              file={pitchdeck}
              onLoadError={console.error}
            >
              <Page pageNumber={pageNum} />
            </Document>
            <div className='nextPage' onClick={() => nextPage()}>
              <ArrowForwardIosIcon style={{ fontSize: '50px' }} />
            </div>

            <div className='lastPage' onClick={() => backPage()}>
              <ArrowBackIosIcon style={{ fontSize: '50px' }} />
            </div>
          </div>
        </Fade>
      </div>
    )
  }
}
