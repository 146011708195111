import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import './Intake.css'
import { useParams } from 'react-router'
import TopbarClient from '../../../../../../../Parts/TopbarClient'
import '../../../../../../../../Inputs/Inputs.css'
import { Button, Card } from 'react-bootstrap'
import { PulseLoader } from 'react-spinners'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import { db } from '../../../../../../../../firebase'
import Q1_1 from './Questions/Q1_1'
import Q2_1 from './Questions/Q2_1'
import GetFinished from './GetFinished'
import GetQOrder from './GetQOrder'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Navbar } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import '../../../../../../../Parts/TopbarIn.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ProgressBar from '@ramonak/react-progress-bar'
import DoneAllIcon from '@mui/icons-material/DoneAll'

export default function Intake(props) {
  //id is the matter id, props.pID is the plaintiff id
  const childCompRef = useRef()

  const componentList = useMemo(
    () => [
      <Q1_1 ref={childCompRef} pID={props.pID}></Q1_1>,
      <Q2_1 ref={childCompRef} pID={props.pID}></Q2_1>,
    ],
    []
  )

  const { id } = useParams()
  const [isMobile, setIsMobile] = useState(false)
  const [hasVerified, setHasVerified] = useState(true) //set to true when developing
  const [verifyInput, setVerifyInput] = useState('')
  const [verifyError, setVerifyError] = useState('')
  const [loadingVerify] = useState(false)
  const [currQuestion, setCurrQuestion] = useState(0)
  const [currIndex, setCurrIndex] = useState(0)
  const [hasFinished, setHasFinished] = useState(false)
  const [questionOrder, setQuestionOrder] = useState([])
  const [finishedQuestions, setFinishedQuestions] = useState([])
  const [progressNum, setProgressNum] = useState(0)

  async function saveAnswer() {
    await childCompRef.current.saveData()
    if (!questionOrder[currIndex + 1]) {
      setHasFinished(true);
      //insert code to update finished status in firebase
    } else {
      setCurrQuestion(questionOrder[currIndex + 1])
      setCurrIndex(currIndex + 1)
    }
  }

  function calcFinished(res, out) {
    setFinishedQuestions(res)
    let p = (res.length / out.length) * 100
    if (p > 100) {
      p = 100
    }
    setProgressNum(Math.round(p))
  }

  // Question skipping
  const updateIntakeData = useCallback(
    async (querySnapshot) => {
      const pSnap = await getDoc(doc(db, 'matters', id, 'plaintiffs', props.pID))
      const pData = pSnap.data()

      const out = await GetQOrder(componentList, pData, querySnapshot)
      setQuestionOrder(out)
      await GetFinished(id, props.pID).then(res => {
        calcFinished(res, out)
      })
    },
    [componentList, id, props.pID]
  )

  const getPlaintiffData = useCallback(async () => {
    const iSnap = await getDoc(
      doc(db, 'matters', id, 'plaintiffs', props.pID, 'forms', 'intake')
    )
    updateIntakeData(iSnap)
  }, [id, props.pID, updateIntakeData])

  useEffect(() => {
    getPlaintiffData()
    onSnapshot(
      doc(db, 'matters', id, 'plaintiffs', props.pID, 'forms', 'intake'),
      querySnapshot => {
        updateIntakeData(querySnapshot)
      }
    )
  }, [getPlaintiffData, id, props.pID, updateIntakeData])

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 840);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function back() {
    await childCompRef.current.saveData()
    if (currQuestion > 0) {
      setCurrQuestion(questionOrder[currIndex - 1])
      setCurrIndex(currIndex - 1)
    }
  }

  async function checkCode() {
    if (verifyInput !== '') {
      const pSnap = await getDoc(doc(db, 'matters', id, 'plaintiffs', props.pID))
      if (pSnap.data().verifyCode === verifyInput) {
        setHasVerified(true)
      } else {
        setVerifyInput('')
        setHasVerified(false)
        setVerifyError('Invalid Code')
      }
    } else {
      setVerifyError('')
      setHasVerified(false)
    }
  }

  async function sideQuestionSelect(key) {
    setHasFinished(false);
    if (key === currIndex) {
      return
    }
    if (!hasFinished) {
      await childCompRef.current.saveData()
    }
    setCurrQuestion(questionOrder[key])
    setCurrIndex(key)
  }

  const IntakeSidebar = () => {
    return (
      <div className='SidebarIntake'>
        <div className='SIList'>
            {questionOrder.map((val, key) => {
              return (
                <div
                  className={key === currIndex ? 'SIRowSelected' : 'SIRow'}
                  onClick={() => sideQuestionSelect(key)}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '30px'
                    }}
                  >
                    Question {key}
                  </div>
                  {finishedQuestions.indexOf(questionOrder[key]) !== -1 ? (
                    <div
                      style={{
                        marginLeft: 'auto',
                        marginRight: '30px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <CheckCircleIcon style={{ color: 'rgb(20, 180, 21)' }} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )
            })}
          </div>
      </div>
    )
  }

  return (
      <div>
        <div style={{ marginTop: '100px' }}></div>
        <div style={{ display: 'flex' }}>
          <div className='QuestionCard'>
            <div
              style={{
                marginTop: '13px',
                display: 'flex',
                borderBottomStyle: 'solid',
                borderColor: '#dddddd',
                borderWidth: '1px',
                paddingBottom: '13px'
              }}
            >
              {currIndex !== 0 ? (
                <div
                  className='TopButton'
                  style={{ marginLeft: '13px' }}
                  onClick={() => back()}
                >
                  <ArrowBackIosIcon /> &nbsp; Prev
                </div>
              )
            
              : (
                <div
                className='TopButtonDisabled'
                style={{ marginLeft: '13px' }}
                onClick={() => back()}
              >
                <ArrowBackIosIcon /> &nbsp; Prev
              </div>
              )}
              
              {finishedQuestions.indexOf(questionOrder[currIndex]) !== -1 ? (
                <div
                  style={{
                    margin: 'auto',
                    marginRight: '30px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div style={{ color: 'green', fontWeight: '500' }}>Done </div>{' '}
                  &nbsp;
                  <CheckCircleIcon
                    style={{ color: 'rgb(20, 180, 21)', fontSize: '30px' }}
                    size='30px'
                  />
                </div>
              ) : (
                ''
              )}
              <div
                className='TopButton'
                style={{ marginRight: '13px' }}
                onClick={() => saveAnswer()}
              >
                Next &nbsp; <ArrowForwardIosIcon />
              </div>
            </div>
            <div style={{ marginTop: '10px' }}></div>
            <div style={{ padding: '20px' }}>{componentList[currQuestion]}</div>
          </div>
        </div>
      </div>
    )
}
