import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Select from 'react-select'
import AddressInput from '../../../../Inputs/AddressInput'

export const Q20_11 = forwardRef((props, ref) => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q20_11, setQ20_11] = useState([])

  const [vehicleList, setVehicleList] = useState([])
  const [inputVal, setInputVal] = useState('')

  const [q20_11vehicle, setQ20_11vehicle] = useState('')
  const [q20_11name, setQ20_11name] = useState('')
  const [q20_11address, setQ20_11address] = useState('')
  const [q20_11phone, setQ20_11phone] = useState('')

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q20_11: q20_11
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q20_11) {
      setQ20_11(docSnap.data().q20_11)
    }

    if (docSnap.data().q20_2) {
      if (docSnap.data().q20_2 !== 'N/A') {
        let newList = []
        docSnap.data().q20_2.forEach(val => {
          const lab = val['Make'] + ', ' + val['License']
          newList.push({ label: lab, value: lab })
        })
        setVehicleList(newList)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addPerson() {
    if (q20_11vehicle !== '' && q20_11name !== '') {
      q20_11.push({
        Vehicle: q20_11vehicle,
        Name: q20_11name,
        Address: q20_11address,
        Phone: q20_11phone
      })

      setQ20_11vehicle('')
      setQ20_11name('')
      setQ20_11address('')
      setQ20_11phone('')

      setInputVal('')

      addressRef.current.clear()
    }
  }

  function remPerson(index) {
    let newList = []
    for (let i = 0; i < q20_11.length; i++) {
      if (i !== index) {
        newList.push(q20_11[i])
      }
    }
    setQ20_11(newList)
  }

  async function processSelect(opt, meta) {
    const action = meta.action
    if (action === 'select-option') {
      setQ20_11vehicle(opt['value'])
      setInputVal(opt)
    } else {
      setQ20_11vehicle('')
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          State the name, address, and phone number of each<br></br>
          owner and person who has had possession since the<br></br>
          incident of each vehicle involved in the incident:
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div>
          <div>
            <div className='drop-input-container'>
              <Select
                options={vehicleList}
                styles={{ control: base => ({ ...base, height: '42px' }) }}
                placeholder='Select vehicle'
                value={inputVal}
                onChange={(opt, meta) => processSelect(opt, meta)}
              />
            </div>
          </div>
          <div style={{ marginTop: '15px' }}></div>
          <div className='input-container'>
            <input
              type='text'
              value={q20_11name}
              required
              onChange={e => setQ20_11name(e.target.value)}
            />
            <label className={q20_11name && 'filled'}>{'Name'}</label>
          </div>

          <div style={{ marginTop: '15px' }}></div>
          <AddressInput
            passData={data => setQ20_11address(data)}
            ref={addressRef}
          />
          <div style={{ marginTop: '15px' }}></div>
          <div className='phone-input-container'>
            <PhoneInput
              enableAreaCodes={false}
              inputStyle={{
                height: '44px',
                width: 'inherit',
                borderColor: 'rgb(214, 214, 214)'
              }}
              disableCountryCode
              country={'us'}
              onlyCountries={['us']}
              value={q20_11phone}
              placeholder=''
              onChange={setQ20_11phone}
            />

            <label className={q20_11phone && 'filled'}>{'Phone Number'}</label>
          </div>

          <div style={{ display: 'flex', marginTop: '15px' }}>
            <div
              className='AddPerson'
              style={{ color: 'white', margin: 'auto' }}
              onClick={() => addPerson()}
            >
              <div>&nbsp;Add&nbsp;</div>
              <AddIcon style={{ color: 'white' }}></AddIcon>
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            <div style={{ textAlign: 'center', marginBottom: '15px' }}>
              {q20_11.length > 0 ? <hr></hr> : ''}
            </div>
            <div>
              {q20_11.map((key, i) => {
                return (
                  <Accordion
                    style={{
                      backgroundColor: 'white',
                      boxShadow: '0px 1px 3px #cccccc',
                      marginBottom: '4px'
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {q20_11[i]['Vehicle']}

                      <div style={{ marginLeft: 'auto' }}>
                        <div className='RemButton' onClick={() => remPerson(i)}>
                          &nbsp;
                          <RemoveIcon></RemoveIcon>
                          Remove&nbsp;&nbsp;&nbsp;
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ maxWidth: '400px' }}>
                        <div
                          style={{
                            display: 'flex',
                            padding: '10px',
                            borderBottomStyle: 'solid',
                            borderWidth: '1px',
                            borderBottomColor: '#dddddd'
                          }}
                        >
                          Vehicle: &nbsp;
                          <div style={{ color: 'gray' }}>
                            {q20_11[i]['Vehicle']}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            padding: '10px',
                            borderBottomStyle: 'solid',
                            borderWidth: '1px',
                            borderBottomColor: '#dddddd'
                          }}
                        >
                          Name: &nbsp;
                          <div style={{ color: 'gray' }}>
                            {q20_11[i]['Name']}
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            padding: '10px',
                            borderBottomStyle: 'solid',
                            borderWidth: '1px',
                            borderBottomColor: '#dddddd'
                          }}
                        >
                          Address: &nbsp;
                          <div style={{ color: 'gray' }}>
                            {q20_11[i]['Address']}
                          </div>
                        </div>
                        <div style={{ display: 'flex', padding: '10px' }}>
                          Phone: &nbsp;
                          <div style={{ color: 'gray' }}>
                            {q20_11[i]['Phone']}
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Q20_11
