import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q9_2 = forwardRef((props, ref) => {
  const addressRef = useRef()

  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q9_2, setQ9_2] = useState([])

  const [q9_2description, setQ9_2description] = useState('')

  const [q9_2people, setQ9_2people] = useState([])
  const [q9_2people_name, setQ9_2people_name] = useState('')
  const [q9_2people_address, setQ9_2people_address] = useState('')
  const [q9_2people_phone, setQ9_2people_phone] = useState('')

  const [hasDocs, setHasDocs] = useState(false)

  async function saveQuestion() {
    if (q9_2.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q9_2: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q9_2: q9_2
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    console.log(docSnap.data())
    if (docSnap.data().q9_2) {
      if (docSnap.data().q9_2 !== 'N/A') {
        setHasDocs(true)
        setQ9_2(docSnap.data().q9_2)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addItem() {
    if (q9_2description !== '') {
      let currList = q9_2
      currList.push({ Description: q9_2description, People: q9_2people })

      setQ9_2(currList)

      setQ9_2description('')
      setQ9_2people([])
    }
  }

  function remItem(index) {
    let newList = []
    for (let i = 0; i < q9_2.length; i++) {
      if (i !== index) {
        newList.push(q9_2[i])
      }
    }
    setQ9_2(newList)
  }

  function noDocs() {
    setHasDocs(false)
    setQ9_2([])
  }

  function addPeople() {
    if (q9_2people_name !== '') {
      let currList = q9_2people
      currList.push({
        Name: q9_2people_name,
        Address: q9_2people_address,
        Phone: q9_2people_phone
      })

      setQ9_2people(currList)
      setQ9_2people_name('')
      setQ9_2people_address('')
      setQ9_2people_phone('')

      addressRef.current.clear()
    }
  }

  function remPeople(index) {
    let newList = []
    for (let i = 0; i < q9_2people.length; i++) {
      if (i !== index) {
        newList.push(q9_2people[i])
      }
    }
    setQ9_2people(newList)
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Description: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Description']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              People: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['People'].map((key, j) => {
                  return <div>{props.dict['People'][j]['Name']}</div>
                })}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Description: &nbsp;
              <div style={{ color: 'gray' }}>
                <textarea
                  defaultValue={props.dict['Description']}
                  onChange={e => (props.dict['Description'] = e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Do any documents support the existence or amount of<br></br>
          any item of damages claimed in the previous question?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasDocs}
                onClick={() => setHasDocs(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasDocs}
                onClick={() => noDocs()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasDocs ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each document, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container' style={{ height: '100px' }}>
                <textarea
                  value={q9_2description}
                  style={{
                    height: '100px',
                    borderStyle: 'none',
                    resize: 'none',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingTop: '10px',
                    borderRadius: '5px'
                  }}
                  required
                  onChange={e => setQ9_2description(e.target.value)}
                />
                <label className={q9_2description && 'filled'}>
                  {'A description of the document'}
                </label>
              </div>

              <div style={{ marginTop: '15px' }}></div>

              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  For each person who has possession of this <br></br>document,
                  state:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q9_2people_name}
                    required
                    onChange={e => setQ9_2people_name(e.target.value)}
                  />
                  <label className={q9_2people_name && 'filled'}>
                    {'Their Name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ9_2people_address(data)}
                  ref={addressRef}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q9_2people_phone}
                    placeholder=''
                    onChange={setQ9_2people_phone}
                  />

                  <label className={q9_2people_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    className='AddPerson'
                    style={{ color: 'white', margin: 'auto' }}
                    onClick={() => addPeople()}
                  >
                    <div>&nbsp;Add&nbsp;</div>
                    <AddIcon style={{ color: 'white' }}></AddIcon>
                  </div>
                </div>
                {q9_2people.length > 0 ? <hr></hr> : ''}
                <div style={{ marginBottom: '-15px' }}>
                  {q9_2people.map((key, i) => {
                    return (
                      <Accordion
                        style={{
                          backgroundColor: 'white',
                          boxShadow: '0px 1px 3px #cccccc',
                          marginBottom: '4px'
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {q9_2people[i]['Name']}

                          <div style={{ marginLeft: 'auto' }}>
                            <div
                              className='RemButton'
                              onClick={() => remPeople(i)}
                            >
                              &nbsp;
                              <RemoveIcon></RemoveIcon>
                              Remove&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ maxWidth: '400px' }}>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Name: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q9_2people[i]['Name']}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Address: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q9_2people[i]['Address']}
                              </div>
                            </div>
                            <div style={{ display: 'flex', padding: '10px' }}>
                              Phone: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q9_2people[i]['Phone']}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </div>
              </Card>

              <div style={{ display: 'flex', marginTop: '35px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '160px' }}
                  onClick={() => addItem()}
                >
                  <div>&nbsp;Add Document&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q9_2.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q9_2.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {'Document ' + (i + 1)}

                        <div style={{ marginLeft: 'auto' }}>
                          <div className='RemButton' onClick={() => remItem(i)}>
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q9_2[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q9_2
