import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const Q6_2 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q6_2, setQ6_2] = useState([])

  const [q6_2injury, setQ6_2injury] = useState('')
  const [q6_2body, setQ6_2body] = useState('')

  const [q6_2hasInjury, setQ6_2hasInjury] = useState(false)

  async function saveQuestion() {
    if (!q6_2hasInjury) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_2: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_2: q6_2
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q6_2) {
      if (docSnap.data().q6_2 !== 'N/A') {
        setQ6_2(docSnap.data().q6_2)
        setQ6_2hasInjury(true)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addInjury() {
    if (q6_2injury !== '') {
      let currList = q6_2
      currList.push({ Injury: q6_2injury, BodyPart: q6_2body })

      setQ6_2(currList)
      setQ6_2injury('')
      setQ6_2body('')
    }
  }

  function remInjury(index) {
    let newList = []
    for (let i = 0; i < q6_2.length; i++) {
      if (i !== index) {
        newList.push(q6_2[i])
      }
    }
    setQ6_2(newList)
  }

  async function noInjuries() {
    setQ6_2hasInjury(false)
    setQ6_2([])
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q6_2: 'N/A'
      }
    )
  }

  async function saveTrue() {
    setQ6_2hasInjury(true)
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q6_2: []
      }
    )
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Do you attribute any physical, mental, or emotional<br></br>
          injuries to the incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q6_2hasInjury}
                onClick={() => saveTrue()}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q6_2hasInjury}
                onClick={() => noInjuries(false)}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        <div style={{ marginTop: '15px' }}></div>
        {q6_2hasInjury ? (
          <div>
            <div style={{ textAlign: 'center', width: '400px' }}>
              Identify each injury you attribute to the incident<br></br>
              and the area of your body affected:
              <div style={{ marginTop: '15px' }}></div>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q6_2injury}
                required
                onChange={e => setQ6_2injury(e.target.value)}
              />
              <label className={q6_2injury && 'filled'}>{'The Injury'}</label>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q6_2body}
                required
                onChange={e => setQ6_2body(e.target.value)}
              />
              <label className={q6_2body && 'filled'}>
                {'Body Part Affected'}
              </label>
            </div>

            <div style={{ marginTop: '15px' }}></div>
            <div style={{ display: 'flex', marginTop: '15px' }}>
              <div
                className='AddPerson'
                style={{ color: 'white', margin: 'auto' }}
                onClick={() => addInjury()}
              >
                <div>&nbsp;Add&nbsp;</div>
                <AddIcon style={{ color: 'white' }}></AddIcon>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q6_2.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q6_2.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q6_2[i]['Injury']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remInjury(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ maxWidth: '400px' }}>
                          <div
                            style={{
                              display: 'flex',
                              padding: '10px',
                              borderBottomStyle: 'solid',
                              borderWidth: '1px',
                              borderBottomColor: '#dddddd'
                            }}
                          >
                            Injury: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q6_2[i]['Injury']}
                            </div>
                          </div>
                          <div style={{ display: 'flex', padding: '10px' }}>
                            Body Part: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q6_2[i]['BodyPart']}
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q6_2
