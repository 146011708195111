import React from 'react'
import NotesIcon from '@mui/icons-material/Notes'
import BusinessIcon from '@mui/icons-material/Business'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import TurnedInNotOutlinedIcon from '@mui/icons-material/TurnedInNotOutlined'

export const SidebarHomeData = [
  {
    title: 'My Matters',
    icon: <NotesIcon />,
    link: ''
  },
  {
    title: 'Shared',
    icon: <PeopleAltOutlinedIcon />,
    link: 'shared_matters'
  },
  {
    title: 'Firm matters',
    icon: <BusinessIcon />,
    link: 'firm_matters'
  },
  {
    title: 'Recent',
    icon: <AccessTimeOutlinedIcon />,
    link: 'recent_matters'
  },
  {
    title: 'Tagged',
    icon: <TurnedInNotOutlinedIcon />,
    link: 'tagged_matters'
  }
]
