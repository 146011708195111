import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Form } from 'react-bootstrap'
import AddressInput from '../../../../Inputs/AddressInput'
import PhoneInput from 'react-phone-input-2'

export const Q2_11 = forwardRef((props, ref) => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  // Question 2_2
  const [q2_11bool, setQ2_11bool] = useState(false)
  const [q2_11a, setQ2_11a] = useState('')
  const [q2_11b, setQ2_11b] = useState('')
  const [q2_11c, setQ2_11c] = useState('')
  const [q2_11d, setQ2_11d] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [filled, setFilled] = useState(false)

  async function saveQuestion() {
    if (q2_11bool) {
      let ans = {
        Name: q2_11a,
        Address: q2_11b,
        Phone: q2_11c,
        Description: q2_11d
      }
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_11: ans
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_11: 'N/A'
        }
      )
    }
  }

  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  function clearFields() {
    setQ2_11a('')
    setQ2_11b('')
    setQ2_11c('')
    setQ2_11d('')
    setQ2_11bool(false)
    addressRef.current.clear()
  }

  const getAndSetIntakeData = useCallback(async () => {
    setLoaded(true)
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q2_11) {
      if (docSnap.data().q2_11 !== 'N/A') {
        setQ2_11bool(true)
        setQ2_11a(docSnap.data().q2_11['Name'])
        setQ2_11b(docSnap.data().q2_11['Address'])
        setQ2_11c(docSnap.data().q2_11['Phone'])
        setQ2_11d(docSnap.data().q2_11['Description'])
      } else {
        setQ2_11bool(false)
      }
    }
  }, [m_id, p_id])

  const fillAddress = useCallback(async () => {
    setFilled(true)
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    addressRef.current.set(docSnap.data().q2_11['Address'])
  }, [m_id, p_id])

  useEffect(() => {
    if (!loaded) {
      getAndSetIntakeData()
    }
    if (addressRef.current && !filled) {
      fillAddress()
    }
  }, [fillAddress, filled, getAndSetIntakeData, loaded])

  return (
    <div>
      <div style={{ textAlign: 'center', maxWidth: '400px', margin: 'auto' }}>
        At the time of the incident, were you acting as an employee or agent for
        any person?
      </div>
      <br></br>
      <div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q2_11bool}
                onClick={() => setQ2_11bool(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q2_11bool}
                onClick={() => clearFields()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
      </div>
      {q2_11bool ? (
        <div
          style={{
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div style={{ marginTop: '15px' }}></div>
          <div>If so, state:</div>
          <div style={{ marginTop: '15px' }}></div>
          <div className='input-container'>
            <input
              type='text'
              value={q2_11a}
              required
              onChange={e => setQ2_11a(e.target.value)}
            />
            <label className={q2_11a && 'filled'}>{"The person's name"}</label>
          </div>
          <div style={{ marginTop: '15px' }}></div>

          <AddressInput passData={data => setQ2_11b(data)} ref={addressRef} />

          <div style={{ marginTop: '15px' }}></div>
          <div className='phone-input-container' style={{ margin: 'auto' }}>
            <PhoneInput
              enableAreaCodes={false}
              inputStyle={{
                height: '44px',
                width: 'inherit',
                borderColor: 'rgb(214, 214, 214)'
              }}
              disableCountryCode
              country={'us'}
              onlyCountries={['us']}
              value={q2_11c}
              placeholder=''
              onChange={setQ2_11c}
            />

            <label className={q2_11c && 'filled'}>{'Their Phone Number'}</label>
          </div>
          <div style={{ marginTop: '15px' }}></div>
          <div className='input-container'>
            <input
              type='text'
              value={q2_11d}
              required
              onChange={e => setQ2_11d(e.target.value)}
            />
            <label className={q2_11d && 'filled'}>
              {'A description of your duties'}
            </label>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
})

export default Q2_11
