import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q20_2 = forwardRef((props, ref) => {
  const addressRef1 = useRef()
  const addressRef2 = useRef()
  const addressRef3 = useRef()
  const addressRef4 = useRef()
  const addressRef5 = useRef()
  const addressRef6 = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q20_2, setQ20_2] = useState([])

  const [q20_2year, setQ20_2year] = useState('')
  const [q20_2make, setQ20_2make] = useState('')
  const [q20_2model, setQ20_2model] = useState('')
  const [q20_2lnum, setQ20_2lnum] = useState('')

  const [q20_2driver_name, setQ20_2driver_name] = useState('')
  const [q20_2driver_address, setQ20_2driver_address] = useState('')
  const [q20_2driver_phone, setQ20_2driver_phone] = useState('')

  const [hadOtherOccupants, setHadOtherOccupants] = useState(false)
  const [q20_2occupants, setQ20_2occupants] = useState([])
  const [q20_2occupant_name, setQ20_2occupant_name] = useState('')
  const [q20_2occupant_address, setQ20_2occupant_address] = useState('')
  const [q20_2occupant_phone, setQ20_2occupant_phone] = useState('')

  const [q20_2reg_owners, setQ20_2reg_owners] = useState([])
  const [q20_2reg_owner_name, setQ20_2reg_owner_name] = useState('')
  const [q20_2reg_owner_address, setQ20_2reg_owner_address] = useState('')
  const [q20_2reg_owner_phone, setQ20_2reg_owner_phone] = useState('')

  const [q20_2lessees, setQ20_2lessees] = useState([])
  const [q20_2lessee_name, setQ20_2lessee_name] = useState('')
  const [q20_2lessee_address, setQ20_2lessee_address] = useState('')
  const [q20_2lessee_phone, setQ20_2lessee_phone] = useState('')

  const [areOtherOwners, setAreOtherOwners] = useState(false)
  const [q20_2other_owners, setQ20_2other_owners] = useState([])
  const [q20_2other_owner_name, setQ20_2other_owner_name] = useState('')
  const [q20_2other_owner_address, setQ20_2other_owner_address] = useState('')
  const [q20_2other_owner_phone, setQ20_2other_owner_phone] = useState('')

  const [gavePerm, setGavePerm] = useState(false)
  const [q20_2perm_owners, setQ20_2perm_owners] = useState([])
  const [q20_2perm_owner_name, setQ20_2perm_owner_name] = useState('')
  const [q20_2perm_owner_address, setQ20_2perm_owner_address] = useState('')
  const [q20_2perm_owner_phone, setQ20_2perm_owner_phone] = useState('')

  const [wasVehicleAccident, setWasVehicleAccident] = useState(false)

  async function saveQuestion() {
    if (!wasVehicleAccident) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q20_2: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q20_2: q20_2
        }
      )
    }
  }

  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q20_2) {
      if (docSnap.data().q20_2 !== 'N/A') {
        setWasVehicleAccident(true)
        setQ20_2(docSnap.data().q20_2)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addVehicle() {
    if (q20_2make !== '') {
      let currList = q20_2
      currList.push({
        Year: q20_2year,
        Make: q20_2make,
        Model: q20_2model,
        License: q20_2lnum,
        DriverName: q20_2driver_name,
        DriverAddress: q20_2driver_address,
        DriverPhone: q20_2driver_phone,
        Occupants: q20_2occupants,
        RegisteredOwners: q20_2reg_owners,
        Lessees: q20_2lessees,
        OtherOwners: q20_2other_owners,
        Permission: q20_2perm_owners
      })

      setQ20_2(currList)

      setQ20_2year('')
      setQ20_2make('')
      setQ20_2model('')
      setQ20_2lnum('')

      setQ20_2driver_name('')
      setQ20_2driver_address('')
      setQ20_2driver_phone('')

      setHadOtherOccupants(false)
      setQ20_2occupants([])
      setQ20_2occupant_name('')
      setQ20_2occupant_address('')
      setQ20_2occupant_phone('')

      setQ20_2reg_owners([])
      setQ20_2reg_owner_name('')
      setQ20_2reg_owner_address('')
      setQ20_2reg_owner_phone('')

      setQ20_2lessees([])
      setQ20_2lessee_name('')
      setQ20_2lessee_address('')
      setQ20_2lessee_phone('')

      setAreOtherOwners(false)
      setQ20_2other_owners('')
      setQ20_2other_owner_name('')
      setQ20_2other_owner_address('')
      setQ20_2other_owner_phone('')

      setGavePerm(false)
      setQ20_2perm_owners([])
      setQ20_2perm_owner_name('')
      setQ20_2perm_owner_address('')
      setQ20_2perm_owner_phone('')

      addressRef1.current.clear()
      addressRef2.current.clear()
      addressRef3.current.clear()
      addressRef4.current.clear()
      addressRef5.current.clear()
      addressRef6.current.clear()
    }
  }

  function remVehicle(index) {
    let newList = []
    for (let i = 0; i < q20_2.length; i++) {
      if (i !== index) {
        newList.push(q20_2[i])
      }
    }
    setQ20_2(newList)
  }

  async function notVehicleAccident() {
    setWasVehicleAccident(false)
    setQ20_2([])
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q20_2: 'N/A'
      }
    )
  }

  async function saveTrue() {
    setWasVehicleAccident(true)
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q20_2: []
      }
    )
  }

  function addOccupant() {
    if (q20_2occupant_name !== '') {
      let currList = q20_2occupants
      currList.push({
        Name: q20_2occupant_name,
        Address: q20_2occupant_address,
        Phone: q20_2occupant_phone
      })

      setQ20_2occupants(currList)
      setQ20_2occupant_name('')
      setQ20_2occupant_address('')
      setQ20_2occupant_phone('')

      addressRef2.current.clear()
    }
  }

  function remOccupant(index) {
    let newList = []
    for (let i = 0; i < q20_2occupants.length; i++) {
      if (i !== index) {
        newList.push(q20_2occupants[i])
      }
    }
    setQ20_2occupants(newList)
  }

  function noOccupants() {
    setHadOtherOccupants(false)
    setQ20_2occupants([])
  }

  function addRegOwner() {
    if (q20_2reg_owner_name !== '') {
      let currList = q20_2reg_owners
      currList.push({
        Name: q20_2reg_owner_name,
        Address: q20_2reg_owner_address,
        Phone: q20_2reg_owner_phone
      })

      setQ20_2reg_owners(currList)
      setQ20_2reg_owner_name('')
      setQ20_2reg_owner_address('')
      setQ20_2reg_owner_phone('')

      addressRef3.current.clear()
    }
  }

  function remRegOwner(index) {
    let newList = []
    for (let i = 0; i < q20_2reg_owners.length; i++) {
      if (i !== index) {
        newList.push(q20_2reg_owners[i])
      }
    }
    setQ20_2reg_owners(newList)
  }

  function addLessee() {
    if (q20_2lessee_name !== '') {
      let currList = q20_2lessees
      currList.push({
        Name: q20_2lessee_name,
        Address: q20_2lessee_address,
        Phone: q20_2lessee_phone
      })

      setQ20_2lessees(currList)
      setQ20_2lessee_name('')
      setQ20_2lessee_address('')
      setQ20_2lessee_phone('')

      addressRef4.current.clear()
    }
  }

  function remLessee(index) {
    let newList = []
    for (let i = 0; i < q20_2lessees.length; i++) {
      if (i !== index) {
        newList.push(q20_2lessees[i])
      }
    }
    setQ20_2lessees(newList)
  }

  function addOtherOwner() {
    if (q20_2other_owner_name !== '') {
      let currList = q20_2other_owners
      currList.push({
        Name: q20_2other_owner_name,
        Address: q20_2other_owner_address,
        Phone: q20_2other_owner_phone
      })

      setQ20_2other_owners(currList)
      setQ20_2other_owner_name('')
      setQ20_2other_owner_address('')
      setQ20_2other_owner_phone('')

      addressRef5.current.clear()
    }
  }

  function remOtherOwner(index) {
    let newList = []
    for (let i = 0; i < q20_2other_owners.length; i++) {
      if (i !== index) {
        newList.push(q20_2other_owners[i])
      }
    }
    setQ20_2other_owners(newList)
  }

  function noOtherOwners() {
    setAreOtherOwners(false)
    setQ20_2other_owners([])
  }

  function addPermOwner() {
    if (q20_2perm_owner_name !== '') {
      let currList = q20_2perm_owners
      currList.push({
        Name: q20_2perm_owner_name,
        Address: q20_2perm_owner_address,
        Phone: q20_2perm_owner_phone
      })

      setQ20_2perm_owners(currList)
      setQ20_2perm_owner_name('')
      setQ20_2perm_owner_address('')
      setQ20_2perm_owner_phone('')

      addressRef6.current.clear()
    }
  }

  function remPermOwner(index) {
    let newList = []
    for (let i = 0; i < q20_2perm_owners.length; i++) {
      if (i !== index) {
        newList.push(q20_2perm_owners[i])
      }
    }
    setQ20_2perm_owners(newList)
  }

  function noPerm() {
    setGavePerm(false)
    setQ20_2perm_owners([])
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Year: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Year']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Make: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Make']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Model: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Model']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              License: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['License']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Driver Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['DriverName']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Driver Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['DriverAddress']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Driver Phone: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['DriverPhone']}</div>
            </div>

            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Occupants: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['Occupants'] ? (
                  <div>
                    {props.dict['Occupants'].map((key, j) => {
                      return <div>{props.dict['Occupants'][j]['Name']}</div>
                    })}{' '}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Registered Owners: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['RegisteredOwners'] ? (
                  <div>
                    {props.dict['RegisteredOwners'].map((key, j) => {
                      return (
                        <div>{props.dict['RegisteredOwners'][j]['Name']}</div>
                      )
                    })}{' '}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Lessees: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['Lessees'] ? (
                  <div>
                    {props.dict['Lessees'].map((key, j) => {
                      return <div>{props.dict['Lessees'][j]['Name']}</div>
                    })}{' '}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Other Owners: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['OtherOwners'] ? (
                  <div>
                    {props.dict['OtherOwners'].map((key, j) => {
                      return <div>{props.dict['OtherOwners'][j]['Name']}</div>
                    })}{' '}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            <div style={{ display: 'flex', padding: '10px' }}>
              Permission: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['Permission'] ? (
                  <div>
                    {props.dict['Permission'].map((key, j) => {
                      return <div>{props.dict['Permission'][j]['Name']}</div>
                    })}{' '}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Year: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Year']}
                  onChange={e => (props.dict['Year'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Make: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Make']}
                  onChange={e => (props.dict['Make'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Model: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Model']}
                  onChange={e => (props.dict['Model'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              License: &nbsp;
              <div style={{ color: 'gray' }}>
                <textarea
                  defaultValue={props.dict['License']}
                  onChange={e => (props.dict['License'] = e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Driver Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['DriverName']}
                  onChange={e => (props.dict['DriverName'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Driver Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['DriverAddress']}
                  onChange={e => (props.dict['DriverAddress'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Driver Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['DriverPhone']}
                  onChange={e => (props.dict['DriverPhone'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Was the incident a motor vehicle accident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={wasVehicleAccident}
                onClick={() => saveTrue()}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!wasVehicleAccident}
                onClick={() => notVehicleAccident()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {wasVehicleAccident ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each vehicle involved, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q20_2year}
                  required
                  onChange={e => setQ20_2year(e.target.value)}
                />
                <label className={q20_2year && 'filled'}>{'The year'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q20_2make}
                  required
                  onChange={e => setQ20_2make(e.target.value)}
                />
                <label className={q20_2make && 'filled'}>{'The make'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q20_2model}
                  required
                  onChange={e => setQ20_2model(e.target.value)}
                />
                <label className={q20_2model && 'filled'}>{'The model'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q20_2lnum}
                  required
                  onChange={e => setQ20_2lnum(e.target.value)}
                />
                <label className={q20_2lnum && 'filled'}>
                  {'License Number'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ textAlign: 'center' }}>
                Answer the following info about the driver<br></br>
                of this vehicle during the incident:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q20_2driver_name}
                  required
                  onChange={e => setQ20_2driver_name(e.target.value)}
                />
                <label className={q20_2driver_name && 'filled'}>
                  {"Driver's name"}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <AddressInput
                passData={data => setQ20_2driver_address(data)}
                ref={addressRef1}
                ph={"Driver's Address"}
              />
              <div style={{ marginTop: '15px' }}></div>
              <div className='phone-input-container'>
                <PhoneInput
                  enableAreaCodes={false}
                  inputStyle={{
                    height: '44px',
                    width: 'inherit',
                    borderColor: 'rgb(214, 214, 214)'
                  }}
                  disableCountryCode
                  country={'us'}
                  onlyCountries={['us']}
                  value={q20_2driver_phone}
                  placeholder=''
                  onChange={setQ20_2driver_phone}
                />

                <label className={q20_2driver_phone && 'filled'}>
                  {"Driver's Phone Number"}
                </label>
              </div>

              <div style={{ marginTop: '15px' }}></div>
              <div style={{ textAlign: 'center' }}>
                Were there other occupants in the car during<br></br>
                the incident?
                <div style={{ marginTop: '15px' }}></div>
                {['radio'].map(type => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Yes'
                      name='group13'
                      type={type}
                      checked={hadOtherOccupants}
                      onClick={() => setHadOtherOccupants(true)}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name='group13'
                      type={type}
                      checked={!hadOtherOccupants}
                      onClick={() => noOccupants()}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </div>

              <div style={{ marginTop: '15px' }}></div>
              {hadOtherOccupants ? (
                <div>
                  <Card style={{ padding: '20px', textAlign: 'center' }}>
                    <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                      For each occupant in the car other than the<br></br>
                      driver during the incident, state the following:
                    </div>
                    <div className='input-container'>
                      <input
                        type='text'
                        value={q20_2occupant_name}
                        required
                        onChange={e => setQ20_2occupant_name(e.target.value)}
                      />
                      <label className={q20_2occupant_name && 'filled'}>
                        {'The name'}
                      </label>
                    </div>
                    <div style={{ marginTop: '15px' }}></div>
                    <AddressInput
                      passData={data => setQ20_2occupant_address(data)}
                      ref={addressRef2}
                    />
                    <div style={{ marginTop: '15px' }}></div>
                    <div className='phone-input-container'>
                      <PhoneInput
                        enableAreaCodes={false}
                        inputStyle={{
                          height: '44px',
                          width: 'inherit',
                          borderColor: 'rgb(214, 214, 214)'
                        }}
                        disableCountryCode
                        country={'us'}
                        onlyCountries={['us']}
                        value={q20_2occupant_phone}
                        placeholder=''
                        onChange={setQ20_2occupant_phone}
                      />

                      <label className={q20_2occupant_phone && 'filled'}>
                        {'Phone Number'}
                      </label>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        marginTop: '15px',
                        marginBottom: '10px'
                      }}
                    >
                      <div
                        className='AddPerson'
                        style={{ color: 'white', margin: 'auto' }}
                        onClick={() => addOccupant()}
                      >
                        <div>&nbsp;Add&nbsp;</div>
                        <AddIcon style={{ color: 'white' }}></AddIcon>
                      </div>
                    </div>
                    {q20_2occupants.length > 0 ? <hr></hr> : ''}
                    <div style={{ marginBottom: '-15px' }}>
                      {q20_2occupants.map((key, i) => {
                        return (
                          <Accordion
                            style={{
                              backgroundColor: 'white',
                              boxShadow: '0px 1px 3px #cccccc',
                              marginBottom: '4px'
                            }}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              {q20_2occupants[i]['Name']}

                              <div style={{ marginLeft: 'auto' }}>
                                <div
                                  className='RemButton'
                                  onClick={() => remOccupant(i)}
                                >
                                  &nbsp;
                                  <RemoveIcon></RemoveIcon>
                                  Remove&nbsp;&nbsp;&nbsp;
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div style={{ maxWidth: '400px' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    padding: '10px',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '1px',
                                    borderBottomColor: '#dddddd'
                                  }}
                                >
                                  Name: &nbsp;
                                  <div style={{ color: 'gray' }}>
                                    {q20_2occupants[i]['Name']}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    padding: '10px',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '1px',
                                    borderBottomColor: '#dddddd'
                                  }}
                                >
                                  Address: &nbsp;
                                  <div style={{ color: 'gray' }}>
                                    {q20_2occupants[i]['Address']}
                                  </div>
                                </div>
                                <div
                                  style={{ display: 'flex', padding: '10px' }}
                                >
                                  Phone: &nbsp;
                                  <div style={{ color: 'gray' }}>
                                    {q20_2occupants[i]['Phone']}
                                  </div>
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        )
                      })}
                    </div>
                  </Card>
                  <div style={{ marginTop: '15px' }}></div>
                </div>
              ) : (
                ''
              )}

              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  For each registered owner of the vehicle, state<br></br>
                  the following:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q20_2reg_owner_name}
                    required
                    onChange={e => setQ20_2reg_owner_name(e.target.value)}
                  />
                  <label className={q20_2reg_owner_name && 'filled'}>
                    {'The name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ20_2reg_owner_address(data)}
                  ref={addressRef3}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q20_2reg_owner_phone}
                    placeholder=''
                    onChange={setQ20_2reg_owner_phone}
                  />

                  <label className={q20_2reg_owner_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    className='AddPerson'
                    style={{ color: 'white', margin: 'auto' }}
                    onClick={() => addRegOwner()}
                  >
                    <div>&nbsp;Add&nbsp;</div>
                    <AddIcon style={{ color: 'white' }}></AddIcon>
                  </div>
                </div>
                {q20_2reg_owners.length > 0 ? <hr></hr> : ''}
                <div style={{ marginBottom: '-15px' }}>
                  {q20_2reg_owners.map((key, i) => {
                    return (
                      <Accordion
                        style={{
                          backgroundColor: 'white',
                          boxShadow: '0px 1px 3px #cccccc',
                          marginBottom: '4px'
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {q20_2reg_owners[i]['Name']}

                          <div style={{ marginLeft: 'auto' }}>
                            <div
                              className='RemButton'
                              onClick={() => remRegOwner(i)}
                            >
                              &nbsp;
                              <RemoveIcon></RemoveIcon>
                              Remove&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ maxWidth: '400px' }}>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Name: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q20_2reg_owners[i]['Name']}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Address: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q20_2reg_owners[i]['Address']}
                              </div>
                            </div>
                            <div style={{ display: 'flex', padding: '10px' }}>
                              Phone: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q20_2reg_owners[i]['Phone']}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </div>
              </Card>
              <div style={{ marginTop: '15px' }}></div>

              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  For each lessee of the vehicle, state<br></br>
                  the following:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q20_2lessee_name}
                    required
                    onChange={e => setQ20_2lessee_name(e.target.value)}
                  />
                  <label className={q20_2lessee_name && 'filled'}>
                    {'The name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ20_2lessee_address(data)}
                  ref={addressRef4}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q20_2lessee_phone}
                    placeholder=''
                    onChange={setQ20_2lessee_phone}
                  />

                  <label className={q20_2lessee_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    className='AddPerson'
                    style={{ color: 'white', margin: 'auto' }}
                    onClick={() => addLessee()}
                  >
                    <div>&nbsp;Add&nbsp;</div>
                    <AddIcon style={{ color: 'white' }}></AddIcon>
                  </div>
                </div>
                {q20_2lessees.length > 0 ? <hr></hr> : ''}
                <div style={{ marginBottom: '-15px' }}>
                  {q20_2lessees.map((key, i) => {
                    return (
                      <Accordion
                        style={{
                          backgroundColor: 'white',
                          boxShadow: '0px 1px 3px #cccccc',
                          marginBottom: '4px'
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {q20_2lessees[i]['Name']}

                          <div style={{ marginLeft: 'auto' }}>
                            <div
                              className='RemButton'
                              onClick={() => remLessee(i)}
                            >
                              &nbsp;
                              <RemoveIcon></RemoveIcon>
                              Remove&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ maxWidth: '400px' }}>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Name: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q20_2lessees[i]['Name']}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Address: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q20_2lessees[i]['Address']}
                              </div>
                            </div>
                            <div style={{ display: 'flex', padding: '10px' }}>
                              Phone: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q20_2lessees[i]['Phone']}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </div>
              </Card>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ textAlign: 'center' }}>
                Are there owners of the vehicle other than the<br></br>
                registered owner or lein holder?
                {['radio'].map(type => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Yes'
                      name='othOwns'
                      type={type}
                      checked={areOtherOwners}
                      onClick={() => setAreOtherOwners(true)}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name='othOwns'
                      type={type}
                      checked={!areOtherOwners}
                      onClick={() => noOtherOwners()}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </div>

              <div style={{ marginTop: '15px' }}></div>

              {areOtherOwners ? (
                <Card style={{ padding: '20px', textAlign: 'center' }}>
                  <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                    For each owner other than the registered owner <br></br>or
                    the lien holder, state:
                  </div>
                  <div className='input-container'>
                    <input
                      type='text'
                      value={q20_2other_owner_name}
                      required
                      onChange={e => setQ20_2other_owner_name(e.target.value)}
                    />
                    <label className={q20_2other_owner_name && 'filled'}>
                      {'The name'}
                    </label>
                  </div>
                  <div style={{ marginTop: '15px' }}></div>
                  <AddressInput
                    passData={data => setQ20_2other_owner_address(data)}
                    ref={addressRef5}
                  />
                  <div style={{ marginTop: '15px' }}></div>
                  <div className='phone-input-container'>
                    <PhoneInput
                      enableAreaCodes={false}
                      inputStyle={{
                        height: '44px',
                        width: 'inherit',
                        borderColor: 'rgb(214, 214, 214)'
                      }}
                      disableCountryCode
                      country={'us'}
                      onlyCountries={['us']}
                      value={q20_2other_owner_phone}
                      placeholder=''
                      onChange={setQ20_2other_owner_phone}
                    />

                    <label className={q20_2other_owner_phone && 'filled'}>
                      {'Phone Number'}
                    </label>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '15px',
                      marginBottom: '10px'
                    }}
                  >
                    <div
                      className='AddPerson'
                      style={{ color: 'white', margin: 'auto' }}
                      onClick={() => addOtherOwner()}
                    >
                      <div>&nbsp;Add&nbsp;</div>
                      <AddIcon style={{ color: 'white' }}></AddIcon>
                    </div>
                  </div>
                  {q20_2other_owners.length > 0 ? <hr></hr> : ''}
                  <div style={{ marginBottom: '-15px' }}>
                    {q20_2other_owners.map((key, i) => {
                      return (
                        <Accordion
                          style={{
                            backgroundColor: 'white',
                            boxShadow: '0px 1px 3px #cccccc',
                            marginBottom: '4px'
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {q20_2other_owners[i]['Name']}

                            <div style={{ marginLeft: 'auto' }}>
                              <div
                                className='RemButton'
                                onClick={() => remOtherOwner(i)}
                              >
                                &nbsp;
                                <RemoveIcon></RemoveIcon>
                                Remove&nbsp;&nbsp;&nbsp;
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ maxWidth: '400px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '10px',
                                  borderBottomStyle: 'solid',
                                  borderWidth: '1px',
                                  borderBottomColor: '#dddddd'
                                }}
                              >
                                Name: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q20_2other_owners[i]['Name']}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '10px',
                                  borderBottomStyle: 'solid',
                                  borderWidth: '1px',
                                  borderBottomColor: '#dddddd'
                                }}
                              >
                                Address: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q20_2other_owners[i]['Address']}
                                </div>
                              </div>
                              <div style={{ display: 'flex', padding: '10px' }}>
                                Phone: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q20_2other_owners[i]['Phone']}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                  </div>
                </Card>
              ) : (
                ''
              )}

              <div style={{ marginTop: '15px' }}></div>
              <div style={{ textAlign: 'center' }}>
                Was a non-owner driving the vehicle during <br></br>
                the incident?
                {['radio'].map(type => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Yes'
                      name='nonOnws'
                      type={type}
                      checked={gavePerm}
                      onClick={() => setGavePerm(true)}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name='nonOwns'
                      type={type}
                      checked={!gavePerm}
                      onClick={() => noPerm()}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </div>

              <div style={{ marginTop: '15px' }}></div>

              {gavePerm ? (
                <Card style={{ padding: '20px', textAlign: 'center' }}>
                  <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                    For each owner who gave permission or consent <br></br>to
                    the driver to operate the vehicle, state the<br></br>{' '}
                    following information:
                  </div>
                  <div className='input-container'>
                    <input
                      type='text'
                      value={q20_2perm_owner_name}
                      required
                      onChange={e => setQ20_2perm_owner_name(e.target.value)}
                    />
                    <label className={q20_2perm_owner_name && 'filled'}>
                      {'The name'}
                    </label>
                  </div>
                  <div style={{ marginTop: '15px' }}></div>
                  <AddressInput
                    passData={data => setQ20_2perm_owner_address(data)}
                    ref={addressRef6}
                  />
                  <div style={{ marginTop: '15px' }}></div>
                  <div className='phone-input-container'>
                    <PhoneInput
                      enableAreaCodes={false}
                      inputStyle={{
                        height: '44px',
                        width: 'inherit',
                        borderColor: 'rgb(214, 214, 214)'
                      }}
                      disableCountryCode
                      country={'us'}
                      onlyCountries={['us']}
                      value={q20_2perm_owner_phone}
                      placeholder=''
                      onChange={setQ20_2perm_owner_phone}
                    />

                    <label className={q20_2perm_owner_phone && 'filled'}>
                      {'Phone Number'}
                    </label>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '15px',
                      marginBottom: '10px'
                    }}
                  >
                    <div
                      className='AddPerson'
                      style={{ color: 'white', margin: 'auto' }}
                      onClick={() => addPermOwner()}
                    >
                      <div>&nbsp;Add&nbsp;</div>
                      <AddIcon style={{ color: 'white' }}></AddIcon>
                    </div>
                  </div>
                  {q20_2perm_owners.length > 0 ? <hr></hr> : ''}
                  <div style={{ marginBottom: '-15px' }}>
                    {q20_2perm_owners.map((key, i) => {
                      return (
                        <Accordion
                          style={{
                            backgroundColor: 'white',
                            boxShadow: '0px 1px 3px #cccccc',
                            marginBottom: '4px'
                          }}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            {q20_2perm_owners[i]['Name']}

                            <div style={{ marginLeft: 'auto' }}>
                              <div
                                className='RemButton'
                                onClick={() => remPermOwner(i)}
                              >
                                &nbsp;
                                <RemoveIcon></RemoveIcon>
                                Remove&nbsp;&nbsp;&nbsp;
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div style={{ maxWidth: '400px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '10px',
                                  borderBottomStyle: 'solid',
                                  borderWidth: '1px',
                                  borderBottomColor: '#dddddd'
                                }}
                              >
                                Name: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q20_2perm_owners[i]['Name']}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '10px',
                                  borderBottomStyle: 'solid',
                                  borderWidth: '1px',
                                  borderBottomColor: '#dddddd'
                                }}
                              >
                                Address: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q20_2perm_owners[i]['Address']}
                                </div>
                              </div>
                              <div style={{ display: 'flex', padding: '10px' }}>
                                Phone: &nbsp;
                                <div style={{ color: 'gray' }}>
                                  {q20_2perm_owners[i]['Phone']}
                                </div>
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                  </div>
                </Card>
              ) : (
                ''
              )}

              <div style={{ marginTop: '15px' }}></div>

              <div style={{ display: 'flex', marginTop: '5px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '180px' }}
                  onClick={() => addVehicle()}
                >
                  <div>&nbsp;Add Vehicle&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q20_2.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q20_2.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q20_2[i]['Make']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remVehicle(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q20_2[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q20_2
