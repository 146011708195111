import React from 'react'
import './Dashboard.css'
import { Outlet } from 'react-router-dom'
import TopbarIn from './Parts/TopbarIn'
// import RetrieveClaims from './RetrieveClaims';

const Dashboard = () => {
  return (
    <div className='Dashboard'>
      <TopbarIn></TopbarIn>
      <Outlet />
    </div>
  )
}

export default Dashboard
