import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q4_1 = forwardRef((props, ref) => {
  const addressRef1 = useRef()
  const addressRef2 = useRef()

  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q4_1, setQ4_1] = useState([])

  const [q4_1kind, setQ4_1kind] = useState('')
  const [q4_1name, setQ4_1name] = useState('')
  const [q4_1address, setQ4_1address] = useState('')

  const [q4_1named, setQ4_1named] = useState([])
  const [q4_1insured_name, setQ4_1insured_name] = useState('')
  const [q4_1insured_address, setQ4_1insured_address] = useState('')
  const [q4_1insured_phone, setQ4_1insured_phone] = useState('')

  const [q4_1policy_num, setQ4_1policy_num] = useState('')
  const [q4_1limit, setQ4_1limit] = useState('')

  const [q4_1reservation_exists, setQ4_1reservation_exists] = useState(false)
  const [q4_1reservation, setQ4_1reservation] = useState('')

  const [q4_1custodian_name, setQ4_1custodian_name] = useState('')
  const [q4_1custodian_address, setQ4_1custodian_address] = useState('')
  const [q4_1custodian_phone, setQ4_1custodian_phone] = useState('')

  const [wasInsured, setWasInsured] = useState(false)

  async function saveQuestion() {
    if (q4_1.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q4_1: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q4_1: q4_1
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q4_1) {
      if (docSnap.data().q4_1 !== 'N/A') {
        setWasInsured(true)
        setQ4_1(docSnap.data().q4_1)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addPolicy() {
    if (q4_1kind !== '') {
      let currList = q4_1
      currList.push({
        Kind: q4_1kind,
        Name: q4_1name,
        Address: q4_1address,
        NamedInsured: q4_1named,
        PolicyNumber: q4_1policy_num,
        Limit: q4_1limit,
        Dispute: q4_1reservation,
        CustodianName: q4_1custodian_name,
        CustodianAddress: q4_1custodian_address,
        CustodianPhone: q4_1custodian_phone
      })

      setQ4_1(currList)

      setQ4_1kind('')
      setQ4_1name('')
      setQ4_1address('')

      setQ4_1named([])
      setQ4_1insured_name('')
      setQ4_1insured_address('')
      setQ4_1insured_phone('')

      setQ4_1policy_num('')

      setQ4_1limit('')

      setQ4_1reservation_exists(false)
      setQ4_1reservation('')

      setQ4_1custodian_name('')
      setQ4_1custodian_address('')
      setQ4_1custodian_phone('')

      addressRef1.current.clear()
      addressRef2.current.clear()
    }
  }

  function remPolicy(index) {
    let newList = []
    for (let i = 0; i < q4_1.length; i++) {
      if (i !== index) {
        newList.push(q4_1[i])
      }
    }
    setQ4_1(newList)
  }

  function noInsurance() {
    setWasInsured(false)
    setQ4_1([])
  }

  function addNamed() {
    if (q4_1insured_name !== '') {
      let currList = q4_1named
      currList.push({
        Name: q4_1insured_name,
        Address: q4_1insured_address,
        Phone: q4_1insured_phone
      })

      setQ4_1named(currList)

      setQ4_1insured_name('')
      setQ4_1insured_address('')
      setQ4_1insured_phone('')
      addressRef2.current.clear()
    }
  }

  function remNamed(index) {
    let newList = []
    for (let i = 0; i < q4_1named.length; i++) {
      if (i !== index) {
        newList.push(q4_1named[i])
      }
    }
    setQ4_1named(newList)
  }

  function noReservation() {
    setQ4_1reservation_exists(false)
    setQ4_1reservation('')
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Kind: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Kind']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurance Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Name']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurance Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Address']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Limit: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Limit']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Policy Number: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['PolicyNumber']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Custodian Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['CustodianName']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Custodian Address: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['CustodianAddress']}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Custodian Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['CustodianPhone']}
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Named Insured: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['NamedInsured'].map((key, j) => {
                  return <div>{props.dict['NamedInsured'][j]['Name']}</div>
                })}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Kind: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Kind']}
                  onChange={e => (props.dict['Kind'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurance Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Name']}
                  onChange={e => (props.dict['Name'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurance Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Address']}
                  onChange={e => (props.dict['Address'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Limit: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Limit']}
                  onChange={e => (props.dict['Limit'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Policy Number: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['PolicyNumber']}
                  onChange={e => (props.dict['PolicyNumber'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Custodian Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['CustodianName']}
                  onChange={e => (props.dict['CustodianName'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Custodian Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['CustodianAddress']}
                  onChange={e =>
                    (props.dict['CustodianAddress'] = e.target.value)
                  }
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Custodian Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['CustodianPhone']}
                  onChange={e =>
                    (props.dict['CustodianPhone'] = e.target.value)
                  }
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          At the time of the incident, did you have insurance<br></br>
          that might provide coverage for the damages, claims, <br></br>
          or actions that have arisen out of the incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={wasInsured}
                onClick={() => setWasInsured(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!wasInsured}
                onClick={() => noInsurance()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {wasInsured ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each policy, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q4_1kind}
                  required
                  onChange={e => setQ4_1kind(e.target.value)}
                />
                <label className={q4_1kind && 'filled'}>
                  {'The kind of coverage'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q4_1name}
                  required
                  onChange={e => setQ4_1name(e.target.value)}
                />
                <label className={q4_1name && 'filled'}>
                  {'Name of insurance company'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <AddressInput
                passData={data => setQ4_1address(data)}
                ref={addressRef1}
                ph={'Address of insurance company'}
              />
              <div style={{ marginTop: '15px' }}></div>

              <div className='input-container'>
                <input
                  type='text'
                  value={q4_1policy_num}
                  required
                  onChange={e => setQ4_1policy_num(e.target.value)}
                />
                <label className={q4_1policy_num && 'filled'}>
                  {'The Policy Number'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q4_1limit}
                  required
                  onChange={e => setQ4_1limit(e.target.value)}
                />
                <label className={q4_1limit && 'filled'}>
                  {'Limit of coverage'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>

              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  For each named insured, state:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q4_1insured_name}
                    required
                    onChange={e => setQ4_1insured_name(e.target.value)}
                  />
                  <label className={q4_1insured_name && 'filled'}>
                    {'Their full name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ4_1insured_address(data)}
                  ref={addressRef2}
                  ph={'Their Address'}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q4_1insured_phone}
                    placeholder=''
                    onChange={setQ4_1insured_phone}
                  />

                  <label className={q4_1insured_phone && 'filled'}>
                    {'Their Phone Number'}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    className='AddPerson'
                    style={{ color: 'white', margin: 'auto' }}
                    onClick={() => addNamed()}
                  >
                    <div>&nbsp;Add&nbsp;</div>
                    <AddIcon style={{ color: 'white' }}></AddIcon>
                  </div>
                </div>

                {q4_1named.length > 0 ? <hr></hr> : ''}
                <div style={{ marginBottom: '-15px' }}>
                  {q4_1named.map((key, i) => {
                    return (
                      <Accordion
                        style={{
                          backgroundColor: 'white',
                          boxShadow: '0px 1px 3px #cccccc',
                          marginBottom: '4px'
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {q4_1named[i]['Name']}

                          <div style={{ marginLeft: 'auto' }}>
                            <div
                              className='RemButton'
                              onClick={() => remNamed(i)}
                            >
                              &nbsp;
                              <RemoveIcon></RemoveIcon>
                              Remove&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ maxWidth: '400px' }}>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Name: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q4_1named[i]['Name']}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Address: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q4_1named[i]['Address']}
                              </div>
                            </div>
                            <div style={{ display: 'flex', padding: '10px' }}>
                              Phone: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q4_1named[i]['Phone']}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </div>
              </Card>
              <div style={{ marginTop: '15px' }}></div>

              <div style={{ textAlign: 'center', width: '400px' }}>
                Does any reservation of rights or controversy or <br></br>
                coverage dispute exist between you and the insurance<br></br>
                company?
                <div style={{ marginTop: '15px' }}></div>
              </div>
              <div style={{ textAlign: 'center' }}>
                {['radio'].map(type => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Yes'
                      name='rights'
                      type={type}
                      checked={q4_1reservation_exists}
                      onClick={() => setQ4_1reservation_exists(true)}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name='rights'
                      type={type}
                      checked={!q4_1reservation_exists}
                      onClick={() => noReservation(false)}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </div>
              {q4_1reservation_exists ? (
                <div>
                  <div style={{ marginTop: '15px' }}></div>
                  <div className='input-container'>
                    <input
                      type='text'
                      value={q4_1reservation}
                      required
                      onChange={e => setQ4_1reservation(e.target.value)}
                    />
                    <label className={q4_1reservation && 'filled'}>
                      {'Explain'}
                    </label>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div style={{ marginTop: '35px' }}></div>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                State the following info for the custodian of<br></br>
                the policy:
              </div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q4_1custodian_name}
                  required
                  onChange={e => setQ4_1custodian_name(e.target.value)}
                />
                <label className={q4_1custodian_name && 'filled'}>
                  {'Custodian Name'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q4_1custodian_address}
                  required
                  onChange={e => setQ4_1custodian_address(e.target.value)}
                />
                <label className={q4_1custodian_address && 'filled'}>
                  {'Custodian Address'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='phone-input-container'>
                <PhoneInput
                  enableAreaCodes={false}
                  inputStyle={{
                    height: '44px',
                    width: 'inherit',
                    borderColor: 'rgb(214, 214, 214)'
                  }}
                  disableCountryCode
                  country={'us'}
                  onlyCountries={['us']}
                  value={q4_1custodian_phone}
                  placeholder=''
                  onChange={setQ4_1custodian_phone}
                />

                <label className={q4_1custodian_phone && 'filled'}>
                  {'Custodian Phone Number'}
                </label>
              </div>

              <div style={{ marginTop: '35px' }}></div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '150px' }}
                  onClick={() => addPolicy()}
                >
                  <div>&nbsp;Add Policy&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q4_1.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q4_1.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q4_1[i]['Name']}, &nbsp;{q4_1[i]['Kind']}
                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remPolicy(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q4_1[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q4_1
