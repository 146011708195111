import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Form, InputGroup } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input-fix'

export const Q8_8 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q8_8facts, setQ8_8facts] = useState('')
  const [q8_8estimate_duration, setQ8_8estimate_duration] = useState('')
  const [q8_8estimate_amount, setQ8_8estimate_amount] = useState('')
  const [q8_8estimate_exp, setQ8_8estimate_exp] = useState('')

  const [q8_8hasLoss, setQ8_8hasLoss] = useState(false)

  async function saveQuestion() {
    if (!q8_8hasLoss) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q8_8: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q8_8: {
            Facts: q8_8facts,
            EstimateDuration: q8_8estimate_duration,
            EstimateAmount: q8_8estimate_amount,
            EstimateExplanation: q8_8estimate_exp
          }
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    console.log(docSnap.data())
    if (docSnap.data().q8_8) {
      if (docSnap.data().q8_8 !== 'N/A') {
        setQ8_8hasLoss(true)

        const qData = docSnap.data().q8_8
        setQ8_8estimate_exp(qData['EstimateExplanation'])
        setQ8_8facts(qData['Facts'])
        setQ8_8estimate_amount(qData['EstimateAmount'])
        setQ8_8estimate_duration(qData['EstimateDuration'])
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Will you lose income in the future as a result of<br></br>
          the incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q8_8hasLoss}
                onClick={() => setQ8_8hasLoss(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q8_8hasLoss}
                onClick={() => setQ8_8hasLoss(false)}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        <div style={{ marginTop: '15px' }}></div>
        {q8_8hasLoss ? (
          <div>
            <div style={{ textAlign: 'center', width: '400px' }}>
              If so, state:
              <div style={{ marginTop: '15px' }}></div>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container' style={{ height: '100px' }}>
              <textarea
                value={q8_8facts}
                style={{
                  height: '100px',
                  borderStyle: 'none',
                  resize: 'none',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  paddingTop: '10px',
                  borderRadius: '5px'
                }}
                required
                onChange={e => setQ8_8facts(e.target.value)}
              />
              <label className={q8_8facts && 'filled'}>
                {'The facts upon which you base this'}
              </label>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q8_8estimate_duration}
                required
                onChange={e => setQ8_8estimate_duration(e.target.value)}
              />
              <label className={q8_8estimate_duration && 'filled'}>
                {'An estimate of how long you will be unable to work'}
              </label>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <InputGroup className='mb-3'>
              <InputGroup.Text>$</InputGroup.Text>
              <div className='input-container'>
                <CurrencyInput
                  id='input-example'
                  name='input-name'
                  value={q8_8estimate_amount}
                  decimalsLimit={2}
                  style={{ width: '363px' }}
                  onValueChange={value => setQ8_8estimate_amount(value)}
                />
                <label className={q8_8estimate_amount && 'filled'}>
                  {'An estimate of how much you will lose'}
                </label>
              </div>
            </InputGroup>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container' style={{ height: '100px' }}>
              <textarea
                value={q8_8estimate_exp}
                style={{
                  height: '100px',
                  borderStyle: 'none',
                  resize: 'none',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  paddingTop: '10px',
                  borderRadius: '5px'
                }}
                required
                onChange={e => setQ8_8estimate_exp(e.target.value)}
              />
              <label className={q8_8estimate_exp && 'filled'}>
                {'Explain how you calculated this number'}
              </label>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q8_8
