import React, { useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { doc, getDoc, deleteDoc, updateDoc } from 'firebase/firestore'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { formatPhoneNumber } from 'react-phone-number-input'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined'
import './PlaintiffCard.css'
import { db, storage } from '../../../../../../../firebase'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { ref, listAll, getDownloadURL } from 'firebase/storage'
import { uploadBytes, deleteObject } from 'firebase/storage'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined'
import { PulseLoader } from 'react-spinners'
import SendIcon from '@mui/icons-material/Send'
import SendForms from './SendForms'

export default function PFormRogCard(props) {
  const [isLoading, setIsLoading] = useState(true)

  const [pData, setPData] = useState({})
  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [contacted, setContacted] = useState(false)
  const [signedRet, setSignedRet] = useState(false)
  const [areYouSure, setAreYouSure] = useState(false)

  const [filesOpen, setFilesOpen] = useState(false)
  const [filesList, setFilesList] = useState([])
  const [loadingFiles, setLoadingFiles] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')

  const [intakeModal, setIntakeModal] = useState(false)

  function UploadFile(props) {
    function uploadFiles(fileUpload) {
      setLoadingMessage('Uploading...')
      if (fileUpload == null) return
      const fileRef = ref(
        storage,
        `plaintiffFiles/${props.mID}/${props.pID}/${fileUpload.name}`
      )
      uploadBytes(fileRef, fileUpload).then(() => {
        setLoadingMessage('')
        retrieveFiles()
      })
    }

    return (
      <input
        style={{
          transform: 'scale(0.9)',
          marginLeft: '-5px',
          marginTop: '-5px',
          width: '110px'
        }}
        type='file'
        onChange={e => uploadFiles(e.target.files[0])}
      />
    )
  }

  async function getAndSetData() {
    const pSnap = await getDoc(
      doc(db, 'matters', props.mID, 'plaintiffs', props.pID)
    )

    if (props.contacted) {
      setContacted(true)
    } else {
      setContacted(false)
    }
    if (props.signedRet) {
      setSignedRet(true)
    } else {
      setSignedRet(false)
    }

    setPData(pSnap.data())
    setTimeout(function () {
      setIsLoading(false)
    }, 500)
  }

  function clearData() {
    setIsLoading(true)

    setPData({})
    setIsExpanded(false)
    setIsEditing(false)
    setContacted(false)
    setSignedRet(false)
    setAreYouSure(false)

    setFilesOpen(false)
    setFilesList([])
    setLoadingFiles(false)
    setLoadingMessage('')
  }

  async function retrieveFiles() {
    let fileList = []
    const fileListRef = ref(
      storage,
      `plaintiffFiles/${props.mID}/${props.pID}/`
    )
    await listAll(fileListRef).then(response => {
      response.items.forEach(item => {
        let entry = [item.name]
        getDownloadURL(item).then(url => entry.push(url))
        fileList.push(entry)
      })
    })
    setFilesList(fileList)
    setLoadingFiles(false)
  }

  async function checkContacted(val) {
    if (val === true) {
      setContacted(true)
      await updateDoc(doc(db, 'matters', props.mID, 'plaintiffs', props.pID), {
        contacted: true
      })
    } else {
      setContacted(false)
      await updateDoc(doc(db, 'matters', props.mID, 'plaintiffs', props.pID), {
        contacted: false
      })
    }
  }

  async function checkSignedRet(val) {
    if (val === true) {
      setSignedRet(true)
      await updateDoc(doc(db, 'matters', props.mID, 'plaintiffs', props.pID), {
        signedRet: true
      })
    } else {
      setSignedRet(false)
      await updateDoc(doc(db, 'matters', props.mID, 'plaintiffs', props.pID), {
        signedRet: false
      })
    }
  }

  function toggleContent() {
    if (isExpanded) {
      setIsExpanded(false)
      setIsEditing(false)
      setFilesOpen(false)
      clearData()
    } else {
      setIsExpanded(true)
      getAndSetData()
    }
  }

  async function savePChanges() {
    setIsEditing(false)
    await updateDoc(
      doc(db, 'matters', props.mID, 'plaintiffs', props.pID),
      pData
    )
  }

  async function actuallyDeletePlaintiff() {
    setAreYouSure(false)
    setIsExpanded(false)
    setIsEditing(false)
    await deleteDoc(doc(db, 'matters', props.mID, 'plaintiffs', props.pID))
  }

  function deletePlaintiff() {
    setAreYouSure(true)
  }

  function openEdit() {
    setPData(pData)
    setIsEditing(true)
  }

  function openFiles() {
    setLoadingFiles(true)
    setFilesOpen(true)
    retrieveFiles()
  }

  function openIntake() {
    setIntakeModal(true)
  }

  const openLink = link => {
    window.open(link)
  }

  async function deleteFile(val) {
    setLoadingMessage('Deleting ' + val[0].slice(0, 5) + '...')
    const name = val[0]

    const fileRef = ref(
      storage,
      `plaintiffFiles/${props.mID}/${props.pID}/${name}`
    )
    await deleteObject(fileRef).then(() => {
      retrieveFiles()
      setLoadingMessage('')
    })
  }

  function openNotes() {
    const plaintiffID = props.pID
    props.openPlaintiffNotes(plaintiffID)
  }

  return (
    <div id='TortPlaintiffRow' className={isExpanded ? 'expanded' : ''}>
      {filesOpen ? (
        <div className='FilesModal'>
          <div
            className='FilesOverlay'
            onClick={() => setFilesOpen(false)}
          ></div>
        </div>
      ) : (
        ''
      )}

      {filesOpen ? (
        <div className='FilesModal'>
          <div className='FilesTop'>
            <FileUploadOutlinedIcon />
            &nbsp;Upload Files:&nbsp;{' '}
            <UploadFile mID={props.mID} pID={props.pID} />
            {loadingMessage}
          </div>
          {loadingFiles ? (
            'Loading...'
          ) : (
            <div className='FilesList'>
              {filesList.length === 0 ? (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  No files yet
                </div>
              ) : (
                ''
              )}
              {filesList.map(val => {
                return (
                  <div className='DocRow'>
                    <div
                      className='LinkOverlay'
                      onClick={() => openLink(val[1])}
                    />
                    <DescriptionOutlinedIcon />
                    &nbsp;
                    {val[0].slice(0, 20)}
                    <div className='DeleteFile' onClick={() => deleteFile(val)}>
                      <CloseOutlinedIcon />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      ) : (
        ''
      )}

      <div className='CardContent'>
        <div className='TopContent' onClick={() => toggleContent()}>
          &nbsp; <AccountCircleIcon />
          &nbsp; {props.name}
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
          </div>
          <div className='LittleBox' style={props.hasSent ? {marginRight:"10px", backgroundColor:"#60BD8F", borderColor:"#509C76"} : {marginRight:"10px"}}>{props.hasSent ? "Sent" :"Not sent"}</div>
          <div className='LittleBox'>{props.hasFinished ? "Completed by user" :"Not completed"}</div>
          <div id='ExpandIconPH' className={isExpanded ? 'expanded' : ''}>
            <ExpandMoreIcon />
          </div>
        </div>
        {!areYouSure && !isLoading ? (
         <div>
          <SendForms mID={props.mID} pID={props.pID} />
        </div>
        ) : areYouSure && !isLoading ? (
          <div>
            <div style={{ textAlign: 'center', marginTop: '4%' }}>
              Are you sure you want to delete this plaintiff?
            </div>
            <div style={{ display: 'flex' }}>
              <div
                style={{ display: 'flex', margin: 'auto', marginTop: '20px' }}
              >
                <div
                  className='YesBtn'
                  onClick={() => actuallyDeletePlaintiff()}
                >
                  Yes
                </div>
                &nbsp;&nbsp;&nbsp;
                <div className='NoBtn' onClick={() => setAreYouSure(false)}>
                  No
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                textAlign: 'center',
                marginTop: '5%',
                marginBottom: '5%'
              }}
            >
              <PulseLoader width={200} color={'#6aa4e6'} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
