import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import './CaseAccess.css'
import {
  doc,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  collection,
  updateDoc,
  onSnapshot
} from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Card } from 'react-bootstrap'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AddIcon from '@mui/icons-material/Add'
import { Form, Button } from 'react-bootstrap'
import { getUserByEmail } from '../../../../firebase'
import ClearIcon from '@mui/icons-material/Clear'
import { Fade } from '@mui/material'
import { UserAuth } from '../../../../contexts/AuthContext'
import { Dropdown } from 'react-bootstrap'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import BusinessIcon from '@mui/icons-material/Business'
import { PulseLoader } from 'react-spinners'
import { Container } from 'react-bootstrap'
import Select from 'react-select'

export default function CaseAccess() {
  const { id } = useParams()
  const [addError, setAddError] = useState('')
  const [userList, setUserList] = useState([])
  const [entireFirmList, setEntireFirmList] = useState([])
  const [userInfoList, setUserInfoList] = useState({})
  const [firmAdminList, setFirmAdminList] = useState([])

  const [newUserEmail, setNewUserEmail] = useState('')
  const [newUserUID, setNewUserUID] = useState('')
  const [newAddLoading, setNewAddLoading] = useState(false)
  const [newUserIsAdmin, setNewUserIsAdmin] = useState(false)

  const [myEmail, setMyEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [creatorID, setCreatorID] = useState('')
  const [matterAdminList, setMatterAdminList] = useState([])
  const [loading, setLoading] = useState(true)
  const [addNew, setAddNew] = useState(false)
  const [emailDisabled, setEmailDisabled] = useState(false)
  const { user } = UserAuth()
  const [firmUserList, setFirmUserList] = useState([])
  const [firmUserListOptions, setFirmUserListOptions] = useState([])
  const [firmUserListInfo, setFirmUserListInfo] = useState({})

  const [searchInput, setSearchInput] = useState(false)

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()
    return cMonth + '/' + cDay + '/' + cYear
  }

  const getAndSetData = useCallback(async () => {
    const sharedUsersRef = collection(db, 'matters', id, 'shared_users')
    const sharedUsersSnap = await getDocs(sharedUsersRef)
    let uidList = []
    let adminList = []
    let allFUsers = []
    let firmUserSelectList = []
    let firmAdminListTemp = []
    sharedUsersSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      if (doc.id !== user.uid) {
        uidList.push(doc.id)
      } else {
        uidList = [user.uid].concat(uidList)
      }
      if (doc.data().isAdmin) {
        adminList.push(doc.id)
      }
    })

    const matterRef = doc(db, 'matters', id)
    const matterSnap = await getDoc(matterRef)
    const creator_id = matterSnap.data().creatorID

    const creatorSnap = await getDoc(doc(db, 'firmUsers', creator_id))
    const myFirmID = creatorSnap.data().firmID
    const firmUsersSnap = await getDocs(
      collection(db, 'firms', myFirmID, 'users')
    )
    firmUsersSnap.forEach(doc => {
      allFUsers.push(doc.id)
      if (doc.data().isAdmin === false && doc.id !== user.uid) {
        firmUserSelectList.push(doc.id)
      }
      // check for firm admins who aren't already part of the matter
      if (doc.data().isAdmin === true && uidList.indexOf(doc.id) === -1) {
        uidList.push(doc.id)
        adminList.push(doc.id)
        firmAdminListTemp.push(doc.id)
      }
    })

    let infoList = {}
    await Promise.all(
      uidList.map(async uid => {
        const userRef = doc(db, 'firmUsers', uid)
        const myRef = await getDoc(userRef)
        const email = myRef.data().email
        const name = myRef.data().fullName

        infoList[uid] = [name, email]
      })
    )

    setEntireFirmList(allFUsers)
    setFirmUserList(firmUserSelectList)
    setUserInfoList(infoList)
    setUserList(uidList)
    setMatterAdminList(adminList)
    setFirmAdminList(firmAdminListTemp)

    setCreatorID(creator_id)

    if (adminList.indexOf(user.uid) !== -1) {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }

    setMyEmail(user.email)
    setLoading(false)
  }, [id, user.email, user.uid])

  useEffect(() => {
    onSnapshot(collection(db, 'matters', id, 'shared_users'), () => {
      getAndSetData()
    })
  }, [getAndSetData, id])

  async function addNewUserByEmail() {
    if (newUserEmail === '') {
      setAddError('You must enter an email')
      setNewAddLoading(false)
      return -1
    }
    setAddError('')
    getUserByEmail({
      email: newUserEmail
    })
      .then(async result => {
        let uid = result.data
        if (userList.indexOf(uid) !== -1) {
          setAddError('User already shared')
          setNewAddLoading(false)
          return -1
        }

        // Add the user to the matter
        await setDoc(doc(db, 'matters', id, 'shared_users', uid), {
          isAdmin: newUserIsAdmin
        })

        // Add the matter to the user
        await setDoc(doc(db, 'firmUsers', uid, 'shared_matters', id), {}).then(
          () => {
            setAddNew(false)
            setNewAddLoading(false)
          }
        )

        // Notify the user
        const matterSnap = await getDoc(doc(db, 'matters', id))
        const nContent =
          user.email +
          ' shared a matter with you: ' +
          matterSnap.data().caseName
        await addDoc(collection(db, 'firmUsers', uid, 'notifications'), {
          content: nContent,
          time: getTime(),
          link: id,
          type: 'matter'
        })
      })
      .catch(function () {
        setAddError('User not found')
        setNewAddLoading(false)
      })
  }

  async function addNewUserFromFirm() {
    if (userList.indexOf(newUserUID) !== -1) {
      setAddError('User already shared')
      setNewAddLoading(false)
      return -1
    }

    // Add the user to the matter
    await setDoc(doc(db, 'matters', id, 'shared_users', newUserUID), {
      isAdmin: newUserIsAdmin
    })

    // Add the matter to the user
    await setDoc(doc(db, 'firmUsers', newUserUID, 'shared_matters', id), {})

    // Notify the user
    const matterSnap = await getDoc(doc(db, 'matters', id))
    const nContent =
      user.email + ' shared a matter with you: ' + matterSnap.data().caseName
    await addDoc(collection(db, 'firmUsers', newUserUID, 'notifications'), {
      content: nContent,
      time: getTime(),
      link: 'matters/' + id,
      type: 'matter'
    })

    setAddNew(false)
  }

  function addNewUser() {
    setNewAddLoading(true)
    setAddError('')
    if (emailDisabled) {
      addNewUserFromFirm().then(() => {
        setNewAddLoading(false)
      })
    } else {
      addNewUserByEmail()
    }
  }

  async function removeUser(data) {
    //only nonadmin users can be deleted. if you want
    //to delete an admin you must first demote the admin

    //remove the user from the matter
    await deleteDoc(doc(db, 'matters', id, 'shared_users', data))

    //remove the matter from the user's shared matter list
    await deleteDoc(doc(db, 'firmUsers', data, 'shared_matters', id))
  }

  async function demote(data) {
    //remove the user from admin list
    await updateDoc(doc(db, 'matters', id, 'shared_users', data), {
      isAdmin: false
    })
  }

  async function makeAdmin(data) {
    await updateDoc(doc(db, 'matters', id, 'shared_users', data), {
      isAdmin: true
    })
  }

  async function loadFirmUsers() {
    let fUserSelectDict = {}
    let fUserOpts = []
    await Promise.all(
      firmUserList.map(async uid => {
        const userRef = doc(db, 'firmUsers', uid)
        const myRef = await getDoc(userRef)
        const email = myRef.data().email
        const name = myRef.data().fullName

        fUserSelectDict[uid] = email
        let val = name + ' - ' + email
        fUserOpts.push({ label: val, value: uid })
      })
    )
    setFirmUserListInfo(fUserSelectDict)
    setFirmUserListOptions(fUserOpts)
  }

  function openEditWindow() {
    setAddError('')
    setNewUserIsAdmin(false)
    setNewUserEmail('')
    setEmailDisabled(false)
    setAddNew(true)
    loadFirmUsers()
    setNewAddLoading(false)
  }

  function dropIsOpen(input) {
    if (input === '') {
      setSearchInput(false)
    } else if (searchInput === false && input !== '') {
      setSearchInput(true)
    }
  }

  async function processUser(opt, meta) {
    const action = meta.action
    if (action === 'select-option') {
      setNewUserUID(opt.value)
      setNewUserEmail(firmUserListInfo[opt.value])
      setEmailDisabled(true)
      //user selected option
    } else {
      //user cleared
      setNewUserEmail('')
      setEmailDisabled(false)
      setAddError('')
    }
  }

  function userIsValid() {
    return true
  }

  if (loading) {
    return (
      <div>
        <div className='FirmLoading'>
          <PulseLoader width={200} color={'#6aa4e6'} />
        </div>
      </div>
    )
  } else {
    return (
      <Fade in={true} appear timeout={800}>
        <div className='PlaintiffPage'>
          <br></br>
          <Card>
            <br></br>
            <div className='PlaintiffsTop'>
              <h5 className='text-center mb-4'>Users</h5>
              {isAdmin ? (
                <div className='Add'>
                  <AddIcon
                    style={{ color: 'white' }}
                    onClick={() => openEditWindow()}
                  ></AddIcon>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className='SharedListFinal'>
              {userList.map(val => {
                return (
                  <div
                    className='SharedRow'
                    style={
                      userInfoList[val][1] === myEmail
                        ? { 'background-color': 'rgb(222, 239, 255)' }
                        : { 'background-color': 'white' }
                    }
                  >
                    &nbsp;{' '}
                    {val === creatorID ? (
                      <AdminPanelSettingsIcon></AdminPanelSettingsIcon>
                    ) : firmAdminList.indexOf(val) !== -1 &&
                      val !== creatorID ? (
                      <BusinessIcon></BusinessIcon>
                    ) : val !== creatorID &&
                      firmAdminList.indexOf(val) === -1 ? (
                      <AccountCircleIcon></AccountCircleIcon>
                    ) : (
                      ''
                    )}
                    &nbsp; {userInfoList[val][0]}
                    &nbsp;
                    <t style={{ color: 'gray' }}>{userInfoList[val][1]}</t>
                    <t style={{ color: 'gray' }}>
                      {val === creatorID ? <t>&nbsp;{'(creator)'}</t> : ''}
                    </t>
                    <t style={{ color: 'gray' }}>
                      {matterAdminList.indexOf(val) !== -1 &&
                      val !== creatorID &&
                      firmAdminList.indexOf(val) === -1 ? (
                        <t>&nbsp;{'(admin)'}</t>
                      ) : (
                        ''
                      )}
                    </t>
                    <t style={{ color: 'gray' }}>
                      {firmAdminList.indexOf(val) !== -1 ? (
                        <t>&nbsp;{'(firm admin)'}</t>
                      ) : (
                        ''
                      )}
                    </t>
                    <t style={{ color: 'gray' }}>
                      {entireFirmList.indexOf(val) === -1 ? (
                        <t>&nbsp;{'(foreign firm user)'}</t>
                      ) : (
                        ''
                      )}
                    </t>
                    {isAdmin &&
                    matterAdminList.indexOf(val) !== -1 &&
                    userInfoList[val][1] !== myEmail &&
                    val !== creatorID &&
                    firmAdminList.indexOf(val) === -1 ? (
                      <div className='actions'>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            id='dropdown-basic-button'
                          >
                            Actions
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => demote(val)}>
                              Demote Admin
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : isAdmin &&
                      userInfoList[val][1] !== myEmail &&
                      val !== creatorID &&
                      firmAdminList.indexOf(val) === -1 ? (
                      <div className='actions'>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            id='dropdown-basic-button'
                          >
                            Actions
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => removeUser(val)}>
                              Remove User
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => makeAdmin(val)}>
                              Make Admin
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )
              })}
            </div>
            <br></br>
          </Card>
          {addNew ? (
            <Fade in={true} appear timeout={500}>
              <div className='PopContent' style={{ height: '525px' }}>
                <div className='Close' onClick={() => setAddNew(false)}>
                  <ClearIcon></ClearIcon>
                </div>
                {/* Popup content here: */}
                <Container
                  className='d-flex
                            justify-content-center'
                  style={{ minHeight: '90vh' }}
                >
                  <div className='PopCard'>
                    <div className='w-100' style={{ maxWidth: '600px' }}>
                      <Card style={{ border: 'white' }}>
                        {!newAddLoading ? (
                          <Card.Body>
                            <h4 className='text-center mb-4'>Add User</h4>
                            <Form.Group id='type'>
                              <Form.Label>Select User From My Firm:</Form.Label>
                              <Select
                                options={firmUserListOptions}
                                placeholder='Enter user'
                                menuIsOpen={searchInput}
                                isClearable={true}
                                onInputChange={e => dropIsOpen(e)}
                                onChange={(opt, meta) => processUser(opt, meta)}
                              />
                            </Form.Group>
                            <br></br>
                            <div
                              style={{ color: 'gray', 'text-align': 'center' }}
                            >
                              -- or add by email --
                            </div>
                            <Form.Group id='userEmail'>
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type='email'
                                onChange={e => setNewUserEmail(e.target.value)}
                                value={newUserEmail}
                                disabled={emailDisabled}
                                required
                              />
                            </Form.Group>
                            <Form.Group>
                              <br></br>
                              <Form.Label>User Permissions</Form.Label>
                              {['radio'].map(type => (
                                <div key={`inline-${type}`} className='mb-3'>
                                  <Form.Check
                                    inline
                                    label='Standard'
                                    defaultChecked
                                    name='group1'
                                    type={type}
                                    onClick={() => setNewUserIsAdmin(false)}
                                    id={`inline-${type}-1`}
                                  />
                                  <Form.Check
                                    inline
                                    label='Admin'
                                    name='group1'
                                    type={type}
                                    onClick={() => setNewUserIsAdmin(true)}
                                    id={`inline-${type}-2`}
                                  />
                                </div>
                              ))}
                              <br></br>
                            </Form.Group>
                            <Button
                              className='w-100'
                              variant='success'
                              disabled={!userIsValid()}
                              onClick={() => addNewUser()}
                            >
                              Add User
                            </Button>
                            &nbsp;
                            <div
                              style={{ color: 'red', 'text-align': 'center' }}
                            >
                              {addError}
                            </div>
                          </Card.Body>
                        ) : (
                          <div>
                            <div className='FirmLoading'>
                              <PulseLoader width={200} color={'#6aa4e6'} />
                            </div>
                          </div>
                        )}
                      </Card>
                    </div>
                  </div>
                </Container>
                {/* End Popup Content */}
              </div>
            </Fade>
          ) : (
            <div></div>
          )}
          {addNew ? <div className='overlayP'></div> : <div></div>}
        </div>
      </Fade>
    )
  }
}
