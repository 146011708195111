import React from 'react'
import './RemovedFromFirm.css'
import { UserAuth } from '../../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'

export default function RemovedFromFirm() {
  const { user } = UserAuth()
  const navigate = useNavigate()
  // Logout function

  function okaySelect() {
    navigate('/')
  }

  if (!user) {
    return (
      <div className='RevokedAccess'>
        <br></br>
        Your access to the firm has been revoked.
        <br></br>
        If you still intend to use TapLaw's services,
        <br></br>
        You must recreate your account.
        <br></br>
        <br></br>
        <button onClick={() => okaySelect()}>Okay</button>
      </div>
    )
  } else {
    navigate('/app')
  }
}
