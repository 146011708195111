import React, { useState, useRef, useEffect } from 'react'
import { getDoc, doc } from 'firebase/firestore'
import { db } from '../../../../../../../firebase'
import Intake from './Intake/Intake'
import CloseIcon from '@mui/icons-material/Close';
import './Plaintiffs.css'

export default function ViewIntake(props) {
  const [pData, setPData] = useState({})
  const [pName, setPName] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  async function getAndSetData() {
    const pSnap = await getDoc(
      doc(db, 'matters', props.mID, 'plaintiffs', props.pID)
    )

    setPData(pSnap.data())
    setPName(pSnap.data().full_name)

    setTimeout(function () {
      setIsLoading(false)
    }, 500)
  }

  useEffect(() => {
    getAndSetData()
  }, [pName])

  const childCompRef = useRef()
  const [currQuestion] = useState(0)

  return(
    <div>
      <div className="CancelBtn1" onClick={() => props.closeWindow()}>
        Back
      </div>
      <div style={{textAlign:"center"}}>
        Viewing form interrogatory responses for plaintiff: {pData.full_name}
      </div>
      <Intake pID={props.pID}/>
    </div>
  )
}
