import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'

export const Q20_4 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q20_4, setQ20_4] = useState('')

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q20_4: q20_4
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q20_4) {
      setQ20_4(docSnap.data().q20_4)
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Describe the route that you followed from the<br></br> beginning of
          your trip to the location of the incident. <br></br>Make sure to state
          the location of each stop, other<br></br> than routine traffic stops:
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container' style={{ height: '100px' }}>
          <textarea
            value={q20_4}
            style={{
              height: '100px',
              borderStyle: 'none',
              borderRadius: '5px',
              resize: 'none',
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingTop: '10px'
            }}
            required
            onChange={e => setQ20_4(e.target.value)}
          />
          <label className={q20_4 && 'filled'}>{'Description'}</label>
        </div>
      </div>
    </div>
  )
})

export default Q20_4
