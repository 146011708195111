import { doc, getDocs, getDoc, collection } from 'firebase/firestore'
import { db } from '../../firebase'

async function LoadAllMatters(user) {
  let returnDict = {}
  //returnDict[id] = [matter_name, mine/shared/firm, creator]
  //Get the user's data
  const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))

  const myMattersRef = collection(db, 'firmUsers', user.uid, 'my_matters')
  const myMattersSnap = await getDocs(myMattersRef)
  let myMattersTemp = []

  myMattersSnap.forEach(val => {
    myMattersTemp.push(val.id)
  })

  const sharedMattersRef = collection(
    db,
    'firmUsers',
    user.uid,
    'shared_matters'
  )
  const sharedMattersSnap = await getDocs(sharedMattersRef)
  let sharedMattersTemp = []

  sharedMattersSnap.forEach(val => {
    sharedMattersTemp.push(val.id)
  })

  let firmMattersTemp = []

  const firmUsersRef = collection(db, 'firms', userSnap.data().firmID, 'users')
  const firmUsersSnap = await getDocs(firmUsersRef)
  let isFAdmin = false
  firmUsersSnap.forEach(doc => {
    if (doc.id === user.uid && doc.data().isAdmin === true) {
      isFAdmin = true
    }
  })

  if (isFAdmin) {
    const firmMattersRef = collection(
      db,
      'firms',
      userSnap.data().firmID,
      'matters'
    )
    const firmMattersSnap = await getDocs(firmMattersRef)

    firmMattersSnap.forEach(val => {
      if (myMattersTemp.indexOf(val.id) === -1) {
        firmMattersTemp.push(val.id)
      }
    })
  }

  if (myMattersTemp.length > 0) {
    await Promise.all(
      myMattersTemp.map(async val => {
        const docRef = doc(db, 'matters', val)
        const myRef = await getDoc(docRef)
        const name = myRef.data().caseName

        returnDict[val] = [name, 'mine', 'Me']
      })
    )
  }

  if (sharedMattersTemp.length > 0) {
    await Promise.all(
      sharedMattersTemp.map(async val => {
        const docRef = doc(db, 'matters', val)
        const myRef = await getDoc(docRef)
        const name = myRef.data().caseName
        const creatorID = myRef.data().creatorID
        const creatorSnap = await getDoc(doc(db, 'firmUsers', creatorID))
        if (creatorSnap.data()) {
          returnDict[val] = [name, 'shared', creatorSnap.data().email]
        }
      })
    )
  }

  if (firmMattersTemp.length > 0) {
    await Promise.all(
      firmMattersTemp.map(async val => {
        const docRef = doc(db, 'matters', val)
        const myRef = await getDoc(docRef)
        const name = myRef.data().caseName
        const creatorID = myRef.data().creatorID
        const creatorSnap = await getDoc(doc(db, 'firmUsers', creatorID))

        if (creatorSnap.data()) {
          returnDict[val] = [name, 'firm', creatorSnap.data().email]
        }
      })
    )
  }

  return returnDict
}

export default LoadAllMatters
