import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form, InputGroup } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CurrencyInput from 'react-currency-input-fix'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q7_1 = forwardRef((props, ref) => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q7_1, setQ7_1] = useState([])

  const [q7_1property, setQ7_1property] = useState('')
  const [q7_1nature, setQ7_1nature] = useState('')
  const [q7_1cost, setQ7_1cost] = useState('')
  const [q7_1cost_exp, setQ7_1cost_exp] = useState('')

  const [q7_1wasSold, setQ7_1wasSold] = useState(false)

  const [q7_1seller_name, setQ7_1seller_name] = useState('')
  const [q7_1seller_address, setQ7_1seller_address] = useState('')
  const [q7_1seller_phone, setQ7_1seller_phone] = useState('')
  const [q7_1seller_date, setQ7_1seller_date] = useState('')
  const [q7_1seller_price, setQ7_1seller_price] = useState('')

  const [q7_1hasLoss, setQ7_1hasLoss] = useState(false)

  async function saveQuestion() {
    if (!q7_1hasLoss) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q7_1: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q7_1: q7_1
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q7_1) {
      if (docSnap.data().q7_1 !== 'N/A') {
        setQ7_1(docSnap.data().q7_1)
        setQ7_1hasLoss(true)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addLoss() {
    if (q7_1property !== '') {
      let currList = q7_1
      currList.push({
        Property: q7_1property,
        Nature: q7_1nature,
        Cost: q7_1cost,
        Explanation: q7_1cost_exp,
        WasSold: q7_1wasSold,
        SellerName: q7_1seller_name,
        SellerAddress: q7_1seller_address,
        SellerPhone: q7_1seller_phone,
        SellerDate: q7_1seller_date,
        SellerPrice: q7_1seller_price
      })

      setQ7_1(currList)

      setQ7_1property('')
      setQ7_1nature('')
      setQ7_1cost('')
      setQ7_1cost_exp('')

      setQ7_1wasSold(false)

      setQ7_1seller_name('')
      setQ7_1seller_address('')
      setQ7_1seller_phone('')
      setQ7_1seller_date('')
      setQ7_1seller_price('')
      addressRef.current.clear()
    }
  }

  function remLoss(index) {
    let newList = []
    for (let i = 0; i < q7_1.length; i++) {
      if (i !== index) {
        newList.push(q7_1[i])
      }
    }
    setQ7_1(newList)
  }

  async function noLoss() {
    setQ7_1hasLoss(false)
    setQ7_1([])
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q7_1: 'N/A'
      }
    )
  }

  async function saveTrue() {
    setQ7_1hasLoss(true)
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q7_1: []
      }
    )
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Item: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Property']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Nature: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Nature']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Cost: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Cost']}</div>
            </div>

            {props.dict['WasSold'] ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    borderBottomStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomColor: '#dddddd'
                  }}
                >
                  Explanation: &nbsp;
                  <div style={{ color: 'gray' }}>
                    {props.dict['Explanation']}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    borderBottomStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomColor: '#dddddd'
                  }}
                >
                  Seller Name: &nbsp;
                  <div style={{ color: 'gray' }}>
                    {props.dict['SellerName']}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    borderBottomStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomColor: '#dddddd'
                  }}
                >
                  Seller Address: &nbsp;
                  <div style={{ color: 'gray' }}>
                    {props.dict['SellerAddress']}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    borderBottomStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomColor: '#dddddd'
                  }}
                >
                  Seller Phone: &nbsp;
                  <div style={{ color: 'gray' }}>
                    {props.dict['SellerPhone']}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    borderBottomStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomColor: '#dddddd'
                  }}
                >
                  Sale Date: &nbsp;
                  <div style={{ color: 'gray' }}>
                    {props.dict['SellerDate']}
                  </div>
                </div>
                <div style={{ display: 'flex', padding: '10px' }}>
                  Sale Price: &nbsp;
                  <div style={{ color: 'gray' }}>
                    {props.dict['SellerPrice']}
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', padding: '10px' }}>
                Explanation: &nbsp;
                <div style={{ color: 'gray' }}>{props.dict['Explanation']}</div>
              </div>
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Item: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Property']}
                  onChange={e => (props.dict['Property'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Nature: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Nature']}
                  onChange={e => (props.dict['Nature'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Cost: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Cost']}
                  onChange={e => (props.dict['Cost'] = e.target.value)}
                ></input>
              </div>
            </div>
            {props.dict['WasSold'] ? (
              <div>
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    borderBottomStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomColor: '#dddddd'
                  }}
                >
                  Explanation: &nbsp;
                  <div style={{ color: 'gray' }}>
                    <input
                      defaultValue={props.dict['Explanation']}
                      onChange={e =>
                        (props.dict['Explanation'] = e.target.value)
                      }
                    ></input>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    borderBottomStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomColor: '#dddddd'
                  }}
                >
                  Seller Name: &nbsp;
                  <div style={{ color: 'gray' }}>
                    <input
                      defaultValue={props.dict['SellerName']}
                      onChange={e =>
                        (props.dict['SellerName'] = e.target.value)
                      }
                    ></input>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    borderBottomStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomColor: '#dddddd'
                  }}
                >
                  Seller Address: &nbsp;
                  <div style={{ color: 'gray' }}>
                    <input
                      defaultValue={props.dict['SellerAddress']}
                      onChange={e =>
                        (props.dict['SellerAddress'] = e.target.value)
                      }
                    ></input>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    borderBottomStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomColor: '#dddddd'
                  }}
                >
                  Seller Phone: &nbsp;
                  <div style={{ color: 'gray' }}>
                    <input
                      defaultValue={props.dict['SellerPhone']}
                      onChange={e =>
                        (props.dict['SellerPhone'] = e.target.value)
                      }
                    ></input>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    padding: '10px',
                    borderBottomStyle: 'solid',
                    borderWidth: '1px',
                    borderBottomColor: '#dddddd'
                  }}
                >
                  Sale Date: &nbsp;
                  <div style={{ color: 'gray' }}>
                    <input
                      defaultValue={props.dict['SellerDate']}
                      onChange={e =>
                        (props.dict['SellerDate'] = e.target.value)
                      }
                    ></input>
                  </div>
                </div>
                <div style={{ display: 'flex', padding: '10px' }}>
                  Sale Price: &nbsp;
                  <div style={{ color: 'gray' }}>
                    <input
                      defaultValue={props.dict['SellerPrice']}
                      onChange={e =>
                        (props.dict['SellerPrice'] = e.target.value)
                      }
                    ></input>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', padding: '10px' }}>
                Explanation: &nbsp;
                <div style={{ color: 'gray' }}>
                  <input
                    defaultValue={props.dict['Explanation']}
                    onChange={e => (props.dict['Explanation'] = e.target.value)}
                  ></input>
                </div>
              </div>
            )}
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Do you attribute any loss of or damage to a<br></br>
          vehicle or other property to the incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q7_1hasLoss}
                onClick={() => saveTrue()}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q7_1hasLoss}
                onClick={() => noLoss(false)}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        <div style={{ marginTop: '15px' }}></div>
        {q7_1hasLoss ? (
          <div>
            <div style={{ textAlign: 'center', width: '400px' }}>
              For each item of property, state:
              <div style={{ marginTop: '15px' }}></div>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q7_1property}
                required
                onChange={e => setQ7_1property(e.target.value)}
              />
              <label className={q7_1property && 'filled'}>
                {'The Property'}
              </label>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q7_1nature}
                required
                onChange={e => setQ7_1nature(e.target.value)}
              />
              <label className={q7_1nature && 'filled'}>
                {'Nature and location of damage'}
              </label>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <InputGroup className='mb-3'>
              <InputGroup.Text>$</InputGroup.Text>
              <div className='input-container'>
                <CurrencyInput
                  id='input-example'
                  name='input-name'
                  value={q7_1cost}
                  decimalsLimit={2}
                  style={{ width: '363px' }}
                  onValueChange={value => setQ7_1cost(value)}
                />
                <label className={q7_1cost && 'filled'}>
                  {'Cost of damage you are claiming'}
                </label>
              </div>
            </InputGroup>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q7_1cost_exp}
                required
                onChange={e => setQ7_1cost_exp(e.target.value)}
              />
              <label className={q7_1cost_exp && 'filled'}>
                {'How did you calculate this number?'}
              </label>
            </div>

            <div style={{ marginTop: '15px' }}></div>
            <div style={{ textAlign: 'center', width: '400px' }}>
              Was this property sold?
              <div style={{ marginTop: '15px' }}></div>
            </div>
            <div style={{ textAlign: 'center' }}>
              {['radio'].map(type => (
                <div key={`inline-${type}`} className='mb-3'>
                  <Form.Check
                    inline
                    label='Yes'
                    name='sold1'
                    type={type}
                    checked={q7_1wasSold}
                    onClick={() => setQ7_1wasSold(true)}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label='No'
                    name='sold1'
                    type={type}
                    checked={!q7_1wasSold}
                    onClick={() => setQ7_1wasSold(false)}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </div>

            {q7_1wasSold ? (
              <div style={{ textAlign: 'center', width: '400px' }}>
                State the following information about the seller:
                <div style={{ marginTop: '15px' }}></div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q7_1seller_name}
                    required
                    onChange={e => setQ7_1seller_name(e.target.value)}
                  />
                  <label className={q7_1seller_name && 'filled'}>
                    {'Seller name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ7_1seller_address(data)}
                  ref={addressRef}
                  ph={'Seller address'}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q7_1seller_phone}
                    placeholder=''
                    onChange={setQ7_1seller_phone}
                  />

                  <label className={q7_1seller_phone && 'filled'}>
                    {'Seller Phone Number'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <div className='input-container'>
                  <input
                    type='date'
                    value={q7_1seller_date}
                    required
                    onChange={e => setQ7_1seller_date(e.target.value)}
                  />
                  <label className={'filled'}>{'Date of sale'}</label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <InputGroup className='mb-3'>
                  <InputGroup.Text>$</InputGroup.Text>
                  <div className='input-container'>
                    <CurrencyInput
                      id='input-example'
                      name='input-name'
                      value={q7_1seller_price}
                      decimalsLimit={2}
                      style={{ width: '363px' }}
                      onValueChange={value => setQ7_1seller_price(value)}
                    />
                    <label className={q7_1seller_price && 'filled'}>
                      {'Sale price'}
                    </label>
                  </div>
                </InputGroup>
              </div>
            ) : (
              ''
            )}

            <div style={{ marginTop: '15px' }}></div>
            <div style={{ display: 'flex', marginTop: '15px' }}>
              <div
                className='AddPerson'
                style={{ color: 'white', margin: 'auto', width: '150px' }}
                onClick={() => addLoss()}
              >
                <div>&nbsp;Add Property&nbsp;</div>
                <AddIcon style={{ color: 'white' }}></AddIcon>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q7_1.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q7_1.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q7_1[i]['Property']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div className='RemButton' onClick={() => remLoss(i)}>
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q7_1[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q7_1
