import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router'
import './Plaintiffs.css'
import {
  doc,
  getDocs,
  getDoc,
  onSnapshot,
  collection
} from 'firebase/firestore'
import { db } from '../../../../../../../firebase'
import 'reactjs-popup/dist/index.css'
import AddIcon from '@mui/icons-material/Add'
import { Form } from 'react-bootstrap'
import ClearIcon from '@mui/icons-material/Clear'
import { Fade } from '@mui/material'
import { Container } from '@mui/system'
import NormalPlaintiffCard from './PFormRogCard'
import FilterListIcon from '@mui/icons-material/FilterList'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import '../../../../../../../Inputs/Inputs.css'
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import ViewIntake from './ViewIntake'

export default function FormRogsR() {
  const { id } = useParams()
  const [isTort, setIsTort] = useState(false)
  const navigate = useNavigate();

  const [showSort, setShowSort] = useState(false)
  const [noPlaintiffs, setNoPlaintiffs] = useState(false)
  const [sortSearch, setSortSearch] = useState('')
  const [sortBySent, setSortBySent] = useState(false)
  const [sortByNotSent, setSortByNotSent] = useState(false)
  const [sortByCompleted, setSortByCompleted] = useState(false)
  const [sortByNotCompleted, setSortByNotCompleted] = useState(false)

  const [, setCaseName] = useState('')
  const [plaintiffList, setPlaintiffList] = useState([])
  const [plaintiffNames, setPlaintiffNames] = useState({})

  const [, setPlaintiffType] = useState('')

  const [overlay, setOverlay] = useState(false)
  const [modal, setModal] = useState(false)
  const [, setIsEditing] = useState(false)

  const [notesOpen, setNotesOpen] = useState(false)
  const [notesID, setNotesID] = useState('')

  const [viewResponse, setViewResponse] = useState(false)
  const [plaintiffToView, setPlaintiffToView] = useState('')

  const toggleModal = () => {
    setPlaintiffType('')
    setModal(!modal)
    setOverlay(!modal)
    setIsEditing(false)
  }

  const getAndSetData = useCallback(async () => {
    const myMattersRef = collection(db, 'matters', id, 'plaintiffs')
    const myMattersSnap = await getDocs(myMattersRef)

    let tempList = []
    let tempDict = {}
    myMattersSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      tempList.push(doc.id)
      if (doc.data().type !== 'Business') {
        tempDict[doc.id] = [
          doc.data().full_name,
          doc.data().sentIntake,
          doc.data().completedIntake
        ]
      } else {
        tempDict[doc.id] = [
          doc.data().business_name,
          doc.data().sentIntake,
          doc.data().completedIntake
        ]
      }
    })

    if (tempList.length === 0) {
      setNoPlaintiffs(true);
    }

    setPlaintiffList(tempList)
    setPlaintiffNames(tempDict)

    const matterSnap = await getDoc(doc(db, 'matters', id))
    setCaseName(matterSnap.data().caseName)
  }, [id])

  const getIsTort = useCallback(async () => {
    const myMatterSnap = await getDoc(doc(db, 'matters', id))
    //determine if mass tort
    if (myMatterSnap.data().coordinationProceeding) {
      setIsTort(true)
    }
  }, [id])


  useEffect(() => {
    onSnapshot(collection(db, 'matters', id, 'plaintiffs'), () => {
      getAndSetData()
    })
    getIsTort()
  }, [getAndSetData, getIsTort, id])

  function openNotes(pID) {
    setNotesID(pID)
    setNotesOpen(true)
  }

  function closeNotes() {
    setNotesOpen(false)
    setNotesID('')
  }

  if (plaintiffToView !== "") {
    return (
      <div>
        <ViewIntake pID={plaintiffToView} mID={id} closeWindow={() => setPlaintiffToView('')}/>
      </div>
    )
  }
  else {
    return (
        <div>
          <Fade in={true} appear timeout={300}>
            <div className='PlaintiffPageWrapper'>


              {notesOpen ? (
                <div className='OverviewOverlay' onClick={() => closeNotes()}></div>
              ) : (
                ''
              )}
              <br></br>
              <div className='PlaintiffMain'>
                <div className='BackBtn' onClick={() => navigate('/app/matters/' + id + '/discovery')}>
                  {"< return to discovery"}
                </div>
                <br></br>
                <div className='PlaintiffPageTop'>
                  <div
                    style={{
                      margin: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <h5 style={{ marginTop: '5px'}}>
                      Form Interrogatories: Response
                    </h5>
                    &nbsp;&nbsp;
                  </div>
                  <div className='SortPlaintiffs' onClick={() => setShowSort(true)}>
                    <FilterListIcon />
                    &nbsp; Sort
                    <div className='SortMenuPH'>
                      {showSort ? (
                        <div className='SortMenu'>
                          <div
                            className='SortMenuTop'
                            style={
                              !isTort
                                ? { borderStyle: 'none', marginBottom: '10px' }
                                : {}
                            }
                          >
                            Sort by keyword
                            <input
                              className='SortInput'
                              value={sortSearch}
                              onChange={e => setSortSearch(e.target.value)}
                            />
                          </div>
                          <div className='SortMenuOpts'>
                              <div style={{ textAlign: 'center' }}>
                                Sort options
                              </div>
                              <div style={{ marginTop: '10px' }}></div>
                              <div>
                                <input
                                  type='checkbox'
                                  className='PCheck'
                                  checked={sortBySent}
                                  onClick={e =>
                                    setSortBySent(e.target.checked)
                                  }
                                />
                                &nbsp; Sent form
                              </div>
                              <div>
                                <input
                                  type='checkbox'
                                  className='PCheck'
                                  checked={sortByNotSent}
                                  onClick={e =>
                                    setSortByNotSent(e.target.checked)
                                  }
                                />
                                &nbsp; Not sent form
                              </div>
                              <div>
                                <input
                                  type='checkbox'
                                  className='PCheck'
                                  checked={sortByCompleted}
                                  onClick={e => setSortByCompleted(e.target.checked)}
                                />
                                &nbsp; Completed form
                              </div>
                              <div>
                                <input
                                  type='checkbox'
                                  className='PCheck'
                                  checked={sortByNotCompleted}
                                  onClick={e =>
                                    setSortByNotCompleted(e.target.checked)
                                  }
                                />
                                &nbsp; Not completed form
                              </div>
                            </div>
                          
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  {showSort ? (
                    <div
                      className='SortMenuOverlay'
                      onClick={() => setShowSort(false)}
                    />
                  ) : (
                    ''
                  )}
                </div>
                <div className='PlaintiffScroll'>
                  {noPlaintiffs && (
                    <div>

                    <div style={{display:"flex"}}>

                      <div style={{margin:"auto"}}>
                        You haven't added any plaintiffs to the matter yet, and thus cannot send
                        any forms. To add a plaintiff, navigate to the "Plaintiffs" tab and click "Add," or click the link below
                      </div>

                    </div>

                    <div style={{display:"flex", marginTop:"20px"}}>
                      <div style={{margin:"auto"}}>
                        <div className='plink' onClick={() => navigate(`/app/matters/${id}/plaintiffs`)}>Navigate To Plaintiffs </div>
                      </div>
                    </div>

                    </div>
                    
                  )}
                  {plaintiffList.map(val => {
                    if (
                      plaintiffNames[val][0]
                        .toLowerCase()
                        .indexOf(sortSearch.toLowerCase()) !== -1
                    ) {
                      if (sortBySent && !plaintiffNames[val][1]) {
                        return ''
                      }
                      if (sortByNotSent && plaintiffNames[val][1]) {
                        return ''
                      }
                      if (sortByCompleted && !plaintiffNames[val][2]) {
                        return ''
                      }
                      if (sortByNotCompleted && plaintiffNames[val][2]) {
                        return ''
                      }

                      
                        return (
                          <NormalPlaintiffCard
                            pID={val}
                            mID={id}
                            name={plaintiffNames[val][0]}
                            hasSent={plaintiffNames[val][1]}
                            hasFinished={plaintiffNames[val][2]}
                            setPlaintiffToView={(val) => setPlaintiffToView(val)}
                          />
                        )
                      }

                    return null
                  })}
                </div>
                <br></br>
              </div>
            </div>
          </Fade>

          {overlay ? <div className='overlayP'></div> : <div></div>}

          {modal ? (
            <Fade in={true} appear timeout={500}>
              <div className='PopContent' style={{ top: '53%' }}>
                <div className='Close' onClick={toggleModal}>
                  <ClearIcon></ClearIcon>
                </div>
                <Container
                  className='d-flex
                    justify-content-center'
                  style={{ minHeight: '90vh' }}
                >
                </Container>
                {/* End Popup Content */}
              </div>
            </Fade>
          ) : (
          ''
        )}
      </div>
    )
  }
  
}
