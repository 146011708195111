import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form, InputGroup } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CurrencyInput from 'react-currency-input-fix'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q6_6 = forwardRef((props, ref) => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q6_6, setQ6_6] = useState([])

  const [q6_6nature, setQ6_6nature] = useState('')
  const [q6_6date, setQ6_6date] = useState('')
  const [q6_6cost, setQ6_6cost] = useState('')

  const [q6_6hcp, setQ6_6hcp] = useState([])
  const [q6_6hcp_name, setQ6_6hcp_name] = useState('')
  const [q6_6hcp_address, setQ6_6hcp_address] = useState('')
  const [q6_6hcp_phone, setQ6_6hcp_phone] = useState('')

  const [hadServices, sethadServices] = useState(false)

  async function saveQuestion() {
    if (q6_6.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_6: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_6: q6_6
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q6_6) {
      if (docSnap.data().q6_6 !== 'N/A') {
        sethadServices(true)
        setQ6_6(docSnap.data().q6_6)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addService() {
    if (q6_6nature !== '') {
      let currList = q6_6
      currList.push({
        Nature: q6_6nature,
        Date: q6_6date,
        Cost: q6_6cost,
        Providers: q6_6hcp
      })

      setQ6_6(currList)

      setQ6_6nature('')
      setQ6_6date('')
      setQ6_6cost('')
      setQ6_6hcp([])
    }
  }

  function remService(index) {
    let newList = []
    for (let i = 0; i < q6_6.length; i++) {
      if (i !== index) {
        newList.push(q6_6[i])
      }
    }
    setQ6_6(newList)
  }

  function noServices() {
    sethadServices(false)
    setQ6_6([])
  }

  function addHCP() {
    if (q6_6hcp_name !== '') {
      let currList = q6_6hcp
      currList.push({
        Name: q6_6hcp_name,
        Address: q6_6hcp_address,
        Phone: q6_6hcp_phone
      })

      setQ6_6hcp(currList)
      setQ6_6hcp_name('')
      setQ6_6hcp_address('')
      setQ6_6hcp_phone('')
      addressRef.current.clear()
    }
  }

  function remHCP(index) {
    let newList = []
    for (let i = 0; i < q6_6hcp.length; i++) {
      if (i !== index) {
        newList.push(q6_6hcp[i])
      }
    }
    setQ6_6hcp(newList)
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Nature: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Nature']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Date']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Cost: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Cost']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Providers: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['Providers'].map((key, j) => {
                  return <div>{props.dict['Providers'][j]['Name']}</div>
                })}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Nature: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Nature']}
                  onChange={e => (props.dict['Nature'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Date']}
                  onChange={e => (props.dict['Date'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Cost: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Cost']}
                  onChange={e => (props.dict['Cost'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Are there any other medical services necessitated by<br></br>
          the injuries you attribute to the incident that, <br></br>
          were not previously listed (for example, ambulance, <br></br>
          nursing, prosthetics)?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hadServices}
                onClick={() => sethadServices(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hadServices}
                onClick={() => noServices()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hadServices ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each service, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_6nature}
                  required
                  onChange={e => setQ6_6nature(e.target.value)}
                />
                <label className={q6_6nature && 'filled'}>
                  {'The nature of service'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='date'
                  value={q6_6date}
                  required
                  onChange={e => setQ6_6date(e.target.value)}
                />
                <label className={'filled'}>{'Date received'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <div className='input-container'>
                  <CurrencyInput
                    id='input-example'
                    name='input-name'
                    value={q6_6cost}
                    decimalsLimit={2}
                    style={{ width: '363px' }}
                    onValueChange={value => setQ6_6cost(value)}
                  />
                  <label className={q6_6cost && 'filled'}>
                    {'Cost of the service'}
                  </label>
                </div>
              </InputGroup>
              <div style={{ marginTop: '15px' }}></div>

              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  For each provider of this service, state:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q6_6hcp_name}
                    required
                    onChange={e => setQ6_6hcp_name(e.target.value)}
                  />
                  <label className={q6_6hcp_name && 'filled'}>
                    {'The Name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ6_6hcp_address(data)}
                  ref={addressRef}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q6_6hcp_phone}
                    placeholder=''
                    onChange={setQ6_6hcp_phone}
                  />

                  <label className={q6_6hcp_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    className='AddPerson'
                    style={{ color: 'white', margin: 'auto' }}
                    onClick={() => addHCP()}
                  >
                    <div>&nbsp;Add&nbsp;</div>
                    <AddIcon style={{ color: 'white' }}></AddIcon>
                  </div>
                </div>
                {q6_6hcp.length > 0 ? <hr></hr> : ''}
                <div style={{ marginBottom: '-15px' }}>
                  {q6_6hcp.map((key, i) => {
                    return (
                      <Accordion
                        style={{
                          backgroundColor: 'white',
                          boxShadow: '0px 1px 3px #cccccc',
                          marginBottom: '4px'
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {q6_6hcp[i]['Name']}

                          <div style={{ marginLeft: 'auto' }}>
                            <div
                              className='RemButton'
                              onClick={() => remHCP(i)}
                            >
                              &nbsp;
                              <RemoveIcon></RemoveIcon>
                              Remove&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ maxWidth: '400px' }}>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Name: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q6_6hcp[i]['Name']}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Address: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q6_6hcp[i]['Address']}
                              </div>
                            </div>
                            <div style={{ display: 'flex', padding: '10px' }}>
                              Phone: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q6_6hcp[i]['Phone']}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </div>
              </Card>

              <div style={{ display: 'flex', marginTop: '35px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '150px' }}
                  onClick={() => addService()}
                >
                  <div>&nbsp;Add Service&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q6_6.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q6_6.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q6_6[i]['Nature']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remService(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q6_6[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q6_6
