import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId
} from 'react-google-places-autocomplete'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q2_12 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  // Question 2_1
  const [q2_12, setQ2_12] = useState([])

  const [q2_12name, setQ2_12name] = useState('')
  const [q2_12address, setQ2_12address] = useState('')
  const [q2_12phone, setQ2_12phone] = useState('')
  const [q2_12condition, setQ2_12condition] = useState('')
  const [q2_12manner, setQ2_12manner] = useState('')

  const [hasCondition, setHasCondition] = useState(false)

  async function saveQuestion() {
    if (q2_12.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_12: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_12: q2_12
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q2_12) {
      if (docSnap.data().q2_12 !== 'N/A') {
        setHasCondition(true)
        setQ2_12(docSnap.data().q2_12)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addPerson() {
    if (q2_12name !== '') {
      q2_12.push({
        Name: q2_12name,
        Address: q2_12address,
        Phone: q2_12phone,
        Condition: q2_12condition,
        Contribution: q2_12manner
      })

      setQ2_12name('')
      setQ2_12address('')
      setQ2_12phone('')
      setQ2_12condition('')
      setQ2_12manner('')

      setMainAddress('')
      setUnitAddress('')
      setUAddress('')
    }
  }

  function remPerson(index) {
    let newList = []
    for (let i = 0; i < q2_12.length; i++) {
      if (i !== index) {
        newList.push(q2_12[i])
      }
    }
    setQ2_12(newList)
  }

  function noPeople() {
    setHasCondition(false)
    setQ2_12([])
  }

  const [mainAddress, setMainAddress] = useState('')
  const [uAddress, setUAddress] = useState('')
  const [unitAddress, setUnitAddress] = useState('')
  const [unitEnabled, setUnitEnabled] = useState(false)

  function processZip(val, un) {
    setUAddress(un)
    setUnitAddress('')
    geocodeByPlaceId(val).then(results =>
      processAddress(results[0]['formatted_address'], un['label'])
    )
  }

  function processUnit(val) {
    setUnitAddress(val)

    const firstCommaIndex = mainAddress.indexOf(',')

    if (val !== '') {
      const final =
        mainAddress.substring(0, firstCommaIndex) +
        ', Unit ' +
        val +
        mainAddress.substring(firstCommaIndex, mainAddress.length)
      setQ2_12address(final)
    } else {
      setQ2_12address(mainAddress)
    }
  }

  function processAddress(val, original) {
    setMainAddress(val)
    setQ2_12address(val)

    //entered place
    if (original.substring(0, 2) !== val.substring(0, 2)) {
      setUnitEnabled(false)
    }
    //entered address
    else {
      setUnitEnabled(true)
    }
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Name']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Address']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Phone:&nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Phone']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Condition: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Condition']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Contributed: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Contribution']}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Name']}
                  onChange={e => (props.dict['Name'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Address']}
                  onChange={e => (props.dict['Address'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Phone']}
                  onChange={e => (props.dict['Phone'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Condition: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Condition']}
                  onChange={e => (props.dict['Condition'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Contribution: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Contribution']}
                  onChange={e => (props.dict['Contribution'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          At the time of the incident, did you or any<br></br>
          other person have any physical, emotional, or<br></br>
          mental disability or condition that may have<br></br>
          contributed to the occurence of the incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasCondition}
                onClick={() => setHasCondition(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasCondition}
                onClick={() => noPeople()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasCondition ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each person, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_12name}
                  required
                  onChange={e => setQ2_12name(e.target.value)}
                />
                <label className={q2_12name && 'filled'}>{'Full Name'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>

              <div className='AddressContainer'>
                <div
                  className='drop-input-container'
                  style={{ width: '300px' }}
                >
                  <GooglePlacesAutocomplete
                    className='addyFill'
                    apiKey='AIzaSyAlu3CLZATNLGotLuWWLJVBSBduTVMeoyI'
                    selectProps={{
                      styles: {
                        input: provided => ({
                          ...provided,
                          height: '34px',
                          paddingTop: '5px'
                        })
                      },
                      placeholder: '',
                      value: uAddress,
                      onChange: res => {
                        processZip(res['value']['place_id'], res)
                      }
                    }}
                  />
                  <label className={mainAddress && 'filled'}>
                    {'Their Address'}
                  </label>
                </div>

                <div
                  className='input-container'
                  style={{ width: '80px', marginLeft: 'auto' }}
                >
                  <input
                    type='text'
                    value={unitAddress}
                    required
                    disabled={!unitEnabled}
                    onChange={e => processUnit(e.target.value)}
                  />
                  <label className={unitAddress && 'filled'}>{'Unit #'}</label>
                </div>
              </div>

              <div style={{ marginTop: '15px' }}></div>
              <div className='phone-input-container' style={{ margin: 'auto' }}>
                <PhoneInput
                  enableAreaCodes={false}
                  inputStyle={{
                    height: '44px',
                    width: 'inherit',
                    borderColor: 'rgb(214, 214, 214)'
                  }}
                  disableCountryCode
                  country={'us'}
                  onlyCountries={['us']}
                  value={q2_12phone}
                  placeholder=''
                  onChange={setQ2_12phone}
                />

                <label className={q2_12phone && 'filled'}>
                  {'Their Phone Number'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_12condition}
                  required
                  onChange={e => setQ2_12condition(e.target.value)}
                />
                <label className={q2_12condition && 'filled'}>
                  {'Their disability or condition'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_12manner}
                  required
                  onChange={e => setQ2_12manner(e.target.value)}
                />
                <label className={q2_12manner && 'filled'}>
                  {'How the condition contributed to the incident'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto' }}
                  onClick={() => addPerson()}
                >
                  <div>&nbsp;Add&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              {q2_12.length >= 1 ? <hr></hr> : ''}
              <div style={{ maxWidth: '400px' }}>
                {q2_12.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q2_12[i]['Name']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remPerson(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q2_12[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q2_12
