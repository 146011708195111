import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'

export const Q2_1 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  // Question 2_1
  const [, setQ2_1] = useState([])
  const [q2_1past, setQ2_1past] = useState([])
  const [q2_1cn, setQ2_1cn] = useState('')
  const [q2_1pn, setQ2_1pn] = useState('')
  const [q2_1df, setQ2_1df] = useState('')
  const [q2_1dt, setQ2_1dt] = useState('')

  const [hasPast, setHasPast] = useState(false)

  async function saveQuestion() {
    let finalArr = q2_1past
    finalArr.push({ Type: 'current', Name: q2_1cn })
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q2_1: finalArr
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  function removePastNames() {
    setHasPast(false)
    setQ2_1past([])
  }

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q2_1) {
      const answerArr = docSnap.data().q2_1
      setQ2_1(answerArr)
      let pastArr = []
      answerArr.forEach(entry => {
        if (entry['Type'] === 'past') {
          pastArr.push(entry)
        } else if (entry['Type'] === 'current') {
          setQ2_1cn(entry['Name'])
        }
      })
      if (pastArr.length > 0) {
        setHasPast(true)
      }
      setQ2_1past(pastArr)
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addName() {
    if (q2_1pn !== '' && q2_1df !== '' && q2_1dt !== '') {
      let currList = q2_1past
      let newEntry = {
        Type: 'past',
        Name: q2_1pn,
        FromDate: q2_1df,
        ToDate: q2_1dt
      }
      currList.push(newEntry)
      setQ2_1past(currList)
      setQ2_1pn('')
      setQ2_1df('')
      setQ2_1dt('')
    }
  }

  function remName(index) {
    let newList = []
    for (let i = 0; i < q2_1past.length; i++) {
      if (i !== index) {
        newList.push(q2_1past[i])
      }
    }
    setQ2_1past(newList)
  }

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          width: '400px',
          margin: 'auto',
          marginTop: '-10px'
        }}
      >
        State your current name
      </div>
      <br></br>
      <div></div>
      <div className='input-container' style={{ width: '400px' }}>
        <input
          type='text'
          value={q2_1cn}
          required
          onChange={e => setQ2_1cn(e.target.value)}
        />
        <label className={q2_1cn && 'filled'}>{'Current Full Name'}</label>
      </div>
      <div style={{ marginTop: '45px' }}></div>
      <div style={{ textAlign: 'center', width: '400px', margin: 'auto' }}>
        Do you have any past names?
        <div style={{ marginTop: '15px' }}></div>
      </div>
      <div style={{ textAlign: 'center' }}>
        {['radio'].map(type => (
          <div key={`inline-${type}`} className='mb-3'>
            <Form.Check
              inline
              label='Yes'
              name='group1'
              type={type}
              checked={hasPast}
              onClick={() => setHasPast(true)}
              id={`inline-${type}-1`}
            />
            <Form.Check
              inline
              label='No'
              name='group1'
              type={type}
              checked={!hasPast}
              onClick={() => removePastNames(false)}
              id={`inline-${type}-2`}
            />
          </div>
        ))}
      </div>
      {hasPast ? (
        <div style={{ width: '400px' }}>
          <div style={{ justifyContent: 'center' }}>
            <div style={{ marginTop: '15px' }}></div>
            <div style={{ textAlign: 'center', color: 'gray' }}>
              Past Names (enter one at a time)
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q2_1pn}
                required
                onChange={e => setQ2_1pn(e.target.value)}
              />
              <label className={q2_1pn && 'filled'}>{'Past Full Name'}</label>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div style={{ textAlign: 'center', color: 'gray' }}>
              Dates you used this name:
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div style={{ display: 'flex' }}>
              <div style={{ textAlign: 'center' }}>
                From:
                <div className='input-container'>
                  <input
                    type='date'
                    value={q2_1df}
                    required
                    onChange={e => setQ2_1df(e.target.value)}
                  />
                </div>
              </div>

              <div style={{ marginLeft: 'auto', textAlign: 'center' }}>
                To:
                <div className='input-container'>
                  <input
                    type='date'
                    value={q2_1dt}
                    required
                    onChange={e => setQ2_1dt(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', marginTop: '25px' }}>
              <div
                className='AddPerson'
                style={{ color: 'white', margin: 'auto' }}
                onClick={() => addName()}
              >
                <div>&nbsp;Add&nbsp;</div>
                <AddIcon style={{ color: 'white' }}></AddIcon>
              </div>
            </div>
            &nbsp;
          </div>
          <div>
            <div style={{ textAlign: 'center', marginBottom: '15px' }}>
              {q2_1past.length >= 1 ? <hr></hr> : ''}
            </div>
            <div>
              {q2_1past.map((key, i) => {
                return (
                  <div className='q1Item'>
                    {q2_1past[i]['Name']}, &nbsp;
                    {q2_1past[i]['FromDate'].slice(0, 4)}-
                    {q2_1past[i]['ToDate'].slice(0, 4)}
                    <div className='RemButton' onClick={() => remName(i)}>
                      &nbsp;
                      <RemoveIcon></RemoveIcon>
                      Remove&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
})

export default Q2_1
