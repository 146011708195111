import { doc, getDoc } from 'firebase/firestore'
import { db } from '../../../../../../../../firebase'

async function GetFinished(m_id, p_id) {
  const pSnap = await getDoc(
    doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
  )
  const pData = pSnap.data()
  let finArr = []
  const qArr = [
    'q1_1',
    'q2_1',
    'q2_4',
    'q2_5',
    'q2_6',
    'q2_7',
    'q2_8',
    'q2_9',
    'q2_10',
    'q2_11',
    'q2_12',
    'q2_13',
    'q3_1',
    'q3_2',
    'q3_3',
    'q3_4',
    'q3_5',
    'q3_6',
    'q3_7',
    'q4_1',
    'q6_2',
    'q6_3',
    'q6_4',
    'q6_5',
    'q6_6',
    'q6_7',
    'q7_1',
    'q7_2',
    'q7_3',
    'q8_2',
    'q8_3',
    'q8_4',
    'q8_5',
    'q8_6',
    'q8_7',
    'q8_8',
    'q9_1',
    'q9_2',
    'q10_1',
    'q10_2',
    'q10_3',
    'q11_1',
    'q11_2',
    'q12_1',
    'q20_2',
    'q20_3',
    'q20_4',
    'q20_7',
    'q20_8',
    'q20_11'
  ]

  for (let i = 0; i < qArr.length; i++) {
    if (pData[qArr[i]]) {
      finArr.push(i)
    }
  }
  return finArr
}

export default GetFinished
