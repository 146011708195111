import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'

export const Q8_3 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q8_3date, setQ8_3date] = useState('')

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q8_3: q8_3date
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    console.log(docSnap.data())
    if (docSnap.data().q8_3) {
      if (docSnap.data().q8_3 !== 'N/A') {
        setQ8_3date(docSnap.data().q8_3)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          State the last date before the incident that you <br></br>
          worked for compensation
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container'>
          <input
            type='date'
            value={q8_3date}
            required
            onChange={e => setQ8_3date(e.target.value)}
          />
        </div>
      </div>
    </div>
  )
})

export default Q8_3
