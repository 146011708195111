import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../../../firebase'
import {
  collection,
  getDocs,
  doc,
  getDoc,
  onSnapshot
} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import '../../Parts/TopbarIn.css'
import { UserAuth } from '../../../contexts/AuthContext'

const ProtectedMatter = ({ children }) => {
  const { user } = UserAuth()
  const { id } = useParams()
  const navigate = useNavigate()
  const [, setMatterUserList] = useState([])
  const [access, setAccess] = useState(0) // 0 loading, 1 no, 2 yes

  const checkIfAccess = useCallback(async () => {
    const myMattersRef = collection(db, 'matters', id, 'shared_users')
    const myMattersSnap = await getDocs(myMattersRef)
    let myMattersTemp = []
    myMattersSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      myMattersTemp.push(doc.id)
    })

    const matterRef = doc(db, 'matters', id)
    const matterSnap = await getDoc(matterRef)
    const creator_id = matterSnap.data().creatorID

    const creatorSnap = await getDoc(doc(db, 'firmUsers', creator_id))
    const myFirmID = creatorSnap.data().firmID
    const firmUsersSnap = await getDocs(
      collection(db, 'firms', myFirmID, 'users')
    )
    firmUsersSnap.forEach(doc => {
      // check for firm admins who aren't already part of the matter
      if (doc.data().isAdmin === true && myMattersTemp.indexOf(doc.id) === -1) {
        myMattersTemp.push(doc.id)
      }
    })

    setMatterUserList(myMattersTemp)

    if (myMattersTemp.indexOf(user.uid) !== -1) {
      setAccess(2)
    } else {
      setAccess(1)
    }
  }, [id, user.uid])

  const checkForUpdates = useCallback(async () => {
    onSnapshot(collection(db, 'matters', id, 'shared_users'), () => {
      checkIfAccess()
    })
  }, [checkIfAccess, id])

  useEffect(() => {
    checkForUpdates()
  }, [checkForUpdates])

  if (access === 0) {
    return <div></div>
  } else if (access === 1) {
    return (
      <div>
        <h4 style={{ 'text-align': 'center', 'padding-top': '60px' }}>
          You do not have permission to view this matter
        </h4>
        <div
          className='LogOutButton'
          style={{
            margin: 'auto',
            'text-align': 'center',
            'margin-top': '30px',
            width: 'fit-content',
            color: 'white'
          }}
          onClick={() => navigate('/app/matters')}
        >
          Go Back
        </div>
      </div>
    )
  } else if (access === 2) {
    return children
  }
}

export default ProtectedMatter
