import React from 'react'
import './About.css'
import { Fade } from 'react-bootstrap'

export default function About() {
  return (
    <Fade in={true} appear timeout={1200}>
      <div className='About'>
        <img
          className='abtimg'
          alt=''
          src={require('../images/home.png')}
        ></img>
      </div>
    </Fade>
  )
}
