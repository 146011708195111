import React, { useState } from 'react'
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import { UserAuth } from '../../../../contexts/AuthContext'
import { returnEmailHTML } from './IntakeEmail'
import { sendEmail, sendSMS } from '../../../../firebase'
import { Fade } from '@mui/material'
import { Form, Button } from 'react-bootstrap'
import SendIcon from '@mui/icons-material/Send'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined'
import PhoneInput from 'react-phone-input-2'
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined'

function SendForms(props) {
  const { user } = UserAuth()
  const [, setIntakeModal] = useState(false)
  const [sendToEmail, setSendToEmail] = useState(false)
  const [sendToPhone, setSendToPhone] = useState(false)
  const [copiedLink, setCopiedLink] = useState(false)

  const [intakeEmail, setIntakeEmail] = useState(false)
  const [intakePhone, setIntakePhone] = useState(false)

  async function sendIntake(type) {
    const verificationCode = 'xxxxxx'
    await updateDoc(doc(db, 'matters', props.mID, 'plaintiffs', props.pID), {
      verifyCode: verificationCode
    })
    setIntakeModal(false)
    setSendToPhone(false)
    setSendToEmail(false)

    //get the plaintiffs's data
    const plaintiffSnap = await getDoc(
      doc(db, 'matters', props.mID, 'plaintiffs', props.pID)
    )
    const plaintiffData = plaintiffSnap.data()

    //get the firm name
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    const firmSnap = await getDoc(doc(db, 'firms', userSnap.data().firmID))
    const iLink =
      'https://taplaw-cfedb.web.app/' +
      'client/' +
      props.mID +
      '/' +
      props.pID +
      '/intake'

    if (!plaintiffData.sentIntake) {
      await updateDoc(doc(db, 'matters', props.mID, 'plaintiffs', props.pID), {
        sentIntake: true
      })
      await setDoc(
        doc(
          db, 'matters', props.mID, 'plaintiffs', props.pID,'forms','intake'
        ),
        {
          q1_1: [
            {
              Address:
                firmSnap.data().street +
                ', ' +
                firmSnap.data().location +
                ' ' +
                firmSnap.zip,
              Name:
                "Plaintiff's (Plaintiffs') counsel: " + firmSnap.data().name,
              Relation: 'firm'
            }
          ]
        }
      )
    }

    if (type === 'email') {
      if (plaintiffData.type === 'business') {
        const emailBody = returnEmailHTML(
          plaintiffData.full_name,
          firmSnap.data().business_name,
          verificationCode,
          iLink
        )
        const subj =
          // eslint-disable-next-line no-useless-concat
          plaintiffData.business_name + ' - ' + 'Form Interrogatories'
        await sendEmail({
          fromEmail: '"TapLaw" <no-reply@taplaw.com>',
          toEmail: intakeEmail,
          subject: subj,
          emailHTML: emailBody
        })
        setIntakeEmail('')
      } else {
        const emailBody = returnEmailHTML(
          plaintiffData.full_name,
          firmSnap.data().name,
          verificationCode,
          iLink
        )
        // eslint-disable-next-line no-useless-concat
        const subj = plaintiffData.full_name + ' - ' + 'Form Interrogatories'
        await sendEmail({
          fromEmail: '"TapLaw" <no-reply@taplaw.com>',
          toEmail: intakeEmail,
          subject: subj,
          emailHTML: emailBody
        })
        setIntakeEmail('')
      }
    } else if (type === 'phone') {
      const subj =
        `Your attorney at ` +
        firmSnap.data().name +
        ` is requesting information regarding your claim. Please follow the link below:\n \n` +
        iLink +
        `\n\nUse the following code to verify: ` +
        verificationCode +
        `\n\nThank you for using TapLaw.`
      await sendSMS({
        toPhone: '1' + intakePhone,
        smsMessage: subj
      })
      setIntakePhone('')
    }
  }

  async function openSendToPhone() {
    setSendToPhone(true)
    const pSnap = await getDoc(
      doc(db, 'matters', props.mID, 'plaintiffs', props.pID)
    )
    if (pSnap.data().phone) {
      setSendToPhone(true)
      setIntakePhone(pSnap.data().phone)
    } else {
      setSendToPhone('')
    }
  }

  async function openSendToEmail() {
    setSendToEmail(true)
    const pSnap = await getDoc(
      doc(db, 'matters', props.mID, 'plaintiffs', props.pID)
    )
    if (pSnap.data().email) {
      setSendToEmail(true)
      setIntakeEmail(pSnap.data().email)
    } else {
      setSendToEmail('')
    }
  }

  function copyInviteLink() {
    setCopiedLink(true)
    sendIntake('link');
    const link =
      'https://taplaw-cfedb.web.app/' +
      'client/' +
      props.mID +
      '/' +
      props.pID +
      '/intake'
    navigator.clipboard.writeText(link)
  }

  return (
    <div>
      {sendToPhone ? (
        <Fade in={true} appear timeout={500}>
          <div style={{ padding: '20px' }}>
            <Form>
              <Form.Group id='pphone2' style={{ margin: 'auto' }}>
                <div style={{ display: 'flex' }}>
                  <SendToMobileOutlinedIcon></SendToMobileOutlinedIcon>
                  &nbsp;Phone Number
                </div>
                <div style={{ marginTop: '10px' }}></div>
                <PhoneInput
                  enableAreaCodes={false}
                  inputStyle={{ height: '40px' }}
                  disableCountryCode
                  country={'us'}
                  onlyCountries={['us']}
                  placeholder='(123) 456-7890'
                  value={intakePhone}
                  onChange={setIntakePhone}
                />
              </Form.Group>
            </Form>
            <div style={{ display: 'flex', marginTop: '15px' }}>
              <Button
                style={{ margin: 'auto' }}
                variant='success'
                onClick={() => sendIntake('phone')}
              >
                <SendIcon></SendIcon>&nbsp;&nbsp;Send
              </Button>
            </div>
          </div>
        </Fade>
      ) : sendToEmail ? (
        <Fade in={true} appear timeout={500}>
          <div style={{ padding: '20px', display: 'flex' }}>
            <div>
              <Form>
                <Form.Group id='pemail2' style={{ margin: 'auto' }}>
                  <div style={{ display: 'flex' }}>
                    <MailOutlineIcon></MailOutlineIcon>
                    &nbsp;Email
                  </div>
                  <div style={{ marginTop: '10px' }}></div>
                  <Form.Control
                    style={{ width: '300px' }}
                    type='email'
                    value={intakeEmail}
                    onChange={e => setIntakeEmail(e.target.value)}
                    required
                  />
                </Form.Group>
              </Form>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <Button
                  style={{ margin: 'auto' }}
                  variant='success'
                  onClick={() => sendIntake('email')}
                >
                  <SendIcon></SendIcon>&nbsp;&nbsp;Send
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      ) : (
        <div>
          <div className='ITop'>Intake & Form Interrogatories</div>
          <div className='IBtn' onClick={() => openSendToEmail(props.pID)}>
            <MailOutlineIcon style={{ marginTop: '1px', width: '20px' }} />
            &nbsp;&nbsp;
            <div>Send via Email</div>
          </div>

          <div className='IBtn' onClick={() => openSendToPhone(props.pID)}>
            <SendToMobileOutlinedIcon
              style={{ marginTop: '1px', width: '20px' }}
            />
            &nbsp;&nbsp;
            <div>Send via Text</div>
          </div>

          <div className='IBtn' onClick={() => copyInviteLink()}>
            <InsertLinkOutlinedIcon
              style={{ marginTop: '1px', width: '20px' }}
            />
            &nbsp;&nbsp;
            <div>{copiedLink ? 'Copied!' : 'Copy Invite Link'}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SendForms
