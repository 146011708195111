import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q3_6 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()
  const addressRef = useRef()

  const [q3_6, setQ3_6] = useState([])

  const [q3_6name, setQ3_6name] = useState('')
  const [q3_6state, setQ3_6state] = useState('')
  const [q3_6county, setQ3_6county] = useState('')
  const [q3_6address, setQ3_6address] = useState('')
  const [q3_6from, setQ3_6from] = useState('')
  const [q3_6to, setQ3_6to] = useState('')

  const [hasFictitious, setHasFictitious] = useState(false)

  async function saveQuestion() {
    if (q3_6.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q3_6: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q3_6: q3_6
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q3_6) {
      if (docSnap.data().q3_6 !== 'N/A') {
        setHasFictitious(true)
        setQ3_6(docSnap.data().q3_6)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addFictitious() {
    if (q3_6name !== '') {
      let currList = q3_6
      currList.push({
        Name: q3_6name,
        State: q3_6state,
        County: q3_6county,
        Address: q3_6address,
        FromDate: q3_6from,
        ToDate: q3_6to
      })

      setQ3_6(currList)
      setQ3_6name('')
      setQ3_6state('')
      setQ3_6county('')
      setQ3_6address('')
      setQ3_6from('')
      setQ3_6to('')
      addressRef.current.clear()
    }
  }

  function remFictitious(index) {
    let newList = []
    for (let i = 0; i < q3_6.length; i++) {
      if (i !== index) {
        newList.push(q3_6[i])
      }
    }
    setQ3_6(newList)
  }

  function noFictitious() {
    setHasFictitious(false)
    setQ3_6([])
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Name']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              County: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['County']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              State:&nbsp;
              <div style={{ color: 'gray' }}>{props.dict['State']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Address']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              From: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['FromDate']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              To: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['ToDate']}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Name']}
                  onChange={e => (props.dict['Name'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              County: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['County']}
                  onChange={e => (props.dict['County'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              State: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['State']}
                  onChange={e => (props.dict['State'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Address']}
                  onChange={e => (props.dict['Address'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              From: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['FromDate']}
                  onChange={e => (props.dict['FromDate'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              To: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['ToDate']}
                  onChange={e => (props.dict['ToDate'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Have you done business under a fictitious name<br></br>
          during the past 10 years?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasFictitious}
                onClick={() => setHasFictitious(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasFictitious}
                onClick={() => noFictitious()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasFictitious ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each fictitious name, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q3_6name}
                  required
                  onChange={e => setQ3_6name(e.target.value)}
                />
                <label className={q3_6name && 'filled'}>{'The Name'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q3_6county}
                  required
                  onChange={e => setQ3_6county(e.target.value)}
                />
                <label className={q3_6county && 'filled'}>
                  {'County it was filed'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q3_6state}
                  required
                  onChange={e => setQ3_6state(e.target.value)}
                />
                <label className={q3_6state && 'filled'}>
                  {'State it was filed'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <AddressInput
                passData={data => setQ3_6address(data)}
                ref={addressRef}
                ph={'Address of business'}
              />
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ textAlign: 'center' }}>
                {' '}
                Dates the name was used:
              </div>
              <div style={{ marginTop: '15px' }}></div>

              <div style={{ display: 'flex' }}>
                <div style={{ textAlign: 'center' }}>
                  From:
                  <div className='input-container'>
                    <input
                      type='date'
                      value={q3_6from}
                      required
                      onChange={e => setQ3_6from(e.target.value)}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 'auto', textAlign: 'center' }}>
                  To:
                  <div className='input-container'>
                    <input
                      type='date'
                      value={q3_6to}
                      required
                      onChange={e => setQ3_6to(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto' }}
                  onClick={() => addFictitious()}
                >
                  <div>&nbsp;Add&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q3_6.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q3_6.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q3_6[i]['Name']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remFictitious(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q3_6[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q3_6
