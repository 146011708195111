import React from 'react'
import './EditMatter.css'
import { Outlet } from 'react-router'
import Sidebar from './Sidebar/Sidebar.js'
import { Helmet } from 'react-helmet'

const EditMatter = () => {
  return (
    <div className='EditMatter'>
      <Helmet>
        <style>{'body { background-color: #fafafa; }'}</style>
      </Helmet>

      <div className='MatterTopBar'></div>
      <Sidebar></Sidebar>
      <div className='PageDisplay'>
        <Outlet />
      </div>
    </div>
  )
}

export default EditMatter
