import React, { useState, useEffect, forwardRef, useRef } from 'react'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Button, Card } from 'react-bootstrap'
import { doc, getDoc, collection, addDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Form } from 'react-bootstrap'
import Papa from 'papaparse'
import PhoneInput from 'react-phone-input-2'
import AddressInput from '../../../../Inputs/AddressInput'

export const AddPlaintiff = forwardRef(props => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { id } = useParams()

  // Question 2_1
  const [plaintiffType, setPlaintiffType] = useState('')
  const [pFullName, setPFullName] = useState('')
  const [pBusName, setPBusName] = useState('')
  const [pGuardName, setPGuardName] = useState('')
  const [pDOB, setPDOB] = useState('')

  const [pLoc, setPLoc] = useState('')
  const [pPhone, setPPhone] = useState('')
  const [pEmail, setPEmail] = useState('')
  const [pAttorney, setPAttorney] = useState('')
  const [pBillingID, setPBillingID] = useState('')

  const [csvData] = useState([])
  const [didUpload] = useState(false)

  const FileInput = ({ value, ...rest }) => {
    const inputRef = useRef()

    useEffect(() => {
      if (value === '') {
        inputRef.current.value = ''
      } else {
        inputRef.current.files = value
      }
    }, [value])

    function returnFile() {
      if (inputRef.current.files[0]['name'].indexOf('.csv') !== -1) {
        setPlaintiffType('Individual')
        // Parse the CSV file using Papa
        Papa.parse(inputRef.current.files[0], {
          //87 is full name
          //88 is address
          //89 is phone number
          //92 is email
          //93 is DOB
        })
      }
    }

    return <input {...rest} type='file' ref={inputRef} onChange={returnFile} />
  }

  function plaintiffIsValid() {
    if (plaintiffType === '') {
      return false
    } else if (plaintiffType === 'Individual') {
      if (pFullName === '' || pDOB === '') {
        return false
      }
    } else if (plaintiffType === 'Minor') {
      if (pFullName === '' || pGuardName === '' || pDOB === '') {
        return false
      }
    } else if (plaintiffType === 'Business') {
      if (pBusName === '') {
        return false
      }
    }
    return true
  }

  async function createPlaintiff() {
    const matterSnap = await getDoc(doc(db, 'matters', id))
    const creatorSnap = await getDoc(
      doc(db, 'firmUsers', matterSnap.data().creatorID)
    )

    await getDoc(doc(db, 'firms', creatorSnap.data().firmID))

    let csvFinal = {}
    if (csvData !== []) {
      for (let i = 0; i < csvData.length; i++) {
        csvFinal[i] = csvData[i]
      }
    }
    // Create the new plaintiff
    await addDoc(collection(db, 'matters', id, 'plaintiffs'), {
      full_name: pFullName,
      guardian_name: pGuardName,
      business_name: pBusName,
      type: plaintiffType,
      DOB: pDOB,
      loc: pLoc,
      phone: pPhone,
      email: pEmail,
      attorney_name: pAttorney,
      sentIntake: false,
      completedIntake: false,
      csvData: csvFinal,
      billingID: pBillingID
    })
    clearValues()
    props.addedPlaintiff()
  }

  function clearValues() {
    setPFullName('')
    setPBusName('')
    setPGuardName('')
    setPDOB('')
    setPLoc('')
    setPPhone('')
    setPEmail('')
    setPAttorney('')
    addressRef.current.clear()
  }

  return (
    <Card style={{ border: 'white', 'margin-top': '-15px' }}>
      <Card.Body>
        <h4 className='text-center mb-4'>Add Plaintiff</h4>
        {props.isTort ? (
          <div>
            <div>Upload CSV file:</div>
            <FileInput />
          </div>
        ) : (
          ''
        )}
        <br></br>
        <Form>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Individual'
                name='group1'
                type={type}
                checked={plaintiffType === 'Individual'}
                onClick={() => setPlaintiffType('Individual')}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='Minor'
                name='group1'
                type={type}
                checked={plaintiffType === 'Minor'}
                onClick={() => setPlaintiffType('Minor')}
                id={`inline-${type}-2`}
              />
              <Form.Check
                inline
                label='Business'
                name='group1'
                type={type}
                checked={plaintiffType === 'Business'}
                onClick={() => setPlaintiffType('Business')}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
          <br></br>
          {plaintiffType === 'Individual' ? (
            <div>
              <div className='input-container'>
                <input
                  type='text'
                  value={pFullName}
                  required
                  onChange={e => setPFullName(e.target.value)}
                />
                <label className={pFullName && 'filled'}>
                  {'Plaintiff Full Legal Name'}
                </label>
              </div>
              <br></br>
              <div className='input-container'>
                <input
                  type={didUpload ? 'text' : 'date'}
                  value={pDOB}
                  required
                  onChange={e => setPDOB(e.target.value)}
                />
                <label className={'filled'}>{'Plaintiff DOB'}</label>
              </div>
              <br></br>
            </div>
          ) : (
            ''
          )}
          {plaintiffType === 'Minor' ? (
            <div>
              <div className='input-container'>
                <input
                  type='text'
                  value={pFullName}
                  required
                  onChange={e => setPFullName(e.target.value)}
                />
                <label className={pFullName && 'filled'}>
                  {'Plaintiff Full Legal Name'}
                </label>
              </div>
              <br></br>
              <div className='input-container'>
                <input
                  type='text'
                  value={pGuardName}
                  required
                  onChange={e => setPGuardName(e.target.value)}
                />
                <label className={pGuardName && 'filled'}>
                  {'By and Through Their Guardian Ad Litem'}
                </label>
              </div>
              <br></br>
              <div className='input-container'>
                <input
                  type={didUpload ? 'text' : 'date'}
                  value={pDOB}
                  required
                  onChange={e => setPDOB(e.target.value)}
                />
                <label className={'filled'}>{'Plaintiff DOB'}</label>
              </div>
              <br></br>
            </div>
          ) : (
            ''
          )}
          {plaintiffType === 'Business' ? (
            <div>
              <div className='input-container'>
                <input
                  type='text'
                  value={pBusName}
                  required
                  onChange={e => setPBusName(e.target.value)}
                />
                <label className={pBusName && 'filled'}>
                  {'Plaintiff Full Business Name'}
                </label>
              </div>
              <br></br>
            </div>
          ) : (
            ''
          )}
          <AddressInput
            passData={data => setPLoc(data)}
            ref={addressRef}
            ph={'Address'}
          />
          <br></br>
          <div className='phone-input-container' style={{ margin: 'auto' }}>
            <PhoneInput
              enableAreaCodes={false}
              inputStyle={{
                height: '44px',
                width: 'inherit',
                borderColor: 'rgb(214, 214, 214)'
              }}
              disableCountryCode
              country={'us'}
              onlyCountries={['us']}
              value={pPhone}
              placeholder=''
              onChange={setPPhone}
            />

            <label className={pPhone && 'filled'}>{'Plaintiff Phone'}</label>
          </div>
          <br></br>
          <div className='input-container'>
            <input
              type='text'
              value={pEmail}
              required
              onChange={e => setPEmail(e.target.value)}
            />
            <label className={pEmail && 'filled'}>{'Email'}</label>
          </div>
          <br></br>
          <div className='input-container'>
            <input
              type='text'
              value={pAttorney}
              required
              onChange={e => setPAttorney(e.target.value)}
            />
            <label className={pAttorney && 'filled'}>{'Attorney Name'}</label>
          </div>
          <br></br>
          <div className='input-container'>
            <input
              type='text'
              value={pBillingID}
              required
              onChange={e => setPBillingID(e.target.value)}
            />
            <label className={pBillingID && 'filled'}>
              {'Internal Billing ID'}
            </label>
          </div>
          <br></br>
          <Button
            className='w-100'
            variant='success'
            disabled={!plaintiffIsValid()}
            onClick={() => createPlaintiff()}
          >
            Add Plaintiff
          </Button>
          <br></br>
          <br></br>
          <p style={{ color: 'gray' }}>You can change these values later</p>
          {/* <Form.Text style={{ color: 'red' }}>
                        {plaintiffError}
                    </Form.Text> */}
          <br></br>
        </Form>
      </Card.Body>
    </Card>
  )
})

export default AddPlaintiff
