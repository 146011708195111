import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q1_1 = forwardRef((props, ref) => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()
  // Question 1_1
  const [q1, setQ1] = useState([])
  const [q1a, setQ1a] = useState('')
  const [q1b, setQ1b] = useState('')
  const [q1c, setQ1c] = useState('')
  const [q1d, setQ1d] = useState('')

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q1_1: q1
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q1_1) {
      setQ1(docSnap.data().q1_1)
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addParty() {
    if (q1a !== '') {
      let currList = q1
      let newEntry = { Name: q1a, Address: q1b, Relation: q1c, Phone: q1d }
      currList.push(newEntry)
      setQ1(currList)

      setQ1a('')
      setQ1b('')
      setQ1c('')
      setQ1d('')
      addressRef.current.clear()
    }
  }

  function remParty(index) {
    let newList = []
    for (let i = 0; i < q1.length; i++) {
      if (i !== index) {
        newList.push(q1[i])
      }
    }
    setQ1(newList)
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Name']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Address']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Relationship: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Relation']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Phone']}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Name']}
                  onChange={e => (props.dict['Name'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Address']}
                  onChange={e => (props.dict['Address'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Relationship: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Relation']}
                  onChange={e => (props.dict['Relation'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Phone']}
                  onChange={e => (props.dict['Phone'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div
        style={{
          textAlign: 'center',
          maxWidth: '400px',
          margin: 'auto',
          marginTop: '-10px'
        }}
      >
        Fill out the following info for each person who prepared or assisted in
        the preparation of the responses to these interrogatories. (Do not
        identify anyone who simply typed or reproduced the responses)
      </div>
      <br></br>
      <div></div>
      <Card style={{ maxWidth: '400px', margin: 'auto', border: 'white' }}>
        <div className='input-container'>
          <input
            type='text'
            value={q1a}
            required
            onChange={e => setQ1a(e.target.value)}
          />
          <label className={q1a && 'filled'}>{'Full Name'}</label>
        </div>
        <div style={{ marginTop: '15px' }}></div>

        <AddressInput passData={data => setQ1b(data)} ref={addressRef} />

        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container'>
          <input
            type='text'
            value={q1c}
            required
            onChange={e => setQ1c(e.target.value)}
          />
          <label className={q1c && 'filled'}>{'Relationship'}</label>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div className='phone-input-container' style={{ margin: 'auto' }}>
          <PhoneInput
            enableAreaCodes={false}
            inputStyle={{
              height: '44px',
              width: '400px',
              borderColor: 'rgb(214, 214, 214)'
            }}
            disableCountryCode
            country={'us'}
            onlyCountries={['us']}
            value={q1d}
            placeholder=''
            onChange={setQ1d}
          />

          <label className={q1d && 'filled'}>{'Phone Number'}</label>
        </div>
        <div style={{ margin: 'auto', marginTop: '15px' }}>
          <div
            className='AddPerson'
            style={{ color: 'white' }}
            onClick={() => addParty()}
          >
            <div>&nbsp;Add&nbsp;</div>
            <AddIcon style={{ color: 'white' }}></AddIcon>
          </div>
        </div>
      </Card>
      {q1.length > 0 ? <hr></hr> : ''}
      <div style={{ marginTop: '20px' }}>
        <div>
          {q1.map((key, i) => {
            return (
              <Accordion
                style={{
                  backgroundColor: 'white',
                  boxShadow: '0px 1px 3px #cccccc',
                  marginBottom: '4px',
                  maxWidth: '600px'
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {q1[i]['Name']}

                  {q1[i]['Relation'] !== 'firm' ? (
                    <div className='RemButton' onClick={() => remParty(i)}>
                      &nbsp;
                      <RemoveIcon></RemoveIcon>
                      Remove&nbsp;&nbsp;&nbsp;
                    </div>
                  ) : (
                    ''
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <EditableAccordionDetails dict={q1[i]} />
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </div>
    </div>
  )
})

export default Q1_1
