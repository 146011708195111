import React, { useState } from 'react'
import './Matters.css'
import { UserAuth } from '../../../contexts/AuthContext'
import { doc, getDoc, setDoc, addDoc, collection } from 'firebase/firestore'
import { db } from '../../../firebase'
import { Card } from 'react-bootstrap'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router'
import 'reactjs-popup/dist/index.css'
import { InputGroup, Form, Button } from 'react-bootstrap'
import RemoveIcon from '@mui/icons-material/Remove'

export default function CreateMatter() {
  const [, setLoading] = useState(false)
  // Variables for matter creation
  const [newMatter, setNewMatter] = useState('')
  const [filedComplaint, setFiledComplaint] = useState(false)
  const [complaintDate, setComplaintDate] = useState('')
  const [cordProc, setCordProc] = useState(false)
  const [stat, setStat] = useState('') // status
  const [dol, setDol] = useState('') // date of loss
  const [sol, setSol] = useState(0) // statute of limitations
  const [dtfc, setDtfc] = useState('') // dead to file comp
  const [newCause, setNewCause] = useState('') // singular cause of action
  const [coa, setCoa] = useState([]) // all cause[s] of action
  const [specialTitle, setSpecialTitle] = useState('') // special title
  const [intID, setIntID] = useState('') // internal ID
  const [js, setJs] = useState('') // jurisdiction state
  const [jc, setJc] = useState('') // jurisdiction county and district
  const [judge, setJudge] = useState('') // judge

  const [chName, setChName] = useState('') // courthouse name
  const [chSt, setChSt] = useState('') // courthouse street
  const [chCity, setChCity] = useState('') // courthouse city
  const [chState, setChState] = useState('') // courthouse state
  const [chZip, setChZip] = useState('') // courthouse zip

  const [department, setDepartment] = useState('') // department

  const [fillMsg, setFillMsg] = useState('') // autofill msg

  const { user } = UserAuth()
  const navigate = useNavigate()

  function addCause(cause) {
    if (cause !== '') {
      let array = coa
      array.push(cause)
      setCoa(array)
      setNewCause('')
    }
  }

  function remCause(cause) {
    const array = []
    coa.forEach(val => {
      array.push(val)
    })

    if (array.length === 1) {
      setCoa([])
    } else {
      let i = coa.indexOf(cause)
      delete array[i]

      setCoa(array)
    }
  }

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()
    return cMonth + '/' + cDay + '/' + cYear
  }

  function matterIsValid() {
    return !(
      newMatter === '' ||
      dol === '' ||
      sol === '' ||
      dol === '' ||
      dtfc === '' ||
      js === '' ||
      jc === '' ||
      stat === '' ||
      intID === ''
    )
  }

  async function createNewMatter() {
    if (matterIsValid()) {
      setLoading(true)
      // get user's firm name:

      const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
      const firmID = userSnap.data().firmID
      // Create the new matter
      const newMatterRef = await addDoc(collection(db, 'matters'), {
        caseName: newMatter,
        dateCreated: getTime(),
        creatorID: user.uid,
        status: stat,
        coordinationProceeding: cordProc,
        specialTitle: specialTitle,
        causesOfAction: coa,

        jdxCourtHouseName: chName,
        jdxCourtHouseState: chState,
        jdxCourtHouseStreetAddress: chSt,
        jdxCourtHouseZip: chZip,
        jdxCourtHouseCity: chCity,

        jdxCounty: jc,
        jdxState: js,
        jdxDepartment: department,
        jdxJudge: judge,

        internalID: intID,
        complaintDeadline: dtfc,
        hasFiledComplaint: filedComplaint,
        dateOfComplaint: complaintDate,
        dateOfLoss: dol,
        statuteOfLimitations: sol
      })

      let createContent = user.email + ' created matter: ' + newMatter

      // Create the activities collection
      await addDoc(collection(db, 'matters', newMatterRef.id, 'activities'), {
        user: user.uid,
        date: getTime(),
        content: createContent
      })

      let myCreateContent = 'Created a matter: ' + newMatter

      // Add to my activities collection
      await addDoc(collection(db, 'firmUsers', user.uid, 'activities'), {
        type: 'matter',
        date: getTime(),
        content: myCreateContent
      })

      // Link the user to the matter
      await setDoc(
        doc(db, 'matters', newMatterRef.id, 'shared_users', user.uid),
        {
          isAdmin: true
        }
      )

      // Then link the matter to the user
      await setDoc(
        doc(db, 'firmUsers', user.uid, 'my_matters', newMatterRef.id),
        {}
      )

      const myFirmRef = doc(db, 'firms', firmID)
      const myFirmSnap = await getDoc(myFirmRef)
      const firmObj = myFirmSnap.data()

      // Then link the matter to the firm
      await setDoc(doc(db, 'firms', firmID, 'matters', newMatterRef.id), {})

      // Finally link the firm to the matter
      await setDoc(doc(db, 'matters', newMatterRef.id, 'firms', firmID), {
        fax: firmObj.fax,
        location: firmObj.location,
        name: firmObj.name,
        street: firmObj.street,
        zip: firmObj.zip,
        firmID: myFirmSnap.id,
        inDB: true
      })

      navigate('/app/matters/' + newMatterRef.id + '/')
    }
  }

  function setSolAndDead(val) {
    setSol(val)
    let year = String(parseInt(dol.substring(0, 4)) + parseInt(val))
    let md = dol.substring(4, dol.length)
    setDtfc(year + md)
    setFillMsg('(you can modify the autofilled value)')
  }

  return (
    <Card style={{ padding: '30px', border: 'white' }}>
      <div className='MattersTop'>
        <h5 className='text-center mb-4'>Create New Matter</h5>
      </div>
      <br></br>
      <Form>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Case Name'
            type='text'
            value={newMatter}
            onChange={e => setNewMatter(e.target.value)}
            required
          />
        </Form.Group>
        &nbsp;
        <Form.Group className='mb-3' controlId='cb1'>
          <Form.Check
            type='checkbox'
            label='Have you Filed A Complaint?'
            onClick={() => setFiledComplaint(!filedComplaint)}
          />
        </Form.Group>
        {filedComplaint ? (
          <Form.Group id='type' style={{ 'padding-left': '50px' }}>
            <Form.Label style={{ color: 'black' }}>
              When did you file the complaint?
            </Form.Label>
            <Form.Control
              type='date'
              value={complaintDate}
              onChange={e => setComplaintDate(e.target.value)}
              required
            />
          </Form.Group>
        ) : (
          <div></div>
        )}
        <br></br>
        <Form.Group id='type'>
          <Form.Label style={{ color: 'black' }}>Date of Loss</Form.Label>
          <Form.Control
            type='date'
            value={dol}
            onChange={e => setDol(e.target.value)}
            required
          />
        </Form.Group>
        <br></br>
        <Form.Select
          aria-label='Default select example'
          onChange={e => setSolAndDead(e.target.value)}
        >
          <option value=''>Statute of Limitations (YEARS)</option>
          <option value='1'>1</option>
          <option value='2'>2</option>
          <option value='3'>3</option>
          <option value='4'>4</option>
        </Form.Select>
        <br></br>
        <Form.Group id='type'>
          <Form.Label style={{ color: 'black' }}>
            Deadline to File Complaint
          </Form.Label>
          <Form.Label style={{ color: 'gray' }}>&nbsp; {fillMsg}</Form.Label>
          <Form.Control
            placeholder='Deadline To File Complaint'
            type='date'
            value={dtfc}
            onChange={e => setDtfc(e.target.value)}
            required
          />
        </Form.Group>
        <br></br>
        <InputGroup className='mb-3' style={{ width: '80%', margin: 'auto' }}>
          <Form.Control
            placeholder='Add Causes Of Action'
            aria-describedby='basic-addon2'
            value={newCause}
            onChange={e => setNewCause(e.target.value)}
          />
          <Button
            variant='outline-secondary'
            id='button-addon2'
            onClick={() => addCause(newCause)}
          >
            <AddIcon></AddIcon>
          </Button>
        </InputGroup>
        {coa.map(val => {
          return (
            <div className='CauseList'>
              <div className='Cause'>
                {val}
                <div className='RemCause' onClick={() => remCause(val)}>
                  <RemoveIcon></RemoveIcon>
                </div>
              </div>
            </div>
          )
        })}
        <br></br>
        <Form.Group className='mb-3' controlId='cb2'>
          <Form.Check
            type='checkbox'
            label='Is this a Coordination Proceeding?'
            onClick={() => setCordProc(!cordProc)}
          />
        </Form.Group>
        {cordProc ? (
          <Form.Group id='type' style={{ 'padding-left': '50px' }}>
            <Form.Control
              placeholder='Special Title of Coordination Proceeding'
              type='text'
              value={specialTitle}
              onChange={e => setSpecialTitle(e.target.value)}
              required
            />
          </Form.Group>
        ) : (
          <div></div>
        )}
        <br></br>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Jurisdiction State'
            type='text'
            value={js}
            onChange={e => setJs(e.target.value)}
            required
          />
        </Form.Group>
        <br></br>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Jurisdiction County and District'
            type='text'
            value={jc}
            onChange={e => setJc(e.target.value)}
            required
          />
        </Form.Group>
        <br></br>
        <br></br>
        <Form.Label style={{ color: 'black' }}>
          Courthouse Information
        </Form.Label>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Courthouse Name (optional)'
            type='text'
            value={chName}
            onChange={e => setChName(e.target.value)}
            required={false}
          />
        </Form.Group>
        <br></br>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Courthouse Street Address (optional)'
            type='text'
            value={chSt}
            onChange={e => setChSt(e.target.value)}
            required={false}
          />
        </Form.Group>
        <br></br>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Courthouse City (optional)'
            type='text'
            value={chCity}
            onChange={e => setChCity(e.target.value)}
            required={false}
          />
        </Form.Group>
        <br></br>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Courthouse State (optional)'
            type='text'
            value={chState}
            onChange={e => setChState(e.target.value)}
            required={false}
          />
        </Form.Group>
        <br></br>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Courthouse Zip (optional)'
            type='text'
            value={chZip}
            onChange={e => setChZip(e.target.value)}
            required={false}
          />
        </Form.Group>
        <br></br>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Department (optional)'
            type='text'
            value={department}
            onChange={e => setDepartment(e.target.value)}
            required={false}
          />
        </Form.Group>
        <br></br>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Judge (optional)'
            type='text'
            value={judge}
            onChange={e => setJudge(e.target.value)}
            required={false}
          />
        </Form.Group>
        <br></br>
        <br></br>
        <Form.Select
          aria-label='Default select example'
          onChange={e => setStat(e.target.value)}
        >
          <option value=''>Status</option>
          <option value='Steady'>Steady</option>
          <option value='Complete'>Complete</option>
          <option value='Urgent'>Urgent</option>
        </Form.Select>
        <br></br>
        <Form.Group id='type'>
          <Form.Control
            placeholder='Internal ID'
            type='text'
            value={intID}
            onChange={e => setIntID(e.target.value)}
            required
          />
        </Form.Group>
        <br></br>
        <Button
          className='w-100'
          variant='success'
          disabled={!matterIsValid()}
          onClick={() => createNewMatter()}
        >
          Create
        </Button>
        <br></br>
        <br></br>
        <p style={{ color: 'gray' }}>You can change these values later</p>
      </Form>
    </Card>
  )
}
