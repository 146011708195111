import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'

export const Q20_3 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q20_3began, setQ20_3began] = useState('')
  const [q20_3destination, setQ20_3destination] = useState('')

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q20_3: { Began: q20_3began, Destination: q20_3destination }
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q20_3) {
      if (docSnap.data().q20_3 !== 'N/A') {
        setQ20_3began(docSnap.data().q20_3['Began'])
        setQ20_3destination(docSnap.data().q20_3['Destination'])
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          State address/location where your trip began <br></br>
          and the address/location of your destination
          <div style={{ marginTop: '15px' }}></div>
        </div>

        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container'>
          <input
            type='text'
            value={q20_3began}
            required
            onChange={e => setQ20_3began(e.target.value)}
          />
          <label className={q20_3began && 'filled'}>{'Beginning'}</label>
        </div>

        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container'>
          <input
            type='text'
            value={q20_3destination}
            required
            onChange={e => setQ20_3destination(e.target.value)}
          />
          <label className={q20_3destination && 'filled'}>
            {'Destination'}
          </label>
        </div>
      </div>
    </div>
  )
})

export default Q20_3
