import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q2_7 = forwardRef((props, ref) => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  // Question 2_1
  const [q2_7, setQ2_7] = useState([])

  const [q2_7name, setQ2_7name] = useState('')
  const [q2_7loc, setQ2_7loc] = useState('')
  const [q2_7level, setQ2_7level] = useState('')
  const [q2_7degree, setQ2_7degree] = useState('')
  const [q2_7from, setQ2_7from] = useState('')
  const [q2_7to, setQ2_7to] = useState('')
  const [q2_7current, setQ2_7current] = useState(false)

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q2_7: q2_7
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q2_7) {
      let qList = docSnap.data().q2_7

      setQ2_7(qList)
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addSchool() {
    if (q2_7name !== '') {
      let currList = q2_7
      if (q2_7current) {
        let newEntry = {
          Type: 'past',
          Name: q2_7name,
          Loc: q2_7loc,
          Level: q2_7level,
          Degree: q2_7degree,
          FromDate: q2_7from,
          ToDate: q2_7to
        }
        currList.push(newEntry)
      } else {
        let newEntry = {
          Type: 'current',
          Name: q2_7name,
          Loc: q2_7loc,
          Level: q2_7level,
          Degree: q2_7degree,
          FromDate: q2_7from,
          ToDate: q2_7to
        }
        currList.push(newEntry)
      }
      setQ2_7loc('')
      setQ2_7degree('')
      setQ2_7level('')
      setQ2_7from('')
      setQ2_7to('')
      setQ2_7name('')
      setQ2_7current(false)

      setQ2_7(currList)
      addressRef.current.clear()
    }
  }

  function remSchool(index) {
    let newList = []
    for (let i = 0; i < q2_7.length; i++) {
      if (i !== index) {
        newList.push(q2_7[i])
      }
    }
    setQ2_7(newList)
  }

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()

    if (cMonth.toString().length === 1) {
      cMonth = '0' + cMonth.toString()
    }
    if (cDay.toString().length === 1) {
      cDay = '0' + cDay.toString()
    }

    return cYear + '-' + cMonth + '-' + cDay
  }

  function setCurrent() {
    setQ2_7current(true)
    setQ2_7to(getTime())
  }

  function setNotCurrent() {
    setQ2_7current(false)
    setQ2_7to('')
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Name']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Loc']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Level Fin:&nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Level']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Degree: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Degree']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              From: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['FromDate']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              To: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['ToDate']}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Name']}
                  onChange={e => (props.dict['Name'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Loc']}
                  onChange={e => (props.dict['Loc'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Level Fin: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Level']}
                  onChange={e => (props.dict['Level'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Degree: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Degree']}
                  onChange={e => (props.dict['Degree'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              From: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['FromDate']}
                  onChange={e => (props.dict['FromDate'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              To: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['ToDate']}
                  onChange={e => (props.dict['ToDate'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ minWidth: '400px', marginTop: '-10px' }}>
      <Card
        style={{ width: '400px', height: 'fit-content', borderColor: 'white' }}
      >
        <div style={{ textAlign: 'center' }}>
          State the following info for each school or other<br></br>
          academic or vocational institution you have<br></br>
          attended, beginning with high school:
        </div>
        <br></br>
        <div className='input-container'>
          <input
            type='text'
            value={q2_7name}
            required
            onChange={e => setQ2_7name(e.target.value)}
          />
          <label className={q2_7name && 'filled'}>
            {'Name of Institution'}
          </label>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          Do you currently attend this institution?
          <div style={{ marginTop: '5px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q2_7current}
                onClick={() => setCurrent()}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q2_7current}
                onClick={() => setNotCurrent()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <AddressInput
          passData={data => setQ2_7loc(data)}
          ref={addressRef}
          ph={'Address (or search name)'}
        />
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container'>
          <input
            type='text'
            value={q2_7level}
            required
            onChange={e => setQ2_7level(e.target.value)}
          />
          <label className={q2_7level && 'filled'}>
            {'Highest Grade Level Completed'}
          </label>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container'>
          <input
            type='text'
            value={q2_7degree}
            required
            onChange={e => setQ2_7degree(e.target.value)}
          />
          <label className={q2_7degree && 'filled'}>{'Degree received'}</label>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div style={{ display: 'flex' }}>
          <div style={{ textAlign: 'center' }}>
            From:
            <div className='input-container'>
              <input
                type='date'
                value={q2_7from}
                required
                onChange={e => setQ2_7from(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginLeft: 'auto', textAlign: 'center' }}>
            To:
            <div className='input-container'>
              <input
                type='date'
                value={q2_7to}
                required
                onChange={e => setQ2_7to(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <div
            className='AddPerson'
            style={{ color: 'white', margin: 'auto' }}
            onClick={() => addSchool()}
          >
            <div>&nbsp;Add&nbsp;</div>
            <AddIcon style={{ color: 'white' }}></AddIcon>
          </div>
        </div>
      </Card>
      <div style={{ marginTop: '20px', maxWidth: '400px' }}>
        {q2_7.length >= 1 ? <hr></hr> : ''}
        <div>
          {q2_7.map((key, i) => {
            return (
              <Accordion
                style={{
                  backgroundColor: 'white',
                  boxShadow: '0px 1px 3px #cccccc',
                  marginBottom: '4px'
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {q2_7[i]['Name']}, &nbsp;
                  {q2_7[i]['FromDate'].slice(0, 4)}-
                  {q2_7[i]['ToDate'].slice(0, 4)}
                  <div className='RemButton' onClick={() => remSchool(i)}>
                    &nbsp;
                    <RemoveIcon></RemoveIcon>
                    Remove&nbsp;&nbsp;&nbsp;
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <EditableAccordionDetails dict={q2_7[i]} />
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </div>
    </div>
  )
})

export default Q2_7
