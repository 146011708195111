import React from 'react'
import { Container } from 'react-bootstrap'
import { Navbar } from 'react-bootstrap'
import './TopbarIn.css'
import logo from '../../images/tl_black.png'

export default function TopbarClient() {
  return (
    <div>
      {/* <div className='placeholder'></div> */}
      <div className='navPlaceHolder'></div>
      <Navbar bg='white' fixed='top' className='TopBarIn'>
        <Container fluid>
          <Navbar.Brand style={{ marginTop: '10px' }}>
            <div className='Logo'>
              <img className='TLLogo' src={logo} alt='logo' />
              <h3>TAP</h3>
              <h3 style={{ color: 'red' }}>LAW</h3>
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </div>
  )
}
