import React, { useState } from 'react'
import { Form, Button, Card } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { UserAuth } from '../contexts/AuthContext'
import { Navigate } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'
import { auth } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import LoginIcon from '@mui/icons-material/Login'
import './Signin.css'
import { Fade } from '@mui/material'
import '../Inputs/Inputs.css'

export default function Signin() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [error, setError] = useState('')
  const { signIn } = UserAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  if (isLoggedIn) {
    return <Navigate to='/app/matters' />
  }

  onAuthStateChanged(auth, user => {
    if (user) {
      if (!isLoggedIn) {
        setIsLoggedIn(true)
      }
    } else {
      if (isLoggedIn) {
        setIsLoggedIn(false)
      }
    }
  })

  async function handleSubmit(e) {
    setLoading(true)
    e.preventDefault()
    setError('')
    try {
      await signIn(email, password).then(() => {
        navigate('/app/matters')
      })
    } catch (e) {
      if (e.message === 'Firebase: Error (auth/wrong-password).') {
        setLoading(false)
        setError('Invalid Password')
      } else if (e.message === 'Firebase: Error (auth/user-not-found).') {
        setLoading(false)
        setError('User Not Found')
      } else {
        setLoading(false)
        setError(e.message)
      }
    }
  }

  return (
    <Fade in={true} appear timeout={800}>
      <div>
        <div className='LoginPage'>
          <div className='LoginContainer'>
            <img
              className='LoginImg'
              alt=''
              src={require('../images/signinimg.png')}
            ></img>
            <div className='RightHalf'>
              <Card border='white'>
                <Card.Body>
                  <h2 className='text-center mb-4'>Log In</h2>
                  <br></br>
                  <Form onSubmit={handleSubmit}>
                    <div className='input-container'>
                      <input
                        type='email'
                        value={email}
                        required
                        onChange={e => setEmail(e.target.value)}
                      />
                      <label className={email && 'filled'}>{'Email'}</label>
                    </div>
                    <br></br>
                    <div className='input-container'>
                      <input
                        type='password'
                        value={password}
                        required
                        onChange={e => setPassword(e.target.value)}
                      />
                      <label className={password && 'filled'}>
                        {'Password'}
                      </label>
                    </div>
                    <br></br>
                    <Button
                      type='submit'
                      style={{
                        background: '#3B8FF0',
                        'border-color': '#3B8FF0',
                        width: '100%',
                        height: '44px',
                        display: 'flex',
                        'justify-content': 'center',
                        'align-items': 'center'
                      }}
                    >
                      {loading ? (
                        <div>
                          <PulseLoader color='white' size='8' />
                        </div>
                      ) : (
                        <div>
                          <LoginIcon
                            style={{ 'margin-top': '-3px' }}
                          ></LoginIcon>
                          &nbsp; Log In
                        </div>
                      )}{' '}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
              <div style={{ color: '#dd5555', textAlign: 'center' }}>
                {error}
              </div>
              {error ? <br></br> : ''}
              <div className='w-100 text-center mt-2'>
                Don't have an account?{' '}
                <Link to='/register' className='underline'>
                  Register
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  )
}
