import React, { useState, useEffect } from 'react'
import './Matters.css'
import { UserAuth } from '../../../contexts/AuthContext'
import { doc, getDocs, getDoc, collection } from 'firebase/firestore'
import { db } from '../../../firebase'
import 'reactjs-popup/dist/index.css'
import { Fade } from 'react-bootstrap'
import { PulseLoader } from 'react-spinners'
import { useParams } from 'react-router'
import MatterBlock from './MatterBlock'

export default function MyMatters() {
  const [myMatters, setMyMatters] = useState([])
  const [myMattersID, setMyMattersID] = useState({})
  const [loadingMatters, setLoadingMatters] = useState(true)
  const { id } = useParams()

  const { user } = UserAuth()

  async function getAndSetUserData(user) {
    const matterList = await getDocs(
      collection(db, 'firmUsers', user.uid, 'my_matters')
    )
    let myMattersTemp = []

    matterList.forEach(doc => {
      myMattersTemp.push(doc.id)
    })

    let tempDict1 = {}

    await Promise.all(
      myMattersTemp.map(async val => {
        const docRef = doc(db, 'matters', val)
        const myRef = await getDoc(docRef)
        const name = myRef.data().caseName
        const date = myRef.data().dateOfLoss
        const status = myRef.data().status
        const dol = myRef.data().dateOfLoss
        const tort = myRef.data().coordinationProceeding
        const tortName = myRef.data().specialTitle
        const loc = myRef.data().jdxState

        tempDict1[val] = [name, date, status, dol, loc, 'Me', tort, tortName]
      })
    )

    setMyMattersID(tempDict1)
    setMyMatters(myMattersTemp)
    setLoadingMatters(false)
  }

  //dont need a listener since they're yours. just load once.
  //inefficient to listen for my matters since you're not being
  //dynamically added or removed from them
  useEffect(() => {
    getAndSetUserData(user)
  }, [user])

  if (!id) {
    document.body.style.overflow = 'hidden'
  }

  if (loadingMatters) {
    return (
      <div style={{ margin: 'auto', marginTop: '10%' }}>
        <PulseLoader width={200} color={'#6aa4e6'} />
      </div>
    )
  } else {
    return (
      <Fade in={true} appear timeout={1200}>
        <div style={{ padding: '30px', overflowY: 'scroll', height: '100vh' }}>
          <div className='MyMattersPage'>
            <div className='MattersRows'>
              {myMatters.map(val => {
                return (
                  <Fade in={true} appear>
                    <MatterBlock data={myMattersID[val]} link={val} />
                  </Fade>
                )
              })}
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </Fade>
    )
  }
}
