import React, { useState, useEffect, useCallback } from 'react'
import './CaseInfo.css'
import { UserAuth } from '../../../../contexts/AuthContext'
import {
  doc,
  addDoc,
  getDoc,
  collection,
  onSnapshot,
  updateDoc
} from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Card } from 'react-bootstrap'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import 'reactjs-popup/dist/index.css'
import CheckIcon from '@mui/icons-material/Check'
import { InputGroup, Form, Button } from 'react-bootstrap'
import RemoveIcon from '@mui/icons-material/Remove'
import { useParams } from 'react-router'
import { useRef } from 'react'
import { Fade } from '@mui/material'
import '../../Account/Account.css'
import CircleIcon from '@mui/icons-material/Circle'

export default function CaseInfo() {
  const checkboxRef = useRef(null)
  const checkbox2Ref = useRef(null)
  const { id } = useParams()
  const [isEditing, setIsEditing] = useState(false)
  const [matterObject, setMatterObject] = useState({})

  // Variables for matter creation
  const [matterID, setMatterID] = useState('')
  const [caseName, setCaseName] = useState('')
  const [filedComplaint, setFiledComplaint] = useState(false)
  const [complaintDate, setComplaintDate] = useState('')
  const [cordProc, setCordProc] = useState(false)
  const [stat, setStat] = useState('') // status
  const [dol, setDol] = useState('') // date of loss
  const [sol, setSol] = useState(0) // statute of limitations
  const [dtfc, setDtfc] = useState('') // dead to file comp
  const [newCause, setNewCause] = useState('') // singular cause of action
  const [coa, setCoa] = useState([]) // all cause[s] of action
  const [specialTitle, setSpecialTitle] = useState('') // special title
  const [intID, setIntID] = useState('') // internal ID
  const [js, setJs] = useState('') // jurisdiction state
  const [jc, setJc] = useState('') // jurisdiction county and district
  const [judge, setJudge] = useState('') // judge

  const [chName, setChName] = useState('') // courthouse name
  const [chSt, setChSt] = useState('') // courthouse street
  const [chCity, setChCity] = useState('') // courthouse city
  const [chState, setChState] = useState('') // courthouse state
  const [chZip, setChZip] = useState('') // courthouse zip

  const [department, setDepartment] = useState('') // department

  const [solOpts, setSolOpts] = useState([])
  const [statOpts, setStatOpts] = useState([])

  const { user } = UserAuth()

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()
    return cMonth + '/' + cDay + '/' + cYear
  }

  function addCause(cause) {
    if (cause !== '') {
      let array = coa
      array.push(cause)
      setCoa(array)
      setNewCause('')
    }
  }

  function remCause(cause) {
    let arr = []
    coa.forEach(val => {
      if (val !== cause) {
        arr.push(val)
      }
    })

    if (arr.length !== 0) {
      setCoa(arr)
    } else {
      setCoa([])
    }
  }

  const retrieveCaseinfo = useCallback(async () => {
    if (!isEditing) {
      const myMatterRef = doc(db, 'matters', id)
      const myMattersSnap = await getDoc(myMatterRef)
      const obj = myMattersSnap.data()

      if (obj.hasFiledComplaint) {
        setFiledComplaint(true)
      }

      setMatterObject(obj)
      setMatterID(myMattersSnap.id)
      setCaseName(obj.caseName)
      setCoa(obj.causesOfAction)
      setFiledComplaint(obj.hasFiledComplaint)
      setComplaintDate(obj.dateOfComplaint)
      setCordProc(obj.coordinationProceeding)
      setStat(obj.status)
      setDol(obj.dateOfLoss)
      setSol(obj.statuteOfLimitations)
      setDtfc(obj.complaintDeadline)
      setSpecialTitle(obj.specialTitle)
      setIntID(obj.internalID)
      setJs(obj.jdxState)
      setJc(obj.jdxCounty)
      setJudge(obj.jdxJudge)
      setChName(obj.jdxCourtHouseName)
      setChSt(obj.jdxCourtHouseState)
      setChCity(obj.jdxCourtHouseCity)
      setChState(obj.jdxCourtHouseState)
      setChZip(obj.jdxCourtHouseZip)
      setDepartment(obj.jdxDepartment)

      let sol1 = ['1', '2', '3', '4']
      let sol2 = []
      sol1.forEach(val => {
        if (val !== obj.statuteOfLimitations) {
          sol2.push(val)
        }
      })
      setSolOpts(sol2)

      let stat1 = ['Steady', 'Complete', 'Urgent']
      let stat2 = []
      stat1.forEach(val => {
        if (val !== obj.status) {
          stat2.push(val)
        }
      })
      setStatOpts(stat2)
    }
  }, [id, isEditing])

  // Retrieve case info on page load
  useEffect(() => {
    onSnapshot(doc(db, 'matters', id), () => {
      retrieveCaseinfo()
    })
  }, [id, retrieveCaseinfo])
  function matterIsValid() {
    return !(
      matterID === '' ||
      dol === '' ||
      sol === '' ||
      dol === '' ||
      dtfc === '' ||
      js === '' ||
      jc === '' ||
      stat === '' ||
      intID === '' ||
      caseName === ''
    )
  }

  async function updateMatter() {
    if (matterIsValid()) {
      setIsEditing(false)
      let new_coa = []

      coa.forEach(val => {
        new_coa.push(val)
      })

      // Create the new matter
      await updateDoc(doc(db, 'matters', matterID), {
        caseName: caseName,
        status: stat,
        coordinationProceeding: cordProc,
        specialTitle: specialTitle,
        causesOfAction: new_coa,

        jdxCourtHouseName: chName,
        jdxCourtHouseState: chState,
        jdxCourtHouseStreetAddress: chSt,
        jdxCourtHouseZip: chZip,
        jdxCourtHouseCity: chCity,

        jdxCounty: jc,
        jdxState: js,
        jdxDepartment: department,
        jdxJudge: judge,

        internalID: intID,
        complaintDeadline: dtfc,
        hasFiledComplaint: filedComplaint,
        dateOfComplaint: complaintDate,
        dateOfLoss: dol,
        statuteOfLimitations: sol
      })

      let editContent = user.email + ' edited this matter'
      // Create the activities collection
      await addDoc(collection(db, 'matters', matterID, 'activities'), {
        user: user.uid,
        date: getTime(),
        content: editContent
      })

      let myEditContent = 'Modified case info for: ' + caseName

      // Add to my activities collection
      await addDoc(collection(db, 'firmUsers', user.uid, 'activities'), {
        type: 'matter',
        date: getTime(),
        content: myEditContent
      })
    }
  }

  return (
    <Fade in={true} appear timeout={800}>
      <div className=''>
        <div className='InfoTop' style={{ paddingTop: '20px' }}>
          <div className='InfoTopItems'>
            {isEditing ? (
              <div>
                <div
                  className='DoneBtn'
                  style={{ marginLeft: '5px' }}
                  onClick={() => updateMatter()}
                >
                  Done &nbsp;<CheckIcon></CheckIcon>
                </div>
              </div>
            ) : (
              <div className='InfoTop'>
                <h5>{caseName} Info</h5> &nbsp;&nbsp;&nbsp;
                <div className='InfoEdit'>
                  <EditIcon
                    style={{ color: 'black' }}
                    onClick={() => setIsEditing(true)}
                  ></EditIcon>
                </div>
              </div>
            )}
          </div>
        </div>
        <br></br>
        {isEditing ? (
          <div className='CaseInfo'>
            <div className='InfoColumn'>
              <Card style={{ padding: '30px' }} className='CardHeight'>
                <Form>
                  <Form.Label style={{ color: 'black' }}>Case Name</Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder='Case Name'
                      type='text'
                      value={caseName}
                      onChange={e => setCaseName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Group className='mb-3' controlId='cb1'>
                    <Form.Check
                      type='checkbox'
                      disabled={!isEditing}
                      label='Have you Filed A Complaint?'
                      ref={checkboxRef}
                      onClick={event => setFiledComplaint(event.target.checked)}
                      checked={filedComplaint}
                    />
                  </Form.Group>
                  {filedComplaint ? (
                    <Form.Group id='type' style={{ 'padding-left': '50px' }}>
                      <Form.Label style={{ color: 'black' }}>
                        When did you file the complaint?
                      </Form.Label>
                      <Form.Control
                        type='date'
                        value={complaintDate}
                        disabled={!isEditing}
                        onChange={e => setComplaintDate(e.target.value)}
                        required
                      />
                    </Form.Group>
                  ) : (
                    <div></div>
                  )}
                  <br></br>
                  <Form.Group id='type'>
                    <Form.Label style={{ color: 'black' }}>
                      Date of Loss
                    </Form.Label>
                    <Form.Control
                      type='date'
                      value={dol}
                      disabled={!isEditing}
                      onChange={e => setDol(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Statute of Limitations
                  </Form.Label>
                  <Form.Select
                    disabled={!isEditing}
                    value={sol}
                    aria-label='Default select example'
                    onChange={e => setSol(e.target.value)}
                  >
                    <option value={matterObject.statuteOfLimitations}>
                      {matterObject.statuteOfLimitations}
                    </option>
                    {solOpts.map(val => {
                      return <option value={val}>{val}</option>
                    })}
                  </Form.Select>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Deadline to File Complaint
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      disabled={!isEditing}
                      placeholder='Deadline To File Complaint'
                      type='text'
                      value={dtfc}
                      onChange={e => setDtfc(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  {isEditing ? (
                    <InputGroup
                      className='mb-3'
                      style={{ width: '80%', margin: 'auto' }}
                    >
                      <Form.Control
                        placeholder='Add Causes Of Action'
                        aria-describedby='basic-addon2'
                        value={newCause}
                        onChange={e => setNewCause(e.target.value)}
                      />
                      <Button
                        variant='outline-secondary'
                        id='button-addon2'
                        onClick={() => addCause(newCause)}
                      >
                        <AddIcon></AddIcon>
                      </Button>
                    </InputGroup>
                  ) : (
                    <Form.Label style={{ color: 'black', position: 'center' }}>
                      Causes of Action
                    </Form.Label>
                  )}
                  {coa.map(val => {
                    return (
                      <div className='CauseList'>
                        <div className='Cause'>
                          {val}
                          {isEditing ? (
                            <div
                              className='RemCause'
                              onClick={() => remCause(val)}
                            >
                              <RemoveIcon></RemoveIcon>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                  <br></br>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>Status</Form.Label>
                  <Form.Select
                    disabled={!isEditing}
                    aria-label='Default select example'
                    onChange={e => setStat(e.target.value)}
                  >
                    <option value={matterObject.status}>
                      {matterObject.status}
                    </option>
                    {statOpts.map(val => {
                      return <option value={val}>{val}</option>
                    })}
                  </Form.Select>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Internal ID
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder='Internal ID'
                      disabled={!isEditing}
                      type='text'
                      value={intID}
                      onChange={e => setIntID(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form>
              </Card>
            </div>
            <div className='InfoColumn'>
              <Card style={{ padding: '30px' }} className='CardHeight'>
                <Form>
                  <Form.Group className='mb-3' controlId='cb2'>
                    <Form.Check
                      disabled={!isEditing}
                      ref={checkbox2Ref}
                      type='checkbox'
                      label='Is this a Coordination Proceeding?'
                      onClick={event => setCordProc(event.target.checked)}
                      checked={cordProc}
                    />
                  </Form.Group>
                  {cordProc ? (
                    <Form.Group id='type' style={{ 'padding-left': '50px' }}>
                      <Form.Label style={{ color: 'black' }}>
                        Special Title of Coordination Proceeding
                      </Form.Label>
                      <Form.Control
                        disabled={!isEditing}
                        placeholder='Special Title of Coordination Proceeding'
                        type='text'
                        value={specialTitle}
                        onChange={e => setSpecialTitle(e.target.value)}
                        required
                      />
                    </Form.Group>
                  ) : (
                    <div></div>
                  )}
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Jurisdiction State
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder='Jurisdiction State'
                      disabled={!isEditing}
                      type='text'
                      value={js}
                      onChange={e => setJs(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Jurisdiction County and District
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      placeholder='Jurisdiction County and District'
                      type='text'
                      disabled={!isEditing}
                      value={jc}
                      onChange={e => setJc(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Courthouse Name {'(optional)'}
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      type='text'
                      disabled={!isEditing}
                      value={chName}
                      onChange={e => setChName(e.target.value)}
                      required={false}
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Courthouse Street Address {'(optional)'}
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      type='text'
                      value={chSt}
                      disabled={!isEditing}
                      onChange={e => setChSt(e.target.value)}
                      required={false}
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Courthouse City {'(optional)'}
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      type='text'
                      value={chCity}
                      disabled={!isEditing}
                      onChange={e => setChCity(e.target.value)}
                      required={false}
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Courthouse State {'(optional)'}
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      type='text'
                      value={chState}
                      disabled={!isEditing}
                      onChange={e => setChState(e.target.value)}
                      required={false}
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Group id='type'>
                    <Form.Label style={{ color: 'black' }}>
                      Courthouse Zip {'(optional)'}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={chZip}
                      disabled={!isEditing}
                      onChange={e => setChZip(e.target.value)}
                      required={false}
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Department {'(optional)'}
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      type='text'
                      value={department}
                      disabled={!isEditing}
                      onChange={e => setDepartment(e.target.value)}
                      required={false}
                    />
                  </Form.Group>
                  <br></br>
                  <Form.Label style={{ color: 'black' }}>
                    Judge {'(optional)'}
                  </Form.Label>
                  <Form.Group id='type'>
                    <Form.Control
                      type='text'
                      value={judge}
                      disabled={!isEditing}
                      onChange={e => setJudge(e.target.value)}
                      required={false}
                    />
                  </Form.Group>
                </Form>
              </Card>
            </div>
          </div>
        ) : (
          <div className='CaseInfo'>
            <div className='InfoColumn'>
              <Card style={{ padding: '30px' }} className='CardHeight'>
                <div className='infoRow'>
                  Have you filed a complaint: &nbsp;{' '}
                  <div style={{ color: 'black' }}>
                    {filedComplaint ? 'Yes' : 'No'}
                  </div>
                </div>
                {filedComplaint ? (
                  <div className='infoRow'>
                    &emsp;&emsp;Date complaint was filed: &nbsp;{' '}
                    <div style={{ color: 'black' }}>{complaintDate}</div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className='infoRow'>
                  Date of loss: &nbsp;{' '}
                  <div style={{ color: 'black' }}>{dol}</div>
                </div>
                <div className='infoRow'>
                  Statute of Limitations: &nbsp;{' '}
                  <div style={{ color: 'black' }}>{sol}</div>
                </div>
                <div className='infoRow'>
                  Deadline to File Complaint: &nbsp;{' '}
                  <div style={{ color: 'black' }}>{dtfc}</div>
                </div>
                <div className='infoRowL'>Causes of Action:</div>
                <div className='coaList'>
                  {coa.map(val => {
                    return (
                      <div className='infoRowX' style={{ color: 'black' }}>
                        &emsp;&emsp;
                        <CircleIcon style={{ width: '3%' }}></CircleIcon>&nbsp;
                        {val}
                      </div>
                    )
                  })}
                </div>
                <div className='infoRowTB'>
                  Status: &nbsp; <div style={{ color: 'black' }}>{stat}</div>
                </div>
                <div className='infoRowL'>
                  Internal ID: &nbsp;{' '}
                  <div style={{ color: 'black' }}>{intID}</div>
                </div>
              </Card>
            </div>
            <div className='InfoColumn'>
              <Card style={{ padding: '30px' }} className='CardHeight'>
                <div className='infoRow'>
                  Is this a Coordination Proceeding: &nbsp;{' '}
                  <div style={{ color: 'black' }}>
                    {cordProc ? 'Yes' : 'No'}
                  </div>
                </div>
                {cordProc ? (
                  <div className='infoRow'>
                    &emsp;&emsp;Special Title: &nbsp;{' '}
                    <div style={{ color: 'black' }}>{specialTitle}</div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className='infoRow'>
                  Jurisdiction State: &nbsp;{' '}
                  <div style={{ color: 'black' }}>{js}</div>
                </div>
                <div className='infoRow'>
                  Jurisdiction County and District: &nbsp;{' '}
                  <div style={{ color: 'black' }}>{jc}</div>
                </div>
                <div className='infoRow'>
                  Courthouse Name: &nbsp;{' '}
                  <div style={{ color: 'black' }}>
                    {chName === '' ? 'N/A' : chName}
                  </div>
                </div>
                <div className='infoRow'>
                  Courthouse Street Address: &nbsp;{' '}
                  <div style={{ color: 'black' }}>
                    {chSt === '' ? 'N/A' : chSt}
                  </div>
                </div>
                <div className='infoRow'>
                  Courthouse City: &nbsp;{' '}
                  <div style={{ color: 'black' }}>
                    {chCity === '' ? 'N/A' : chCity}
                  </div>
                </div>
                <div className='infoRow'>
                  Courthouse State: &nbsp;{' '}
                  <div style={{ color: 'black' }}>
                    {chState === '' ? 'N/A' : chState}
                  </div>
                </div>
                <div className='infoRow'>
                  Courthouse Zip: &nbsp;{' '}
                  <div style={{ color: 'black' }}>
                    {chZip === '' ? 'N/A' : chZip}
                  </div>
                </div>
                <div className='infoRow'>
                  Department: &nbsp;{' '}
                  <div style={{ color: 'black' }}>
                    {department === '' ? 'N/A' : department}
                  </div>
                </div>
                <div className='infoRowL'>
                  Judge: &nbsp;{' '}
                  <div style={{ color: 'black' }}>
                    {judge === '' ? 'N/A' : judge}
                  </div>
                </div>
              </Card>
            </div>
          </div>
        )}

        <br></br>
        <br></br>
        <br></br>
      </div>
    </Fade>
  )
}
