import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q11_1 = forwardRef((props, ref) => {
  const addressRef1 = useRef()
  const addressRef2 = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q11_1, setQ11_1] = useState([])

  const [q11_1date, setQ11_1date] = useState('')
  const [q11_1time, setQ11_1time] = useState('')
  const [q11_1location, setQ11_1location] = useState('')
  const [q11_1description, setQ11_1description] = useState('')

  const [q11_1court, setQ11_1court] = useState('')
  const [q11_1parties, setQ11_1parties] = useState('')
  const [q11_1cnum, setQ11_1cnum] = useState('')

  const [q11_1att_name, setQ11_1att_name] = useState('')
  const [q11_1att_address, setQ11_1att_address] = useState('')
  const [q11_1att_phone, setQ11_1att_phone] = useState('')

  const [q11_1PPL, setQ11_1PPL] = useState([])
  const [q11_1PPL_name, setQ11_1PPL_name] = useState('')
  const [q11_1PPL_address, setQ11_1PPL_address] = useState('')
  const [q11_1PPL_phone, setQ11_1PPL_phone] = useState('')

  const [q11_1status, setQ11_1status] = useState('')

  const [hasFiled, setHasFiled] = useState(false)

  async function saveQuestion() {
    if (q11_1.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q11_1: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q11_1: q11_1
        }
      )
    }
  }

  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q11_1) {
      if (docSnap.data().q11_1 !== 'N/A') {
        setHasFiled(true)
        setQ11_1(docSnap.data().q11_1)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addCase() {
    if (q11_1description !== '') {
      let currList = q11_1
      currList.push({
        Date: q11_1date,
        Time: q11_1time,
        Location: q11_1location,
        Court: q11_1court,
        Parties: q11_1parties,
        CaseNumber: q11_1cnum,
        AttorneyName: q11_1att_name,
        AttorneyAddress: q11_1att_address,
        AttorneyPhone: q11_1att_phone,
        Status: q11_1status,
        Description: q11_1description,
        People: q11_1PPL
      })

      setQ11_1(currList)

      setQ11_1date('')
      setQ11_1time('')
      setQ11_1location('')
      setQ11_1description('')
      setQ11_1court('')
      setQ11_1parties('')
      setQ11_1cnum('')
      setQ11_1att_name('')
      setQ11_1att_address('')
      setQ11_1att_phone('')
      setQ11_1status('')

      setQ11_1PPL([])
      setQ11_1PPL_name('')
      setQ11_1PPL_address('')
      setQ11_1PPL_phone('')

      addressRef1.current.clear()
      addressRef2.current.clear()
    }
  }

  function remCase(index) {
    let newList = []
    for (let i = 0; i < q11_1.length; i++) {
      if (i !== index) {
        newList.push(q11_1[i])
      }
    }
    setQ11_1(newList)
  }

  function notFiled() {
    setHasFiled(false)
    setQ11_1([])
  }

  function addPPL() {
    if (q11_1PPL_name !== '') {
      let currList = q11_1PPL
      currList.push({
        Name: q11_1PPL_name,
        Address: q11_1PPL_address,
        Phone: q11_1PPL_phone
      })

      setQ11_1PPL(currList)
      setQ11_1PPL_name('')
      setQ11_1PPL_address('')
      setQ11_1PPL_phone('')

      addressRef1.current.clear()
    }
  }

  function remPPL(index) {
    let newList = []
    for (let i = 0; i < q11_1PPL.length; i++) {
      if (i !== index) {
        newList.push(q11_1PPL[i])
      }
    }
    setQ11_1PPL(newList)
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Date']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Time: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Time']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Location: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Location']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Description: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Description']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Court: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Court']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Parties: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Parties']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Case Number: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['CaseNumber']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Attorney Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['AttorneyName']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Attorney Address: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['AttorneyAddress']}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Attorney Phone: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['AttorneyPhone']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Status: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Status']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Against: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['People'].map((key, j) => {
                  return <div>{props.dict['People'][j]['Name']}</div>
                })}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Date: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Date']}
                  onChange={e => (props.dict['Date'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Time: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Time']}
                  onChange={e => (props.dict['Time'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Location: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Location']}
                  onChange={e => (props.dict['Location'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Description: &nbsp;
              <div style={{ color: 'gray' }}>
                <textarea
                  defaultValue={props.dict['Description']}
                  onChange={e => (props.dict['Description'] = e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Court: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Court']}
                  onChange={e => (props.dict['Court'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Parties: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Parties']}
                  onChange={e => (props.dict['Parties'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Case Number: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['CaseNumber']}
                  onChange={e => (props.dict['CaseNumber'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Attorney Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['AttorneyName']}
                  onChange={e => (props.dict['AttorneyName'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Attorney Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['AttorneyAddress']}
                  onChange={e =>
                    (props.dict['AttorneyAddress'] = e.target.value)
                  }
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Attorney Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['AttorneyPhone']}
                  onChange={e => (props.dict['AttorneyPhone'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Status: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Status']}
                  onChange={e => (props.dict['Status'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Except for this action, in the past 10 years have<br></br>
          you filed a lawsuit or made a claim for personal injury?<br></br>
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasFiled}
                onClick={() => setHasFiled(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasFiled}
                onClick={() => notFiled()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasFiled ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each action, claim, or demand state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='date'
                  value={q11_1date}
                  required
                  onChange={e => setQ11_1date(e.target.value)}
                />
                <label className={'filled'}>{'Date of incident'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='time'
                  value={q11_1time}
                  required
                  onChange={e => setQ11_1time(e.target.value)}
                />
                <label className={'filled'}>{'Time of incident'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q11_1location}
                  required
                  onChange={e => setQ11_1location(e.target.value)}
                />
                <label className={q11_1location && 'filled'}>
                  {'Place of incident (closest address/intersection)'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container' style={{ height: '100px' }}>
                <textarea
                  value={q11_1description}
                  style={{
                    height: '100px',
                    borderStyle: 'none',
                    resize: 'none',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingTop: '10px',
                    borderRadius: '5px'
                  }}
                  required
                  onChange={e => setQ11_1description(e.target.value)}
                />
                <label className={q11_1description && 'filled'}>
                  {'Description of the injury'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  For each person against whom the claim or <br></br>demand was
                  made or the action filed, state the <br></br>following:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q11_1PPL_name}
                    required
                    onChange={e => setQ11_1PPL_name(e.target.value)}
                  />
                  <label className={q11_1PPL_name && 'filled'}>
                    {'Their full name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ11_1PPL_address(data)}
                  ref={addressRef1}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q11_1PPL_phone}
                    placeholder=''
                    onChange={setQ11_1PPL_phone}
                  />

                  <label className={q11_1PPL_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    className='AddPerson'
                    style={{ color: 'white', margin: 'auto' }}
                    onClick={() => addPPL()}
                  >
                    <div>&nbsp;Add&nbsp;</div>
                    <AddIcon style={{ color: 'white' }}></AddIcon>
                  </div>
                </div>
                {q11_1PPL.length > 0 ? <hr></hr> : ''}
                <div style={{ marginBottom: '-15px' }}>
                  {q11_1PPL.map((key, i) => {
                    return (
                      <Accordion
                        style={{
                          backgroundColor: 'white',
                          boxShadow: '0px 1px 3px #cccccc',
                          marginBottom: '4px'
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {q11_1PPL[i]['Name']}

                          <div style={{ marginLeft: 'auto' }}>
                            <div
                              className='RemButton'
                              onClick={() => remPPL(i)}
                            >
                              &nbsp;
                              <RemoveIcon></RemoveIcon>
                              Remove&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ maxWidth: '400px' }}>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Name: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q11_1PPL[i]['Name']}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Address: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q11_1PPL[i]['Address']}
                              </div>
                            </div>
                            <div style={{ display: 'flex', padding: '10px' }}>
                              Phone: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q11_1PPL[i]['Phone']}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </div>
              </Card>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q11_1court}
                  required
                  onChange={e => setQ11_1court(e.target.value)}
                />
                <label className={q11_1court && 'filled'}>{'The court'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q11_1parties}
                  required
                  onChange={e => setQ11_1parties(e.target.value)}
                />
                <label className={q11_1parties && 'filled'}>
                  {'Names of the parties'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q11_1cnum}
                  required
                  onChange={e => setQ11_1cnum(e.target.value)}
                />
                <label className={q11_1cnum && 'filled'}>{'Case Number'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <Form.Select
                aria-label='Default select example'
                style={{ height: '43px' }}
                onChange={e => setQ11_1status(e.target.value)}
                value={q11_1status}
              >
                <option value=''>Status</option>
                <option value='Resolved'>Resolved</option>
                <option value='Pending'>Pending</option>
              </Form.Select>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q11_1att_name}
                  required
                  onChange={e => setQ11_1att_name(e.target.value)}
                />
                <label className={q11_1att_name && 'filled'}>
                  {"Your attorney's name"}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <AddressInput
                passData={data => setQ11_1att_address(data)}
                ref={addressRef2}
              />
              <div style={{ marginTop: '15px' }}></div>
              <div className='phone-input-container'>
                <PhoneInput
                  enableAreaCodes={false}
                  inputStyle={{
                    height: '44px',
                    width: 'inherit',
                    borderColor: 'rgb(214, 214, 214)'
                  }}
                  disableCountryCode
                  country={'us'}
                  onlyCountries={['us']}
                  value={q11_1att_phone}
                  placeholder=''
                  onChange={setQ11_1att_phone}
                />

                <label className={q11_1att_phone && 'filled'}>
                  {"Your attorney's phone number"}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>

              <div style={{ marginTop: '15px' }}></div>

              <div style={{ display: 'flex', marginTop: '35px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '140px' }}
                  onClick={() => addCase()}
                >
                  <div>&nbsp;Add Action&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q11_1.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q11_1.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {'Action ' + (i + 1)}

                        <div style={{ marginLeft: 'auto' }}>
                          <div className='RemButton' onClick={() => remCase(i)}>
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q11_1[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q11_1
