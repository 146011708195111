import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form, InputGroup } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CurrencyInput from 'react-currency-input-fix'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q6_4 = forwardRef((props, ref) => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q6_4, setQ6_4] = useState([])

  const [q6_4name, setQ6_4name] = useState('')
  const [q6_4address, setQ6_4address] = useState('')
  const [q6_4phone, setQ6_4phone] = useState('')
  const [q6_4type, setQ6_4type] = useState('')

  const [q6_4dates, setQ6_4dates] = useState('')

  const [q6_4totalCharge, setQ6_4totalCharge] = useState('')
  const [q6_4amountPaid, setQ6_4amountPaid] = useState('')
  const [q6_4paidBy, setQ6_4paidBy] = useState('')
  const [q6_4adjustment, setQ6_4adjustment] = useState('')
  const [q6_4remaining, setQ6_4remaining] = useState('')

  const [hasHCP, sethasHCP] = useState(false)

  async function saveQuestion() {
    if (q6_4.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_4: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_4: q6_4
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q6_4) {
      if (docSnap.data().q6_4 !== 'N/A') {
        sethasHCP(true)
        setQ6_4(docSnap.data().q6_4)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addHCP() {
    if (q6_4name !== '') {
      let currList = q6_4
      currList.push({
        Name: q6_4name,
        Address: q6_4address,
        Phone: q6_4phone,
        Type: q6_4type,
        Dates: q6_4dates,
        TotalCharge: q6_4totalCharge,
        AmountPaid: q6_4amountPaid,
        PaidBy: q6_4paidBy,
        Adjustment: q6_4adjustment,
        RemainingBalance: q6_4remaining
      })

      setQ6_4(currList)

      setQ6_4name('')
      setQ6_4address('')
      setQ6_4phone('')
      setQ6_4type('')

      setQ6_4dates('')

      setQ6_4totalCharge('')
      setQ6_4amountPaid('')
      setQ6_4paidBy('')
      setQ6_4adjustment('')
      setQ6_4remaining('')
      addressRef.current.clear()
    }
  }

  function remHCP(index) {
    let newList = []
    for (let i = 0; i < q6_4.length; i++) {
      if (i !== index) {
        newList.push(q6_4[i])
      }
    }
    setQ6_4(newList)
  }

  function noHCP() {
    sethasHCP(false)
    setQ6_4([])
  }

  function modifyAdjustment(val) {
    setQ6_4adjustment(val)
    const remainingVal = q6_4totalCharge - q6_4amountPaid - val
    setQ6_4remaining(Math.round(remainingVal * 100) / 100)
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Name']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Phone']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Address']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Type: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Type']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Dates: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Dates']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Charge: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['TotalCharge']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Paid: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['AmountPaid']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Paid By: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['PaidBy']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Adjustment: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Adjustment']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Remaining: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['RemainingBalance']}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Name']}
                  onChange={e => (props.dict['Name'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Phone']}
                  onChange={e => (props.dict['Phone'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Address']}
                  onChange={e => (props.dict['Address'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Type: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Type']}
                  onChange={e => (props.dict['Type'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Dates: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Dates']}
                  onChange={e => (props.dict['Dates'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Charge: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['TotalCharge']}
                  onChange={e => (props.dict['TotalCharge'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Paid: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['AmountPaid']}
                  onChange={e => (props.dict['AmountPaid'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Paid by: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['PaidBy']}
                  onChange={e => (props.dict['PaidBy'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Adjustment: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Adjustment']}
                  onChange={e => (props.dict['Adjustment'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Remaining: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['RemainingBalance']}
                  onChange={e =>
                    (props.dict['RemainingBalance'] = e.target.value)
                  }
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Did you consultation, examination, or<br></br>
          treatment from a health care provider for any injury <br></br>
          you attribute to the incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasHCP}
                onClick={() => sethasHCP(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasHCP}
                onClick={() => noHCP()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasHCP ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each health care provider, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_4name}
                  required
                  onChange={e => setQ6_4name(e.target.value)}
                />
                <label className={q6_4name && 'filled'}>
                  {'Health care provider name'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <AddressInput
                passData={data => setQ6_4address(data)}
                ref={addressRef}
                ph={'Health care provider address'}
              />

              <div style={{ marginTop: '15px' }}></div>
              <div className='phone-input-container'>
                <PhoneInput
                  enableAreaCodes={false}
                  inputStyle={{
                    height: '44px',
                    width: 'inherit',
                    borderColor: 'rgb(214, 214, 214)'
                  }}
                  disableCountryCode
                  country={'us'}
                  onlyCountries={['us']}
                  value={q6_4phone}
                  placeholder=''
                  onChange={setQ6_4phone}
                />

                <label className={q6_4phone && 'filled'}>
                  {'Their Phone Number'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_4dates}
                  required
                  onChange={e => setQ6_4dates(e.target.value)}
                />
                <label className={q6_4dates && 'filled'}>
                  {'Dates you received treatment'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container' style={{ height: '100px' }}>
                <textarea
                  value={q6_4type}
                  style={{
                    height: '100px',
                    borderStyle: 'none',
                    resize: 'none',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '5px',
                    borderRadius: '5px'
                  }}
                  required
                  onChange={e => setQ6_4type(e.target.value)}
                />
                <label className={q6_4type && 'filled'}>
                  {'Description of treatment provided'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <div className='input-container'>
                  <CurrencyInput
                    id='input-example'
                    name='input-name'
                    value={q6_4totalCharge}
                    decimalsLimit={2}
                    style={{ width: '363px' }}
                    onValueChange={value => setQ6_4totalCharge(value)}
                  />
                  <label className={q6_4totalCharge && 'filled'}>
                    {'Sum of total charges'}
                  </label>
                </div>
              </InputGroup>
              <div style={{ marginTop: '15px' }}></div>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <div className='input-container'>
                  <CurrencyInput
                    id='input-example'
                    name='input-name'
                    value={q6_4amountPaid}
                    decimalsLimit={2}
                    style={{ width: '363px' }}
                    onValueChange={value => setQ6_4amountPaid(value)}
                  />
                  <label className={q6_4amountPaid && 'filled'}>
                    {'Total amount paid'}
                  </label>
                </div>
              </InputGroup>
              <div style={{ marginTop: '15px' }}></div>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <div className='input-container'>
                  <CurrencyInput
                    id='input-example'
                    name='input-name'
                    value={q6_4adjustment}
                    decimalsLimit={2}
                    style={{ width: '363px' }}
                    onValueChange={value => modifyAdjustment(value)}
                  />
                  <label className={q6_4adjustment && 'filled'}>
                    {'Adjustment'}
                  </label>
                </div>
              </InputGroup>
              <div style={{ marginTop: '15px' }}></div>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <div className='input-container'>
                  <CurrencyInput
                    id='input-example'
                    name='input-name'
                    value={q6_4remaining}
                    decimalsLimit={2}
                    style={{ width: '363px' }}
                    onValueChange={value => setQ6_4remaining(value)}
                  />
                  <label className={q6_4remaining && 'filled'}>
                    {'Remaining balance'}
                  </label>
                </div>
              </InputGroup>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_4paidBy}
                  required
                  onChange={e => setQ6_4paidBy(e.target.value)}
                />
                <label className={q6_4paidBy && 'filled'}>{'Paid by'}</label>
              </div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '150px' }}
                  onClick={() => addHCP()}
                >
                  <div>&nbsp;Add Provider&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q6_4.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q6_4.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q6_4[i]['Name']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div className='RemButton' onClick={() => remHCP(i)}>
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q6_4[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q6_4
