import React from 'react'
import './Matters.css'
import { useNavigate } from 'react-router'
import 'reactjs-popup/dist/index.css'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'

export default function MatterBlock(props) {
  const navigate = useNavigate()
  return (
    <div
      className='MatterBlock'
      onClick={() => navigate('/app/matters/' + props.link)}
    >
      <div style={{ display: 'flex', marginTop: '14px' }}>
        <div style={{ display: 'flex', margin: 'auto' }}>
          <BusinessCenterOutlinedIcon></BusinessCenterOutlinedIcon>
          &nbsp;
          {props.data[6] ? (
            <div style={{ fontWeight: '600' }}>{props.data[7]}</div>
          ) : (
            <div style={{ fontWeight: '600' }}>{props.data[0]}</div>
          )}
        </div>
      </div>
      <div className='InfoTab'>
        &nbsp;&nbsp;&nbsp;Status: &nbsp;
        <t className={props.data[2]}>{props.data[2]}</t>
        <br></br>
        <div style={{ marginTop: '7px' }}></div>
        &nbsp;&nbsp;&nbsp;Date of Loss: &nbsp;
        <t style={{ color: 'gray' }}>{props.data[3]}</t>
        <br></br>
        <div style={{ marginTop: '7px' }}></div>
        &nbsp;&nbsp;&nbsp;Created by: &nbsp;
        <t style={{ color: 'gray' }}>{props.data[5]}</t>
        {/* Display if mass tort */}
        <br></br>
        <div
          style={{
            marginTop: '7px',
            textAlign: 'center',
            color: 'rgb(60,60,60)',
            fontWeight: '600'
          }}
        >
          &nbsp;&nbsp;&nbsp;{props.data[6] ? 'MASS TORT' : ''}
        </div>
        <div style={{ marginTop: '10px' }}>
          &nbsp;&nbsp;&nbsp;<t style={{ color: 'gray' }}>{props.data[1]}</t>
        </div>
      </div>
    </div>
  )
}
