import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import './Plaintiffs.css'
import {
  doc,
  getDocs,
  getDoc,
  onSnapshot,
  collection
} from 'firebase/firestore'
import { db } from '../../../../firebase'
import 'reactjs-popup/dist/index.css'
import AddIcon from '@mui/icons-material/Add'
import { Form } from 'react-bootstrap'
import ClearIcon from '@mui/icons-material/Clear'
import { Fade } from '@mui/material'
import { Container } from '@mui/system'
import TortPlaintiffCard from './TortPlaintiffCard'
import NormalPlaintiffCard from './NormalPlaintiffCard'
import FilterListIcon from '@mui/icons-material/FilterList'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { PlaintiffOverview } from './PlaintiffOverview'
import '../../../../Inputs/Inputs.css'
import { AddPlaintiff } from './AddPlaintiff'
import PlaintiffNotes from './PlaintiffNotes'

export default function Plaintiffs() {
  const { id } = useParams()
  const [isTort, setIsTort] = useState(false)

  const [showSort, setShowSort] = useState(false)
  const [sortSearch, setSortSearch] = useState('')
  const [sortByContacted, setSortByContacted] = useState(false)
  const [sortBySigned, setSortBySigned] = useState(false)

  const [showOverview, setShowOverview] = useState(false)

  const [, setCaseName] = useState('')
  const [plaintiffList, setPlaintiffList] = useState([])
  const [plaintiffNames, setPlaintiffNames] = useState({})

  const [, setPlaintiffType] = useState('')

  const [overlay, setOverlay] = useState(false)
  const [modal, setModal] = useState(false)
  const [, setIsEditing] = useState(false)

  const [notesOpen, setNotesOpen] = useState(false)
  const [notesID, setNotesID] = useState('')

  //Special matter types:
  const [isLejeune, setIsLejeune] = useState(false)
  const [l_Sort_MR, setL_Sort_MR] = useState('')
  const [l_Sort_S, setL_Sort_S] = useState('')
  const [l_Sort_IT, setL_Sort_IT] = useState('')
  const [l_Sort_MCR, setL_Sort_MCR] = useState('')

  const toggleModal = () => {
    setPlaintiffType('')
    setModal(!modal)
    setOverlay(!modal)
    setIsEditing(false)
  }

  const getAndSetData = useCallback(async () => {
    const myMattersRef = collection(db, 'matters', id, 'plaintiffs')
    const myMattersSnap = await getDocs(myMattersRef)

    let tempList = []
    let tempDict = {}
    myMattersSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      tempList.push(doc.id)
      if (doc.data().type !== 'Business') {
        tempDict[doc.id] = [
          doc.data().full_name,
          doc.data().contacted,
          doc.data().signedRet,
          doc.data().MilitaryRecords,
          doc.data().MilitaryStatus,
          doc.data().InjuryTier,
          doc.data().MedicalRecords
        ]
      } else {
        tempDict[doc.id] = [
          doc.data().business_name,
          doc.data().contacted,
          doc.data().signedRet,
          doc.data().MilitaryRecords,
          doc.data().MilitaryStatus,
          doc.data().InjuryTier,
          doc.data().MedicalRecords
        ]
      }
    })

    setPlaintiffList(tempList)
    setPlaintiffNames(tempDict)

    const matterSnap = await getDoc(doc(db, 'matters', id))
    setCaseName(matterSnap.data().caseName)
  }, [id])

  const getIsTort = useCallback(async () => {
    const myMatterSnap = await getDoc(doc(db, 'matters', id))
    //determine if mass tort
    if (myMatterSnap.data().coordinationProceeding) {
      setIsTort(true)
    }
  }, [id])

  //Check if matter is lejeune
  const isCampLejeune = useCallback(async () => {
    const matterSnap = await getDoc(doc(db, 'matters', id))
    const specTitle = matterSnap.data().specialTitle.toLowerCase()
    if (specTitle.indexOf('lejeune') !== -1) {
      setIsLejeune(true)
    } else {
      setIsLejeune(false)
    }
  }, [id])

  useEffect(() => {
    onSnapshot(collection(db, 'matters', id, 'plaintiffs'), () => {
      getAndSetData()
    })
    getIsTort()
    isCampLejeune()
  }, [getAndSetData, getIsTort, id, isCampLejeune])

  const militaryRecordsOptions = [
    'Ordered',
    'Waiting for client signature',
    'Received'
  ]
  const statusOptions = [
    'Member of the Armed Forces',
    'Civil Service Employee',
    'Civilian Military Depentent',
    'Civilian Employee Working for Private Company on Base',
    'Not Yet Born (In Utero Injuries)'
  ]
  const injuryTierOptions = ['1', '2', 'Below Equipoise', 'Other']
  const medicalRecordsOptions = [
    'Requested',
    'Waiting for client signature',
    'Received'
  ]

  function openNotes(pID) {
    setNotesID(pID)
    setNotesOpen(true)
  }

  function closeNotes() {
    setNotesOpen(false)
    setNotesID('')
  }

  return (
    <div>
      <Fade in={true} appear timeout={300}>
        <div className='PlaintiffPageWrapper'>
          {showOverview ? (
            <div
              className='OverviewOverlay'
              onClick={() => setShowOverview(false)}
            ></div>
          ) : (
            ''
          )}
          {showOverview ? (
            <div
              className='CloseOverview'
              onClick={() => setShowOverview(false)}
            >
              <CloseOutlinedIcon /> &nbsp;Close
            </div>
          ) : (
            ''
          )}
          {showOverview ? (
            <PlaintiffOverview mID={id} isLejeune={isLejeune} />
          ) : (
            ''
          )}

          {notesOpen ? (
            <div className='MiddleAnchor'>
              <PlaintiffNotes pID={notesID} mID={id} closeNotes={() => setNotesOpen(false)}/>
            </div>
          ) : (
            ''
          )}

          {notesOpen ? (
            <div className='OverviewOverlay' onClick={() => closeNotes()}></div>
          ) : (
            ''
          )}
          <br></br>
          <div className='PlaintiffMain'>
            <br></br>
            <div className='PlaintiffPageTop'>
              <div
                className='OverviewBtn'
                onClick={() => setShowOverview(true)}
              >
                <GridViewOutlinedIcon /> &nbsp;Plaintiff Overview
              </div>
              <div
                style={{
                  margin: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <h5 style={{ marginTop: '5px', marginLeft: '-80px' }}>
                  Plaintiffs
                </h5>
                &nbsp;&nbsp;
                <div className='AddPlaintiff' onClick={toggleModal}>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
              <div className='SortPlaintiffs' onClick={() => setShowSort(true)}>
                <FilterListIcon />
                &nbsp; Sort
                <div className='SortMenuPH'>
                  {showSort ? (
                    <div className='SortMenu'>
                      <div
                        className='SortMenuTop'
                        style={
                          !isTort
                            ? { borderStyle: 'none', marginBottom: '10px' }
                            : {}
                        }
                      >
                        Sort by keyword
                        <input
                          className='SortInput'
                          value={sortSearch}
                          onChange={e => setSortSearch(e.target.value)}
                        />
                      </div>
                      {isTort ? (
                        <div className='SortMenuOpts'>
                          <div style={{ textAlign: 'center' }}>
                            Sort options
                          </div>
                          <div style={{ marginTop: '10px' }}></div>
                          <div>
                            <input
                              type='checkbox'
                              className='PCheck'
                              checked={sortByContacted}
                              onClick={e =>
                                setSortByContacted(e.target.checked)
                              }
                            />
                            &nbsp; Contacted
                          </div>
                          <div>
                            <input
                              type='checkbox'
                              className='PCheck'
                              checked={sortBySigned}
                              onClick={e => setSortBySigned(e.target.checked)}
                            />
                            &nbsp; Signed Retainer
                          </div>
                          <div style={{ marginTop: '10px' }}></div>
                        </div>
                      ) : (
                        ''
                      )}
                      {isLejeune ? (
                        <div
                          className='SortMenuOpts'
                          style={{
                            borderTopStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: 'rgb(210,210,210)'
                          }}
                        >
                          <div style={{ textAlign: 'center' }}>
                            Lejeune sort options
                          </div>
                          <div style={{ marginTop: '10px' }}></div>
                          <div>
                            Military Records
                            <Form.Select
                              aria-label='Default select example'
                              onChange={e => setL_Sort_MR(e.target.value)}
                            >
                              <option value=''>
                                {l_Sort_MR !== '' ? l_Sort_MR : 'Select'}
                              </option>
                              {militaryRecordsOptions.map(val => {
                                if (val !== l_Sort_MR) {
                                  return <option value={val}>{val}</option>
                                }

                                return null
                              })}
                              {l_Sort_MR !== '' ? (
                                <option value=''>None</option>
                              ) : (
                                ''
                              )}
                            </Form.Select>
                            <div style={{ marginTop: '10px' }}></div>
                            Status
                            <Form.Select
                              aria-label='Default select example'
                              onChange={e => setL_Sort_S(e.target.value)}
                            >
                              <option value=''>
                                {l_Sort_S !== '' ? l_Sort_S : 'Select'}
                              </option>
                              {statusOptions.map(val => {
                                if (val !== l_Sort_S) {
                                  return <option value={val}>{val}</option>
                                }

                                return null
                              })}
                              {l_Sort_S !== '' ? (
                                <option value=''>None</option>
                              ) : (
                                ''
                              )}
                            </Form.Select>
                            <div style={{ marginTop: '10px' }}></div>
                            Injury Tier
                            <Form.Select
                              aria-label='Default select example'
                              onChange={e => setL_Sort_IT(e.target.value)}
                            >
                              <option value=''>
                                {l_Sort_IT !== '' ? l_Sort_IT : 'Select'}
                              </option>
                              {injuryTierOptions.map(val => {
                                if (val !== l_Sort_IT) {
                                  return <option value={val}>{val}</option>
                                }

                                return null
                              })}
                              {l_Sort_IT !== '' ? (
                                <option value=''>None</option>
                              ) : (
                                ''
                              )}
                            </Form.Select>
                            <div style={{ marginTop: '10px' }}></div>
                            Medical Records
                            <Form.Select
                              aria-label='Default select example'
                              onChange={e => setL_Sort_MCR(e.target.value)}
                            >
                              <option value=''>
                                {l_Sort_MCR !== '' ? l_Sort_MCR : 'Select'}
                              </option>
                              {medicalRecordsOptions.map(val => {
                                if (val !== l_Sort_MCR) {
                                  return <option value={val}>{val}</option>
                                }

                                return null
                              })}
                              {l_Sort_MCR !== '' ? (
                                <option value=''>None</option>
                              ) : (
                                ''
                              )}
                            </Form.Select>
                            <div style={{ marginTop: '10px' }}></div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              {showSort ? (
                <div
                  className='SortMenuOverlay'
                  onClick={() => setShowSort(false)}
                />
              ) : (
                ''
              )}
            </div>
            <div className='PlaintiffScroll'>
              {plaintiffList.map(val => {
                if (
                  plaintiffNames[val][0]
                    .toLowerCase()
                    .indexOf(sortSearch.toLowerCase()) !== -1
                ) {
                  if (sortByContacted && !plaintiffNames[val][1]) {
                    return ''
                  }
                  if (sortBySigned && !plaintiffNames[val][2]) {
                    return ''
                  }
                  if (isLejeune) {
                    if (
                      l_Sort_MR !== '' &&
                      plaintiffNames[val][3] !== l_Sort_MR
                    ) {
                      return ''
                    }
                    if (
                      l_Sort_S !== '' &&
                      plaintiffNames[val][4] !== l_Sort_S
                    ) {
                      return ''
                    }
                    if (
                      l_Sort_IT !== '' &&
                      plaintiffNames[val][5] !== l_Sort_IT
                    ) {
                      return ''
                    }
                    if (
                      l_Sort_MCR !== '' &&
                      plaintiffNames[val][6] !== l_Sort_MCR
                    ) {
                      return ''
                    }
                  }

                  if (isTort) {
                    return (
                      <TortPlaintiffCard
                        pID={val}
                        mID={id}
                        isLejeune={isLejeune}
                        openPlaintiffNotes={openNotes}
                        name={plaintiffNames[val][0]}
                        contacted={plaintiffNames[val][1]}
                        signedRet={plaintiffNames[val][2]}
                      />
                    )
                  } else {
                    return (
                      <NormalPlaintiffCard
                        pID={val}
                        mID={id}
                        openPlaintiffNotes={openNotes}
                        name={plaintiffNames[val][0]}
                        contacted={plaintiffNames[val][1]}
                        signedRet={plaintiffNames[val][2]}
                      />
                    )
                  }
                }

                return null
              })}
            </div>
            <br></br>
          </div>
        </div>
      </Fade>

      {overlay ? <div className='overlayP'></div> : <div></div>}

      {modal ? (
        <Fade in={true} appear timeout={500}>
          <div className='PopContent' style={{ top: '53%' }}>
            <div className='Close' onClick={toggleModal}>
              <ClearIcon></ClearIcon>
            </div>
            <Container
              className='d-flex
                justify-content-center'
              style={{ minHeight: '90vh' }}
            >
              <div className='PopCard'>
                <div className='w-100' style={{ maxWidth: '600px' }}>
                  <AddPlaintiff isTort={isTort} addedPlaintiff={toggleModal} />
                </div>
              </div>
            </Container>
            {/* End Popup Content */}
          </div>
        </Fade>
      ) : (
        ''
      )}
    </div>
  )
}
