import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q11_2 = forwardRef((props, ref) => {
  const addressRef1 = useRef()
  const addressRef2 = useRef()
  const addressRef3 = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q11_2, setQ11_2] = useState([])

  const [q11_2date, setQ11_2date] = useState('')
  const [q11_2time, setQ11_2time] = useState('')
  const [q11_2location, setQ11_2location] = useState('')
  const [q11_2description, setQ11_2description] = useState('')
  const [q11_2from, setQ11_2from] = useState('')
  const [q11_2to, setQ11_2to] = useState('')
  const [q11_2cnum, setQ11_2cnum] = useState('')

  const [q11_2employer_name, setQ11_2employer_name] = useState('')
  const [q11_2employer_address, setQ11_2employer_address] = useState('')
  const [q11_2employer_phone, setQ11_2employer_phone] = useState('')

  const [q11_2insurer_name, setQ11_2insurer_name] = useState('')
  const [q11_2insurer_address, setQ11_2insurer_address] = useState('')
  const [q11_2insurer_phone, setQ11_2insurer_phone] = useState('')
  const [q11_2insurer_cnum, setQ11_2insurer_cnum] = useState('')

  const [q11_2HCP, setQ11_2HCP] = useState([])
  const [q11_2HCP_name, setQ11_2HCP_name] = useState('')
  const [q11_2HCP_address, setQ11_2HCP_address] = useState('')
  const [q11_2HCP_phone, setQ11_2HCP_phone] = useState('')

  const [hasFiled, setHasFiled] = useState(false)

  async function saveQuestion() {
    if (q11_2.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q11_2: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q11_2: q11_2
        }
      )
    }
  }

  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q11_2) {
      if (docSnap.data().q11_2 !== 'N/A') {
        setHasFiled(true)
        setQ11_2(docSnap.data().q11_2)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addCase() {
    if (q11_2date !== '') {
      let currList = q11_2
      currList.push({
        Date: q11_2date,
        Time: q11_2time,
        Location: q11_2location,
        Description: q11_2description,
        From: q11_2from,
        To: q11_2to,
        WorkersCaseNumber: q11_2cnum,
        EmployerName: q11_2employer_name,
        EmployerAddress: q11_2employer_address,
        EmployerPhone: q11_2employer_phone,
        InsurerName: q11_2insurer_name,
        InsurerAddress: q11_2insurer_address,
        InsurerPhone: q11_2insurer_phone,
        InsurerClaimNumber: q11_2insurer_cnum,
        HCP: q11_2HCP
      })

      setQ11_2(currList)

      setQ11_2date('')
      setQ11_2time('')
      setQ11_2location('')
      setQ11_2description('')
      setQ11_2from('')
      setQ11_2to('')
      setQ11_2cnum('')

      setQ11_2employer_name('')
      setQ11_2employer_address('')
      setQ11_2employer_phone('')

      setQ11_2insurer_name('')
      setQ11_2insurer_address('')
      setQ11_2insurer_phone('')
      setQ11_2insurer_cnum('')

      setQ11_2HCP([])
      setQ11_2HCP_name('')
      setQ11_2HCP_address('')
      setQ11_2HCP_phone('')

      addressRef1.current.clear()
      addressRef2.current.clear()
      addressRef3.current.clear()
    }
  }

  function remCase(index) {
    let newList = []
    for (let i = 0; i < q11_2.length; i++) {
      if (i !== index) {
        newList.push(q11_2[i])
      }
    }
    setQ11_2(newList)
  }

  function notFiled() {
    setHasFiled(false)
    setQ11_2([])
  }

  function addHCP() {
    if (q11_2HCP_name !== '') {
      let currList = q11_2HCP
      currList.push({
        Name: q11_2HCP_name,
        Address: q11_2HCP_address,
        Phone: q11_2HCP_phone
      })

      setQ11_2HCP(currList)
      setQ11_2HCP_name('')
      setQ11_2HCP_address('')
      setQ11_2HCP_phone('')
      addressRef3.current.clear()
    }
  }

  function remHCP(index) {
    let newList = []
    for (let i = 0; i < q11_2HCP.length; i++) {
      if (i !== index) {
        newList.push(q11_2HCP[i])
      }
    }
    setQ11_2HCP(newList)
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Date']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Time: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Time']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Location: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Location']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Description: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Description']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Benefits from: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['From']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Benefits to: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['To']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Case Number: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['WorkersCaseNumber']}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Employer Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['EmployerName']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Employer Address: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['EmployerAddress']}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Employer Phone: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['EmployerPhone']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurer Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['InsurerName']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurer Address: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['InsurerAddress']}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurer Phone: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['InsurerPhone']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurer Claim Number: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['InsurerClaimNumber']}
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Providers: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['HCP'].map((key, j) => {
                  return <div>{props.dict['HCP'][j]['Name']}</div>
                })}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Date']}
                  onChange={e => (props.dict['Date'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Time: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Time']}
                  onChange={e => (props.dict['Time'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Location: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Location']}
                  onChange={e => (props.dict['Location'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Description: &nbsp;
              <div style={{ color: 'gray' }}>
                <textarea
                  defaultValue={props.dict['Description']}
                  onChange={e => (props.dict['Description'] = e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Benefits from: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['From']}
                  onChange={e => (props.dict['From'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Benefits to: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['To']}
                  onChange={e => (props.dict['To'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Case Number: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['WorkersCaseNumber']}
                  onChange={e =>
                    (props.dict['WorkersCaseNumber'] = e.target.value)
                  }
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Employee Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['EmployerName']}
                  onChange={e => (props.dict['EmployerName'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Employee Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['EmployerAddress']}
                  onChange={e =>
                    (props.dict['EmployerAddress'] = e.target.value)
                  }
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Employee Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['EmployerPhone']}
                  onChange={e => (props.dict['EmployerPhone'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurer Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['InsurerName']}
                  onChange={e => (props.dict['InsurerName'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurer Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['InsurerAddress']}
                  onChange={e =>
                    (props.dict['InsurerAddress'] = e.target.value)
                  }
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Insurer Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['InsurerPhone']}
                  onChange={e => (props.dict['InsurerPhone'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div style={{ display: 'flex', padding: '10px' }}>
              Insurer Claim Num: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['InsurerClaimNumber']}
                  onChange={e =>
                    (props.dict['InsurerClaimNumber'] = e.target.value)
                  }
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          In the past 10 years have you made a written claim<br></br>
          or demand for workers' compensation benefits?<br></br>
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasFiled}
                onClick={() => setHasFiled(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasFiled}
                onClick={() => notFiled()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasFiled ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each claim or demand, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='date'
                  value={q11_2date}
                  required
                  onChange={e => setQ11_2date(e.target.value)}
                />
                <label className={'filled'}>{'Date of incident'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='time'
                  value={q11_2time}
                  required
                  onChange={e => setQ11_2time(e.target.value)}
                />
                <label className={'filled'}>{'Time of incident'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q11_2location}
                  required
                  onChange={e => setQ11_2location(e.target.value)}
                />
                <label className={q11_2location && 'filled'}>
                  {'Place of the incident'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container' style={{ height: '100px' }}>
                <textarea
                  value={q11_2description}
                  style={{
                    height: '100px',
                    borderStyle: 'none',
                    resize: 'none',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    paddingTop: '10px',
                    borderRadius: '5px'
                  }}
                  required
                  onChange={e => setQ11_2description(e.target.value)}
                />
                <label className={q11_2description && 'filled'}>
                  {'Description of the injury'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ textAlign: 'center' }}>
                Period of time you received workers' compensation<br></br>{' '}
                benefits:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ display: 'flex' }}>
                <div style={{ textAlign: 'center' }}>
                  Date began:
                  <div className='input-container'>
                    <input
                      type='date'
                      value={q11_2from}
                      required
                      onChange={e => setQ11_2from(e.target.value)}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 'auto', textAlign: 'center' }}>
                  Date ended:
                  <div className='input-container'>
                    <input
                      type='date'
                      value={q11_2to}
                      required
                      onChange={e => setQ11_2to(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q11_2cnum}
                  required
                  onChange={e => setQ11_2cnum(e.target.value)}
                />
                <label className={q11_2cnum && 'filled'}>
                  {"Case Number (Workers' Comp. Appeals Board)"}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  State the following info for your employer <br></br>
                  at the time of the injury:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q11_2employer_name}
                    required
                    onChange={e => setQ11_2employer_name(e.target.value)}
                  />
                  <label className={q11_2employer_name && 'filled'}>
                    {'Employer Name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ11_2employer_address(data)}
                  ref={addressRef1}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q11_2employer_phone}
                    placeholder=''
                    onChange={setQ11_2employer_phone}
                  />

                  <label className={q11_2employer_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
              </Card>
              <div style={{ marginTop: '15px' }}></div>
              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  State the following info for your workers' <br></br>
                  compensation insurer:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q11_2insurer_name}
                    required
                    onChange={e => setQ11_2insurer_name(e.target.value)}
                  />
                  <label className={q11_2insurer_name && 'filled'}>
                    {'Name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ11_2insurer_address(data)}
                  ref={addressRef2}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q11_2insurer_phone}
                    placeholder=''
                    onChange={setQ11_2insurer_phone}
                  />

                  <label className={q11_2insurer_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q11_2insurer_cnum}
                    required
                    onChange={e => setQ11_2insurer_cnum(e.target.value)}
                  />
                  <label className={q11_2insurer_cnum && 'filled'}>
                    {'Claim Number'}
                  </label>
                </div>
              </Card>
              <div style={{ marginTop: '15px' }}></div>
              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  For each health care provider who provided <br></br>
                  services, state the following:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q11_2HCP_name}
                    required
                    onChange={e => setQ11_2HCP_name(e.target.value)}
                  />
                  <label className={q11_2HCP_name && 'filled'}>
                    {'The name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ11_2HCP_address(data)}
                  ref={addressRef3}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q11_2HCP_phone}
                    placeholder=''
                    onChange={setQ11_2HCP_phone}
                  />

                  <label className={q11_2HCP_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    className='AddPerson'
                    style={{ color: 'white', margin: 'auto' }}
                    onClick={() => addHCP()}
                  >
                    <div>&nbsp;Add&nbsp;</div>
                    <AddIcon style={{ color: 'white' }}></AddIcon>
                  </div>
                </div>
                {q11_2HCP.length > 0 ? <hr></hr> : ''}
                <div style={{ marginBottom: '-15px' }}>
                  {q11_2HCP.map((key, i) => {
                    return (
                      <Accordion
                        style={{
                          backgroundColor: 'white',
                          boxShadow: '0px 1px 3px #cccccc',
                          marginBottom: '4px'
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {q11_2HCP[i]['Name']}

                          <div style={{ marginLeft: 'auto' }}>
                            <div
                              className='RemButton'
                              onClick={() => remHCP(i)}
                            >
                              &nbsp;
                              <RemoveIcon></RemoveIcon>
                              Remove&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ maxWidth: '400px' }}>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Name: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q11_2HCP[i]['Name']}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Address: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q11_2HCP[i]['Address']}
                              </div>
                            </div>
                            <div style={{ display: 'flex', padding: '10px' }}>
                              Phone: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q11_2HCP[i]['Phone']}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </div>
              </Card>

              <div style={{ display: 'flex', marginTop: '35px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '180px' }}
                  onClick={() => addCase()}
                >
                  <div>&nbsp;Add Claim/Demand&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q11_2.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q11_2.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {'Claim/demand ' + (i + 1)}

                        <div style={{ marginLeft: 'auto' }}>
                          <div className='RemButton' onClick={() => remCase(i)}>
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q11_2[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q11_2
