import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const Q8_5 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q8_5, setQ8_5] = useState([])

  const [q8_5place, setQ8_5place] = useState('')
  const [q8_5date, setQ8_5date] = useState('')

  async function saveQuestion() {
    if (q8_5.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q8_5: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q8_5: q8_5
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q8_5) {
      if (docSnap.data().q8_5 !== 'N/A') {
        setQ8_5(docSnap.data().q8_5)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addEmployment() {
    if (q8_5place !== '') {
      let currList = q8_5
      currList.push({ Place: q8_5place, Date: q8_5date })

      setQ8_5(currList)
      setQ8_5place('')
      setQ8_5date('')
    }
  }

  function remEmployment(index) {
    let newList = []
    for (let i = 0; i < q8_5.length; i++) {
      if (i !== index) {
        newList.push(q8_5[i])
      }
    }
    setQ8_5(newList)
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          State the date you returned to work at<br></br>
          each place of employment you previously<br></br>
          listed
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container'>
          <input
            type='text'
            value={q8_5place}
            required
            onChange={e => setQ8_5place(e.target.value)}
          />
          <label className={q8_5place && 'filled'}>
            {'Company where you work'}
          </label>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container'>
          <input
            type='date'
            value={q8_5date}
            required
            onChange={e => setQ8_5date(e.target.value)}
          />
          <label className={'filled'}>{'Date you returned to work'}</label>
        </div>

        <div style={{ marginTop: '15px' }}></div>
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <div
            className='AddPerson'
            style={{ color: 'white', margin: 'auto' }}
            onClick={() => addEmployment()}
          >
            <div>&nbsp;Add&nbsp;</div>
            <AddIcon style={{ color: 'white' }}></AddIcon>
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div style={{ textAlign: 'center', marginBottom: '15px' }}>
            {q8_5.length > 0 ? <hr></hr> : ''}
          </div>
          <div>
            {q8_5.map((key, i) => {
              return (
                <Accordion
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 3px #cccccc',
                    marginBottom: '4px'
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {q8_5[i]['Place']}

                    <div style={{ marginLeft: 'auto' }}>
                      <div
                        className='RemButton'
                        onClick={() => remEmployment(i)}
                      >
                        &nbsp;
                        <RemoveIcon></RemoveIcon>
                        Remove&nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ maxWidth: '400px' }}>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px',
                          borderBottomStyle: 'solid',
                          borderWidth: '1px',
                          borderBottomColor: '#dddddd'
                        }}
                      >
                        Place: &nbsp;
                        <div style={{ color: 'gray' }}>{q8_5[i]['Place']}</div>
                      </div>
                      <div style={{ display: 'flex', padding: '10px' }}>
                        Returned: &nbsp;
                        <div style={{ color: 'gray' }}>{q8_5[i]['Date']}</div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
})

export default Q8_5
