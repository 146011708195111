import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form, InputGroup } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CurrencyInput from 'react-currency-input-fix'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q7_3 = forwardRef((props, ref) => {
  const addressRef1 = useRef()
  const addressRef2 = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q7_3, setQ7_3] = useState([])

  const [q7_3date, setQ7_3date] = useState('')
  const [q7_3description, setQ7_3description] = useState('')
  const [q7_3cost, setQ7_3cost] = useState('')

  const [q7_3repair_name, setQ7_3repair_name] = useState('')
  const [q7_3repair_phone, setQ7_3repair_phone] = useState('')
  const [q7_3repair_address, setQ7_3repair_address] = useState('')

  const [q7_3paid_name, setQ7_3paid_name] = useState('')
  const [q7_3paid_phone, setQ7_3paid_phone] = useState('')
  const [q7_3paid_address, setQ7_3paid_address] = useState('')

  const [hasRepairs, sethasRepairs] = useState(false)

  async function saveQuestion() {
    if (q7_3.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q7_3: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q7_3: q7_3
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    console.log(docSnap.data())
    if (docSnap.data().q7_3) {
      if (docSnap.data().q7_3 !== 'N/A') {
        sethasRepairs(true)
        setQ7_3(docSnap.data().q7_3)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addRepair() {
    if (q7_3date !== '') {
      let currList = q7_3
      currList.push({
        Date: q7_3date,
        Description: q7_3description,
        Cost: q7_3cost,
        RepairName: q7_3repair_name,
        RepairAddress: q7_3repair_address,
        RepairPhone: q7_3repair_phone,
        PaidName: q7_3paid_name,
        PaidAddress: q7_3paid_address,
        PaidPhone: q7_3paid_phone
      })

      setQ7_3(currList)

      setQ7_3date('')
      setQ7_3description('')
      setQ7_3cost('')

      setQ7_3repair_name('')
      setQ7_3repair_phone('')
      setQ7_3repair_address('')

      setQ7_3paid_name('')
      setQ7_3paid_phone('')
      setQ7_3paid_address('')

      addressRef1.current.clear()
      addressRef2.current.clear()
    }
  }

  function remRepair(index) {
    let newList = []
    for (let i = 0; i < q7_3.length; i++) {
      if (i !== index) {
        newList.push(q7_3[i])
      }
    }
    setQ7_3(newList)
  }

  function noRepairs() {
    sethasRepairs(false)
    setQ7_3([])
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date Repaired: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Date']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Description: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Description']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Cost: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Cost']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Person who repaired: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['RepairName']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['RepairAddress']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['RepairPhone']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Person who paid: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['PaidName']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['PaidAddress']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['PaidPhone']}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date Repaired: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Date']}
                  onChange={e => (props.dict['Date'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Description: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Description']}
                  onChange={e => (props.dict['Description'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Cost: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Cost']}
                  onChange={e => (props.dict['Cost'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Person who repaired: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['RepairName']}
                  onChange={e => (props.dict['RepairName'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['RepairAddress']}
                  onChange={e => (props.dict['RepairAddress'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['RepairPhone']}
                  onChange={e => (props.dict['RepairPhone'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Person who paid: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['PaidName']}
                  onChange={e => (props.dict['PaidName'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['PaidAddress']}
                  onChange={e => (props.dict['PaidAddress'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['PaidPhone']}
                  onChange={e => (props.dict['PaidPhone'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Has any item you listed been repaired?<br></br>
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasRepairs}
                onClick={() => sethasRepairs(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasRepairs}
                onClick={() => noRepairs()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasRepairs ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each repaired item, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='date'
                  value={q7_3date}
                  required
                  onChange={e => setQ7_3date(e.target.value)}
                />
                <label className={'filled'}>{'Date Repaired'}</label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q7_3description}
                  required
                  onChange={e => setQ7_3description(e.target.value)}
                />
                <label className={q7_3description && 'filled'}>
                  {'Description of the repair'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <div className='input-container'>
                  <CurrencyInput
                    id='input-example'
                    name='input-name'
                    value={q7_3cost}
                    decimalsLimit={2}
                    style={{ width: '363px' }}
                    onValueChange={value => setQ7_3cost(value)}
                  />
                  <label className={q7_3cost && 'filled'}>
                    {'Cost of repair'}
                  </label>
                </div>
              </InputGroup>
              <div style={{ marginTop: '15px' }}></div>

              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  State the following info for the person <br></br>who repaired
                  it<br></br>
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q7_3repair_name}
                    required
                    onChange={e => setQ7_3repair_name(e.target.value)}
                  />
                  <label className={q7_3repair_name && 'filled'}>
                    {'Their Name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ7_3repair_address(data)}
                  ref={addressRef1}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q7_3repair_phone}
                    placeholder=''
                    onChange={setQ7_3repair_phone}
                  />

                  <label className={q7_3repair_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
              </Card>

              <div style={{ marginTop: '15px' }}></div>

              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  State the following info for the person who<br></br> paid for
                  the repairs<br></br>
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q7_3paid_name}
                    required
                    onChange={e => setQ7_3paid_name(e.target.value)}
                  />
                  <label className={q7_3paid_name && 'filled'}>
                    {'Their Name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ7_3paid_address(data)}
                  ref={addressRef2}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q7_3paid_phone}
                    placeholder=''
                    onChange={setQ7_3paid_phone}
                  />

                  <label className={q7_3paid_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
              </Card>

              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '180px' }}
                  onClick={() => addRepair()}
                >
                  <div>&nbsp;Add Repair&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q7_3.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q7_3.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q7_3[i]['Date']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remRepair(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q7_3[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q7_3
