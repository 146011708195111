import React from 'react'
import { Form } from 'react-bootstrap'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'

export function LejeuneData(props) {
  async function updatePlaintiff(key, val) {
    await updateDoc(doc(db, 'matters', props.mID, 'plaintiffs', props.pID), {
      [key]: val
    })
  }

  const militaryRecordsOptions = [
    'Ordered',
    'Waiting for client signature',
    'Received'
  ]
  const statusOptions = [
    'Member of the Armed Forces',
    'Civil Service Employee',
    'Civilian Military Depentent',
    'Civilian Employee Working for Private Company on Base',
    'Not Yet Born (In Utero Injuries)'
  ]
  const injuryTierOptions = ['1', '2', 'Below Equipoise', 'Other']
  const injury1List = [
    'Kidney Cancer - Sufficient*',
    'Non-Hodgkin Lymphoma - Sufficient*',
    'Leukemias - Sufficient*',
    'Liver Cancer - Sufficient',
    'Bladder Cancer - Sufficient',
    'Major Cardiac Birth Defects - Sufficient',
    'Parkinson Disease - Equipoise and above',
    'Kidney Disease - Equipoise and above',
    'Multiple Myeloma - Equipoise and above*',
    'Systematic Sclerosis/Scleroderma - Equipoise and above*'
  ]
  const injury2List = [
    'Aplastic Anemia - VA Presumptive',
    'Hepatic Steatosis - VA Presumptive',
    'Lung Cancer - VA Presumptive',
    'Miscarriage - VA Presumptive',
    'Neurobehavioral Effects - VA Presumptive',
    'Female Infertility - VA Presumptive',
    'Breast Cancer (male and female) - VA Presumptive',
    'Esophageal Cancer - VA Presumptive',
    'Myelodysplastic Syndromes',
    'Renal Toxicity'
  ]
  const injury3List = [
    'Breast Cancer (male and female)',
    'Esophageal Cancer',
    'Brain/CNS Cancer',
    'Prostate Cancer',
    'Pancreatic Cancer',
    'Rectal Cancer'
  ]
  const injury4List = [
    'Major Fetal Malformations',
    'Fetal Death',
    'Cervical Cancer',
    'Gallblader Cancer',
    'Thyroid Cancer',
    'Intestinal Cancer',
    'Colorectal Cancer',
    'Bile Duct Cancer',
    'Ovarian Cancer',
    'Spinal Cancer',
    'Hodgkin Disease',
    'Soft Tissue Sarcoma',
    'Birth Defects',
    'Other Unlisted Cancer'
  ]

  const medicalRecordsOptions = [
    'Requested',
    'Waiting for client signature',
    'Received'
  ]

  return (
    <div>
      Military Records
      <Form.Select
        aria-label='Default select example'
        onChange={e => updatePlaintiff('MilitaryRecords', e.target.value)}
      >
        <option value=''>{props.pData['MilitaryRecords']}</option>
        {militaryRecordsOptions.map(val => {
          if (val !== props.pData['MilitaryRecords']) {
            return <option value={val}>{val}</option>
          }

          return null
        })}
      </Form.Select>
      <div style={{ marginTop: '10px' }}></div>
      Status
      <Form.Select
        aria-label='Default select example'
        onChange={e => updatePlaintiff('MilitaryStatus', e.target.value)}
      >
        <option value=''>{props.pData['MilitaryStatus']}</option>
        {statusOptions.map(val => {
          if (val !== props.pData['MilitaryStatus']) {
            return <option value={val}>{val}</option>
          }

          return null
        })}
      </Form.Select>
      <div style={{ marginTop: '10px' }}></div>
      Injury Tier
      <Form.Select
        aria-label='Default select example'
        onChange={e => updatePlaintiff('InjuryTier', e.target.value)}
      >
        <option value=''>{props.pData['InjuryTier']}</option>
        {injuryTierOptions.map(val => {
          if (val !== props.pData['InjuryTier']) {
            return <option value={val}>{val}</option>
          }

          return null
        })}
      </Form.Select>
      <div style={{ marginTop: '10px' }}></div>
      Injury
      {props.pData['InjuryTier'] === '1' ? (
        <Form.Select
          aria-label='Default select example'
          onChange={e => updatePlaintiff('Injury', e.target.value)}
        >
          <option value=''>
            {injury1List.indexOf(props.pData['Injury']) !== -1
              ? props.pData['Injury']
              : 'Select'}
          </option>
          {injury1List.map(val => {
            if (val !== props.pData['Injury']) {
              return <option value={val}>{val}</option>
            }

            return null
          })}
        </Form.Select>
      ) : props.pData['InjuryTier'] === '2' ? (
        <Form.Select
          aria-label='Default select example'
          onChange={e => updatePlaintiff('Injury', e.target.value)}
        >
          <option value=''>
            {injury2List.indexOf(props.pData['Injury']) !== -1
              ? props.pData['Injury']
              : 'Select'}
          </option>
          {injury2List.map(val => {
            if (val !== props.pData['Injury']) {
              return <option value={val}>{val}</option>
            }

            return null
          })}
        </Form.Select>
      ) : props.pData['InjuryTier'] === 'Below Equipoise' ? (
        <Form.Select
          aria-label='Default select example'
          onChange={e => updatePlaintiff('Injury', e.target.value)}
        >
          <option value=''>
            {injury3List.indexOf(props.pData['Injury']) !== -1
              ? props.pData['Injury']
              : 'Select'}
          </option>
          {injury3List.map(val => {
            if (val !== props.pData['Injury']) {
              return <option value={val}>{val}</option>
            }

            return null
          })}
        </Form.Select>
      ) : props.pData['InjuryTier'] === 'Other' ? (
        <Form.Select
          aria-label='Default select example'
          onChange={e => updatePlaintiff('Injury', e.target.value)}
        >
          <option value=''>
            {injury4List.indexOf(props.pData['Injury']) !== -1
              ? props.pData['Injury']
              : 'Select'}
          </option>
          {injury4List.map(val => {
            if (val !== props.pData['Injury']) {
              return <option value={val}>{val}</option>
            }

            return null
          })}
        </Form.Select>
      ) : (
        ''
      )}
      <div style={{ marginTop: '10px' }}></div>
      Main Contact&nbsp;&nbsp;
      <input
        type='text'
        defaultValue={props.pData['MainContact']}
        onChange={e => updatePlaintiff('MainContact', e.target.value)}
      />
      <div style={{ marginTop: '10px' }}></div>
      Last Contacted&nbsp;&nbsp;
      <input
        type='date'
        defaultValue={props.pData['LastContacted']}
        onChange={e => updatePlaintiff('LastContacted', e.target.value)}
      />
      <div style={{ marginTop: '10px' }}></div>
      Medical Records
      <Form.Select
        aria-label='Default select example'
        onChange={e => updatePlaintiff('MedicalRecords', e.target.value)}
      >
        <option value=''>{props.pData['MedicalRecords']}</option>
        {medicalRecordsOptions.map(val => {
          if (val !== props.pData['MedicalRecords']) {
            return <option value={val}>{val}</option>
          }

          return null
        })}
      </Form.Select>
    </div>
  )
}
