import React, { useState, useEffect, useCallback } from 'react'
import { Container } from 'react-bootstrap'
import { Navbar, Nav } from 'react-bootstrap'
import './TopbarIn.css'
import { UserAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import logo from '../../images/tl_black.png'
import { db } from '../../firebase'
import BusinessIcon from '@mui/icons-material/Business'
import {
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  collection,
  onSnapshot
} from 'firebase/firestore'
import SearchIcon from '@mui/icons-material/Search'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LoadAllMatters from './LoadAllMatters'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CloseIcon from '@mui/icons-material/Close'

export default function TopbarIn() {
  const [isFirmAdmin, setIsFirmAdmin] = useState(false)
  const [userColor, setUserColor] = useState('')
  const [, setUserData] = useState({})
  const [userName, setUserName] = useState('')
  const [userFirst, setUserFirst] = useState('')
  const [openProfile, setOpenProfile] = useState(false)
  const [matterList, setMatterList] = useState([])
  const [matterData, setMatterData] = useState({})
  const [openSearch, setOpenSearch] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [openNotifications, setOpenNotifications] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [notifCount, setNotifCount] = useState(0)
  const { user, logout } = UserAuth()

  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      await logout()
    } catch (e) {}
  }

  const getAndSetMyInfo = useCallback(async () => {
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    setUserData(userSnap.data())
    const thisFirmID = userSnap.data().firmID
    const sharedUsersRef = collection(db, 'firms', thisFirmID, 'users')
    const sharedUsersSnap = await getDocs(sharedUsersRef)
    sharedUsersSnap.forEach(doc => {
      if (doc.id === user.uid && doc.data().isAdmin) {
        setIsFirmAdmin(true)
      } else if (doc.id === user.uid && !doc.data().isAdmin) {
        setIsFirmAdmin(false)
      }
    })

    setUserName(userSnap.data().fullName)
    setUserFirst(userSnap.data().fullName.slice(0, 1))

    const asc = userSnap.data().fullName.charCodeAt(0)
    const num = asc % 10

    if (num < 4) {
      setUserColor('#869fe4')
    } else if (num >= 4 && num < 7) {
      setUserColor('#c99e7a')
    } else if (num >= 7) {
      setUserColor('#8ecb8b')
    }
  }, [user.uid])

  const getNotifications = useCallback(async () => {
    let notifTemp = []
    let notifCount = 0
    const notifSnap = await getDocs(
      collection(db, 'firmUsers', user.uid, 'notifications')
    )
    notifSnap.forEach(doc => {
      notifCount++
      notifTemp.push([
        doc.data().content,
        doc.data().time,
        doc.data().link,
        doc.data().type,
        doc.id
      ])
    })
    setNotifications(notifTemp)
    await getDoc(doc(db, 'firmUsers', user.uid))
    setNotifCount(notifCount)
  }, [user.uid])

  async function delNotif(val) {
    await deleteDoc(doc(db, 'firmUsers', user.uid, 'notifications', val))
  }

  async function viewNotif(notif, link) {
    navigate('/app/' + link)
    setOpenNotifications(false)
    await deleteDoc(doc(db, 'firmUsers', user.uid, 'notifications', notif))
  }

  async function openNotifWindow() {
    setOpenNotifications(true)
  }

  const formatMatters = useCallback(async () => {
    LoadAllMatters(user).then(dict => {
      setMatterData(dict)
    })
  }, [user])

  const checkForUpdates = useCallback(async () => {
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    const userData = userSnap.data()
    const firmID = userData.firmID
    let isAdmin = false
    onSnapshot(doc(db, 'firms', firmID, 'users', user.uid), doc => {
      getAndSetMyInfo()
      isAdmin = doc.data().isAdmin
    })
    onSnapshot(collection(db, 'firmUsers', user.uid, 'notifications'), () => {
      getNotifications()
    })
    onSnapshot(collection(db, 'firmUsers', user.uid, 'my_matters'), () => {
      setOpenSearch(false)
      setSearchQuery('')
      formatMatters()
      //prevent crash by closing input on change
    })
    onSnapshot(collection(db, 'firmUsers', user.uid, 'shared_matters'), () => {
      setOpenSearch(false)
      setSearchQuery('')
      formatMatters()
    })
    if (isAdmin) {
      onSnapshot(collection(db, 'firms', firmID, 'matters'), () => {
        setOpenSearch(false)
        setSearchQuery('')
        formatMatters()
      })
    }
  }, [formatMatters, getAndSetMyInfo, getNotifications, user.uid])

  useEffect(() => {
    checkForUpdates()
  }, [checkForUpdates])

  function openAccount() {
    setOpenProfile(false)
    navigate('/app/account')
  }

  function processNav(val) {
    setSearchQuery('')
    setOpenSearch(false)
    navigate('/app/matters/' + val)
  }

  function processSearchInput(val) {
    setSearchQuery(val)
    if (val.length > 0) {
      setOpenSearch(true)
      let newList = []
      for (const [key, value] of Object.entries(matterData)) {
        if (value[0].toLowerCase().includes(val.toLowerCase())) {
          newList.push(key)
        }
      }
      setMatterList(newList)
    } else {
      setOpenSearch(false)
    }
  }

  if (
    window.location.pathname === '/app/matters' ||
    window.location.pathname === '/app/matters/shared_matters' ||
    window.location.pathname === '/app/matters/firm_matters' ||
    window.location.pathname === '/app/matters/recent_matters' ||
    window.location.pathname === '/app/matters/tagged_matters'
  ) {
    return (
      <div>
        <div className='navPlaceHolder'></div>
        <Navbar bg='white' fixed='top' className='TopBarIn'>
          <Container fluid>
            <Navbar.Brand
              onClick={() => navigate('/app')}
              style={{ marginLeft: '10px', marginTop: '3px' }}
            >
              <div className='Logo'>
                <img className='TLLogo' src={logo} alt='logo' />
                <h3>TAP</h3>
                <h3 style={{ color: 'red' }}>LAW</h3>
              </div>
            </Navbar.Brand>
            <div className='TopbarLinks' style={{ marginTop: '2px' }}>
              <div className='SearchBarPH'>
                <input
                  type='text'
                  onClick={
                    searchQuery !== '' ? () => setOpenSearch(!openSearch) : ''
                  }
                  onChange={e => processSearchInput(e.target.value)}
                  value={searchQuery}
                  className='SearchInput'
                  placeholder='Search matters...'
                ></input>

                <div className='SearchIconOverlay'>
                  <SearchIcon style={{ color: 'gray' }}></SearchIcon>
                </div>

                {openSearch ? (
                  <div className='SearchDropDown'>
                    <div className='SearchList'>
                      {matterList.map(val => {
                        return (
                          <div
                            className='SearchRow'
                            onClick={() => processNav(val)}
                          >
                            <div style={{ marginTop: '-3px' }}>
                              {matterData[val][1] === 'mine' ? (
                                <PersonOutlineOutlinedIcon />
                              ) : (
                                ''
                              )}
                              {matterData[val][1] === 'shared' ? (
                                <PeopleOutlineOutlinedIcon />
                              ) : (
                                ''
                              )}
                              {matterData[val][1] === 'firm' ? (
                                <BusinessIcon />
                              ) : (
                                ''
                              )}
                            </div>
                            &nbsp;&nbsp;
                            {matterData[val][0]}
                            <div
                              style={{
                                marginLeft: 'auto',
                                marginRight: '15px',
                                color: 'gray'
                              }}
                            >
                              {matterData[val][1] === 'mine'
                                ? 'Created by me'
                                : ''}
                              {matterData[val][1] === 'shared'
                                ? matterData[val][2]
                                : ''}
                              {matterData[val][1] === 'firm'
                                ? matterData[val][2]
                                : ''}
                            </div>
                          </div>
                        )
                      })}
                      {matterList.length === 0 ? (
                        <div className='SearchRow'>
                          No matters found with your search
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {openSearch ? (
                <div
                  className='SearchOverlay'
                  onClick={() => setOpenSearch(false)}
                ></div>
              ) : (
                ''
              )}
            </div>
            {isFirmAdmin ? (
              <div
                className={
                  window.location.pathname === '/app/firmsettings'
                    ? 'TopInButtonSelected'
                    : 'TopInButton'
                }
                onClick={() => navigate('/app/firmsettings')}
              >
                <BusinessIcon></BusinessIcon>
              </div>
            ) : (
              ''
            )}

            <div className='TopInButton' onClick={() => openNotifWindow()}>
              {openNotifications ? (
                <NotificationsIcon />
              ) : (
                <NotificationsOutlinedIcon />
              )}
              {notifCount > 0 ? (
                <div className='NotifCount'>{notifCount}</div>
              ) : (
                ''
              )}
            </div>

            <div className='TopbarLinks2'>
              <Nav className='me-auto'>
                <div
                  className='Profile'
                  onClick={() => setOpenProfile(!openProfile)}
                >
                  <div
                    className='profileIcon'
                    style={{ background: userColor }}
                  >
                    <div style={{ margin: 'auto' }}>{userFirst}</div>
                  </div>
                  <div className='Items' style={{ marginLeft: '-8px' }}>
                    <h6 style={{ marginTop: '10px' }}>{userName}</h6>
                  </div>
                  &nbsp;
                  <div className={'ProfileOpen'}>
                    <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                  </div>
                </div>
                {openNotifications ? (
                  <div className='NotificationWindow'>
                    <h5 style={{ textAlign: 'center', marginTop: '15px' }}>
                      Notifications
                    </h5>
                    {notifications.map(val => {
                      return (
                        <div className='NotificationRow'>
                          <div style={{ margin: 'auto' }}>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              {val[3] === 'matter' ? (
                                <BusinessCenterOutlinedIcon />
                              ) : (
                                ''
                              )}
                              {val[3] === 'firm' ? (
                                <PersonOutlineOutlinedIcon />
                              ) : (
                                ''
                              )}
                              <div
                                className='NotifViewer'
                                style={{
                                  marginLeft: '10px',
                                  marginRight: '10px'
                                }}
                                onClick={() => viewNotif(val[4], val[2])}
                              >
                                {val[0]}
                              </div>
                              <div
                                className='closeNotification'
                                onClick={() => delNotif(val[4])}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    {notifications.length === 0 ? (
                      <div
                        style={{
                          textAlign: 'center',
                          color: 'gray',
                          marginTop: '30px'
                        }}
                      >
                        You're all caught up!
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
                {openNotifications ? (
                  <div
                    className='NotificationOverlay'
                    onClick={() => setOpenNotifications(false)}
                  />
                ) : (
                  ''
                )}

                {openProfile ? (
                  <div
                    className='AccountOptOverlay'
                    onClick={() => setOpenProfile(false)}
                  ></div>
                ) : (
                  ''
                )}
                {openProfile ? (
                  <div className='ProfileOptions'>
                    <div style={{ padding: '20px', paddingBottom: '0px' }}>
                      <h6 style={{ marginTop: '-0.5px' }}>{userName}</h6>
                      <div style={{ color: 'gray', marginTop: '-9px' }}>
                        {user.email}
                      </div>
                    </div>
                    <hr></hr>
                    <div className='AccountOpt' onClick={() => openAccount()}>
                      Manage Account
                    </div>
                    <div className='AccountOpt'>Help</div>
                    <div className='AccountOpt'>Pricing</div>
                    <div className='AccountOpt'>Terms & Policies</div>
                    <hr></hr>
                    <div className='AccountOpt' onClick={() => handleLogout()}>
                      Log out
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </Nav>
            </div>
          </Container>
        </Navbar>
      </div>
    )
  } else {
    return (
      <div>
        {/* <div className='placeholder'></div> */}
        <div className='navPlaceHolder'></div>
        <Navbar bg='white' fixed='top' className='TopBarIn'>
          <Container fluid>
            <Navbar.Brand
              onClick={() => navigate('/app')}
              style={{ marginLeft: '10px', marginTop: '3px' }}
            >
              <div className='Logo'>
                <img className='TLLogo' src={logo} alt='logo' />
                <h3>TAP</h3>
                <h3 style={{ color: 'red' }}>LAW</h3>
              </div>
            </Navbar.Brand>
            <div className='TopbarLinks' style={{ marginTop: '2px' }}>
              <div
                className='GoBack'
                onClick={() => navigate('/app/matters')}
                style={{ margin: 'auto' }}
              >
                <ArrowBackIosIcon
                  style={{ width: '19px', marginTop: '-3px' }}
                />
                Return Home
              </div>
            </div>
            {isFirmAdmin ? (
              <div
                className={
                  window.location.pathname === '/app/firmsettings'
                    ? 'TopInButtonSelected'
                    : 'TopInButton'
                }
                onClick={() => navigate('/app/firmsettings')}
              >
                <BusinessIcon></BusinessIcon>
              </div>
            ) : (
              ''
            )}

            <div className='TopInButton' onClick={() => openNotifWindow()}>
              {openNotifications ? (
                <NotificationsIcon />
              ) : (
                <NotificationsOutlinedIcon />
              )}
              {notifCount > 0 ? (
                <div className='NotifCount'>{notifCount}</div>
              ) : (
                ''
              )}
            </div>

            <div className='TopbarLinks2'>
              <Nav className='me-auto'>
                <div
                  className='Profile'
                  onClick={() => setOpenProfile(!openProfile)}
                >
                  <div
                    className='profileIcon'
                    style={{ background: userColor }}
                  >
                    <div style={{ margin: 'auto' }}>{userFirst}</div>
                  </div>
                  <div className='Items' style={{ marginLeft: '-8px' }}>
                    <h6 style={{ marginTop: '10px' }}>{userName}</h6>
                  </div>
                  &nbsp;
                  <div className={'ProfileOpen'}>
                    <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                  </div>
                </div>
                {openNotifications ? (
                  <div className='NotificationWindow'>
                    <h5 style={{ textAlign: 'center', marginTop: '15px' }}>
                      Notifications
                    </h5>
                    {notifications.map(val => {
                      return (
                        <div className='NotificationRow'>
                          <div style={{ margin: 'auto' }}>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              {val[3] === 'matter' ? (
                                <BusinessCenterOutlinedIcon />
                              ) : (
                                ''
                              )}
                              {val[3] === 'firm' ? (
                                <PersonOutlineOutlinedIcon />
                              ) : (
                                ''
                              )}
                              <div
                                className='NotifViewer'
                                style={{
                                  marginLeft: '10px',
                                  marginRight: '10px'
                                }}
                                onClick={() => viewNotif(val[4], val[2])}
                              >
                                {val[0]}
                              </div>
                              <div
                                className='closeNotification'
                                onClick={() => delNotif(val[4])}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    {notifications.length === 0 ? (
                      <div
                        style={{
                          textAlign: 'center',
                          color: 'gray',
                          marginTop: '30px'
                        }}
                      >
                        You're all caught up!
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
                {openNotifications ? (
                  <div
                    className='NotificationOverlay'
                    onClick={() => setOpenNotifications(false)}
                  />
                ) : (
                  ''
                )}

                {/* Place the overlay to exit on click capture */}
                {openProfile ? (
                  <div
                    className='AccountOptOverlay'
                    onClick={() => setOpenProfile(false)}
                  ></div>
                ) : (
                  ''
                )}
                {openProfile ? (
                  <div className='ProfileOptions'>
                    <div style={{ padding: '20px', paddingBottom: '0px' }}>
                      <h6 style={{ marginTop: '-0.5px' }}>{userName}</h6>
                      <div style={{ color: 'gray', marginTop: '-9px' }}>
                        {user.email}
                      </div>
                    </div>
                    <hr></hr>
                    <div className='AccountOpt' onClick={() => openAccount()}>
                      Manage Account
                    </div>
                    <div className='AccountOpt'>Help</div>
                    <div className='AccountOpt'>Pricing</div>
                    <div className='AccountOpt'>Terms & Policies</div>
                    <hr></hr>
                    <div className='AccountOpt' onClick={() => handleLogout()}>
                      Log out
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </Nav>
            </div>
          </Container>
        </Navbar>
      </div>
    )
  }
}
