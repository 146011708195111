import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'

export const Q20_8 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q20_8before, setQ20_8before] = useState('')
  const [q20_8during, setQ20_8during] = useState('')
  const [q20_8after, setQ20_8after] = useState('')

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q20_8: { Before: q20_8before, During: q20_8during, After: q20_8after }
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q20_8) {
      const qData = docSnap.data().q20_8
      setQ20_8before(qData['Before'])
      setQ20_8during(qData['During'])
      setQ20_8after(qData['After'])
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Describe, in your own words, how the incident<br></br>
          occurred. Make sure to include the speed, direction, <br></br>
          and location of each vehicle involved:
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container' style={{ height: '100px' }}>
          <textarea
            value={q20_8before}
            style={{
              height: '100px',
              borderStyle: 'none',
              borderRadius: '5px',
              resize: 'none',
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingTop: '10px'
            }}
            required
            onChange={e => setQ20_8before(e.target.value)}
          />
          <label className={q20_8before && 'filled'}>
            {'Before the incident'}
          </label>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container' style={{ height: '100px' }}>
          <textarea
            value={q20_8during}
            style={{
              height: '100px',
              borderStyle: 'none',
              borderRadius: '5px',
              resize: 'none',
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingTop: '10px'
            }}
            required
            onChange={e => setQ20_8during(e.target.value)}
          />
          <label className={q20_8during && 'filled'}>
            {'During the incident'}
          </label>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container' style={{ height: '100px' }}>
          <textarea
            value={q20_8after}
            style={{
              height: '100px',
              borderStyle: 'none',
              borderRadius: '5px',
              resize: 'none',
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingTop: '10px'
            }}
            required
            onChange={e => setQ20_8after(e.target.value)}
          />
          <label className={q20_8after && 'filled'}>
            {'After the incident'}
          </label>
        </div>
      </div>
    </div>
  )
})

export default Q20_8
