import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'

export const Q8_6 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q8_6, setQ8_6] = useState('')

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q8_6: q8_6
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    console.log(docSnap.data())
    if (docSnap.data().q8_6) {
      setQ8_6(docSnap.data().q8_6)
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          State the dates you did not work and for which <br></br>
          you lost income as a result of the incident
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container' style={{ height: '100px' }}>
          <textarea
            value={q8_6}
            style={{
              height: '100px',
              borderStyle: 'none',
              borderRadius: '5px',
              resize: 'none',
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingTop: '10px'
            }}
            required
            onChange={e => setQ8_6(e.target.value)}
          />
          <label className={q8_6 && 'filled'}>{'Dates and/or duration'}</label>
        </div>
      </div>
    </div>
  )
})

export default Q8_6
