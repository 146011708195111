import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import './Intake.css'
import { useParams } from 'react-router'
import TopbarClient from '../../Parts/TopbarClient'
import '../../../Inputs/Inputs.css'
import { Button, Card } from 'react-bootstrap'
import { PulseLoader } from 'react-spinners'
import { doc, getDoc, onSnapshot } from 'firebase/firestore'
import { db } from '../../../firebase'
import Q1_1 from './Questions/Q1_1'
import Q2_1 from './Questions/Q2_1'
import Q2_4 from './Questions/Q2_4'
import Q2_5 from './Questions/Q2_5'
import Q2_6 from './Questions/Q2_6'
import Q2_7 from './Questions/Q2_7'
import Q2_8 from './Questions/Q2_8'
import Q2_9 from './Questions/Q2_9'
import Q2_10 from './Questions/Q2_10'
import Q2_11 from './Questions/Q2_11'
import Q2_12 from './Questions/Q2_12'
import Q2_13 from './Questions/Q2_13'
import Q3_1 from './Questions/Q3_1'
import Q3_2 from './Questions/Q3_2'
import Q3_3 from './Questions/Q3_3'
import Q3_4 from './Questions/Q3_4'
import Q3_5 from './Questions/Q3_5'
import Q3_6 from './Questions/Q3_6'
import Q3_7 from './Questions/Q3_7'
import Q4_1 from './Questions/Q4_1'
import Q6_2 from './Questions/Q6_2'
import Q6_3 from './Questions/Q6_3'
import Q6_4 from './Questions/Q6_4'
import Q6_5 from './Questions/Q6_5'
import Q6_6 from './Questions/Q6_6'
import Q6_7 from './Questions/Q6_7'
import Q7_1 from './Questions/Q7_1'
import Q7_2 from './Questions/Q7_2'
import Q7_3 from './Questions/Q7_3'
import Q8_2 from './Questions/Q8_2'
import Q8_3 from './Questions/Q8_3'
import Q8_4 from './Questions/Q8_4'
import Q8_5 from './Questions/Q8_5'
import Q8_6 from './Questions/Q8_6'
import Q8_7 from './Questions/Q8_7'
import Q8_8 from './Questions/Q8_8'
import Q9_1 from './Questions/Q9_1'
import Q9_2 from './Questions/Q9_2'
import Q10_1 from './Questions/Q10_1'
import Q10_2 from './Questions/Q10_2'
import Q10_3 from './Questions/Q10_3'
import Q11_1 from './Questions/Q11_1'
import Q11_2 from './Questions/Q11_2'
import Q12_1 from './Questions/Q12_1'
import Q20_2 from './Questions/Q20_2'
import Q20_3 from './Questions/Q20_3'
import Q20_4 from './Questions/Q20_4'
import Q20_7 from './Questions/Q20_7'
import Q20_8 from './Questions/Q20_8'
import Q20_11 from './Questions/Q20_11'
import GetFinished from './GetFinished'
import GetQOrder from './GetQOrder'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Navbar } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import '../../Parts/TopbarIn.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ProgressBar from '@ramonak/react-progress-bar'
import DoneAllIcon from '@mui/icons-material/DoneAll'

export default function Intake() {
  //m_id is the matter id, p_id is the plaintiff id
  const childCompRef = useRef()

  const componentList = useMemo(
    () => [
      <Q1_1 ref={childCompRef}></Q1_1>,
      <Q2_1 ref={childCompRef}></Q2_1>,
      <Q2_4 ref={childCompRef}></Q2_4>,
      <Q2_5 ref={childCompRef}></Q2_5>,
      <Q2_6 ref={childCompRef}></Q2_6>,
      <Q2_7 ref={childCompRef}></Q2_7>,
      <Q2_8 ref={childCompRef}></Q2_8>,
      <Q2_9 ref={childCompRef}></Q2_9>,
      <Q2_10 ref={childCompRef}></Q2_10>,
      <Q2_11 ref={childCompRef}></Q2_11>,
      <Q2_12 ref={childCompRef}></Q2_12>,
      <Q2_13 ref={childCompRef}></Q2_13>,
      <Q3_1 ref={childCompRef}></Q3_1>,
      <Q3_2 ref={childCompRef}></Q3_2>,
      <Q3_3 ref={childCompRef}></Q3_3>,
      <Q3_4 ref={childCompRef}></Q3_4>,
      <Q3_5 ref={childCompRef}></Q3_5>,
      <Q3_6 ref={childCompRef}></Q3_6>,
      <Q3_7 ref={childCompRef}></Q3_7>,
      <Q4_1 ref={childCompRef}></Q4_1>,
      <Q6_2 ref={childCompRef}></Q6_2>,
      <Q6_3 ref={childCompRef}></Q6_3>,
      <Q6_4 ref={childCompRef}></Q6_4>,
      <Q6_5 ref={childCompRef}></Q6_5>,
      <Q6_6 ref={childCompRef}></Q6_6>,
      <Q6_7 ref={childCompRef}></Q6_7>,
      <Q7_1 ref={childCompRef}></Q7_1>,
      <Q7_2 ref={childCompRef}></Q7_2>,
      <Q7_3 ref={childCompRef}></Q7_3>,
      <Q8_2 ref={childCompRef}></Q8_2>,
      <Q8_3 ref={childCompRef}></Q8_3>,
      <Q8_4 ref={childCompRef}></Q8_4>,
      <Q8_5 ref={childCompRef}></Q8_5>,
      <Q8_6 ref={childCompRef}></Q8_6>,
      <Q8_7 ref={childCompRef}></Q8_7>,
      <Q8_8 ref={childCompRef}></Q8_8>,
      <Q9_1 ref={childCompRef}></Q9_1>,
      <Q9_2 ref={childCompRef}></Q9_2>,
      <Q10_1 ref={childCompRef}></Q10_1>,
      <Q10_2 ref={childCompRef}></Q10_2>,
      <Q10_3 ref={childCompRef}></Q10_3>,
      <Q11_1 ref={childCompRef}></Q11_1>,
      <Q11_2 ref={childCompRef}></Q11_2>,
      <Q12_1 ref={childCompRef}></Q12_1>,
      <Q20_2 ref={childCompRef}></Q20_2>,
      <Q20_3 ref={childCompRef}></Q20_3>,
      <Q20_4 ref={childCompRef}></Q20_4>,
      <Q20_7 ref={childCompRef}></Q20_7>,
      <Q20_8 ref={childCompRef}></Q20_8>,
      <Q20_11 ref={childCompRef}></Q20_11>
    ],
    []
  )

  const { m_id, p_id } = useParams()
  const [isMobile, setIsMobile] = useState(false)
  const [hasVerified, setHasVerified] = useState(true) //set to true when developing
  const [verifyInput, setVerifyInput] = useState('')
  const [verifyError, setVerifyError] = useState('')
  const [loadingVerify] = useState(false)
  const [currQuestion, setCurrQuestion] = useState(0)
  const [currIndex, setCurrIndex] = useState(0)
  const [hasFinished, setHasFinished] = useState(false)
  const [questionOrder, setQuestionOrder] = useState([])
  const [finishedQuestions, setFinishedQuestions] = useState([])
  const [progressNum, setProgressNum] = useState(0)

  async function saveAnswer() {
    await childCompRef.current.saveData()
    if (!questionOrder[currIndex + 1]) {
      setHasFinished(true);
      //insert code to update finished status in firebase
    } else {
      setCurrQuestion(questionOrder[currIndex + 1])
      setCurrIndex(currIndex + 1)
    }
  }

  function calcFinished(res, out) {
    setFinishedQuestions(res)
    let p = (res.length / out.length) * 100
    if (p > 100) {
      p = 100
    }
    setProgressNum(Math.round(p))
  }

  // Question skipping
  const updateIntakeData = useCallback(
    async (querySnapshot) => {
      const pSnap = await getDoc(doc(db, 'matters', m_id, 'plaintiffs', p_id))
      const pData = pSnap.data()

      const out = await GetQOrder(componentList, pData, querySnapshot)
      setQuestionOrder(out)
      await GetFinished(m_id, p_id).then(res => {
        calcFinished(res, out)
      })
    },
    [componentList, m_id, p_id]
  )

  const getPlaintiffData = useCallback(async () => {
    const iSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    updateIntakeData(iSnap)
  }, [m_id, p_id, updateIntakeData])

  useEffect(() => {
    getPlaintiffData()
    onSnapshot(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      querySnapshot => {
        updateIntakeData(querySnapshot)
      }
    )
  }, [getPlaintiffData, m_id, p_id, updateIntakeData])

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 840);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function back() {
    await childCompRef.current.saveData()
    if (currQuestion > 0) {
      setCurrQuestion(questionOrder[currIndex - 1])
      setCurrIndex(currIndex - 1)
    }
  }

  async function checkCode() {
    if (verifyInput !== '') {
      const pSnap = await getDoc(doc(db, 'matters', m_id, 'plaintiffs', p_id))
      if (pSnap.data().verifyCode === verifyInput) {
        setHasVerified(true)
      } else {
        setVerifyInput('')
        setHasVerified(false)
        setVerifyError('Invalid Code')
      }
    } else {
      setVerifyError('')
      setHasVerified(false)
    }
  }

  async function sideQuestionSelect(key) {
    setHasFinished(false);
    if (key === currIndex) {
      return
    }
    if (!hasFinished) {
      await childCompRef.current.saveData()
    }
    setCurrQuestion(questionOrder[key])
    setCurrIndex(key)
  }

  const IntakeSidebar = () => {
    return (
      <div className='SidebarIntake'>
        <div className='SIList'>
            {questionOrder.map((val, key) => {
              return (
                <div
                  className={key === currIndex ? 'SIRowSelected' : 'SIRow'}
                  onClick={() => sideQuestionSelect(key)}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '30px'
                    }}
                  >
                    Question {key}
                  </div>
                  {finishedQuestions.indexOf(questionOrder[key]) !== -1 ? (
                    <div
                      style={{
                        marginLeft: 'auto',
                        marginRight: '30px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <CheckCircleIcon style={{ color: 'rgb(20, 180, 21)' }} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )
            })}
          </div>
      </div>
    )
  }

  if (hasFinished) {
    return (
      <div>
        <Navbar
          bg='white'
          fixed='top'
          style={{
            borderBottomStyle: 'solid',
            height: '60px',
            borderWidth: '1px',
            borderColor: 'rgb(228, 228, 228)'
          }}
        >
          <Container style={{ display: 'grid', justifyContent: 'center' }}>
            {
              <ProgressBar
                completed={progressNum}
                height='20px'
                width='70vw'
                bgColor='rgb(130, 200, 220)'
              />
            }
          </Container>
        </Navbar>
        <div style={{ marginTop: '150px' }}></div>
        <IntakeSidebar />
        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto' }}>
            <div style={{ textAlign: 'center', fontSize:"25px", color:"green", marginBottom:"15px" }}>
              <DoneAllIcon /> Intake Complete
            </div>
              You may close this window or review/edit your answers.
          </div>
        </div>
      </div>
    )
  }
  else if (!hasVerified) {
    return (
      <div>
        <TopbarClient></TopbarClient>
        <div className='Intake'>
          <div style={{ margin: 'auto', marginTop: '0px' }}>
            <Card style={{ padding: '50px', paddingBottom: '10px' }}>
              Enter the verification code
              <br></br>
              that was sent to you
              <br></br>
              <br></br>
              <div className='input-container'>
                <input
                  type='text'
                  value={verifyInput}
                  required
                  onChange={e => setVerifyInput(e.target.value)}
                />
                <label className={verifyInput && 'filled'}>
                  {'Verification Code'}
                </label>
              </div>
              <br></br>
              <Button onClick={() => checkCode()}>
                {loadingVerify ? (
                  <div>
                    <PulseLoader color='white' size='8' />
                  </div>
                ) : (
                  <div>&nbsp;&nbsp;Verify&nbsp;&nbsp;</div>
                )}
              </Button>
              <br></br>
              <br></br>
              <div style={{ color: 'red' }}>{verifyError}</div>
            </Card>
          </div>
        </div>
      </div>
    )
  } else if (hasVerified) {
    return (
      <div>
        <Navbar
          bg='white'
          fixed='top'
          style={{
            borderBottomStyle: 'solid',
            height: '60px',
            borderWidth: '1px',
            borderColor: 'rgb(228, 228, 228)'
          }}
        >
          <Container style={{ display: 'grid', justifyContent: 'center' }}>
            {
              <ProgressBar
                completed={progressNum}
                height='20px'
                width='70vw'
                bgColor='rgb(130, 200, 220)'
              />
            }
          </Container>
        </Navbar>
        <div style={{ marginTop: '150px' }}></div>
        {!isMobile && (<IntakeSidebar />)}
        <div style={{ display: 'flex' }}>
          <div className='QuestionCard'>
            <div
              style={{
                marginTop: '13px',
                display: 'flex',
                borderBottomStyle: 'solid',
                borderColor: '#dddddd',
                borderWidth: '1px',
                paddingBottom: '13px'
              }}
            >
              {currIndex !== 0 ? (
                <div
                  className='TopButton'
                  style={{ marginLeft: '13px' }}
                  onClick={() => back()}
                >
                  <ArrowBackIosIcon /> &nbsp; Prev
                </div>
              )
            
              : (
                <div
                className='TopButtonDisabled'
                style={{ marginLeft: '13px' }}
                onClick={() => back()}
              >
                <ArrowBackIosIcon /> &nbsp; Prev
              </div>
              )}
              
              {finishedQuestions.indexOf(questionOrder[currIndex]) !== -1 ? (
                <div
                  style={{
                    margin: 'auto',
                    marginRight: '30px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <div style={{ color: 'green', fontWeight: '500' }}>Done </div>{' '}
                  &nbsp;
                  <CheckCircleIcon
                    style={{ color: 'rgb(20, 180, 21)', fontSize: '30px' }}
                    size='30px'
                  />
                </div>
              ) : (
                ''
              )}
              <div
                className='TopButton'
                style={{ marginRight: '13px' }}
                onClick={() => saveAnswer()}
              >
                Next &nbsp; <ArrowForwardIosIcon />
              </div>
            </div>
            <div style={{ marginTop: '10px' }}></div>
            <div style={{ padding: '20px' }}>{componentList[currQuestion]}</div>
          </div>
        </div>
      </div>
    )
  }
}
