import React, { useState, useEffect, useCallback } from 'react'
import { Fade } from '@mui/material'
import './Plaintiffs.css'
import { getDocs, collection } from 'firebase/firestore'
import { db } from '../../../../firebase'

export function PlaintiffOverview(props) {
  const [pData, setPData] = useState([])
  const [, setPlaintiffNames] = useState({})

  const getAndSetData = useCallback(async () => {
    const myMattersRef = collection(db, 'matters', props.mID, 'plaintiffs')
    const myMattersSnap = await getDocs(myMattersRef)

    let tempList = []
    let tempDict = {}
    myMattersSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      tempList.push(doc)
      if (doc.data().type !== 'Business') {
        tempDict[doc.id] = doc.data().full_name
      } else {
        tempDict[doc.id] = doc.data().business_name
      }
    })
    setPData(tempList)
    setPlaintiffNames(tempDict)
  }, [props.mID])

  useEffect(() => {
    getAndSetData()
  }, [getAndSetData])

  return (
    <Fade in={true} appear timeout={300}>
      <div className='PlaintiffOverview'>
        <div className='OverviewTop' style={{ width: 'fit-content' }}>
          <div className='GridItem'>Plaintiff Name</div>
          <div className='GridItem'>Email</div>
          <div className='GridItem'>Phone Number</div>
          {props.isLejeune ? (
            <div style={{ display: 'flex' }}>
              <div className='GridItem'>Contacted?</div>
              <div className='GridItem'>Signed Retainer?</div>
              <div className='GridItem'>Military Records</div>
              <div className='GridItem'>Military Status</div>
              <div className='GridItem'>Injury Tier</div>
              <div className='GridItem'>Injury</div>
              <div className='GridItem'>Main Contact</div>
              <div className='GridItem'>Last Contacted</div>
              <div className='GridItemL'>Medical Records</div>
            </div>
          ) : (
            <div className='GridItemL'>Address</div>
          )}
        </div>
        {pData.map(val => {
          return (
            <div className='OverviewRow' style={{ width: 'fit-content' }}>
              <div className='GridItem'>
                {val.data().type !== 'Business'
                  ? val.data().full_name
                  : val.data().business_name}
              </div>
              <div className='GridItem'>{val.data().email}</div>
              <div className='GridItem'>{val.data().phone}</div>
              {props.isLejeune ? (
                <div style={{ display: 'flex' }}>
                  <div className='GridItem'>
                    {val.data().contacted ? 'X' : ''}
                  </div>
                  <div className='GridItem'>
                    {val.data().signedRet ? 'X' : ''}
                  </div>
                  <div className='GridItem'>
                    {val.data()['MilitaryRecords']}
                  </div>
                  <div className='GridItem'>{val.data()['MilitaryStatus']}</div>
                  <div className='GridItem'>{val.data()['InjuryTier']}</div>
                  <div className='GridItem'>{val.data()['Injury']}</div>
                  <div className='GridItem'>{val.data()['MainContact']}</div>
                  <div className='GridItem'>{val.data()['LastContacted']}</div>
                  <div className='GridItemL'>
                    {val.data()['MedicalRecords']}
                  </div>
                </div>
              ) : (
                <div className='GridItemL'>{val.data().loc}</div>
              )}
            </div>
          )
        })}
      </div>
    </Fade>
  )
}
