import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Form } from 'react-bootstrap'

export const Q20_7 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q20_7location, setQ20_7location] = useState('')
  const [q20_7color, setQ20_7color] = useState('')
  const [q20_7seconds, setQ20_7seconds] = useState('')
  const [q20_7changed, setQ20_7changed] = useState(false)

  const [q20_7wasSignal, setQ20_7wasSignal] = useState(false)

  async function saveQuestion() {
    if (!q20_7wasSignal) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q20_7: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q20_7: {
            Location: q20_7location,
            Color: q20_7color,
            Seconds: q20_7seconds,
            Changed: q20_7changed
          }
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q20_7) {
      if (docSnap.data().q20_7 !== 'N/A') {
        setQ20_7wasSignal(true)

        const qData = docSnap.data().q20_7
        setQ20_7location(qData['Location'])
        setQ20_7color(qData['Color'])
        setQ20_7seconds(qData['Seconds'])
        setQ20_7changed(qData['Changed'])
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Was there a traffic signal facing you at the time of<br></br>
          the incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q20_7wasSignal}
                onClick={() => setQ20_7wasSignal(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q20_7wasSignal}
                onClick={() => setQ20_7wasSignal(false)}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        <div style={{ marginTop: '15px' }}></div>
        {q20_7wasSignal ? (
          <div>
            <div style={{ textAlign: 'center', width: '400px' }}>
              If so, state:
              <div style={{ marginTop: '15px' }}></div>
            </div>

            <div className='input-container'>
              <input
                type='text'
                value={q20_7location}
                required
                onChange={e => setQ20_7location(e.target.value)}
              />
              <label className={q20_7location && 'filled'}>
                {'The location when you first saw it'}
              </label>
            </div>

            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q20_7color}
                required
                onChange={e => setQ20_7color(e.target.value)}
              />
              <label className={q20_7color && 'filled'}>{'The color'}</label>
            </div>

            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q20_7seconds}
                required
                onChange={e => setQ20_7seconds(e.target.value)}
              />
              <label className={q20_7seconds && 'filled'}>
                {'The number of seconds it had been that color'}
              </label>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div style={{ textAlign: 'center' }}>
              Did the color change between the time you first<br></br>
              saw it and the incident?
              <div style={{ marginTop: '15px' }}></div>
              {['radio'].map(type => (
                <div key={`inline-${type}`} className='mb-3'>
                  <Form.Check
                    inline
                    label='Yes'
                    name='changed'
                    type={type}
                    checked={q20_7changed}
                    onClick={() => setQ20_7changed(true)}
                    id={`inline-${type}-1`}
                  />
                  <Form.Check
                    inline
                    label='No'
                    name='changed'
                    type={type}
                    checked={!q20_7changed}
                    onClick={() => setQ20_7changed(false)}
                    id={`inline-${type}-2`}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q20_7
