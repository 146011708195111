import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Form } from 'react-bootstrap'

export const Q2_4 = forwardRef((props, ref) => {
  const { m_id, p_id } = useParams()

  const [q2_4bool, setQ2_4bool] = useState(false)
  const [q2_4a, setQ2_4a] = useState('')
  const [q2_4b, setQ2_4b] = useState('')
  const [q2_4c, setQ2_4c] = useState('')
  const [q2_4d, setQ2_4d] = useState('')

  async function saveQuestion() {
    if (q2_4bool) {
      let ans = { a: q2_4a, b: q2_4b, c: q2_4c, d: q2_4d }
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_4: ans
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_4: 'N/A'
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  function clearFields() {
    setQ2_4a('')
    setQ2_4b('')
    setQ2_4c('')
    setQ2_4d('')
    setQ2_4bool(false)
  }

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q2_4) {
      if (docSnap.data().q2_4 !== 'N/A') {
        setQ2_4bool(true)
        setQ2_4a(docSnap.data().q2_4['a'])
        setQ2_4b(docSnap.data().q2_4['b'])
        setQ2_4c(docSnap.data().q2_4['c'])
        setQ2_4d(docSnap.data().q2_4['d'])
      } else {
        setQ2_4bool(false)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          width: '400px',
          margin: 'auto',
          marginTop: '-10px'
        }}
      >
        At the time of the incident, did you have any other <br></br> permit or
        license for the operation of a motor vehicle?
      </div>
      <br></br>
      <div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q2_4bool}
                onClick={() => setQ2_4bool(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q2_4bool}
                onClick={() => clearFields()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
      </div>
      {q2_4bool ? (
        <div>
          <div style={{ marginTop: '15px' }}></div>
          <div>If so, state:</div>
          <div style={{ marginTop: '15px' }}></div>
          <div className='input-container'>
            <input
              type='text'
              value={q2_4a}
              required
              onChange={e => setQ2_4a(e.target.value)}
            />
            <label className={q2_4a && 'filled'}>
              {'The state of other issuing entity'}
            </label>
          </div>
          <div style={{ marginTop: '15px' }}></div>
          <div className='input-container'>
            <input
              type='text'
              value={q2_4b}
              required
              onChange={e => setQ2_4b(e.target.value)}
            />
            <label className={q2_4b && 'filled'}>
              {'The license number and type'}
            </label>
          </div>
          <div style={{ marginTop: '15px' }}></div>
          <div className='input-container'>
            <input
              type='date'
              value={q2_4c}
              placeholder=''
              required
              onChange={e => setQ2_4c(e.target.value)}
            />
            <label className={'filled'}>{'The date of issuance'}</label>
          </div>
          <div style={{ marginTop: '15px' }}></div>
          <div className='input-container'>
            <input
              type='text'
              value={q2_4d}
              required
              onChange={e => setQ2_4d(e.target.value)}
            />
            <label className={q2_4d && 'filled'}>
              {'All the restrictions'}
            </label>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
})

export default Q2_4
