import React, { useState, useEffect, useCallback } from 'react'
import './Matters.css'
import { UserAuth } from '../../../contexts/AuthContext'
import {
  doc,
  getDocs,
  onSnapshot,
  getDoc,
  collection
} from 'firebase/firestore'
import { db } from '../../../firebase'
import 'reactjs-popup/dist/index.css'
import { Fade } from 'react-bootstrap'
import { PulseLoader } from 'react-spinners'
import MatterBlock from './MatterBlock'

export default function FirmMatters() {
  const [loadingMatters, setLoadingMatters] = useState(true)
  const [firmMatters, setFirmMatters] = useState([])
  const [firmMattersID, setFirmMattersID] = useState({})
  const [isFirmAdmin, setIsFirmAdmin] = useState(false)

  const { user } = UserAuth()

  const getAndSetUserData = useCallback(async () => {
    //Get the user's data so we can get their firm
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))

    const firmUsersRef = collection(
      db,
      'firms',
      userSnap.data().firmID,
      'users'
    )
    const firmUsersSnap = await getDocs(firmUsersRef)
    let isFAdmin = false
    firmUsersSnap.forEach(doc => {
      if (doc.id === user.uid && doc.data().isAdmin === true) {
        isFAdmin = true
        setIsFirmAdmin(true)
      }
    })
    let tempDict3 = {}
    let allFirmMatters = []
    let firmMattersList = []

    if (isFAdmin) {
      const firmMattersSnap = await getDocs(
        collection(db, 'firms', userSnap.data().firmID, 'matters')
      )

      firmMattersSnap.forEach(doc => {
        allFirmMatters.push(doc.id)
      })

      await Promise.all(
        allFirmMatters.map(async val => {
          const newMatterSnap = await getDoc(doc(db, 'matters', val))
          if (newMatterSnap.data().creatorID !== user.uid) {
            const name = newMatterSnap.data().caseName
            const date = newMatterSnap.data().dateOfLoss
            const status = newMatterSnap.data().status
            const dol = newMatterSnap.data().dateOfLoss
            const tort = newMatterSnap.data().coordinationProceeding
            const tortName = newMatterSnap.data().specialTitle

            const loc = newMatterSnap.data().jdxState
            const creatorUID = newMatterSnap.data().creatorID
            const creatorRef = await getDoc(doc(db, 'firmUsers', creatorUID))
            if (creatorRef.data()) {
              firmMattersList.push(val)
              tempDict3[val] = [
                name,
                date,
                status,
                dol,
                loc,
                creatorRef.data().email,
                tort,
                tortName
              ]
            }
          }
        })
      )

      if (firmMattersList.length > 0) {
        setFirmMatters(firmMattersList)
        setFirmMattersID(tempDict3)
      }
    }
    setLoadingMatters(false)
  }, [user.uid])

  const checkForUpdates = useCallback(async () => {
    const userSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    const userData = userSnap.data()
    const firmID = userData.firmID
    onSnapshot(collection(db, 'firms', firmID, 'matters'), () => {
      getAndSetUserData()
    })
  }, [getAndSetUserData, user.uid])

  useEffect(() => {
    checkForUpdates()
  }, [checkForUpdates])

  if (loadingMatters) {
    return (
      <div style={{ margin: 'auto', marginTop: '10%' }}>
        <PulseLoader width={200} color={'#6aa4e6'} />
      </div>
    )
  } else if (isFirmAdmin) {
    return (
      <Fade in={true} appear timeout={1200}>
        <div style={{ padding: '30px', overflowY: 'scroll', height: '100vh' }}>
          <div className='MyMattersPage'>
            <div className='MattersRows'>
              {firmMatters.map(val => {
                return (
                  <Fade in={true} appear>
                    <MatterBlock data={firmMattersID[val]} link={val} />
                  </Fade>
                )
              })}
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </Fade>
    )
  } else {
    return (
      <div style={{ margin: 'auto', marginTop: '10%' }}>
        You must be a firm admin to view this page
      </div>
    )
  }
}
