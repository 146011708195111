import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'

export const Q3_5 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()
  const addressRef = useRef()

  const [loaded, setLoaded] = useState(false)
  const [filled, setFilled] = useState(false)

  const [q3_5name, setQ3_5name] = useState('')
  const [q3_5address, setQ3_5address] = useState('')

  const [q3_5prev, setQ3_5prev] = useState([])
  const [q3_5has_prev, setQ3_5has_prev] = useState(false)
  const [q3_5prev_name, setQ3_5prev_name] = useState('')
  const [q3_5prev_from, setQ3_5prev_from] = useState('')
  const [q3_5prev_to, setQ3_5prev_to] = useState('')

  const [isUnincorporatedAssociation, setIsUnincorporatedAssociation] =
    useState(false)

  async function saveQuestion() {
    if (!isUnincorporatedAssociation) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q3_5: 'N/A'
        }
      )
    } else if (isUnincorporatedAssociation && q3_5name !== '') {
      if (q3_5has_prev) {
        await updateDoc(
          doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
          {
            q3_5: { Name: q3_5name, Address: q3_5address, PrevNames: q3_5prev }
          }
        )
      } else {
        await updateDoc(
          doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
          {
            q3_5: { Name: q3_5name, Address: q3_5address, PrevNames: [] }
          }
        )
      }
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    setLoaded(true)
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q3_5) {
      if (docSnap.data().q3_5 !== 'N/A') {
        setIsUnincorporatedAssociation(true)

        const docData = docSnap.data().q3_5
        setQ3_5name(docData['Name'])
        setQ3_5address(docData['Address'])
        setQ3_5prev(docData['PrevNames'])
        if (docData['PrevNames'].length > 0) {
          setQ3_5has_prev(true)
        }
      }
    }
  }, [m_id, p_id])

  const fillAddress = useCallback(async () => {
    setFilled(true)
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    addressRef.current.set(docSnap.data().q3_5['Address'])
  }, [m_id, p_id])

  useEffect(() => {
    if (!loaded) {
      getAndSetIntakeData()
    }
    if (addressRef.current && !filled) {
      fillAddress()
    }
  }, [fillAddress, filled, getAndSetIntakeData, loaded])

  function addPrev() {
    if (q3_5prev_name !== '') {
      let newList = []
      for (let i = 0; i < q3_5prev.length; i++) {
        newList.push(q3_5prev[i])
      }
      newList.push({
        Name: q3_5prev_name,
        FromDate: q3_5prev_from,
        ToDate: q3_5prev_to
      })

      setQ3_5prev(newList)
      setQ3_5prev_name('')
      setQ3_5prev_to('')
      setQ3_5prev_from('')
    }
  }

  function remPrev(index) {
    let newList = []
    for (let i = 0; i < q3_5prev.length; i++) {
      if (i !== index) {
        newList.push(q3_5prev[i])
      }
    }
    setQ3_5prev(newList)
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Are you an unincorporated association?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='ispart'
                type={type}
                checked={isUnincorporatedAssociation}
                onClick={() => setIsUnincorporatedAssociation(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='ispart'
                type={type}
                checked={!isUnincorporatedAssociation}
                onClick={() => setIsUnincorporatedAssociation(false)}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {isUnincorporatedAssociation ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                If so, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q3_5name}
                  required
                  onChange={e => setQ3_5name(e.target.value)}
                />
                <label className={q3_5name && 'filled'}>
                  {'Current unincorporated association name'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <AddressInput
                passData={data => setQ3_5address(data)}
                ref={addressRef}
                ph={'Address of business'}
              />
              <div style={{ marginTop: '15px' }}></div>

              <div style={{ textAlign: 'center', width: '400px' }}>
                Has your unincorporated association used other <br></br>
                names within the past 10 years?
                <div style={{ marginTop: '15px' }}></div>
                {['radio'].map(type => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Yes'
                      name='hasprev'
                      type={type}
                      checked={q3_5has_prev}
                      onClick={() => setQ3_5has_prev(true)}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name='hasprev'
                      type={type}
                      checked={!q3_5has_prev}
                      onClick={() => setQ3_5has_prev(false)}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </div>
              {q3_5has_prev ? (
                <Card style={{ padding: '20px', textAlign: 'center' }}>
                  State each name your joint venture has used <br></br>
                  over the past 10 years. Add one at a time.
                  <div style={{ marginTop: '15px' }}></div>
                  <div className='input-container'>
                    <input
                      type='text'
                      value={q3_5prev_name}
                      required
                      onChange={e => setQ3_5prev_name(e.target.value)}
                    />
                    <label className={q3_5prev_name && 'filled'}>
                      {'Previous Name'}
                    </label>
                  </div>
                  <div style={{ marginTop: '15px' }}></div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ textAlign: 'center' }}>
                      From:
                      <div className='input-container'>
                        <input
                          type='date'
                          value={q3_5prev_from}
                          required
                          onChange={e => setQ3_5prev_from(e.target.value)}
                        />
                      </div>
                    </div>
                    <div style={{ marginLeft: 'auto', textAlign: 'center' }}>
                      To:
                      <div className='input-container'>
                        <input
                          type='date'
                          value={q3_5prev_to}
                          required
                          onChange={e => setQ3_5prev_to(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '15px' }}></div>
                  <div style={{ display: 'flex', marginTop: '15px' }}>
                    <div
                      className='AddPerson'
                      style={{ color: 'white', margin: 'auto' }}
                      onClick={() => addPrev()}
                    >
                      <div>&nbsp;Add&nbsp;</div>
                      <AddIcon style={{ color: 'white' }}></AddIcon>
                    </div>
                  </div>
                  {q3_5prev.length > 0 ? <hr></hr> : ''}
                  {q3_5prev.length > 0 ? (
                    <div>
                      {q3_5prev.map((key, i) => {
                        return (
                          <Accordion
                            style={{
                              backgroundColor: 'white',
                              boxShadow: '0px 1px 3px #cccccc',
                              marginBottom: '4px'
                            }}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              {q3_5prev[i]['Name']}

                              <div style={{ marginLeft: 'auto' }}>
                                <div
                                  className='RemButton'
                                  onClick={() => remPrev(i)}
                                >
                                  &nbsp;
                                  <RemoveIcon></RemoveIcon>
                                  Remove&nbsp;&nbsp;&nbsp;
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div style={{ maxWidth: '400px' }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    padding: '10px',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '1px',
                                    borderBottomColor: '#dddddd'
                                  }}
                                >
                                  Name: &nbsp;
                                  <div style={{ color: 'gray' }}>
                                    {q3_5prev[i]['Name']}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    padding: '10px',
                                    borderBottomStyle: 'solid',
                                    borderWidth: '1px',
                                    borderBottomColor: '#dddddd'
                                  }}
                                >
                                  From: &nbsp;
                                  <div style={{ color: 'gray' }}>
                                    {q3_5prev[i]['FromDate']}
                                  </div>
                                </div>
                                <div
                                  style={{ display: 'flex', padding: '10px' }}
                                >
                                  To: &nbsp;
                                  <div style={{ color: 'gray' }}>
                                    {q3_5prev[i]['ToDate']}
                                  </div>
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        )
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </Card>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q3_5
