import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q2_5 = forwardRef((props, ref) => {
  const addressRef = useRef()

  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  // Question 2_1
  const [q2_5, setQ2_5] = useState([])

  const [q2_5address, setQ2_5address] = useState('')
  const [q2_5from, setQ2_5from] = useState('')
  const [q2_5to, setQ2_5to] = useState('')
  const [q2_5isCurrent, setQ2_5isCurrent] = useState('')

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q2_5: q2_5
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q2_5) {
      const answerArr = docSnap.data().q2_5
      setQ2_5(answerArr)
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addAddress() {
    if (q2_5address !== '') {
      let currList = q2_5
      if (q2_5isCurrent) {
        let newEntry = {
          Type: 'current',
          Address: q2_5address,
          FromDate: q2_5from,
          ToDate: q2_5to
        }
        currList.push(newEntry)
      } else {
        let newEntry = {
          Type: 'past',
          Address: q2_5address,
          FromDate: q2_5from,
          ToDate: q2_5to
        }
        currList.push(newEntry)
      }
      setQ2_5(currList)

      setQ2_5address('')
      setQ2_5from('')
      setQ2_5to('')
      addressRef.current.clear()
    }
  }

  function remAddress(index) {
    let newList = []
    for (let i = 0; i < q2_5.length; i++) {
      if (i !== index) {
        newList.push(q2_5[i])
      }
    }
    setQ2_5(newList)
  }

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()

    if (cMonth.toString().length === 1) {
      cMonth = '0' + cMonth.toString()
    }
    if (cDay.toString().length === 1) {
      cDay = '0' + cDay.toString()
    }

    return cYear + '-' + cMonth + '-' + cDay
  }

  function setCurrent() {
    setQ2_5isCurrent(true)
    setQ2_5to(getTime())
  }

  function setNotCurrent() {
    setQ2_5isCurrent(false)
    setQ2_5to('')
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Address']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              From: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['FromDate']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              To: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['ToDate']}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Address']}
                  onChange={e => (props.dict['Address'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              From: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['FromDate']}
                  onChange={e => (props.dict['FromDate'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              To: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['ToDate']}
                  onChange={e => (props.dict['ToDate'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <Card
        style={{
          width: '400px',
          marginTop: '-11px',
          padding: '0px',
          height: 'fit-content',
          borderColor: 'white'
        }}
      >
        <div style={{ textAlign: 'center' }}>
          State each residential address over the<br></br>
          past 5 years (enter one at a time):
        </div>
        <br></br>
        <AddressInput
          passData={data => setQ2_5address(data)}
          ref={addressRef}
        />
        <div style={{ marginTop: '15px' }}></div>
        <div style={{ textAlign: 'center' }}>Is this your current address?</div>
        <div style={{ marginTop: '15px' }}></div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q2_5isCurrent}
                onClick={() => setCurrent()}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q2_5isCurrent}
                onClick={() => setNotCurrent()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ textAlign: 'center' }}>
            From:
            <div className='input-container'>
              <input
                type='date'
                value={q2_5from}
                required
                onChange={e => setQ2_5from(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginLeft: 'auto', textAlign: 'center' }}>
            To:
            <div className='input-container'>
              <input
                type='date'
                value={q2_5to}
                required
                onChange={e => setQ2_5to(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <div
            className='AddPerson'
            style={{ color: 'white', margin: 'auto' }}
            onClick={() => addAddress()}
          >
            <div>&nbsp;Add&nbsp;</div>
            <AddIcon style={{ color: 'white' }}></AddIcon>
          </div>
        </div>
      </Card>
      <div>
        {q2_5.length >= 1 ? <hr></hr> : ''}
        <div style={{ maxWidth: '400px' }}>
          {q2_5.map((key, i) => {
            return (
              <Accordion
                style={{
                  backgroundColor: 'white',
                  boxShadow: '0px 1px 3px #cccccc',
                  marginBottom: '4px'
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {q2_5[i]['FromDate'].slice(0, 4)}-
                  {q2_5[i]['ToDate'].slice(0, 4)}
                  <div className='RemButton' onClick={() => remAddress(i)}>
                    &nbsp;
                    <RemoveIcon></RemoveIcon>
                    Remove&nbsp;&nbsp;&nbsp;
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <EditableAccordionDetails dict={q2_5[i]} />
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </div>
    </div>
  )
})

export default Q2_5
