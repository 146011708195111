import React from 'react'
import './Matters.css'
import { useParams } from 'react-router'
import { Outlet } from 'react-router'
import SidebarHome from './SidebarHome/SidebarHome'
import SidebarRight from './SidebarHome/SidebarRight'

const Matters = () => {
  const { id } = useParams()
  if (!id) {
    return (
      <div style={{ display: 'flex' }}>
        <SidebarHome></SidebarHome>
        <Outlet />
        <SidebarRight></SidebarRight>
      </div>
    )
  } else {
    return <Outlet />
  }
}

export default Matters
