import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const Q8_2 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q8_2, setQ8_2] = useState([])

  const [q8_2nature, setQ8_2nature] = useState('')
  const [q8_2title, setQ8_2title] = useState('')
  const [q8_2date, setQ8_2date] = useState('')

  const [q8_2hasLoss, setQ8_2hasLoss] = useState(false)

  async function saveQuestion() {
    if (!q8_2hasLoss) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q8_2: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q8_2: q8_2
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    console.log(docSnap.data())
    if (docSnap.data().q8_2) {
      if (docSnap.data().q8_2 !== 'N/A') {
        setQ8_2(docSnap.data().q8_2)
        setQ8_2hasLoss(true)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addEmployment() {
    if (q8_2nature !== '') {
      let currList = q8_2
      currList.push({ Nature: q8_2nature, Title: q8_2title, Date: q8_2date })

      setQ8_2(currList)
      setQ8_2nature('')
      setQ8_2title('')
      setQ8_2date('')
    }
  }

  function remEmployment(index) {
    let newList = []
    for (let i = 0; i < q8_2.length; i++) {
      if (i !== index) {
        newList.push(q8_2[i])
      }
    }
    setQ8_2(newList)
  }

  async function noLoss() {
    setQ8_2hasLoss(false)
    setQ8_2([])
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q8_2: 'N/A'
      }
    )
  }

  async function saveTrue() {
    setQ8_2hasLoss(true)
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q8_2: []
      }
    )
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Do you attribute any loss of income or earning<br></br>
          capacity to the incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q8_2hasLoss}
                onClick={() => saveTrue()}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q8_2hasLoss}
                onClick={() => noLoss()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        <div style={{ marginTop: '15px' }}></div>
        {q8_2hasLoss ? (
          <div>
            <div style={{ textAlign: 'center', width: '400px' }}>
              For each employment affected by the incident,<br></br>
              state the following:
              <div style={{ marginTop: '15px' }}></div>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q8_2nature}
                required
                onChange={e => setQ8_2nature(e.target.value)}
              />
              <label className={q8_2nature && 'filled'}>
                {'The nature of your work'}
              </label>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q8_2title}
                required
                onChange={e => setQ8_2title(e.target.value)}
              />
              <label className={q8_2title && 'filled'}>
                {'Job title at the time of the incident'}
              </label>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='date'
                value={q8_2date}
                required
                onChange={e => setQ8_2date(e.target.value)}
              />
              <label className={'filled'}>{'Date your employment began'}</label>
            </div>

            <div style={{ marginTop: '15px' }}></div>
            <div style={{ display: 'flex', marginTop: '15px' }}>
              <div
                className='AddPerson'
                style={{ color: 'white', margin: 'auto' }}
                onClick={() => addEmployment()}
              >
                <div>&nbsp;Add&nbsp;</div>
                <AddIcon style={{ color: 'white' }}></AddIcon>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q8_2.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q8_2.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q8_2[i]['Title']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remEmployment(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ maxWidth: '400px' }}>
                          <div
                            style={{
                              display: 'flex',
                              padding: '10px',
                              borderBottomStyle: 'solid',
                              borderWidth: '1px',
                              borderBottomColor: '#dddddd'
                            }}
                          >
                            Nature: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q8_2[i]['Nature']}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              padding: '10px',
                              borderBottomStyle: 'solid',
                              borderWidth: '1px',
                              borderBottomColor: '#dddddd'
                            }}
                          >
                            Title: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q8_2[i]['Title']}
                            </div>
                          </div>
                          <div style={{ display: 'flex', padding: '10px' }}>
                            Began: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q8_2[i]['Date']}
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q8_2
