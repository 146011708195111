import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form, InputGroup } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CurrencyInput from 'react-currency-input-fix'
import AddressInput from '../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q6_7 = forwardRef((props, ref) => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q6_7, setQ6_7] = useState([])

  const [q6_7nature, setQ6_7nature] = useState('')
  const [q6_7duration, setQ6_7duration] = useState('')
  const [q6_7cost, setQ6_7cost] = useState('')

  const [q6_7hcp, setQ6_7hcp] = useState([])
  const [q6_7hcp_name, setQ6_7hcp_name] = useState('')
  const [q6_7hcp_address, setQ6_7hcp_address] = useState('')
  const [q6_7hcp_phone, setQ6_7hcp_phone] = useState('')

  const [q6_7complaints, setQ6_7complaints] = useState([])
  const [q6_7complaint, setQ6_7complaint] = useState('')

  const [hadInjuries, setHadInjuries] = useState(false)

  async function saveQuestion() {
    if (q6_7.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_7: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_7: q6_7
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q6_7) {
      if (docSnap.data().q6_7 !== 'N/A') {
        setHadInjuries(true)
        setQ6_7(docSnap.data().q6_7)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addInjury() {
    if (q6_7nature !== '') {
      let currList = q6_7
      currList.push({
        Nature: q6_7nature,
        Duration: q6_7duration,
        Cost: q6_7cost,
        Providers: q6_7hcp,
        Complaints: q6_7complaints
      })

      setQ6_7(currList)

      setQ6_7nature('')
      setQ6_7duration('')
      setQ6_7cost('')
      setQ6_7hcp([])

      setQ6_7complaints([])
      setQ6_7complaint('')
    }
  }

  function remInjury(index) {
    let newList = []
    for (let i = 0; i < q6_7.length; i++) {
      if (i !== index) {
        newList.push(q6_7[i])
      }
    }
    setQ6_7(newList)
  }

  function noInjuries() {
    setHadInjuries(false)
    setQ6_7([])
  }

  function addHCP() {
    if (q6_7hcp_name !== '') {
      let currList = q6_7hcp
      currList.push({
        Name: q6_7hcp_name,
        Address: q6_7hcp_address,
        Phone: q6_7hcp_phone
      })

      setQ6_7hcp(currList)
      setQ6_7hcp_name('')
      setQ6_7hcp_address('')
      setQ6_7hcp_phone('')
      addressRef.current.clear()
    }
  }

  function remHCP(index) {
    let newList = []
    for (let i = 0; i < q6_7hcp.length; i++) {
      if (i !== index) {
        newList.push(q6_7hcp[i])
      }
    }
    setQ6_7hcp(newList)
  }

  function addComplaint() {
    if (q6_7complaint !== '') {
      let currList = q6_7complaints
      currList.push(q6_7complaint)

      setQ6_7complaints(currList)
      setQ6_7complaint('')
    }
  }

  function remComplaint(index) {
    let newList = []
    for (let i = 0; i < q6_7complaints.length; i++) {
      if (i !== index) {
        newList.push(q6_7complaints[i])
      }
    }
    setQ6_7complaints(newList)
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Nature: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Nature']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Duration: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Duration']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Cost: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Cost']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Providers: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['Providers'].map((key, j) => {
                  return <div>{props.dict['Providers'][j]['Name']}</div>
                })}
              </div>
              <br></br>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Complaints: &nbsp;
              <div style={{ color: 'gray' }}>
                {props.dict['Complaints'].map((key, j) => {
                  if (j === props.dict['Complaints'].length - 1) {
                    return props.dict['Complaints'][j]
                  } else {
                    return props.dict['Complaints'][j] + ', '
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Nature: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Nature']}
                  onChange={e => (props.dict['Nature'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Duration: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Duration']}
                  onChange={e => (props.dict['Duration'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Cost: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Cost']}
                  onChange={e => (props.dict['Cost'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Has any health care provider advised that you may<br></br>
          require future or additional treatment for any injuries that you
          attribute to the incident? <br></br>
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hadInjuries}
                onClick={() => setHadInjuries(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hadInjuries}
                onClick={() => noInjuries()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hadInjuries ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each injury, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_7nature}
                  required
                  onChange={e => setQ6_7nature(e.target.value)}
                />
                <label className={q6_7nature && 'filled'}>
                  {'The nature of treatment'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_7duration}
                  required
                  onChange={e => setQ6_7duration(e.target.value)}
                />
                <label className={q6_7duration && 'filled'}>
                  {'Duration of treatment'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <div className='input-container'>
                  <CurrencyInput
                    id='input-example'
                    name='input-name'
                    value={q6_7cost}
                    decimalsLimit={2}
                    style={{ width: '363px' }}
                    onValueChange={value => setQ6_7cost(value)}
                  />
                  <label className={q6_7cost && 'filled'}>
                    {'Cost of treatment'}
                  </label>
                </div>
              </InputGroup>
              <div style={{ marginTop: '15px' }}></div>

              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  For each health care provider that treated this <br></br>
                  injury, state:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q6_7hcp_name}
                    required
                    onChange={e => setQ6_7hcp_name(e.target.value)}
                  />
                  <label className={q6_7hcp_name && 'filled'}>
                    {'Health Care Provider Name'}
                  </label>
                </div>
                <div style={{ marginTop: '15px' }}></div>
                <AddressInput
                  passData={data => setQ6_7hcp_address(data)}
                  ref={addressRef}
                  ph={'Health Care Provider Address'}
                />
                <div style={{ marginTop: '15px' }}></div>
                <div className='phone-input-container'>
                  <PhoneInput
                    enableAreaCodes={false}
                    inputStyle={{
                      height: '44px',
                      width: 'inherit',
                      borderColor: 'rgb(214, 214, 214)'
                    }}
                    disableCountryCode
                    country={'us'}
                    onlyCountries={['us']}
                    value={q6_7hcp_phone}
                    placeholder=''
                    onChange={setQ6_7hcp_phone}
                  />

                  <label className={q6_7hcp_phone && 'filled'}>
                    {'Phone Number'}
                  </label>
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    className='AddPerson'
                    style={{ color: 'white', margin: 'auto' }}
                    onClick={() => addHCP()}
                  >
                    <div>&nbsp;Add&nbsp;</div>
                    <AddIcon style={{ color: 'white' }}></AddIcon>
                  </div>
                </div>
                {q6_7hcp.length > 0 ? <hr></hr> : ''}
                <div style={{ marginBottom: '-15px' }}>
                  {q6_7hcp.map((key, i) => {
                    return (
                      <Accordion
                        style={{
                          backgroundColor: 'white',
                          boxShadow: '0px 1px 3px #cccccc',
                          marginBottom: '4px'
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {q6_7hcp[i]['Name']}

                          <div style={{ marginLeft: 'auto' }}>
                            <div
                              className='RemButton'
                              onClick={() => remHCP(i)}
                            >
                              &nbsp;
                              <RemoveIcon></RemoveIcon>
                              Remove&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ maxWidth: '400px' }}>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Name: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q6_7hcp[i]['Name']}
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                padding: '10px',
                                borderBottomStyle: 'solid',
                                borderWidth: '1px',
                                borderBottomColor: '#dddddd'
                              }}
                            >
                              Address: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q6_7hcp[i]['Address']}
                              </div>
                            </div>
                            <div style={{ display: 'flex', padding: '10px' }}>
                              Phone: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q6_7hcp[i]['Phone']}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </div>
              </Card>

              <div style={{ marginTop: '15px' }}></div>

              <Card style={{ padding: '20px', textAlign: 'center' }}>
                <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                  State each complaint for which the treatment <br></br>
                  was advised:
                </div>
                <div className='input-container'>
                  <input
                    type='text'
                    value={q6_7complaint}
                    required
                    onChange={e => setQ6_7complaint(e.target.value)}
                  />
                  <label className={q6_7complaint && 'filled'}>
                    {'State complaint'}
                  </label>
                </div>

                <div
                  style={{
                    display: 'flex',
                    marginTop: '15px',
                    marginBottom: '10px'
                  }}
                >
                  <div
                    className='AddPerson'
                    style={{ color: 'white', margin: 'auto' }}
                    onClick={() => addComplaint()}
                  >
                    <div>&nbsp;Add&nbsp;</div>
                    <AddIcon style={{ color: 'white' }}></AddIcon>
                  </div>
                </div>
                {q6_7complaints.length > 0 ? <hr></hr> : ''}
                <div style={{ marginBottom: '-15px' }}>
                  {q6_7complaints.map((key, i) => {
                    return (
                      <Accordion
                        style={{
                          backgroundColor: 'white',
                          boxShadow: '0px 1px 3px #cccccc',
                          marginBottom: '4px'
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          {q6_7complaints[i]}

                          <div style={{ marginLeft: 'auto' }}>
                            <div
                              className='RemButton'
                              onClick={() => remComplaint(i)}
                            >
                              &nbsp;
                              <RemoveIcon></RemoveIcon>
                              Remove&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ maxWidth: '400px' }}>
                            <div style={{ display: 'flex', padding: '10px' }}>
                              Complaint: &nbsp;
                              <div style={{ color: 'gray' }}>
                                {q6_7complaints[i]}
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })}
                </div>
              </Card>

              <div style={{ display: 'flex', marginTop: '35px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '180px' }}
                  onClick={() => addInjury()}
                >
                  <div>&nbsp;Add Treatment&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q6_7.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q6_7.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q6_7[i]['Nature']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remInjury(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q6_7[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q6_7
