// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAa50lhdeSgZyWlUT6exk4LPgD6KMXFqVc',
  authDomain: 'taplaw-cfedb.firebaseapp.com',
  projectId: 'taplaw-cfedb',
  storageBucket: 'taplaw-cfedb.appspot.com',
  messagingSenderId: '162279501486',
  appId: '1:162279501486:web:12af19ec9f2665b825473f',
  measurementId: 'G-8FT8TL5QSF'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Cloud functions
const functions = getFunctions(app)
export const setCustomUserClaims = httpsCallable(
  functions,
  'setCustomUserClaims'
)
export const deleteUser = httpsCallable(functions, 'deleteUser')
export const getUserByEmail = httpsCallable(functions, 'getUserByEmail')
export const sendEmail = httpsCallable(functions, 'sendEmail')
export const sendSMS = httpsCallable(functions, 'sendSMS')

// Basic stuff
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const db = getFirestore()
export const storage = getStorage()
export default app
