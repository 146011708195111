import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form } from 'react-bootstrap'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'

export const Q2_13 = forwardRef((props, ref) => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  // Question 2_1
  const [q2_13, setQ2_13] = useState([])

  const [q2_13name, setQ2_13name] = useState('')
  const [q2_13address, setQ2_13address] = useState('')
  const [q2_13phone, setQ2_13phone] = useState('')
  const [hadSubs, setHadSubs] = useState(false)

  async function saveQuestion() {
    if (q2_13.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_13: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_13: q2_13
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q2_13) {
      if (docSnap.data().q2_13 !== 'N/A') {
        setHadSubs(true)
        setQ2_13(docSnap.data().q2_13)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addPerson() {
    let currList = q2_13
    currList.push({
      Name: q2_13name,
      Address: q2_13address,
      Phone: q2_13phone,
      Subs: []
    })

    setQ2_13(currList)

    setQ2_13name('')
    setQ2_13address('')
    setQ2_13phone('')

    addressRef.current.clear()
  }

  function remPerson(index) {
    let newList = []
    for (let i = 0; i < q2_13.length; i++) {
      if (i !== index) {
        newList.push(q2_13[i])
      }
    }
    setQ2_13(newList)
  }

  function noPeople() {
    setHadSubs(false)
    setQ2_13([])
  }

  const PersonListItem = props => {
    const addressRef2 = useRef()
    const addressRef3 = useRef()
    const addressRef4 = useRef()

    const index_main = props.data
    const [subList, setSubList] = useState([])
    useEffect(() => {
      setSubList(q2_13[index_main]['Subs'])
    }, [index_main])

    const [q2_13_sub_nature, setQ2_13_sub_nature] = useState('')
    const [q2_13_sub_quantity, setQ2_13_sub_quantity] = useState('')
    const [q2_13_sub_date, setQ2_13_sub_date] = useState('')
    const [q2_13_sub_time, setQ2_13_sub_time] = useState('')
    const [q2_13_sub_address, setQ2_13_sub_address] = useState('')
    const [q2_13_sub_has_people, setQ2_13_sub_has_people] = useState(false)
    const [q2_13_sub_people, setQ2_13_sub_people] = useState([])
    const [q2_13_sub_p_name, setQ2_13_sub_p_name] = useState('')
    const [q2_13_sub_p_address, setQ2_13_sub_p_address] = useState('')
    const [q2_13_sub_p_phone, setQ2_13_sub_p_phone] = useState('')
    const [q2_13_sub_has_hcp, setQ2_13_sub_has_hcp] = useState(false)
    const [q2_13_sub_hcp, setQ2_13_sub_hcp] = useState([])
    const [q2_13_sub_hcp_name, setQ2_13_sub_hcp_name] = useState('')
    const [q2_13_sub_hcp_address, setQ2_13_sub_hcp_address] = useState('')
    const [q2_13_sub_hcp_phone, setQ2_13_sub_hcp_phone] = useState('')
    const [q2_13_sub_hcp_condition, setQ2_13_sub_hcp_condition] = useState('')

    function addSubPerson() {
      if (q2_13_sub_p_name !== '') {
        const entry = {
          Name: q2_13_sub_p_name,
          Address: q2_13_sub_p_address,
          Phone: q2_13_sub_p_phone
        }
        let currPList = q2_13_sub_people
        currPList.push(entry)
        setQ2_13_sub_people(currPList)

        setQ2_13_sub_p_name('')
        setQ2_13_sub_p_address('')
        setQ2_13_sub_p_phone('')

        addressRef3.current.clear()
      }
    }

    function remSubPerson(index) {
      let newList = []
      for (let i = 0; i < q2_13_sub_people.length; i++) {
        if (i !== index) {
          newList.push(q2_13_sub_people[i])
        }
      }
      setQ2_13_sub_people(newList)
    }

    function addSubHCP() {
      if (q2_13_sub_hcp_name !== '') {
        const entry = {
          Name: q2_13_sub_hcp_name,
          Address: q2_13_sub_hcp_address,
          Phone: q2_13_sub_hcp_phone,
          Condition: q2_13_sub_hcp_condition
        }
        let currPList = q2_13_sub_hcp
        currPList.push(entry)
        setQ2_13_sub_hcp(currPList)

        setQ2_13_sub_hcp_name('')
        setQ2_13_sub_hcp_address('')
        setQ2_13_sub_hcp_phone('')
        setQ2_13_sub_hcp_condition('')

        addressRef4.current.clear()
      }
    }

    function remSubHCP(index) {
      let newList = []
      for (let i = 0; i < q2_13_sub_hcp.length; i++) {
        if (i !== index) {
          newList.push(q2_13_sub_hcp[i])
        }
      }
      setQ2_13_sub_hcp(newList)
    }

    function addSubstance() {
      if (q2_13_sub_nature !== '') {
        const entry = {
          Nature: q2_13_sub_nature,
          Quantity: q2_13_sub_quantity,
          Date: q2_13_sub_date,
          Time: q2_13_sub_time,
          AddressTaken: q2_13_sub_address,
          OtherPeople: q2_13_sub_people,
          HCP: q2_13_sub_hcp
        }
        let arr = subList
        arr.push(entry)

        setSubList(arr)

        let tempQ2_13 = q2_13
        tempQ2_13[index_main]['Subs'] = arr

        setQ2_13(tempQ2_13)

        setQ2_13_sub_has_people(false)
        setQ2_13_sub_has_hcp(false)

        setQ2_13_sub_hcp_name('')
        setQ2_13_sub_hcp_address('')
        setQ2_13_sub_hcp_phone('')
        setQ2_13_sub_hcp_condition('')

        setQ2_13_sub_nature('')
        setQ2_13_sub_address('')
        setQ2_13_sub_date('')
        setQ2_13_sub_time('')
        setQ2_13_sub_quantity('')

        setQ2_13_sub_people([])
        setQ2_13_sub_hcp([])

        addressRef2.current.clear()
      }
    }

    function remSubstance(i) {
      let tempSubList = []
      for (let x = 0; x < subList.length; x++) {
        if (x !== i) {
          tempSubList.push(subList[x])
        }
      }
      setSubList(tempSubList)

      let tempQ2_13 = q2_13
      tempQ2_13[index_main]['Subs'] = tempSubList

      setQ2_13(tempQ2_13)
    }
    return (
      <div>
        <Accordion
          style={{
            backgroundColor: 'white',
            boxShadow: '0px 1px 3px #cccccc',
            marginBottom: '4px'
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {q2_13[index_main]['Name']}
            <div
              style={
                q2_13[index_main]['Subs'].length < 1
                  ? { marginLeft: 'auto', marginRight: '15px', color: 'red' }
                  : { marginLeft: 'auto', marginRight: '15px' }
              }
            >
              {q2_13[index_main]['Subs'].length < 1 ? (
                <div style={{ display: 'flex' }}>
                  <ErrorOutlineIcon />
                  &nbsp;Add Substances
                </div>
              ) : (
                `Substances (${q2_13[index_main]['Subs'].length})`
              )}
            </div>
            <div
              className='AccRemButton'
              style={{ marginLeft: '0px', width: '40px' }}
              onClick={() => remPerson(index_main)}
            >
              <RemoveIcon></RemoveIcon>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <hr></hr>
            <div
              style={{
                padding: '5px',
                margin: '6px',
                backgroundColor: 'white',
                borderRadius: '10px'
              }}
            >
              For each substance this person took, state:
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_13_sub_nature}
                  required
                  onChange={e => setQ2_13_sub_nature(e.target.value)}
                />
                <label className={q2_13_sub_nature && 'filled'}>
                  {'The name of the substance'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_13_sub_quantity}
                  required
                  onChange={e => setQ2_13_sub_quantity(e.target.value)}
                />
                <label className={q2_13_sub_quantity && 'filled'}>
                  {'Quantity taken/used'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              Date and time of day it was taken:
              <div style={{ display: 'flex' }}>
                <div className='input-container'>
                  <input
                    type='date'
                    value={q2_13_sub_time}
                    required
                    onChange={e => setQ2_13_sub_time(e.target.value)}
                  />
                </div>
                <div className='input-container' style={{ marginLeft: '20px' }}>
                  <input
                    type='time'
                    value={q2_13_sub_date}
                    required
                    placeholder='12:34 AM'
                    onChange={e => setQ2_13_sub_date(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <AddressInput
                passData={data => setQ2_13_sub_address(data)}
                ref={addressRef2}
              />
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ textAlign: 'center' }}>
                Were other people present when this <br></br>substance was
                taken?
                <div style={{ marginTop: '15px' }}></div>
                {['radio'].map(type => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Yes'
                      name={`p1${props.data}`}
                      type={type}
                      checked={q2_13_sub_has_people}
                      onClick={() => setQ2_13_sub_has_people(true)}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name={`p1${props.data}`}
                      type={type}
                      checked={!q2_13_sub_has_people}
                      onClick={() => setQ2_13_sub_has_people(false)}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </div>
              {q2_13_sub_has_people ? (
                <Card style={{ padding: '8px', marginBottom: '15px' }}>
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ color: 'blue' }}>
                      State the following info for each person<br></br> present
                      when the substance was taken:
                    </div>

                    <div style={{ marginTop: '15px' }}></div>
                    <div className='input-container'>
                      <input
                        type='text'
                        value={q2_13_sub_p_name}
                        required
                        onChange={e => setQ2_13_sub_p_name(e.target.value)}
                      />
                      <label className={q2_13_sub_p_name && 'filled'}>
                        {'Name'}
                      </label>
                    </div>
                    <div style={{ marginTop: '15px' }}></div>
                    <AddressInput
                      passData={data => setQ2_13_sub_p_address(data)}
                      ref={addressRef3}
                    />
                    <div style={{ marginTop: '15px' }}></div>
                    <div className='phone-input-container'>
                      <PhoneInput
                        enableAreaCodes={false}
                        inputStyle={{
                          height: '44px',
                          width: 'inherit',
                          borderColor: 'rgb(214, 214, 214)'
                        }}
                        disableCountryCode
                        country={'us'}
                        onlyCountries={['us']}
                        value={q2_13_sub_p_phone}
                        placeholder=''
                        onChange={setQ2_13_sub_p_phone}
                      />

                      <label className={q2_13_sub_p_phone && 'filled'}>
                        {'Their Phone Number'}
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '15px',
                      marginBottom: '10px',
                      paddingBottom: '5px'
                    }}
                  >
                    <div
                      className='AddPerson'
                      style={{ color: 'white', margin: 'auto' }}
                      onClick={() => addSubPerson()}
                    >
                      <div>&nbsp;Add&nbsp;</div>
                      <AddIcon style={{ color: 'white' }}></AddIcon>
                    </div>
                  </div>
                  {q2_13_sub_people.length > 0 ? <hr></hr> : ''}
                  <div style={{ marginBottom: '-15px' }}>
                    {q2_13_sub_people.map((key, i) => {
                      return (
                        <div className='q1Item'>
                          {q2_13_sub_people[i]['Name']}, &nbsp;
                          {q2_13_sub_people[i]['Phone']}
                          <div
                            className='RemButton'
                            onClick={() => remSubPerson(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Card>
              ) : (
                ''
              )}
              <div style={{ textAlign: 'center' }}>
                Was this substance prescribed/furnished by<br></br>
                healthcare provider(s)?
                <div style={{ marginTop: '15px' }}></div>
                {['radio'].map(type => (
                  <div key={`inline-${type}`} className='mb-3'>
                    <Form.Check
                      inline
                      label='Yes'
                      name={`p2${props.data}`}
                      type={type}
                      checked={q2_13_sub_has_hcp}
                      onClick={() => setQ2_13_sub_has_hcp(true)}
                      id={`inline-${type}-1`}
                    />
                    <Form.Check
                      inline
                      label='No'
                      name={`p2${props.data}`}
                      type={type}
                      checked={!q2_13_sub_has_hcp}
                      onClick={() => setQ2_13_sub_has_hcp(false)}
                      id={`inline-${type}-2`}
                    />
                  </div>
                ))}
              </div>
              {q2_13_sub_has_hcp ? (
                <Card style={{ padding: '5px' }}>
                  <div style={{ color: 'blue', textAlign: 'center' }}>
                    State the following info for each health <br></br>care
                    provider who prescribed or furnished<br></br> the substance:
                  </div>

                  <div style={{ marginTop: '15px' }}></div>
                  <div className='input-container'>
                    <input
                      type='text'
                      value={q2_13_sub_hcp_name}
                      required
                      onChange={e => setQ2_13_sub_hcp_name(e.target.value)}
                    />
                    <label className={q2_13_sub_hcp_name && 'filled'}>
                      {'Name'}
                    </label>
                  </div>
                  <div style={{ marginTop: '15px' }}></div>
                  <AddressInput
                    passData={data => setQ2_13_sub_hcp_address(data)}
                    ref={addressRef4}
                  />
                  <div style={{ marginTop: '15px' }}></div>
                  <div className='phone-input-container'>
                    <PhoneInput
                      enableAreaCodes={false}
                      inputStyle={{
                        height: '44px',
                        width: 'inherit',
                        borderColor: 'rgb(214, 214, 214)'
                      }}
                      disableCountryCode
                      country={'us'}
                      onlyCountries={['us']}
                      value={q2_13_sub_hcp_phone}
                      placeholder=''
                      onChange={setQ2_13_sub_hcp_phone}
                    />

                    <label className={q2_13_sub_hcp_phone && 'filled'}>
                      {'Phone Number'}
                    </label>
                  </div>
                  <div style={{ marginTop: '15px' }}></div>
                  <div className='input-container'>
                    <input
                      type='text'
                      value={q2_13_sub_hcp_condition}
                      required
                      onChange={e => setQ2_13_sub_hcp_condition(e.target.value)}
                    />
                    <label className={q2_13_sub_hcp_condition && 'filled'}>
                      {'Condition it was prescribed for'}
                    </label>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '15px',
                      paddingBottom: '5px'
                    }}
                  >
                    <div
                      className='AddPerson'
                      style={{
                        color: 'white',
                        margin: 'auto',
                        marginBottom: '10px'
                      }}
                      onClick={() => addSubHCP()}
                    >
                      <div>&nbsp;Add&nbsp;</div>
                      <AddIcon style={{ color: 'white' }}></AddIcon>
                    </div>
                  </div>
                  {q2_13_sub_hcp.length > 0 ? <hr></hr> : ''}
                  <div style={{ marginBottom: '-15px' }}>
                    {q2_13_sub_hcp.map((key, i) => {
                      return (
                        <div className='q1Item'>
                          {q2_13_sub_hcp[i]['Name']}, &nbsp;
                          {q2_13_sub_hcp[i]['Condition']}
                          <div
                            className='RemButton'
                            onClick={() => remSubHCP(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Card>
              ) : (
                ''
              )}
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div
                  className='AddSubstance'
                  style={{
                    color: 'white',
                    margin: 'auto',
                    marginBottom: '10px'
                  }}
                  onClick={() => addSubstance()}
                >
                  <div>&nbsp;Add&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
              <div style={{ marginBottom: '-15px' }}></div>
            </div>
            {subList.length > 0 ? (
              <div style={{ marginTop: '20px' }}></div>
            ) : (
              ''
            )}
            {subList.map((key, i) => {
              return (
                <div className='qSub'>
                  {subList[i]['Nature']}, &nbsp;
                  {subList[i]['Quantity']}
                  <div className='RemButton' onClick={() => remSubstance(i)}>
                    &nbsp;
                    <RemoveIcon></RemoveIcon>
                    Remove&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              )
            })}
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }
  return (
    <div style={{ width: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '420px' }}>
          Within 24 hours of the incident did you or any person involved use or
          take any of the following substances:
          <br></br>
          <br></br>Alcoholic beverage(s), marijuana, or other drug or medication
          of any kind (prescription or not)?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hadSubs}
                onClick={() => setHadSubs(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hadSubs}
                onClick={() => noPeople()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hadSubs ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each person, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_13name}
                  required
                  onChange={e => setQ2_13name(e.target.value)}
                />
                <label className={q2_13name && 'filled'}>
                  {'Their Full Name'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>

              <AddressInput
                passData={data => setQ2_13address(data)}
                ref={addressRef}
              />

              <div style={{ marginTop: '15px' }}></div>
              <div className='phone-input-container' style={{ margin: 'auto' }}>
                <PhoneInput
                  enableAreaCodes={false}
                  inputStyle={{
                    height: '44px',
                    width: 'inherit',
                    borderColor: 'rgb(214, 214, 214)'
                  }}
                  disableCountryCode
                  country={'us'}
                  onlyCountries={['us']}
                  value={q2_13phone}
                  placeholder=''
                  onChange={setQ2_13phone}
                />

                <label className={q2_13phone && 'filled'}>
                  {'Phone Number'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <br></br>

              <div style={{ display: 'flex', marginTop: '0px' }}>
                <div
                  className='SavePerson'
                  style={{ color: 'white', margin: 'auto' }}
                  onClick={() => addPerson()}
                >
                  <div>&nbsp;Add&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px', maxWidth: '400px' }}>
              {q2_13.length >= 1 ? <hr></hr> : ''}
              <div>
                {q2_13.map((key, i) => {
                  return <PersonListItem data={i}></PersonListItem>
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q2_13
