import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { PulseLoader } from 'react-spinners'
import {
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  collection,
  onSnapshot,
  addDoc,
  updateDoc
} from 'firebase/firestore'
import { db } from '../../../../firebase'
import '../Plaintiffs/Plaintiffs.css'
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import AddIcon from '@mui/icons-material/Add'
import { UserAuth } from '../../../../contexts/AuthContext'
import ClearIcon from '@mui/icons-material/Clear'
import NotesIcon from '@mui/icons-material/Notes'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import EditIcon from '@mui/icons-material/Edit'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

export default function Notes() {
  const { user } = UserAuth()
  const { id } = useParams()

  const [notesLoading, setNotesLoading] = useState(false)
  const [notesList, setNotesList] = useState([])
  const [notesName, setNotesName] = useState('')
  const [createNew, setCreateNew] = useState(false)
  const [newNote, setNewNote] = useState('')
  const [newNoteTitle, setNewNoteTitle] = useState('')

  const [editNote, setEditNote] = useState(false)
  const [editing, setEditing] = useState('')

  const loadNotes = useCallback(async () => {
    setNewNote('')
    setNewNoteTitle('')

    setNotesLoading(true)

    const pSnap = await getDoc(doc(db, 'matters', id))
    setNotesName(pSnap.data().caseName)

    let tempList = []
    const notesSnap = await getDocs(collection(db, 'matters', id, 'notes'))
    notesSnap.forEach(doc => {
      tempList.push(doc)
    })
    setNotesLoading(false)
    setNotesList(tempList)
  }, [id])

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()
    return cMonth + '/' + cDay + '/' + cYear
  }

  async function addNote() {
    const time = getTime()
    setNotesLoading(true)
    setCreateNew(false)
    await addDoc(collection(db, 'matters', id, 'notes'), {
      title: newNoteTitle,
      creator: user.email,
      createdAt: time,
      lastEdited: time,
      content: newNote
    }).then(() => loadNotes())
  }

  async function saveNote() {
    const time = getTime()
    setNotesLoading(true)
    setEditNote(false)
    await updateDoc(doc(db, 'matters', id, 'notes', editing), {
      title: newNoteTitle,
      lastEdited: time,
      content: newNote
    }).then(() => loadNotes())
    setEditing('')
  }

  async function openEditNote(val) {
    setEditNote(true)
    setEditing(val)
    const noteSnap = await getDoc(doc(db, 'matters', id, 'notes', val))
    setNewNoteTitle(noteSnap.data().title)
    setNewNote(noteSnap.data().content)
  }

  async function deleteNote(val) {
    await deleteDoc(doc(db, 'matters', id, 'notes', val)).then(() => {
      loadNotes()
    })
  }

  useEffect(() => {
    onSnapshot(collection(db, 'matters', id, 'notes'), () => {
      if (!editNote && !createNew) {
        loadNotes()
      }
    })
  }, [createNew, editNote, id, loadNotes])

  function cancelView() {
    setEditing('')
    setNewNote('')
    setNewNoteTitle('')
    setEditNote(false)
    setCreateNew(false)
  }

  function toggleView() {
    if (!createNew && !editNote) {
      setCreateNew(true)
    } else {
      cancelView()
    }
  }

  if (notesLoading) {
    return (
      <div>
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <PulseLoader width={200} color={'#6aa4e6'} />
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ height: '95%', paddingTop: '20px', overflowY: 'scroll' }}>
        <div style={{ display: 'flex' }}>
          {!createNew && !editNote ? (
            <h5 style={{ margin: 'auto', paddingLeft: '10%' }}>
              {notesName}&nbsp;Notes
            </h5>
          ) : createNew ? (
            <h5 style={{ margin: 'auto', paddingLeft: '16.5%' }}>New Note</h5>
          ) : editNote ? (
            <h5 style={{ margin: 'auto', paddingLeft: '16.5%' }}>Edit Note</h5>
          ) : (
            ''
          )}
          <div
            className='FilesP'
            onClick={() => toggleView()}
            style={{ width: '120px', display: 'flex' }}
          >
            <div style={{ margin: 'auto' }}>
              {createNew || editNote ? (
                <div>
                  <ClearIcon style={{ marginTop: '-3px' }} />
                  &nbsp;Cancel
                </div>
              ) : (
                <div>
                  <AddCommentOutlinedIcon style={{ marginTop: '-3px' }} />
                  &nbsp;New Note
                </div>
              )}
            </div>
          </div>
        </div>

        {createNew && !editNote ? (
          <div style={{ height: 'inherit' }}>
            <div style={{ marginTop: '10px' }} />
            Note Title:{' '}
            <input
              style={{
                borderStyle: 'none',
                background: 'none',
                borderBottomStyle: 'solid'
              }}
              value={newNoteTitle}
              onChange={e => setNewNoteTitle(e.target.value)}
            ></input>
            <div style={{ marginTop: '20px' }} />
            <ReactQuill
              theme='snow'
              value={newNote}
              onChange={setNewNote}
              style={{ height: '55vh' }}
            />
            <div style={{ marginTop: '65px' }} />
            <div style={{ display: 'flex' }}>
              <div className='SaveNotes' onClick={() => addNote()}>
                <AddIcon /> &nbsp; Add Note
              </div>
            </div>
          </div>
        ) : editNote && !createNew ? (
          <div style={{ height: 'inherit' }}>
            <div style={{ marginTop: '10px' }} />
            Edit Note:{' '}
            <input
              style={{ borderStyle: 'none', borderBottomStyle: 'solid' }}
              value={newNoteTitle}
              onChange={e => setNewNoteTitle(e.target.value)}
            ></input>
            <div style={{ marginTop: '20px' }} />
            <ReactQuill
              theme='snow'
              value={newNote}
              onChange={setNewNote}
              className='RichTextArea'
            />
            <div style={{ marginTop: '65px' }} />
            <div style={{ display: 'flex' }}>
              <div className='SaveNotes' onClick={() => saveNote()}>
                <SaveOutlinedIcon /> &nbsp; Save Note
              </div>
            </div>
          </div>
        ) : (
          <div style={{ height: 'inherit' }}>
            <div className='NotesList'>
              {notesList.map(val => {
                return (
                  <div className='NoteRow'>
                    <NotesIcon />
                    &nbsp;&nbsp;
                    {val.data().title}
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 'auto',
                        width: '50%',
                        marginRight: '20px'
                      }}
                    >
                      <PersonOutlineOutlinedIcon /> {val.data().creator}:{' '}
                      {val.data().createdAt}
                    </div>
                    <div
                      className='OpenNote'
                      onClick={() => openEditNote(val.id)}
                    >
                      <EditIcon /> &nbsp; View
                    </div>
                    <div
                      className='DelBtnNotes'
                      onClick={() => deleteNote(val.id)}
                    >
                      X
                    </div>
                  </div>
                )
              })}
              {notesList.length === 0 ? (
                <div style={{ textAlign: 'center', marginTop: '25%' }}>
                  No notes yet
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}
