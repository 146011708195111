import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import NotesIcon from '@mui/icons-material/Notes'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import BusinessIcon from '@mui/icons-material/Business'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import AppsIcon from '@mui/icons-material/Apps';

export const SidebarData = [
  {
    title: 'Apps',
    icon: <AppsIcon />,
    link: ''
  },
  {
    title: 'Case Info',
    icon: <InfoOutlinedIcon />,
    link: 'caseinfo'
  },
  {
    title: 'Plaintiffs',
    icon: <PersonAddAltOutlinedIcon />,
    link: 'plaintiffs/'
  },
  {
    title: 'Defendants',
    icon: <PersonAddAltOutlinedIcon />,
    link: 'defendants/'
  },
  {
    title: 'Notes',
    icon: <NotesIcon />,
    link: 'notes/'
  },
  {
    title: 'Attorneys',
    icon: <AdminPanelSettingsOutlinedIcon />,
    link: 'attorneys/'
  },
  {
    title: 'Case Access',
    icon: <ShareOutlinedIcon />,
    link: 'access/'
  },
  {
    title: 'Firms',
    icon: <BusinessIcon />,
    link: 'firms/'
  },
  {
    title: 'Initial Docs',
    icon: <FolderOutlinedIcon />,
    link: 'na/'
  },
  {
    title: 'Exhibits',
    icon: <DescriptionOutlinedIcon />,
    link: 'na/'
  },
  {
    title: 'Pleadings',
    icon: <DescriptionOutlinedIcon />,
    link: 'na/'
  },
  {
    title: 'Facts',
    icon: <FactCheckOutlinedIcon />,
    link: 'na/'
  }
]
