async function GetQOrder(componentList, pData, querySnapshot) {
  let n = componentList.length
  let arr = Array.from(Array(n).keys())

  if (pData.type === 'Business') {
    arr.splice(1, 11)
  } else {
    arr.splice(12, 7)
  }

  if (querySnapshot.data().q6_2) {
    if (querySnapshot.data().q6_2 === 'N/A') {
      arr = arr.filter(function (value) {
        return value <= 20 || value >= 26
      })
    }
  } else {
    arr = arr.filter(function (value) {
      return value <= 20 || value >= 26
    })
  }

  if (querySnapshot.data().q7_1) {
    if (querySnapshot.data().q7_1 === 'N/A') {
      arr = arr.filter(function (value) {
        return value < 27 || value > 28
      })
    }
  } else {
    arr = arr.filter(function (value) {
      return value < 27 || value > 28
    })
  }

  if (querySnapshot.data().q8_2) {
    if (querySnapshot.data().q8_2 === 'N/A') {
      arr = arr.filter(function (value) {
        return value < 30 || value > 35
      })
    }
  } else {
    arr = arr.filter(function (value) {
      return value < 30 || value > 35
    })
  }

  if (querySnapshot.data().q9_1) {
    if (querySnapshot.data().q9_1 === 'N/A') {
      arr = arr.filter(function (value) {
        return value < 37 || value >= 38
      })
    }
  } else {
    arr = arr.filter(function (value) {
      return value < 37 || value >= 38
    })
  }

  if (querySnapshot.data().q10_1) {
    if (querySnapshot.data().q10_1 === 'N/A') {
      arr = arr.filter(function (value) {
        return value < 39 || value > 40
      })
    }
  } else {
    arr = arr.filter(function (value) {
      return value < 39 || value > 40
    })
  }

  if (querySnapshot.data().q20_2) {
    if (querySnapshot.data().q20_2 === 'N/A') {
      arr = arr.filter(function (value) {
        return value < 45 || value > 50
      })
    }
  } else {
    arr = arr.filter(function (value) {
      return value < 45 || value > 50
    })
  }

  return arr
}

export default GetQOrder
