import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../../../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore'
import { db } from '../../../../../../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../../../../../../Inputs/AddressInput'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ReactQuill from 'react-quill'

export const Q1_1 = forwardRef((props, ref) => {
  const { id } = useParams()
  const [formResponse, setFormResponse] = useState('')
  const [isNew, setIsNew] = useState(false)
  const [q1_1, setQ1_1] = useState('')

  async function saveQuestion() {
    if (isNew) {
      await setDoc(
        doc(db, 'matters', id, 'plaintiffs', props.pID, 'forms', 'intake_response'),
        {
          q1_1: formResponse
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', id, 'plaintiffs', props.pID, 'forms', 'intake_response'),
        {
          q1_1: formResponse
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const propoundSnap = await getDoc(
      doc(db, 'matters', id, 'plaintiffs', props.pID, 'forms', 'intake')
    )
    const responseSnap = await getDoc(
      doc(db, 'matters', id, 'plaintiffs', props.pID, 'forms', 'intake_response')
    )

    const plaintiffValues = propoundSnap.data().q1_1
    
    if (!responseSnap.data() || !responseSnap.data().q1_1) {
      let finalString = ''
      plaintiffValues.forEach((value, index) => {
        if (value.Phone) {
          finalString += `<p>${value.Name}, ${value.Address}, ${value.Phone}, ${value.Relation}</p>`
        } else {
          finalString += `<p>${value.Name}, ${value.Address}, ${value.Phone}, ${value.Relation}</p>`
        }
        if (index !== plaintiffValues.length - 1) {
          finalString += `<p><br></p>`
        }
        setIsNew(true);
      })
      setFormResponse(finalString);
    } else {
      setFormResponse(responseSnap.data().q1_1)
    }
    setQ1_1(propoundSnap.data().q1_1)
  }, [id, props.pID])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  return (
    <div style={{ width: '400px' }}>
      <div
        style={{
          textAlign: 'center',
          maxWidth: '400px',
          margin: 'auto',
          marginTop: '-10px'
        }}
      >
              <h5>FORM INTERROGATORY NO. 1.1:</h5>
        State the name, ADDRESS, telephone number, and relationship to you of each PERSON
        who prepared or assisted in the preparation of the responses to these interrogatories. (Do not
        identify anyone who simply typed or reproduced the responses).
      </div>
      <br></br>
      <div></div>
      <Card style={{ maxWidth: '400px', margin: 'auto', border: 'white' }}>
                  <ReactQuill
              theme='snow'
              value={formResponse}
              onChange={setFormResponse}
              className='RichTextArea'
              height='200'
            />
      </Card>
      </div>
  )
})

export default Q1_1
