//make a react file that renders 2 cards
import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import 'reactjs-popup/dist/index.css'
import { useNavigate, useLocation } from 'react-router'
import GavelIcon from '@mui/icons-material/Gavel';
import { Outlet } from 'react-router-dom'

export default function Discovery() {
  const propoundingServices = [["Form Interrogatories", "formrog"]]
  const respondingServices = [["Form Interrogatories", "formrog"]]
  const navigate = useNavigate();
  const [inPR, setInPR] = useState(false)

  let location = useLocation();

  function handleLocationChange() {
      if (window.location.pathname.indexOf('formrog') !== -1) {
      setInPR(true);
      } else {
      setInPR(false);
      }
  };

  useEffect(() => {
    handleLocationChange();
  }, [location])

  if (inPR) {
    return(
        <Outlet />
    )
  }
  else {
  return(<div>
    <div style={{textAlign:"center", fontSize:"25px", marginTop:"30px"}}>Discovery</div>

    <div style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>
    <Card style={{padding:"20px", textAlign:"center"}}>
        Propounding Services
        {propoundingServices.map((service) => {
                return(
                    <div style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>
                        <div style={{width:"200px"}}>
                            <div style={{textAlign:"center"}}>
                                <div className="appIcon" onClick={() => navigate(`propounding/${service[1]}`)}>
                                    <div style={{scale:"2"}}><GavelIcon /></div>
                                </div>
                                <div style={{marginTop:"-15px"}}>{service[0]}</div>
                            </div>
                        </div>
                    </div>
                )
            })}
    </Card>
    <div style={{width:"20px"}} />
    <Card style={{padding:"20px", textAlign:"center"}}>
        Responding Services
        {respondingServices.map((service) => {
                return(
                    <div style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>
                        <div style={{width:"200px"}}>
                            <div style={{textAlign:"center"}}>
                                <div className="appIcon" onClick={() => navigate(`response/${service[1]}`)}>
                                    <div style={{scale:"2"}}><GavelIcon /></div>
                                </div>
                                <div style={{marginTop:"-15px"}}>{service[0]}</div>
                            </div>
                        </div>
                    </div>
                )
            })}
    </Card>

    </div>
  </div>)
  }
}
