import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const Q3_7 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q3_7, setQ3_7] = useState([])

  const [q3_7license, setQ3_7license] = useState('')
  const [q3_7name, setQ3_7name] = useState('')
  const [q3_7dateIssued, setQ3_7dateIssued] = useState('')
  const [q3_7dateExpiry, setQ3_7dateExpiry] = useState('')

  const [wasLicensed, setWasLicensed] = useState(false)

  async function saveQuestion() {
    if (q3_7.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q3_7: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q3_7: q3_7
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q3_7) {
      if (docSnap.data().q3_7 !== 'N/A') {
        setWasLicensed(true)
        setQ3_7(docSnap.data().q3_7)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addLicense() {
    if (q3_7name !== '') {
      let currList = q3_7
      currList.push({
        Name: q3_7name,
        License: q3_7license,
        DateIssued: q3_7dateIssued,
        DateExpiry: q3_7dateExpiry
      })

      setQ3_7(currList)
      setQ3_7name('')
      setQ3_7license('')
      setQ3_7dateIssued('')
      setQ3_7dateExpiry('')
    }
  }

  function remLicense(index) {
    let newList = []
    for (let i = 0; i < q3_7.length; i++) {
      if (i !== index) {
        newList.push(q3_7[i])
      }
    }
    setQ3_7(newList)
  }

  function noLicense() {
    setWasLicensed(false)
    setQ3_7([])
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Has any public entity registered or licensed your<br></br>
          business within the past 5 years?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={wasLicensed}
                onClick={() => setWasLicensed(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!wasLicensed}
                onClick={() => noLicense()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {wasLicensed ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each license or registration, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q3_7name}
                  required
                  onChange={e => setQ3_7name(e.target.value)}
                />
                <label className={q3_7name && 'filled'}>
                  {'The name of the public entity'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q3_7license}
                  required
                  onChange={e => setQ3_7license(e.target.value)}
                />
                <label className={q3_7license && 'filled'}>
                  {'The license or registration'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ display: 'flex' }}>
                <div style={{ textAlign: 'center' }}>
                  Date issued:
                  <div className='input-container'>
                    <input
                      type='date'
                      value={q3_7dateIssued}
                      required
                      onChange={e => setQ3_7dateIssued(e.target.value)}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 'auto', textAlign: 'center' }}>
                  Date of expiration:
                  <div className='input-container'>
                    <input
                      type='date'
                      value={q3_7dateExpiry}
                      required
                      onChange={e => setQ3_7dateExpiry(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto' }}
                  onClick={() => addLicense()}
                >
                  <div>&nbsp;Add&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q3_7.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q3_7.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q3_7[i]['Name']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remLicense(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ maxWidth: '400px' }}>
                          <div
                            style={{
                              display: 'flex',
                              padding: '10px',
                              borderBottomStyle: 'solid',
                              borderWidth: '1px',
                              borderBottomColor: '#dddddd'
                            }}
                          >
                            Name: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q3_7[i]['Name']}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              padding: '10px',
                              borderBottomStyle: 'solid',
                              borderWidth: '1px',
                              borderBottomColor: '#dddddd'
                            }}
                          >
                            License/Registration: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q3_7[i]['License']}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              padding: '10px',
                              borderBottomStyle: 'solid',
                              borderWidth: '1px',
                              borderBottomColor: '#dddddd'
                            }}
                          >
                            Date Issued:&nbsp;
                            <div style={{ color: 'gray' }}>
                              {q3_7[i]['DateIssued']}
                            </div>
                          </div>
                          <div style={{ display: 'flex', padding: '10px' }}>
                            Date of Expiration: &nbsp;
                            <div style={{ color: 'gray' }}>
                              {q3_7[i]['DateExpiry']}
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q3_7
