import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddressInput from '../../../../Inputs/AddressInput'

export const Q12_1 = forwardRef((props, ref) => {
  const addressRef1 = useRef()
  const addressRef2 = useRef()
  const addressRef3 = useRef()
  const addressRef4 = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q12_1witnessed, setQ12_1witnessed] = useState([])
  const [q12_1witnessed_name, setQ12_1witnessed_name] = useState('')
  const [q12_1witnessed_address, setQ12_1witnessed_address] = useState('')
  const [q12_1witnessed_phone, setQ12_1witnessed_phone] = useState('')

  const [q12_1madeStatement, setQ12_1madeStatement] = useState([])
  const [q12_1madeStatement_name, setQ12_1madeStatement_name] = useState('')
  const [q12_1madeStatement_address, setQ12_1madeStatement_address] =
    useState('')
  const [q12_1madeStatement_phone, setQ12_1madeStatement_phone] = useState('')

  const [q12_1heardStatement, setQ12_1heardStatement] = useState([])
  const [q12_1heardStatement_name, setQ12_1heardStatement_name] = useState('')
  const [q12_1heardStatement_address, setQ12_1heardStatement_address] =
    useState('')
  const [q12_1heardStatement_phone, setQ12_1heardStatement_phone] = useState('')

  const [q12_1hasKnowledge, setQ12_1hasKnowledge] = useState([])
  const [q12_1hasKnowledge_name, setQ12_1hasKnowledge_name] = useState('')
  const [q12_1hasKnowledge_address, setQ12_1hasKnowledge_address] = useState('')
  const [q12_1hasKnowledge_phone, setQ12_1hasKnowledge_phone] = useState('')

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q12_1: {
          Witnessed: q12_1witnessed,
          MadeStatement: q12_1madeStatement,
          HeardStatement: q12_1heardStatement,
          HasKnowledge: q12_1hasKnowledge
        }
      }
    )
  }

  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q12_1) {
      const qData = docSnap.data().q12_1
      setQ12_1witnessed(qData['Witnessed'])
      setQ12_1madeStatement(qData['MadeStatement'])
      setQ12_1heardStatement(qData['HeardStatement'])
      setQ12_1hasKnowledge(qData['HasKnowledge'])
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addWitnessed() {
    if (q12_1witnessed_name !== '') {
      let currList = q12_1witnessed
      currList.push({
        Name: q12_1witnessed_name,
        Address: q12_1witnessed_address,
        Phone: q12_1witnessed_phone
      })

      setQ12_1witnessed(currList)
      setQ12_1witnessed_name('')
      setQ12_1witnessed_address('')
      setQ12_1witnessed_phone('')

      addressRef1.current.clear()
    }
  }

  function remWitnessed(index) {
    let newList = []
    for (let i = 0; i < q12_1witnessed.length; i++) {
      if (i !== index) {
        newList.push(q12_1witnessed[i])
      }
    }
    setQ12_1witnessed(newList)
  }

  function addMadeStatement() {
    if (q12_1madeStatement_name !== '') {
      let currList = q12_1madeStatement
      currList.push({
        Name: q12_1madeStatement_name,
        Address: q12_1madeStatement_address,
        Phone: q12_1madeStatement_phone
      })

      setQ12_1madeStatement(currList)
      setQ12_1madeStatement_name('')
      setQ12_1madeStatement_address('')
      setQ12_1madeStatement_phone('')

      addressRef2.current.clear()
    }
  }

  function remMadeStatement(index) {
    let newList = []
    for (let i = 0; i < q12_1madeStatement.length; i++) {
      if (i !== index) {
        newList.push(q12_1madeStatement[i])
      }
    }
    setQ12_1madeStatement(newList)
  }

  function addHeardStatement() {
    if (q12_1heardStatement_name !== '') {
      let currList = q12_1heardStatement
      currList.push({
        Name: q12_1heardStatement_name,
        Address: q12_1heardStatement_address,
        Phone: q12_1heardStatement_phone
      })

      setQ12_1heardStatement(currList)
      setQ12_1heardStatement_name('')
      setQ12_1heardStatement_address('')
      setQ12_1heardStatement_phone('')

      addressRef3.current.clear()
    }
  }

  function remHeardStatement(index) {
    let newList = []
    for (let i = 0; i < q12_1heardStatement.length; i++) {
      if (i !== index) {
        newList.push(q12_1heardStatement[i])
      }
    }
    setQ12_1heardStatement(newList)
  }

  function addHasKnowledge() {
    if (q12_1hasKnowledge_name !== '') {
      let currList = q12_1hasKnowledge
      currList.push({
        Name: q12_1hasKnowledge_name,
        Address: q12_1hasKnowledge_address,
        Phone: q12_1hasKnowledge_phone
      })

      setQ12_1hasKnowledge(currList)
      setQ12_1hasKnowledge_name('')
      setQ12_1hasKnowledge_address('')
      setQ12_1hasKnowledge_phone('')

      addressRef4.current.clear()
    }
  }

  function remHasKnowledge(index) {
    let newList = []
    for (let i = 0; i < q12_1hasKnowledge.length; i++) {
      if (i !== index) {
        newList.push(q12_1hasKnowledge[i])
      }
    }
    setQ12_1hasKnowledge(newList)
  }

  return (
    <div style={{ width: '400px' }}>
      <div style={{ textAlign: 'center', width: '400px' }}>
        State the following information for each<br></br>
        type of individual involved:
        <div style={{ marginTop: '15px' }}></div>
        <Card style={{ padding: '20px', textAlign: 'center' }}>
          <div style={{ textAlign: 'center', marginBottom: '15px' }}>
            For each person who witnessed the incident,<br></br>
            state the following:
          </div>
          <div className='input-container'>
            <input
              type='text'
              value={q12_1witnessed_name}
              required
              onChange={e => setQ12_1witnessed_name(e.target.value)}
            />
            <label className={q12_1witnessed_name && 'filled'}>
              {'Their name'}
            </label>
          </div>
          <div style={{ marginTop: '15px' }}></div>
          <AddressInput
            passData={data => setQ12_1witnessed_address(data)}
            ref={addressRef1}
          />
          <div style={{ marginTop: '15px' }}></div>
          <div className='phone-input-container'>
            <PhoneInput
              enableAreaCodes={false}
              inputStyle={{
                height: '44px',
                width: 'inherit',
                borderColor: 'rgb(214, 214, 214)'
              }}
              disableCountryCode
              country={'us'}
              onlyCountries={['us']}
              value={q12_1witnessed_phone}
              placeholder=''
              onChange={setQ12_1witnessed_phone}
            />

            <label className={q12_1witnessed_phone && 'filled'}>
              {'Phone Number'}
            </label>
          </div>
          <div
            style={{ display: 'flex', marginTop: '15px', marginBottom: '10px' }}
          >
            <div
              className='AddPerson'
              style={{ color: 'white', margin: 'auto' }}
              onClick={() => addWitnessed()}
            >
              <div>&nbsp;Add&nbsp;</div>
              <AddIcon style={{ color: 'white' }}></AddIcon>
            </div>
          </div>
          {q12_1witnessed.length > 0 ? <hr></hr> : ''}
          <div style={{ marginBottom: '-15px' }}>
            {q12_1witnessed.map((key, i) => {
              return (
                <Accordion
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 3px #cccccc',
                    marginBottom: '4px'
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {q12_1witnessed[i]['Name']}

                    <div style={{ marginLeft: 'auto' }}>
                      <div
                        className='RemButton'
                        onClick={() => remWitnessed(i)}
                      >
                        &nbsp;
                        <RemoveIcon></RemoveIcon>
                        Remove&nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ maxWidth: '400px' }}>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px',
                          borderBottomStyle: 'solid',
                          borderWidth: '1px',
                          borderBottomColor: '#dddddd'
                        }}
                      >
                        Name: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1witnessed[i]['Name']}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px',
                          borderBottomStyle: 'solid',
                          borderWidth: '1px',
                          borderBottomColor: '#dddddd'
                        }}
                      >
                        Address: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1witnessed[i]['Address']}
                        </div>
                      </div>
                      <div style={{ display: 'flex', padding: '10px' }}>
                        Phone: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1witnessed[i]['Phone']}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
        </Card>
        <div style={{ marginTop: '15px' }}></div>
        <Card style={{ padding: '20px', textAlign: 'center' }}>
          <div style={{ textAlign: 'center', marginBottom: '15px' }}>
            For each person who made any statement at<br></br>
            the scene of the incident, state the following:
          </div>
          <div className='input-container'>
            <input
              type='text'
              value={q12_1madeStatement_name}
              required
              onChange={e => setQ12_1madeStatement_name(e.target.value)}
            />
            <label className={q12_1madeStatement_name && 'filled'}>
              {'Their name'}
            </label>
          </div>
          <div style={{ marginTop: '15px' }}></div>
          <AddressInput
            passData={data => setQ12_1madeStatement_address(data)}
            ref={addressRef2}
            style={{ width: 'inherit' }}
          />
          <div style={{ marginTop: '15px' }}></div>
          <div className='phone-input-container'>
            <PhoneInput
              enableAreaCodes={false}
              inputStyle={{
                height: '44px',
                width: 'inherit',
                borderColor: 'rgb(214, 214, 214)'
              }}
              disableCountryCode
              country={'us'}
              onlyCountries={['us']}
              value={q12_1madeStatement_phone}
              placeholder=''
              onChange={setQ12_1madeStatement_phone}
            />

            <label className={q12_1madeStatement_phone && 'filled'}>
              {'Phone Number'}
            </label>
          </div>
          <div
            style={{ display: 'flex', marginTop: '15px', marginBottom: '10px' }}
          >
            <div
              className='AddPerson'
              style={{ color: 'white', margin: 'auto' }}
              onClick={() => addMadeStatement()}
            >
              <div>&nbsp;Add&nbsp;</div>
              <AddIcon style={{ color: 'white' }}></AddIcon>
            </div>
          </div>
          {q12_1madeStatement.length > 0 ? <hr></hr> : ''}
          <div style={{ marginBottom: '-15px' }}>
            {q12_1madeStatement.map((key, i) => {
              return (
                <Accordion
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 3px #cccccc',
                    marginBottom: '4px'
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {q12_1madeStatement[i]['Name']}

                    <div style={{ marginLeft: 'auto' }}>
                      <div
                        className='RemButton'
                        onClick={() => remMadeStatement(i)}
                      >
                        &nbsp;
                        <RemoveIcon></RemoveIcon>
                        Remove&nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ maxWidth: '400px' }}>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px',
                          borderBottomStyle: 'solid',
                          borderWidth: '1px',
                          borderBottomColor: '#dddddd'
                        }}
                      >
                        Name: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1madeStatement[i]['Name']}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px',
                          borderBottomStyle: 'solid',
                          borderWidth: '1px',
                          borderBottomColor: '#dddddd'
                        }}
                      >
                        Address: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1madeStatement[i]['Address']}
                        </div>
                      </div>
                      <div style={{ display: 'flex', padding: '10px' }}>
                        Phone: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1madeStatement[i]['Phone']}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
        </Card>
        <div style={{ marginTop: '15px' }}></div>
        <Card style={{ padding: '20px', textAlign: 'center' }}>
          <div style={{ textAlign: 'center', marginBottom: '15px' }}>
            For each person who heard any statements made<br></br>
            about the incident by any individual at the<br></br>
            scene, state the following:
          </div>
          <div className='input-container'>
            <input
              type='text'
              value={q12_1heardStatement_name}
              required
              onChange={e => setQ12_1heardStatement_name(e.target.value)}
            />
            <label className={q12_1heardStatement_name && 'filled'}>
              {'Their name'}
            </label>
          </div>
          <div style={{ marginTop: '15px' }}></div>
          <AddressInput
            passData={data => setQ12_1heardStatement_address(data)}
            ref={addressRef3}
            style={{ width: 'inherit' }}
          />
          <div style={{ marginTop: '15px' }}></div>
          <div className='phone-input-container'>
            <PhoneInput
              enableAreaCodes={false}
              inputStyle={{
                height: '44px',
                width: 'inherit',
                borderColor: 'rgb(214, 214, 214)'
              }}
              disableCountryCode
              country={'us'}
              onlyCountries={['us']}
              value={q12_1heardStatement_phone}
              placeholder=''
              onChange={setQ12_1heardStatement_phone}
            />

            <label className={q12_1heardStatement_phone && 'filled'}>
              {'Phone Number'}
            </label>
          </div>
          <div
            style={{ display: 'flex', marginTop: '15px', marginBottom: '10px' }}
          >
            <div
              className='AddPerson'
              style={{ color: 'white', margin: 'auto' }}
              onClick={() => addHeardStatement()}
            >
              <div>&nbsp;Add&nbsp;</div>
              <AddIcon style={{ color: 'white' }}></AddIcon>
            </div>
          </div>
          {q12_1heardStatement.length > 0 ? <hr></hr> : ''}
          <div style={{ marginBottom: '-15px' }}>
            {q12_1heardStatement.map((key, i) => {
              return (
                <Accordion
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 3px #cccccc',
                    marginBottom: '4px'
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {q12_1heardStatement[i]['Name']}

                    <div style={{ marginLeft: 'auto' }}>
                      <div
                        className='RemButton'
                        onClick={() => remHeardStatement(i)}
                      >
                        &nbsp;
                        <RemoveIcon></RemoveIcon>
                        Remove&nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ maxWidth: '400px' }}>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px',
                          borderBottomStyle: 'solid',
                          borderWidth: '1px',
                          borderBottomColor: '#dddddd'
                        }}
                      >
                        Name: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1heardStatement[i]['Name']}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px',
                          borderBottomStyle: 'solid',
                          borderWidth: '1px',
                          borderBottomColor: '#dddddd'
                        }}
                      >
                        Address: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1heardStatement[i]['Address']}
                        </div>
                      </div>
                      <div style={{ display: 'flex', padding: '10px' }}>
                        Phone: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1heardStatement[i]['Phone']}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
        </Card>
        <div style={{ marginTop: '15px' }}></div>
        <Card style={{ padding: '20px', textAlign: 'center' }}>
          <div style={{ textAlign: 'center', marginBottom: '15px' }}>
            For any person (you or anyone acting on<br></br> your behalf) who
            has knowledge of the incident<br></br> (except for expert
            witnesses), state the following:
          </div>
          <div className='input-container'>
            <input
              type='text'
              value={q12_1hasKnowledge_name}
              required
              onChange={e => setQ12_1hasKnowledge_name(e.target.value)}
            />
            <label className={q12_1hasKnowledge_name && 'filled'}>
              {'Their name'}
            </label>
          </div>
          <div style={{ marginTop: '15px' }}></div>
          <AddressInput
            passData={data => setQ12_1hasKnowledge_address(data)}
            ref={addressRef4}
            style={{ width: 'inherit' }}
          />
          <div style={{ marginTop: '15px' }}></div>
          <div className='phone-input-container'>
            <PhoneInput
              enableAreaCodes={false}
              inputStyle={{
                height: '44px',
                width: 'inherit',
                borderColor: 'rgb(214, 214, 214)'
              }}
              disableCountryCode
              country={'us'}
              onlyCountries={['us']}
              value={q12_1hasKnowledge_phone}
              placeholder=''
              onChange={setQ12_1hasKnowledge_phone}
            />

            <label className={q12_1hasKnowledge_phone && 'filled'}>
              {'Phone Number'}
            </label>
          </div>
          <div
            style={{ display: 'flex', marginTop: '15px', marginBottom: '10px' }}
          >
            <div
              className='AddPerson'
              style={{ color: 'white', margin: 'auto' }}
              onClick={() => addHasKnowledge()}
            >
              <div>&nbsp;Add&nbsp;</div>
              <AddIcon style={{ color: 'white' }}></AddIcon>
            </div>
          </div>
          {q12_1hasKnowledge.length > 0 ? <hr></hr> : ''}
          <div style={{ marginBottom: '-15px' }}>
            {q12_1hasKnowledge.map((key, i) => {
              return (
                <Accordion
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0px 1px 3px #cccccc',
                    marginBottom: '4px'
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {q12_1hasKnowledge[i]['Name']}

                    <div style={{ marginLeft: 'auto' }}>
                      <div
                        className='RemButton'
                        onClick={() => remHasKnowledge(i)}
                      >
                        &nbsp;
                        <RemoveIcon></RemoveIcon>
                        Remove&nbsp;&nbsp;&nbsp;
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ maxWidth: '400px' }}>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px',
                          borderBottomStyle: 'solid',
                          borderWidth: '1px',
                          borderBottomColor: '#dddddd'
                        }}
                      >
                        Name: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1hasKnowledge[i]['Name']}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          padding: '10px',
                          borderBottomStyle: 'solid',
                          borderWidth: '1px',
                          borderBottomColor: '#dddddd'
                        }}
                      >
                        Address: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1hasKnowledge[i]['Address']}
                        </div>
                      </div>
                      <div style={{ display: 'flex', padding: '10px' }}>
                        Phone: &nbsp;
                        <div style={{ color: 'gray' }}>
                          {q12_1hasKnowledge[i]['Phone']}
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
        </Card>
      </div>
    </div>
  )
})

export default Q12_1
