import './SidebarHome.css'
import React, { useCallback, useEffect, useState } from 'react'
import { UserAuth } from '../../../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { collection, doc, getDoc, getDocs } from 'firebase/firestore'
import { db } from '../../../../firebase'
import moment from 'moment'
import HistoryIcon from '@mui/icons-material/History'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined'
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

const SidebarRight = () => {
  const [rightTab, setRightTab] = useState('Activities')
  const { user } = UserAuth()
  const [collapsedRight, setCollapsedRight] = useState(true)
  const navigate = useNavigate()
  const [myActivities1, setMyActivities1] = useState([])
  const [myActivities2, setMyActivities2] = useState([])
  const [myActivities3, setMyActivities3] = useState([])
  const [toDoList, setToDoList] = useState([])
  const [toDoData, setToDoData] = useState({})

  function getTime(mVal) {
    return moment().subtract(mVal, 'days').format('l')
  }

  async function getMyInfo(user) {
    let myMattersList = []
    const myMattersSnap = await getDocs(
      collection(db, 'firmUsers', user.uid, 'my_matters')
    )

    let tempList = []
    let tempDict = []

    myMattersSnap.forEach(val => {
      myMattersList.push(val.id)
    })

    await Promise.all(
      myMattersList.map(async val => {
        const pSnap = await getDocs(
          collection(db, 'matters', val, 'plaintiffs')
        )
        const dSnap = await getDocs(
          collection(db, 'matters', val, 'defendants')
        )
        const aSnap = await getDocs(collection(db, 'matters', val, 'attorneys'))
        const matterSnap = await getDoc(doc(db, 'matters', val))

        let theseVals = [matterSnap.data().caseName, false, false, false] //d, p, a
        if (pSnap.size === 0) {
          theseVals[1] = true
        }
        if (dSnap.size === 0) {
          theseVals[2] = true
        }
        if (aSnap.size === 0) {
          theseVals[3] = true
        }

        // If we find a matter with errors:
        if (theseVals.indexOf(true) !== -1) {
          tempList.push(val)
          tempDict[val] = theseVals
        }
      })
    )

    setToDoList(tempList)
    setToDoData(tempDict)
  }

  const getMyActivities = useCallback(async user => {
    const myActivitiesSnap = await getDocs(
      collection(db, 'firmUsers', user.uid, 'activities')
    )

    let docs = myActivitiesSnap.docs
    docs.reverse()

    let tempArr1 = []
    let tempArr2 = []
    let tempArr3 = []

    docs.forEach(item => {
      // today
      if (item.data().date === getTime(0) && tempArr1.length <= 2) {
        tempArr1.push(item.data())
      }
      // yesterday
      else if (item.data().date === getTime(1) && tempArr2.length <= 2) {
        tempArr2.push(item.data())
      }
      // two days ago
      else if (item.data().date === getTime(2) && tempArr3.length <= 2) {
        tempArr3.push(item.data())
      }
    })

    setMyActivities1(tempArr1)
    setMyActivities2(tempArr2)
    setMyActivities3(tempArr3)
  }, [])

  //for now, don't update realtime. it's sort of a waste
  //here unless we see a need for it
  useEffect(() => {
    getMyInfo(user)
    getMyActivities(user)
  }, [getMyActivities, user])

  if (!collapsedRight) {
    return (
      <div>
        <div className='SidebarRight'>
          <div className='RightNavButtons'>
            <div
              className={
                rightTab === 'Activities' ? 'RightBtnSelected' : 'RightBtn'
              }
              onClick={() => setRightTab('Activities')}
            >
              <HistoryIcon fontSize='medium'></HistoryIcon>
            </div>
            <div
              className={rightTab === 'To do' ? 'RightBtnSelected' : 'RightBtn'}
              onClick={() => setRightTab('To do')}
            >
              <PlaylistAddCheckIcon fontSize='medium'></PlaylistAddCheckIcon>
            </div>
            <div
              style={{
                textAlign: 'center',
                fontSize: '18px',
                marginTop: '-80px',
                marginLeft: '8px'
              }}
            >
              {rightTab}
            </div>
            <div
              className='collapseRight'
              onClick={() => setCollapsedRight(true)}
            >
              Close&nbsp;
              <ArrowForwardIosIcon
                style={{ width: '20px', marginBottom: '3px' }}
              />
            </div>
          </div>
          <div className='RightScrollContainer'>
            <br></br>
            {rightTab === 'Activities' ? (
              <div className='HomeMiddleRow'>
                <div className='AllActivities'>
                  <div className='Activities'>
                    <div className='Top'>{getTime(0)}</div>
                    <div className='ActivitiesList'>
                      {myActivities1.map(val => {
                        return (
                          <div className='Activity'>
                            <div className='TypeIcon'>
                              {val.type === 'matter' ? (
                                <BusinessCenterOutlinedIcon></BusinessCenterOutlinedIcon>
                              ) : val.type === 'add_party' ? (
                                <PersonAddAlt1OutlinedIcon></PersonAddAlt1OutlinedIcon>
                              ) : val.type === 'rem_party' ? (
                                <PersonRemoveAlt1OutlinedIcon></PersonRemoveAlt1OutlinedIcon>
                              ) : val.type === 'firm' ? (
                                <BusinessOutlinedIcon></BusinessOutlinedIcon>
                              ) : val.type === 'account' ? (
                                <AccountCircleOutlinedIcon></AccountCircleOutlinedIcon>
                              ) : val.type === 'create' ? (
                                <AdminPanelSettingsOutlinedIcon></AdminPanelSettingsOutlinedIcon>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='ContentText'>{val.content}</div>
                          </div>
                        )
                      })}

                      {myActivities1.length === 0 ? (
                        <div style={{ textAlign: 'center', marginTop: '15px' }}>
                          N/A<br></br>
                          <br></br>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className='Activities'>
                    <div className='Top'>{getTime(1)}</div>
                    <div className='ActivitiesList'>
                      {myActivities2.map(val => {
                        return (
                          <div className='Activity'>
                            <div className='TypeIcon'>
                              {val.type === 'matter' ? (
                                <BusinessCenterOutlinedIcon></BusinessCenterOutlinedIcon>
                              ) : val.type === 'add_party' ? (
                                <PersonAddAlt1OutlinedIcon></PersonAddAlt1OutlinedIcon>
                              ) : val.type === 'rem_party' ? (
                                <PersonRemoveAlt1OutlinedIcon></PersonRemoveAlt1OutlinedIcon>
                              ) : val.type === 'firm' ? (
                                <BusinessOutlinedIcon></BusinessOutlinedIcon>
                              ) : val.type === 'account' ? (
                                <AccountCircleOutlinedIcon></AccountCircleOutlinedIcon>
                              ) : val.type === 'create' ? (
                                <AdminPanelSettingsOutlinedIcon></AdminPanelSettingsOutlinedIcon>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='ContentText'>{val.content}</div>
                          </div>
                        )
                      })}
                      {myActivities2.length === 0 ? (
                        <div style={{ textAlign: 'center', marginTop: '15px' }}>
                          N/A<br></br>
                          <br></br>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className='Activities'>
                    <div className='Top'>{getTime(2)}</div>
                    <div className='ActivitiesList'>
                      {myActivities3.map(val => {
                        return (
                          <div className='Activity'>
                            <div className='TypeIcon'>
                              {val.type === 'matter' ? (
                                <BusinessCenterOutlinedIcon></BusinessCenterOutlinedIcon>
                              ) : val.type === 'add_party' ? (
                                <PersonAddAlt1OutlinedIcon></PersonAddAlt1OutlinedIcon>
                              ) : val.type === 'rem_party' ? (
                                <PersonRemoveAlt1OutlinedIcon></PersonRemoveAlt1OutlinedIcon>
                              ) : val.type === 'firm' ? (
                                <BusinessOutlinedIcon></BusinessOutlinedIcon>
                              ) : val.type === 'account' ? (
                                <AccountCircleOutlinedIcon></AccountCircleOutlinedIcon>
                              ) : val.type === 'create' ? (
                                <AdminPanelSettingsOutlinedIcon></AdminPanelSettingsOutlinedIcon>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='ContentText'>{val.content}</div>
                          </div>
                        )
                      })}
                      {myActivities3.length === 0 ? (
                        <div style={{ textAlign: 'center', marginTop: '15px' }}>
                          N/A<br></br>
                          <br></br>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <br></br>
                <br></br>
                {toDoList.map(key => {
                  return (
                    <div className='ToDoRow'>
                      <div
                        className='ToDoLink'
                        onClick={() => navigate(key + '/')}
                      >
                        {toDoData[key][0]}&nbsp;&nbsp;
                        <ArrowForwardIosIcon style={{ width: '15px' }} />
                      </div>
                      <div style={{ marginTop: '20px', marginBottom: '30px' }}>
                        {toDoData[key][1] ? (
                          <div className='BulletRow'>
                            <CircleIcon
                              style={{ width: '10px', marginTop: '-5px' }}
                            />
                            &nbsp;&nbsp;Add plaintiffs
                          </div>
                        ) : (
                          ''
                        )}
                        {toDoData[key][2] ? (
                          <div className='BulletRow'>
                            <CircleIcon
                              style={{ width: '10px', marginTop: '-5px' }}
                            />
                            &nbsp;&nbsp;Add defendants
                          </div>
                        ) : (
                          ''
                        )}
                        {toDoData[key][3] ? (
                          <div className='BulletRow'>
                            <CircleIcon
                              style={{ width: '10px', marginTop: '-5px' }}
                            />
                            &nbsp;&nbsp;Add attorneys
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
        <div className='PlaceHolderHome2'></div>
      </div>
    )
  } else {
    return (
      <div>
        <div className='CSidebarRight'>
          <div className='RightNavButtons'>
            <div
              className='collapseRight'
              onClick={() => setCollapsedRight(false)}
            >
              <ArrowBackIosIcon
                style={{
                  width: '20px',
                  marginBottom: '-10px',
                  marginRight: '8px'
                }}
              />
            </div>
          </div>
        </div>
        <div className='PlaceHolderHome3'></div>
      </div>
    )
  }
}

export default SidebarRight
