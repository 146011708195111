import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Form } from 'react-bootstrap'

export const Q2_10 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  // Question 2_1
  const [q2_10, setQ2_10] = useState('')

  const [isEnglish, setIsEnglish] = useState(true)

  async function saveQuestion() {
    if (q2_10 === '') {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_10: 'Yes'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q2_10: q2_10
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q2_10) {
      if (docSnap.data().q2_10 !== 'Yes') {
        setQ2_10(docSnap.data().q2_10)
        setIsEnglish(false)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function noOthers() {
    setIsEnglish(true)
    setQ2_10('')
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Can you read/write english with ease?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={isEnglish}
                onClick={() => noOthers()}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!isEnglish}
                onClick={() => setIsEnglish(false)}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {!isEnglish ? (
          <div>
            <Card style={{ margin: '30px', padding: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                If not, what language and dialect <br></br>do you normally use?:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q2_10}
                  required
                  onChange={e => setQ2_10(e.target.value)}
                />
                <label className={q2_10 && 'filled'}>
                  {'Language/dialect'}
                </label>
              </div>
            </Card>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q2_10
