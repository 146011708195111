import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import './Attorneys.css'
import {
  doc,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  collection,
  addDoc,
  onSnapshot,
  updateDoc
} from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Card } from 'react-bootstrap'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import 'reactjs-popup/dist/index.css'
import AddIcon from '@mui/icons-material/Add'
import { Form, Button } from 'react-bootstrap'
import ClearIcon from '@mui/icons-material/Clear'
import { Fade } from '@mui/material'
import { UserAuth } from '../../../../contexts/AuthContext'
import { Container } from '@mui/system'
import EditIcon from '@mui/icons-material/Edit'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import Select from 'react-select'

export default function Attorneys() {
  const { id } = useParams()
  const [caseName, setCaseName] = useState('')
  const [addError, setAddError] = useState('')
  const [attorneyList, setAttorneyList] = useState([])
  const [attorneyNames, setAttorneyNames] = useState({})

  const [attorneyName, setAttorneyName] = useState('')
  const [barNumber, setBarNumber] = useState('')
  const [attorneyUID, setAttorneyUID] = useState('')

  const [editAttorneyModal, setEditAttorneyModal] = useState(false)
  const [overlay, setOverlay] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editingSlug, setEditingSlug] = useState('')

  const [searchInput, setSearchInput] = useState(false)
  const [firmAttorneys, setFirmAttorneys] = useState([])

  const [disableFields, setDisableFields] = useState(false)

  const { user } = UserAuth()

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()
    return cMonth + '/' + cDay + '/' + cYear
  }

  function attorneyIsValid() {
    return attorneyName !== ''
  }

  const toggleModal = () => {
    setAddError('')
    loadFirmAttorneys()
    setBarNumber('')
    setAttorneyName('')
    setDisableFields(false)
    setAttorneyUID('')
    setModal(!modal)
    setOverlay(!modal)
    if (modal) {
      setAttorneyName('')
      setBarNumber('')
    }
  }

  async function getAndSetAttorney(slug) {
    const attorneyRef = doc(db, 'matters', id, 'attorneys', slug)
    const attorneySnap = await getDoc(attorneyRef)
    const aData = attorneySnap.data()

    setEditingSlug(slug)

    setAttorneyName(aData.name)
    setBarNumber(aData.bar)
  }

  const toggleEditModal = slug => {
    if (!editAttorneyModal) {
      getAndSetAttorney(slug)
    }
    setOverlay(!editAttorneyModal)
    setEditAttorneyModal(!editAttorneyModal)
  }

  const getAndSetData = useCallback(async () => {
    const myMattersRef = collection(db, 'matters', id, 'attorneys')
    const myMattersSnap = await getDocs(myMattersRef)
    let tempList = []
    let tempDict = {}
    myMattersSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      tempList.push(doc.id)
      tempDict[doc.id] = doc.data().name
    })
    setAttorneyList(tempList)
    setAttorneyNames(tempDict)

    const matterSnap = await getDoc(doc(db, 'matters', id))
    setCaseName(matterSnap.data().caseName)
  }, [id])

  useEffect(() => {
    onSnapshot(collection(db, 'matters', id, 'attorneys'), () => {
      getAndSetData()
    })
    getAndSetData()
  }, [getAndSetData, id, user])

  const updateAttorney = useCallback(async () => {
    await updateDoc(doc(db, 'matters', id, 'attorneys', editingSlug), {
      name: attorneyName,
      bar: barNumber
    })
  }, [attorneyName, barNumber, editingSlug, id])

  const dropIsOpen = input => {
    if (input === '') {
      setSearchInput(false)
    } else if (searchInput === false && input !== '') {
      setSearchInput(true)
    }
  }

  const createAttorney = async () => {
    setAddError('')
    const creatorSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    const myFirmID = creatorSnap.data().firmID

    // add the attorney to this matter and
    // add the attorney to the firm. if exists in the firm, copy over.
    // If not, generate unique ID and double check to make sure it
    // doesn't already exist (i.e. if user manually inputs preexisting user)
    // just check to see if bar exists already
    // thus matter attorney UIDs will match firm attorney UIDs

    if (disableFields) {
      // if user is adding one from the firm. not much work to do here except copy over
      await setDoc(doc(db, 'matters', id, 'attorneys', attorneyUID), {
        name: attorneyName,
        bar: barNumber
      })
    } else {
      const matterAttorneySnap = await getDocs(
        collection(db, 'matters', id, 'attorneys')
      )
      let matterAttorneyBars = []

      matterAttorneySnap.forEach(doc => {
        matterAttorneyBars.push(doc.data().bar)
      })

      if (matterAttorneyBars.indexOf(barNumber) === -1) {
        const docRef = await addDoc(
          collection(db, 'matters', id, 'attorneys'),
          {
            name: attorneyName,
            bar: barNumber
          }
        )

        const firmAttorneySnap = await getDocs(
          collection(db, 'firms', myFirmID, 'attorneys')
        )
        let firmAttorneyBars = []

        firmAttorneySnap.forEach(doc => {
          firmAttorneyBars.push(doc.data().bar)
        })

        if (firmAttorneyBars.indexOf(barNumber) === -1) {
          await setDoc(doc(db, 'firms', myFirmID, 'attorneys', docRef.id), {
            name: attorneyName,
            bar: barNumber
          })
        }
      } else {
        setAddError('Attorney with this bar number already added')
        return -1
      }
    }

    let createContent = user.email + ' added attorney: ' + attorneyName
    // Create the activities collection
    await addDoc(collection(db, 'matters', id, 'activities'), {
      user: user.uid,
      date: getTime(),
      content: createContent
    })

    let myCreateContent =
      'Added attorney: ' + attorneyName + ', to matter: ' + caseName

    // Add to my activities collection
    await addDoc(collection(db, 'firmUsers', user.uid, 'activities'), {
      type: 'add_party',
      date: getTime(),
      content: myCreateContent
    })

    toggleModal()
  }

  function cancelEdit() {
    getAndSetAttorney(editingSlug)
    setIsEditing(false)
  }

  async function saveEdit() {
    await updateAttorney()
    await getAndSetAttorney(editingSlug)
    setIsEditing(false)
  }

  async function deleteAttorney() {
    await deleteDoc(doc(db, 'matters', id, 'attorneys', editingSlug))
    await getAndSetData()
    toggleEditModal()
  }

  async function loadFirmAttorneys() {
    const creatorSnap = await getDoc(doc(db, 'firmUsers', user.uid))
    const myFirmID = creatorSnap.data().firmID

    const fAttorneySnap = await getDocs(
      collection(db, 'firms', myFirmID, 'attorneys')
    )

    let attorneyList = []
    fAttorneySnap.forEach(doc => {
      let val = doc.data().name + ' - ' + doc.data().bar
      attorneyList.push({ label: val, value: doc.id })
    })

    setFirmAttorneys(attorneyList)
  }

  async function processUser(opt, meta) {
    const action = meta.action
    if (action === 'select-option') {
      setAddError('')
      const creatorSnap = await getDoc(doc(db, 'firmUsers', user.uid))
      const myFirmID = creatorSnap.data().firmID

      const attorneySnap = await getDoc(
        doc(db, 'firms', myFirmID, 'attorneys', opt.value)
      )

      setBarNumber(attorneySnap.data().bar)
      setAttorneyName(attorneySnap.data().name)
      setAttorneyUID(opt.value)

      setDisableFields(true)
      //user selected option
    } else {
      setDisableFields(false)
      setBarNumber('')
      setAttorneyName('')
      setDisableFields(false)
      setAttorneyUID('')
      setAddError('')
      //user cleared
    }
  }

  return (
    <div>
      <Fade in={true} appear timeout={300}>
        <div className='PlaintiffPage'>
          <br></br>
          <Card>
            <br></br>
            <div className='PlaintiffsTop'>
              <h5 className='text-center mb-4'>Attorneys</h5>
              <div className='Add' onClick={toggleModal}>
                <AddIcon style={{ color: 'white' }}></AddIcon>
              </div>
            </div>
            <div className='SharedListFinal'>
              {attorneyList.map(val => {
                return (
                  <div className='PlaintiffRow'>
                    &nbsp; <AccountCircleIcon></AccountCircleIcon>
                    &nbsp; {attorneyNames[val]}
                    <div className='PlaintiffView'>
                      <OpenInFullIcon
                        onClick={() => toggleEditModal(val)}
                      ></OpenInFullIcon>
                    </div>
                  </div>
                )
              })}
            </div>
            <br></br>
          </Card>
        </div>
      </Fade>

      {overlay ? <div className='overlayP'></div> : <div></div>}

      {modal ? (
        <Fade in={true} appear timeout={500}>
          <div className='APopContent' style={{ height: '590px' }}>
            <div className='Close' onClick={toggleModal}>
              <ClearIcon></ClearIcon>
            </div>
            {/* Popup content here: */}
            <Container
              className='d-flex
                justify-content-center'
              style={{ minHeight: '90vh' }}
            >
              <div className='PopCard'>
                <div className='w-100' style={{ maxWidth: '600px' }}>
                  <Card style={{ border: 'white', 'margin-top': '-15px' }}>
                    <Card.Body>
                      <h2 className='text-center mb-4'>Add Attorney</h2>
                      <Form>
                        <Form.Group id='type'>
                          <Form.Label>Select Attorney:</Form.Label>
                          <Select
                            options={firmAttorneys}
                            placeholder='Enter name or email'
                            menuIsOpen={searchInput}
                            isClearable={true}
                            onInputChange={e => dropIsOpen(e)}
                            onChange={(opt, meta) => processUser(opt, meta)}
                          />
                        </Form.Group>
                        <br></br>
                        <div style={{ color: 'gray', 'text-align': 'center' }}>
                          -- or create manually --
                        </div>
                        <Form.Group id='pst'>
                          <Form.Label>Attorney Name</Form.Label>
                          <Form.Control
                            type='text'
                            onChange={e => setAttorneyName(e.target.value)}
                            value={attorneyName}
                            disabled={disableFields}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <Form.Group id='ploc'>
                          <Form.Label>
                            Attorney Bar Number{' '}
                            <t style={{ color: 'gray' }}>{'(optional)'}</t>
                          </Form.Label>
                          <Form.Control
                            type='text'
                            onChange={e => setBarNumber(e.target.value)}
                            value={barNumber}
                            disabled={disableFields}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <Button
                          className='w-100'
                          variant='success'
                          disabled={!attorneyIsValid()}
                          onClick={() => createAttorney()}
                        >
                          Add Attorney
                        </Button>
                        <br></br>
                        <br></br>
                        <div style={{ color: 'red', 'text-align': 'center' }}>
                          {addError}
                        </div>
                        <br></br>
                        <p style={{ color: 'gray', 'text-align': 'center' }}>
                          You can change these values later
                        </p>
                        {/* <Form.Text style={{ color: 'red' }}>
                                {defendantError}
                            </Form.Text> */}
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Container>
            {/* End Popup Content */}
          </div>
        </Fade>
      ) : (
        <div></div>
      )}
      {editAttorneyModal ? (
        <Fade in={true} appear timeout={500}>
          <div
            className='PopContent'
            style={isEditing ? { height: '380px' } : { height: '270px' }}
          >
            <div className='Close' onClick={toggleEditModal}>
              <ClearIcon></ClearIcon>
            </div>
            {/* Popup content here: */}
            <Container
              className='d-flex
                justify-content-center'
              style={{ minHeight: '90vh' }}
            >
              <div className='PopCard'>
                <div className='w-100' style={{ maxWidth: '600px' }}>
                  <Card style={{ border: 'white' }}>
                    {isEditing ? (
                      <div className='ModifyOpts'>
                        {attorneyIsValid() ? (
                          <div className='SaveBtn1' onClick={() => saveEdit()}>
                            <CheckIcon></CheckIcon>&nbsp;&nbsp;Save
                          </div>
                        ) : (
                          <div className='SaveBtnDisabled'>
                            <CheckIcon></CheckIcon>&nbsp;&nbsp;Save
                          </div>
                        )}
                        &nbsp;&nbsp;
                        <div
                          className='CancelBtn1'
                          onClick={() => cancelEdit()}
                        >
                          <CloseIcon></CloseIcon>&nbsp;Cancel
                        </div>
                        {/* &nbsp;&nbsp; */}
                        {/* <div className="DelBtn"
                        onClick={()=>(deletePlaintiff())}><DeleteIcon></DeleteIcon>&nbsp;Delete Plaintiff</div> */}
                      </div>
                    ) : (
                      <div className='PEditTop'>
                        <h5>{attorneyNames[editingSlug]}</h5>
                        <div className='PlaintiffEdit'>
                          <EditIcon
                            onClick={() => setIsEditing(true)}
                          ></EditIcon>
                        </div>
                      </div>
                    )}
                    {isEditing ? (
                      <Card.Body>
                        {isEditing ? (
                          <div></div>
                        ) : (
                          <div className='PEditTop'>
                            <h5>{attorneyNames[editingSlug]}</h5>
                            <div className='PlaintiffEdit'>
                              <EditIcon
                                onClick={() => setIsEditing(true)}
                              ></EditIcon>
                            </div>
                          </div>
                        )}
                        <Form>
                          <Form.Group id='pst'>
                            <Form.Label>Attorney Name</Form.Label>
                            <Form.Control
                              type='text'
                              onChange={e => setAttorneyName(e.target.value)}
                              disabled={!isEditing}
                              value={attorneyName}
                              required
                            />
                          </Form.Group>
                          <br></br>
                          <Form.Group id='ploc'>
                            <Form.Label>Attorney Bar Number</Form.Label>
                            <Form.Control
                              type='text'
                              onChange={e => setBarNumber(e.target.value)}
                              disabled={!isEditing}
                              value={barNumber}
                              required
                            />
                          </Form.Group>
                          <br></br>
                          <br></br>
                          <div
                            className='DelBtn'
                            style={{ width: '200px' }}
                            onClick={() => deleteAttorney()}
                          >
                            <DeleteIcon></DeleteIcon>&nbsp;Remove Attorney
                          </div>
                        </Form>
                      </Card.Body>
                    ) : (
                      <Card.Body>
                        <div className='infoRow'>
                          Attorney Name: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{attorneyName}</div>
                        </div>
                        <div className='infoRowL'>
                          Attorney Bar Number: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{barNumber}</div>
                        </div>
                      </Card.Body>
                    )}
                  </Card>
                </div>
              </div>
            </Container>
            {/* End Popup Content */}
          </div>
        </Fade>
      ) : (
        <div></div>
      )}
    </div>
  )
}
