import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form, InputGroup } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CurrencyInput from 'react-currency-input-fix'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q6_5 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q6_5, setQ6_5] = useState([])

  const [q6_5name, setQ6_5name] = useState('')
  const [q6_5person, setQ6_5person] = useState('')
  const [q6_5date, setQ6_5date] = useState('')
  const [q6_5duration, setQ6_5duration] = useState('')
  const [q6_5cost, setQ6_5cost] = useState('')

  const [hasMeds, setHasMeds] = useState(false)

  async function saveQuestion() {
    if (q6_5.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_5: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q6_5: q6_5
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q6_5) {
      if (docSnap.data().q6_5 !== 'N/A') {
        setHasMeds(true)
        setQ6_5(docSnap.data().q6_5)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addMed() {
    if (q6_5name !== '') {
      let currList = q6_5
      currList.push({
        Name: q6_5name,
        Person: q6_5person,
        Date: q6_5date,
        Duration: q6_5duration,
        Cost: q6_5cost
      })

      setQ6_5(currList)

      setQ6_5name('')
      setQ6_5person('')
      setQ6_5date('')
      setQ6_5duration('')
      setQ6_5cost('')
    }
  }

  function remMed(index) {
    let newList = []
    for (let i = 0; i < q6_5.length; i++) {
      if (i !== index) {
        newList.push(q6_5[i])
      }
    }
    setQ6_5(newList)
  }

  function noMeds() {
    setHasMeds(false)
    setQ6_5([])
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Name']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Person: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Person']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Date']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Duration: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Duration']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Cost: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Cost']}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Name']}
                  onChange={e => (props.dict['Name'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Person: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Person']}
                  onChange={e => (props.dict['Person'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Date: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Date']}
                  onChange={e => (props.dict['Date'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Duration: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Duration']}
                  onChange={e => (props.dict['Duration'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              Cost: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Cost']}
                  onChange={e => (props.dict['Cost'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Have you taken any medication, prescribed or not, as<br></br>a result
          of injuries that you attribute to the <br></br>
          incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={hasMeds}
                onClick={() => setHasMeds(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!hasMeds}
                onClick={() => noMeds()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        {hasMeds ? (
          <div>
            <div>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                For each medication, state:
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_5name}
                  required
                  onChange={e => setQ6_5name(e.target.value)}
                />
                <label className={q6_5name && 'filled'}>
                  {'Name of medication'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_5person}
                  required
                  onChange={e => setQ6_5person(e.target.value)}
                />
                <label className={q6_5person && 'filled'}>
                  {'Full name of person who prescribed it'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='date'
                  value={q6_5date}
                  required
                  onChange={e => setQ6_5date(e.target.value)}
                />
                <label className={'filled'}>
                  {'Date it was prescribed or furnished'}
                </label>
              </div>
              <div style={{ marginTop: '15px' }}></div>
              <div className='input-container'>
                <input
                  type='text'
                  value={q6_5duration}
                  required
                  onChange={e => setQ6_5duration(e.target.value)}
                />
                <label className={q6_5duration && 'filled'}>
                  {'How long did you take the medication?'}
                </label>
              </div>

              <div style={{ marginTop: '15px' }}></div>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <div className='input-container'>
                  <CurrencyInput
                    id='input-example'
                    name='input-name'
                    value={q6_5cost}
                    decimalsLimit={2}
                    style={{ width: '363px' }}
                    onValueChange={value => setQ6_5cost(value)}
                  />
                  <label className={q6_5cost && 'filled'}>
                    {'The cost to date'}
                  </label>
                </div>
              </InputGroup>

              <div style={{ display: 'flex', marginTop: '15px' }}>
                <div
                  className='AddPerson'
                  style={{ color: 'white', margin: 'auto', width: '180px' }}
                  onClick={() => addMed()}
                >
                  <div>&nbsp;Add Medication&nbsp;</div>
                  <AddIcon style={{ color: 'white' }}></AddIcon>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q6_5.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q6_5.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q6_5[i]['Name']}

                        <div style={{ marginLeft: 'auto' }}>
                          <div className='RemButton' onClick={() => remMed(i)}>
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <EditableAccordionDetails dict={q6_5[i]} />
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q6_5
