import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { Card } from 'react-bootstrap'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import PhoneInput from 'react-phone-input-2'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import AddressInput from '../../../../Inputs/AddressInput'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'

export const Q2_6 = forwardRef((props, ref) => {
  const addressRef = useRef()
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  // Question 2_1
  const [q2_6, setQ2_6] = useState([])

  const [q2_6name, setQ2_6name] = useState('')
  const [q2_6loc, setQ2_6loc] = useState('')
  const [q2_6title, setQ2_6title] = useState('')
  const [q2_6phone, setQ2_6phone] = useState('')
  const [q2_6from, setQ2_6from] = useState('')
  const [q2_6to, setQ2_6to] = useState('')
  const [q2_6current, setQ2_6current] = useState(false)

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q2_6: q2_6
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q2_6) {
      let qList = docSnap.data().q2_6
      setQ2_6(qList)
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addEmployment() {
    console.log("Employment from: " + q2_6from +", " + q2_6to)
    if (q2_6name !== '') {
      let currList = q2_6
      if (q2_6current) {
        let newEntry = {
          Type: 'past',
          Name: q2_6name,
          Loc: q2_6loc,
          Phone: q2_6phone,
          JobTitle: q2_6title,
          FromDate: q2_6from,
          ToDate: q2_6to
        }
        currList.push(newEntry)
      } else {
        let newEntry = {
          Type: 'current',
          Name: q2_6name,
          Loc: q2_6loc,
          Phone: q2_6phone,
          JobTitle: q2_6title,
          FromDate: q2_6from,
          ToDate: q2_6to
        }
        currList.push(newEntry)
      }
      setQ2_6loc('')
      setQ2_6title('')
      setQ2_6phone('')
      setQ2_6name('')
      setQ2_6from('')
      setQ2_6to('')
      setQ2_6(currList)
      setQ2_6current(false)
      addressRef.current.clear()
    }
  }

  function remEmployment(index) {
    let newList = []
    for (let i = 0; i < q2_6.length; i++) {
      if (i !== index) {
        newList.push(q2_6[i])
      }
    }
    setQ2_6(newList)
  }

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()

    if (cMonth.toString().length === 1) {
      cMonth = '0' + cMonth.toString()
    }
    if (cDay.toString().length === 1) {
      cDay = '0' + cDay.toString()
    }

    return cYear + '-' + cMonth + '-' + cDay
  }

  function setCurrent() {
    setQ2_6current(true)
    setQ2_6to(getTime())
  }

  function setNotCurrent() {
    setQ2_6current(false)
    setQ2_6to('')
  }

  const EditableAccordionDetails = props => {
    const [isEditing, setIsEditing] = useState(false)

    if (!isEditing) {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(true)}>
              <EditOutlinedIcon></EditOutlinedIcon> &nbsp;Edit
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Name']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Loc']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Phone:&nbsp;
              <div style={{ color: 'gray' }}>{props.dict['Phone']}</div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              From: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['FromDate']}</div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              To: &nbsp;
              <div style={{ color: 'gray' }}>{props.dict['ToDate']}</div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <div className='EditAccordion' onClick={() => setIsEditing(false)}>
              <CheckOutlinedIcon></CheckOutlinedIcon> &nbsp;Done
            </div>
          </div>
          <div style={{ maxWidth: '400px' }}>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Name: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Name']}
                  onChange={e => (props.dict['Name'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Address: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Loc']}
                  onChange={e => (props.dict['Loc'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              Phone: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['Phone']}
                  onChange={e => (props.dict['Phone'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '10px',
                borderBottomStyle: 'solid',
                borderWidth: '1px',
                borderBottomColor: '#dddddd'
              }}
            >
              From: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['FromDate']}
                  onChange={e => (props.dict['FromDate'] = e.target.value)}
                ></input>
              </div>
            </div>
            <div style={{ display: 'flex', padding: '10px' }}>
              To: &nbsp;
              <div style={{ color: 'gray' }}>
                <input
                  defaultValue={props.dict['ToDate']}
                  onChange={e => (props.dict['ToDate'] = e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <Card
        style={{
          width: '400px',
          marginTop: '-11px',
          height: 'fit-content',
          borderColor: 'white'
        }}
      >
        <div style={{ textAlign: 'center' }}>
          State the following info for each employer<br></br>
          you've had over the past 5 years:
        </div>
        <br></br>
        <div className='input-container'>
          <input
            type='text'
            value={q2_6name}
            required
            onChange={e => setQ2_6name(e.target.value)}
          />
          <label className={q2_6name && 'filled'}>{'Employer Name'}</label>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          Do you currently work here?
          <div style={{ marginTop: '5px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q2_6current}
                onClick={() => setCurrent()}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q2_6current}
                onClick={() => setNotCurrent()}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        <div style={{ marginTop: '15px' }}></div>

        <AddressInput passData={data => setQ2_6loc(data)} ref={addressRef} />

        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container'>
          <input
            type='text'
            value={q2_6title}
            required
            onChange={e => setQ2_6title(e.target.value)}
          />
          <label className={q2_6title && 'filled'}>
            {'Job Title & Nature of Work'}
          </label>
        </div>
        <div style={{ marginTop: '20px' }}></div>
        <div
          className='phone-input-container'
          style={{ margin: 'auto', width: '400px' }}
        >
          <PhoneInput
            enableAreaCodes={false}
            inputStyle={{
              height: '44px',
              width: '400px',
              borderColor: 'rgb(214, 214, 214)'
            }}
            disableCountryCode
            country={'us'}
            onlyCountries={['us']}
            value={q2_6phone}
            placeholder=''
            onChange={setQ2_6phone}
          />

          <label className={q2_6phone && 'filled'}>
            {'Employer Phone Number'}
          </label>
        </div>
        <div style={{ marginTop: '15px' }}></div>

        <div style={{ display: 'flex' }}>
          <div style={{ textAlign: 'center' }}>
            From:
            <div className='input-container'>
              <input
                type='date'
                value={q2_6from}
                required
                onChange={e => setQ2_6from(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginLeft: 'auto', textAlign: 'center' }}>
            To:
            <div className='input-container'>
              <input
                type='date'
                value={q2_6to}
                required
                onChange={e => setQ2_6to(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '15px' }}></div>
        <div style={{ display: 'flex', marginTop: '15px' }}>
          <div
            className='AddPerson'
            style={{ color: 'white', margin: 'auto' }}
            onClick={() => addEmployment()}
          >
            <div>&nbsp;Add&nbsp;</div>
            <AddIcon style={{ color: 'white' }}></AddIcon>
          </div>
        </div>
      </Card>
      <div style={{ maxWidth: '400px' }}>
        {q2_6.length >= 1 ? <hr></hr> : ''}
        <div>
          {q2_6.map((key, i) => {
            return (
              <Accordion
                style={{
                  backgroundColor: 'white',
                  boxShadow: '0px 0px 3px #cccccc',
                  marginBottom: '4px'
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {q2_6[i]['Name']}, &nbsp;
                  {q2_6[i]['FromDate'].slice(0, 4)}-
                  {q2_6[i]['ToDate'].slice(0, 4)}
                  <div className='RemButton' onClick={() => remEmployment(i)}>
                    &nbsp;
                    <RemoveIcon></RemoveIcon>
                    Remove&nbsp;&nbsp;&nbsp;
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <EditableAccordionDetails dict={q2_6[i]} />
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </div>
    </div>
  )
})

export default Q2_6
