import React, { useState, useEffect } from 'react'
import './Matters.css'
import { UserAuth } from '../../../contexts/AuthContext'
import {
  doc,
  getDocs,
  getDoc,
  collection,
  onSnapshot
} from 'firebase/firestore'
import { db } from '../../../firebase'
import 'reactjs-popup/dist/index.css'
import { Fade } from 'react-bootstrap'
import { PulseLoader } from 'react-spinners'
import MatterBlock from './MatterBlock'

export default function SharedMatters() {
  const [sharedMatters, setSharedMatters] = useState([])
  const [sharedMattersID, setSharedMattersID] = useState({})

  const [loadingMatters, setLoadingMatters] = useState(true)

  const { user } = UserAuth()

  async function getAndSetUserData(user) {
    const uid = user.uid

    const sharedMattersRef = collection(db, 'firmUsers', uid, 'shared_matters')
    const sharedMattersSnap = await getDocs(sharedMattersRef)
    let sharedMattersTemp = []

    sharedMattersSnap.forEach(val => {
      sharedMattersTemp.push(val.id)
    })

    let tempDict2 = {}

    await Promise.all(
      sharedMattersTemp.map(async val => {
        const docRef = doc(db, 'matters', val)
        const myRef = await getDoc(docRef)
        const name = myRef.data().caseName
        const date = myRef.data().dateOfLoss
        const status = myRef.data().status
        const dol = myRef.data().dateOfLoss
        const tort = myRef.data().coordinationProceeding
        const tortName = myRef.data().specialTitle

        const loc = myRef.data().jdxState
        const creatorUID = myRef.data().creatorID
        const creatorRef = await getDoc(doc(db, 'firmUsers', creatorUID))
        const creatorEmail = creatorRef.data().email

        tempDict2[val] = [
          name,
          date,
          status,
          dol,
          loc,
          creatorEmail,
          tort,
          tortName
        ]
      })
    )

    setSharedMattersID(tempDict2)
    setSharedMatters(sharedMattersTemp)
    setLoadingMatters(false)
  }

  useEffect(() => {
    //getAndSetUserData(user);
    onSnapshot(collection(db, 'firmUsers', user.uid, 'shared_matters'), () => {
      getAndSetUserData(user)
    })
  }, [user])

  if (loadingMatters) {
    return (
      <div style={{ margin: 'auto', marginTop: '10%' }}>
        <PulseLoader width={200} color={'#6aa4e6'} />
      </div>
    )
  } else {
    return (
      <Fade in={true} appear timeout={1200}>
        <div style={{ padding: '30px', overflowY: 'scroll', height: '100vh' }}>
          <div className='MyMattersPage'>
            <div className='MattersRows'>
              {sharedMatters.map(val => {
                return (
                  <Fade in={true} appear>
                    <MatterBlock data={sharedMattersID[val]} link={val} />
                  </Fade>
                )
              })}
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
          </div>
        </div>
      </Fade>
    )
  }
}
