import React, { useState, forwardRef, useImperativeHandle } from 'react'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import './Inputs.css'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'

const AddressInput = forwardRef((props, ref) => {
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: 'AIzaSyAlu3CLZATNLGotLuWWLJVBSBduTVMeoyI'
  })

  const [query, setQuery] = useState('')
  const [dropIsOpen, setDropIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(false)

  useImperativeHandle(ref, () => ({
    clear() {
      processQuery('')
    },
    set(val) {
      setQuery(val)
    }
  }))

  function processQuery(val) {
    if (val.length > 0) {
      setDropIsOpen(true)
    } else {
      setDropIsOpen(false)
      setSelectedItem(false)
    }

    props.passData(val)
    getPlacePredictions({ input: val })
    setQuery(val)
  }

  function sendSelectedAddress(res) {
    props.passData(res)
    setQuery(res)
  }

  function selectAddress(item) {
    geocodeByPlaceId(item).then(results =>
      sendSelectedAddress(results[0]['formatted_address'])
    )
    setDropIsOpen(false)
    setSelectedItem(true)
  }

  function clearAddress() {
    processQuery('')
    setSelectedItem(false)
  }

  return (
    <div className='input-container'>
      <input
        onChange={event => processQuery(event.target.value)}
        value={query}
        placeholder=''
        style={{ paddingRight: '10%' }}
      />
      <label className={query && 'filled'}>{'Address'}</label>
      {query.length > 0 ? (
        <div className='AddressClear' onClick={() => clearAddress('')}>
          <ClearOutlinedIcon></ClearOutlinedIcon>
        </div>
      ) : (
        ''
      )}
      {dropIsOpen && !selectedItem ? (
        <div className='AddressInputDropdown'>
          <div className='DropList'>
            {placePredictions.map((key, i) => {
              return (
                <div
                  className='AddressItem'
                  onClick={() => selectAddress(placePredictions[i]['place_id'])}
                >
                  <LocationOnOutlinedIcon style={{ color: 'gray' }} /> &nbsp;
                  <div style={{ height: '22px', overflowY: 'hidden' }}>
                    {placePredictions[i]['description']}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
})

export default AddressInput
