import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router'
import './Defendants.css'
import {
  doc,
  getDocs,
  getDoc,
  setDoc,
  deleteDoc,
  collection,
  addDoc,
  updateDoc,
  onSnapshot
} from 'firebase/firestore'
import { db } from '../../../../firebase'
import { Card } from 'react-bootstrap'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import 'reactjs-popup/dist/index.css'
import AddIcon from '@mui/icons-material/Add'
import { Form, Button } from 'react-bootstrap'
import ClearIcon from '@mui/icons-material/Clear'
import { Fade } from '@mui/material'
import { UserAuth } from '../../../../contexts/AuthContext'
import { Container } from '@mui/system'
import PhoneInput from 'react-phone-input-2'
import EditIcon from '@mui/icons-material/Edit'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { formatPhoneNumber } from 'react-phone-number-input'

export default function Defendants() {
  const { id } = useParams()
  const [defendantList, setDefendantList] = useState([])
  const [defendantNames, setDefendantNames] = useState({})
  const [caseName, setCaseName] = useState('')

  const [defendantType, setDefendantType] = useState('')
  const [dFirstName, setDFirstName] = useState('')
  const [dLastName, setDLastName] = useState('')
  const [dBusName, setDBusName] = useState('')
  const [dGovName, setDGovName] = useState('')
  const [dDOB, setDDOB] = useState('')

  const [dSt, setDSt] = useState('')
  const [dLoc, setDLoc] = useState('')
  const [dPhone, setDPhone] = useState('')
  const [dEmail, setDEmail] = useState('')
  const [dAttorney, setDAttorney] = useState('')

  const [overlay, setOverlay] = useState(false)
  const [modal, setModal] = useState(false)
  const [editDefendantModal, setEditDefendantmodal] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [editingSlug, setEditingSlug] = useState('')

  const [popHeight, setPopHeight] = useState('')

  function getTime() {
    let currentDate = new Date()
    let cDay = currentDate.getDate()
    let cMonth = currentDate.getMonth() + 1
    let cYear = currentDate.getFullYear()
    return cMonth + '/' + cDay + '/' + cYear
  }

  function defendantIsValid() {
    if (defendantType === '') {
      return false
    } else if (defendantType === 'Individual') {
      if (dFirstName === '' || dLastName === '') {
        return false
      }
    } else if (defendantType === 'Business') {
      if (dBusName === '') {
        return false
      }
    } else if (defendantType === 'Government') {
      if (dGovName === '') {
        return false
      }
    }
    return true
  }

  const toggleModal = () => {
    setModal(!modal)
    setOverlay(!modal)
    if (modal) {
      setDefendantType('')
      setDFirstName('')
      setDLastName('')
      setDBusName('')
      setDGovName('')
      setDDOB('')
      setDSt('')
      setDLoc('')
      setDPhone('')
      setDEmail('')
      setDAttorney('')
    }
  }

  async function getAndSetDefendant(slug) {
    const defendantRef = doc(db, 'matters', id, 'defendants', slug)
    const defendantSnap = await getDoc(defendantRef)
    const dData = defendantSnap.data()

    setEditingSlug(slug)

    setDefendantType(dData.type)
    if (dData.type === 'Individual') {
      setPopHeight('540px')
    } else if (dData.type === 'Minor') {
      setPopHeight('580px')
    } else if (dData.type === 'Business') {
      setPopHeight('440px')
    } else if (dData.type === 'Government') {
      setPopHeight('440px')
    }

    setDFirstName(dData.first_name)
    setDLastName(dData.last_name)
    setDBusName(dData.business_name)
    setDGovName(dData.gov_name)
    setDDOB(dData.DOB)
    setDSt(dData.st)
    setDLoc(dData.loc)
    setDPhone(dData.phone)
    setDEmail(dData.email)
    setDAttorney(dData.attorney_name)
  }

  const toggleEditModal = slug => {
    if (!editDefendantModal) {
      getAndSetDefendant(slug)
    }
    setOverlay(!editDefendantModal)
    setEditDefendantmodal(!editDefendantModal)
  }

  const { user } = UserAuth()

  const getAndSetData = useCallback(async () => {
    const myMattersRef = collection(db, 'matters', id, 'defendants')
    const myMattersSnap = await getDocs(myMattersRef)
    let tempList = []
    let tempDict = {}
    myMattersSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      tempList.push(doc.id)
      tempDict[doc.id] = doc.data().dTitle
    })
    setDefendantList(tempList)
    setDefendantNames(tempDict)

    const matterSnap = await getDoc(doc(db, 'matters', id))
    setCaseName(matterSnap.data().caseName)
  }, [id])

  useEffect(() => {
    onSnapshot(collection(db, 'matters', id, 'defendants'), () => {
      getAndSetData()
    })
  }, [getAndSetData, id])

  function dUidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return 'D' + S4() + S4()
  }

  async function updateDefendant() {
    if (defendantType === 'Individual') {
      const f0 = dFirstName + ' ' + dLastName
      await updateDoc(doc(db, 'matters', id, 'defendants', editingSlug), {
        dTitle: f0,
        first_name: dFirstName,
        last_name: dLastName,
        type: defendantType,
        DOB: dDOB,
        st: dSt,
        loc: dLoc,
        phone: formatPhoneNumber('+1' + dPhone),
        email: dEmail,
        attorney_name: dAttorney
      })
    } else if (defendantType === 'Business') {
      await updateDoc(doc(db, 'matters', id, 'defendants', editingSlug), {
        dTitle: dBusName,
        business_name: dBusName,
        type: defendantType,
        st: dSt,
        loc: dLoc,
        phone: formatPhoneNumber('+1' + dPhone),
        email: dEmail,
        attorney_name: dAttorney
      })
    } else if (defendantType === 'Government') {
      await updateDoc(doc(db, 'matters', id, 'defendants', editingSlug), {
        dTitle: dGovName,
        gov_name: dGovName,
        type: defendantType,
        st: dSt,
        loc: dLoc,
        phone: formatPhoneNumber('+1' + dPhone),
        email: dEmail,
        attorney_name: dAttorney
      })
    }
  }

  async function createDefendant() {
    let pt = ''
    // Create the new defendant
    const duid = dUidGenerator()
    if (defendantType === 'Individual') {
      const f0 = dFirstName + ' ' + dLastName
      pt = f0
      const f1 = dFirstName + '-' + dLastName
      const f2 = f1.toLowerCase()
      const slug = f2 + '-' + duid
      await setDoc(doc(db, 'matters', id, 'defendants', slug), {
        dTitle: f0,
        first_name: dFirstName,
        last_name: dLastName,
        type: defendantType,
        DOB: dDOB,
        st: dSt,
        loc: dLoc,
        phone: formatPhoneNumber('+1' + dPhone),
        email: dEmail,
        attorney_name: dAttorney
      })
    } else if (defendantType === 'Business') {
      pt = dBusName
      const f1 = dBusName.replace(/[^a-zA-Z ]/g, '')
      const f2 = f1.replaceAll(' ', '-')
      const f3 = f2.toLowerCase()
      const slug = f3 + '-' + duid
      await setDoc(doc(db, 'matters', id, 'defendants', slug), {
        dTitle: dBusName,
        business_name: dBusName,
        type: defendantType,
        st: dSt,
        loc: dLoc,
        phone: formatPhoneNumber('+1' + dPhone),
        email: dEmail,
        attorney_name: dAttorney
      })
    } else if (defendantType === 'Government') {
      pt = dGovName
      const f1 = dGovName.replace(/[^a-zA-Z ]/g, '')
      const f2 = f1.replaceAll(' ', '-')
      const f3 = f2.toLowerCase()
      const slug = f3 + '-' + duid
      await setDoc(doc(db, 'matters', id, 'defendants', slug), {
        dTitle: dGovName,
        gov_name: dGovName,
        type: defendantType,
        st: dSt,
        loc: dLoc,
        phone: formatPhoneNumber('+1' + dPhone),
        email: dEmail,
        attorney_name: dAttorney
      })
    }

    let createContent = user.email + ' added defendant: ' + pt
    // Create the activities collection
    await addDoc(collection(db, 'matters', id, 'activities'), {
      user: user.uid,
      date: getTime(),
      content: createContent
    })

    let myCreateContent = 'Added defendant: ' + pt + ', to matter: ' + caseName

    // Add to my activities collection
    await addDoc(collection(db, 'firmUsers', user.uid, 'activities'), {
      type: 'add_party',
      date: getTime(),
      content: myCreateContent
    })

    toggleModal()
  }

  function cancelEdit() {
    getAndSetDefendant(editingSlug)
    setIsEditing(false)
  }

  async function saveEdit() {
    await updateDefendant()
    getAndSetDefendant(editingSlug)
    setIsEditing(false)
  }

  async function deleteDefendant() {
    await deleteDoc(doc(db, 'matters', id, 'defendants', editingSlug))
    toggleEditModal()
  }

  return (
    <div>
      <Fade in={true} appear timeout={300}>
        <div className='PlaintiffPage'>
          <br></br>
          <Card>
            <br></br>
            <div className='PlaintiffsTop'>
              <h5 className='text-center mb-4'>Defendants</h5>
              <div className='Add' onClick={toggleModal}>
                <AddIcon style={{ color: 'white' }}></AddIcon>
              </div>
            </div>
            <div className='SharedListFinal'>
              {defendantList.map(val => {
                return (
                  <div className='DefendantRow'>
                    &nbsp; <AccountCircleIcon></AccountCircleIcon>
                    &nbsp; {defendantNames[val]}
                    <div className='DefendantView'>
                      <OpenInFullIcon
                        onClick={() => toggleEditModal(val)}
                      ></OpenInFullIcon>
                    </div>
                  </div>
                )
              })}
            </div>
            <br></br>
          </Card>
        </div>
      </Fade>

      {overlay ? <div className='overlayP'></div> : <div></div>}

      {modal ? (
        <Fade in={true} appear timeout={500}>
          <div className='PopContent' style={{ top: '52%', height: '700px' }}>
            <div className='Close' onClick={toggleModal}>
              <ClearIcon></ClearIcon>
            </div>
            {/* Popup content here: */}
            <Container
              className='d-flex
                justify-content-center'
              style={{ minHeight: '90vh' }}
            >
              <div className='PopCard' style={{ height: '630px' }}>
                <div className='w-100' style={{ maxWidth: '600px' }}>
                  <Card style={{ border: 'white', 'margin-top': '-15px' }}>
                    <Card.Body>
                      <h2 className='text-center mb-4'>Add Defendant</h2>
                      <Form>
                        {['radio'].map(type => (
                          <div key={`inline-${type}`} className='mb-3'>
                            <Form.Check
                              inline
                              label='Individual'
                              name='group1'
                              type={type}
                              onClick={() => setDefendantType('Individual')}
                              id={`inline-${type}-1`}
                            />
                            <Form.Check
                              inline
                              label='Business'
                              name='group1'
                              type={type}
                              onClick={() => setDefendantType('Business')}
                              id={`inline-${type}-2`}
                            />
                            <Form.Check
                              inline
                              label='Government'
                              name='group1'
                              type={type}
                              onClick={() => setDefendantType('Government')}
                              id={`inline-${type}-2`}
                            />
                          </div>
                        ))}
                        <br></br>
                        {defendantType === 'Individual' ? (
                          <div>
                            <Form.Group id='dfname'>
                              <Form.Label style={{ color: 'green' }}>
                                Defendant First Name
                              </Form.Label>
                              <Form.Control
                                type='text'
                                onChange={e => setDFirstName(e.target.value)}
                                required
                              />
                            </Form.Group>
                            <br></br>
                            <Form.Group id='dlname'>
                              <Form.Label style={{ color: 'green' }}>
                                Defendant Last Name
                              </Form.Label>
                              <Form.Control
                                type='text'
                                onChange={e => setDLastName(e.target.value)}
                                required
                              />
                            </Form.Group>
                            <br></br>
                            <Form.Group id='ddob'>
                              <Form.Label style={{ color: 'green' }}>
                                Defendant DOB
                              </Form.Label>
                              <Form.Control
                                type='date'
                                onChange={e => setDDOB(e.target.value)}
                                required
                              />
                            </Form.Group>
                            <br></br>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {defendantType === 'Business' ? (
                          <div>
                            <Form.Group id='dfbname'>
                              <Form.Label style={{ color: 'green' }}>
                                Defendant Full Business Name
                              </Form.Label>
                              <Form.Control
                                type='text'
                                onChange={e => setDBusName(e.target.value)}
                                required
                              />
                            </Form.Group>
                            <br></br>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {defendantType === 'Government' ? (
                          <div>
                            <Form.Group id='dfgname'>
                              <Form.Label style={{ color: 'green' }}>
                                Full Government Entity Name
                              </Form.Label>
                              <Form.Control
                                type='text'
                                onChange={e => setDGovName(e.target.value)}
                                required
                              />
                            </Form.Group>
                            <br></br>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <Form.Group id='dst'>
                          <Form.Label>Street Address</Form.Label>
                          <Form.Control
                            type='text'
                            onChange={e => setDSt(e.target.value)}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <Form.Group id='dloc'>
                          <Form.Label>City, State, and Zip</Form.Label>
                          <Form.Control
                            type='text'
                            onChange={e => setDLoc(e.target.value)}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <Form.Group id='dphone'>
                          <Form.Label>Defendant Phone</Form.Label>
                          <div className='PhoneArea'>
                            <PhoneInput
                              enableAreaCodes={false}
                              inputStyle={{ height: '40px' }}
                              disableCountryCode
                              country={'us'}
                              onlyCountries={['us']}
                              placeholder='(123) 456-7890'
                              onChange={setDPhone}
                            />
                          </div>
                        </Form.Group>
                        <br></br>
                        <Form.Group id='demail'>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type='email'
                            onChange={e => setDEmail(e.target.value)}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <Form.Group id='attorneyname'>
                          <Form.Label>Attorney Name</Form.Label>
                          <Form.Control
                            type='text'
                            onChange={e => setDAttorney(e.target.value)}
                            required
                          />
                        </Form.Group>
                        <br></br>
                        <Button
                          className='w-100'
                          variant='success'
                          disabled={!defendantIsValid()}
                          onClick={() => createDefendant()}
                        >
                          Add Defendant
                        </Button>
                        <br></br>
                        <br></br>
                        <p style={{ color: 'gray' }}>
                          You can change these values later
                        </p>
                        {/* <Form.Text style={{ color: 'red' }}>
                                {defendantError}
                            </Form.Text> */}
                        <br></br>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Container>
            {/* End Popup Content */}
          </div>
        </Fade>
      ) : (
        <div></div>
      )}
      {editDefendantModal ? (
        <Fade in={true} appear timeout={500}>
          <div
            className='PopContent'
            style={isEditing ? { top: '53%' } : { height: popHeight }}
          >
            <div className='Close' onClick={toggleEditModal}>
              <ClearIcon></ClearIcon>
            </div>
            {/* Popup content here: */}
            <Container
              className='d-flex
                justify-content-center'
              style={{ minHeight: '90vh' }}
            >
              <div className='PopCard'>
                <div className='w-100' style={{ maxWidth: '600px' }}>
                  <Card style={{ border: 'white' }}>
                    {isEditing ? (
                      <div className='ModifyOpts'>
                        {defendantIsValid() ? (
                          <div className='SaveBtn1' onClick={() => saveEdit()}>
                            <CheckIcon></CheckIcon>&nbsp;&nbsp;Save
                          </div>
                        ) : (
                          <div className='SaveBtnDisabled'>
                            <CheckIcon></CheckIcon>&nbsp;&nbsp;Save
                          </div>
                        )}
                        &nbsp;&nbsp;
                        <div
                          className='CancelBtn1'
                          onClick={() => cancelEdit()}
                        >
                          <CloseIcon></CloseIcon>&nbsp;Cancel
                        </div>
                        &nbsp;&nbsp;
                        <div
                          className='DelBtn2'
                          onClick={() => deleteDefendant()}
                        >
                          <DeleteIcon></DeleteIcon>&nbsp;Remove Defendant
                        </div>
                        {/* &nbsp;&nbsp; */}
                        {/* <div className="DelBtn"
                        onClick={()=>(deletePlaintiff())}><DeleteIcon></DeleteIcon>&nbsp;Delete Plaintiff</div> */}
                      </div>
                    ) : (
                      <div className='DEditTop'>
                        <h5>{defendantNames[editingSlug]}</h5>
                        <div className='DefendantEdit'>
                          <EditIcon
                            onClick={() => setIsEditing(true)}
                          ></EditIcon>
                        </div>
                      </div>
                    )}
                    {isEditing ? (
                      <Card.Body>
                        <br></br>
                        <Form>
                          {['radio'].map(type => (
                            <div key={`inline-${type}`} className='mb-3'>
                              <Form.Check
                                inline
                                label='Individual'
                                name='group1'
                                type={type}
                                disabled={!isEditing}
                                checked={defendantType === 'Individual'}
                                onClick={() => setDefendantType('Individual')}
                                id={`inline-${type}-1`}
                              />
                              <Form.Check
                                inline
                                label='Business'
                                name='group1'
                                type={type}
                                disabled={!isEditing}
                                onClick={() => setDefendantType('Business')}
                                checked={defendantType === 'Business'}
                                id={`inline-${type}-2`}
                              />
                              <Form.Check
                                inline
                                label='Government'
                                name='group1'
                                type={type}
                                disabled={!isEditing}
                                onClick={() => setDefendantType('Government')}
                                checked={defendantType === 'Government'}
                                id={`inline-${type}-2`}
                              />
                            </div>
                          ))}
                          <br></br>
                          {defendantType === 'Individual' ? (
                            <div>
                              <Form.Group id='dfname'>
                                <Form.Label style={{ color: 'green' }}>
                                  Defendant First Name
                                </Form.Label>
                                <Form.Control
                                  type='text'
                                  onChange={e => setDFirstName(e.target.value)}
                                  disabled={!isEditing}
                                  value={dFirstName}
                                  required
                                />
                              </Form.Group>
                              <br></br>
                              <Form.Group id='dlname'>
                                <Form.Label style={{ color: 'green' }}>
                                  Defendant Last Name
                                </Form.Label>
                                <Form.Control
                                  type='text'
                                  onChange={e => setDLastName(e.target.value)}
                                  disabled={!isEditing}
                                  value={dLastName}
                                  required
                                />
                              </Form.Group>
                              <br></br>
                              <Form.Group id='ddob'>
                                <Form.Label style={{ color: 'green' }}>
                                  Defendant DOB
                                </Form.Label>
                                <Form.Control
                                  type='date'
                                  disabled={!isEditing}
                                  onChange={e => setDDOB(e.target.value)}
                                  value={dDOB}
                                  required
                                />
                              </Form.Group>
                              <br></br>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {defendantType === 'Business' ? (
                            <div>
                              <Form.Group id='dfbname'>
                                <Form.Label style={{ color: 'green' }}>
                                  Defendant Full Business Name
                                </Form.Label>
                                <Form.Control
                                  type='text'
                                  onChange={e => setDBusName(e.target.value)}
                                  disabled={!isEditing}
                                  value={dBusName}
                                  required
                                />
                              </Form.Group>
                              <br></br>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          {defendantType === 'Government' ? (
                            <div>
                              <Form.Group id='dfgname'>
                                <Form.Label style={{ color: 'green' }}>
                                  Full Government Entity Name
                                </Form.Label>
                                <Form.Control
                                  type='text'
                                  onChange={e => setDGovName(e.target.value)}
                                  disabled={!isEditing}
                                  value={dGovName}
                                  required
                                />
                              </Form.Group>
                              <br></br>
                            </div>
                          ) : (
                            <div></div>
                          )}
                          <Form.Group id='dst'>
                            <Form.Label>Street Address</Form.Label>
                            <Form.Control
                              type='text'
                              onChange={e => setDSt(e.target.value)}
                              disabled={!isEditing}
                              value={dSt}
                              required
                            />
                          </Form.Group>
                          <br></br>
                          <Form.Group id='dloc'>
                            <Form.Label>City, State, and Zip</Form.Label>
                            <Form.Control
                              type='text'
                              onChange={e => setDLoc(e.target.value)}
                              disabled={!isEditing}
                              value={dLoc}
                              required
                            />
                          </Form.Group>
                          <br></br>
                          <Form.Group id='dphone'>
                            <Form.Label>Defendant Phone</Form.Label>
                            <div className='PhoneArea'>
                              <PhoneInput
                                enableAreaCodes={false}
                                inputStyle={{ height: '40px' }}
                                disableCountryCode
                                country={'us'}
                                onlyCountries={['us']}
                                disabled={!isEditing}
                                value={dPhone}
                                onChange={setDPhone}
                              />
                            </div>
                          </Form.Group>
                          <br></br>
                          <Form.Group id='demail'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type='email'
                              onChange={e => setDEmail(e.target.value)}
                              disabled={!isEditing}
                              value={dEmail}
                              required
                            />
                          </Form.Group>
                          <br></br>
                          <Form.Group id='attorneyname'>
                            <Form.Label>Attorney Name</Form.Label>
                            <Form.Control
                              type='text'
                              onChange={e => setDAttorney(e.target.value)}
                              disabled={!isEditing}
                              value={dAttorney}
                              required
                            />
                          </Form.Group>
                          <br></br>
                        </Form>
                      </Card.Body>
                    ) : (
                      <Card.Body>
                        <div className='infoRow'>
                          Defendant Type: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{defendantType}</div>
                        </div>
                        {defendantType === 'Individual' ? (
                          <div>
                            <div className='infoRow'>
                              Defendant First Name: &nbsp;{' '}
                              <div style={{ color: 'black' }}>{dFirstName}</div>
                            </div>
                            <div className='infoRow'>
                              Defendant Last Name: &nbsp;{' '}
                              <div style={{ color: 'black' }}>{dLastName}</div>
                            </div>
                            <div className='infoRow'>
                              Defendant DOB: &nbsp;{' '}
                              <div style={{ color: 'black' }}>{dDOB}</div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {defendantType === 'Business' ? (
                          <div>
                            <div className='infoRow'>
                              Defendant Full Business Name: &nbsp;{' '}
                              <div style={{ color: 'black' }}>{dBusName}</div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {defendantType === 'Government' ? (
                          <div>
                            <div className='infoRow'>
                              Full Government Entity Name: &nbsp;{' '}
                              <div style={{ color: 'black' }}>{dGovName}</div>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                        <div className='infoRow'>
                          Street Address: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{dSt}</div>
                        </div>
                        <div className='infoRow'>
                          City, State, Zip: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{dLoc}</div>
                        </div>
                        <div className='infoRow'>
                          Defendant Phone: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{dPhone}</div>
                        </div>
                        <div className='infoRow'>
                          Defendant Email: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{dEmail}</div>
                        </div>
                        <div className='infoRowL'>
                          Attorney Name: &nbsp;{' '}
                          <div style={{ color: 'black' }}>{dAttorney}</div>
                        </div>
                      </Card.Body>
                    )}
                  </Card>
                </div>
              </div>
            </Container>
            {/* End Popup Content */}
          </div>
        </Fade>
      ) : (
        <div></div>
      )}
    </div>
  )
}
