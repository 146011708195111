import './Sidebar.css'
import React, { useCallback, useEffect, useState } from 'react'
import { SidebarData } from './SidebarData'
import { useNavigate, useParams } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot
} from 'firebase/firestore'
import { db } from '../../../../firebase'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

const Sidebar = () => {
  const { id } = useParams()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const navigate = useNavigate()

  function collapseSidebar() {
    setIsCollapsed(true)
  }

  function expandSidebar() {
    setIsCollapsed(false)
  }

  function getCurrentLocation() {
    const currPath = window.location.pathname

    const mIndex = currPath.indexOf(id)
    return currPath.slice(mIndex + id.length + 1, currPath.length)
  }

  const [pErr, setPErr] = useState(false)
  const [dErr, setDErr] = useState(false)
  const [fErr, setFErr] = useState(false)
  const [aErr, setAErr] = useState(false)

  const [, setMatterObject] = useState({})

  const retrieveCaseinfo = useCallback(async () => {
    const myMatterRef = doc(db, 'matters', id)
    const myMattersSnap = await getDoc(myMatterRef)
    const obj = myMattersSnap.data()

    setMatterObject(obj)

    const pRef = collection(db, 'matters', id, 'plaintiffs')
    const pSnap = await getDocs(pRef)
    if (pSnap.size === 0) {
      setPErr(true)
    } else {
      setPErr(false)
    }

    const dRef = collection(db, 'matters', id, 'defendants')
    const dSnap = await getDocs(dRef)
    if (dSnap.size === 0) {
      setDErr(true)
    } else {
      setDErr(false)
    }

    const aRef = collection(db, 'matters', id, 'attorneys')
    const aSnap = await getDocs(aRef)
    if (aSnap.size === 0) {
      setAErr(true)
    } else {
      setAErr(false)
    }

    const fRef = collection(db, 'matters', id, 'firms')
    const fSnap = await getDocs(fRef)
    if (fSnap.size === 0) {
      setFErr(true)
    } else {
      setFErr(false)
    }
  }, [id])

  const checkForUpdates = useCallback(async () => {
    onSnapshot(collection(db, 'matters', id, 'plaintiffs'), () => {
      retrieveCaseinfo()
    })
    onSnapshot(collection(db, 'matters', id, 'defendants'), () => {
      retrieveCaseinfo()
    })
    onSnapshot(collection(db, 'matters', id, 'attorneys'), () => {
      retrieveCaseinfo()
    })
    onSnapshot(collection(db, 'matters', id, 'firms'), () => {
      retrieveCaseinfo()
    })
  }, [id, retrieveCaseinfo])

  // Retrieve case info constantly
  useEffect(() => {
    checkForUpdates()
  }, [checkForUpdates])

  if (!isCollapsed) {
    return (
      <div>
        <div className='Sidebar'>
          <div className='Top'>
            <div className='Collapse'>
              <ChevronLeftIcon
                onClick={() => collapseSidebar()}
              ></ChevronLeftIcon>
            </div>
          </div>
          <br></br>
          <ul className='SidebarList'>
            {SidebarData.map((val, key) => {
              return (
                <li
                  className='row'
                  id={getCurrentLocation() === val.link ? 'active' : ''}
                  key={key}
                  onClick={() => navigate(val.link)}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {val.icon} &nbsp; {val.title}
                    <div style={{ marginLeft: 'auto', marginRight: '7px' }}>
                      <div style={{ display: 'flex' }}>
                        {val.title === 'Plaintiffs' && pErr ? (
                          <ErrorOutlineOutlinedIcon
                            style={{ color: '#cc6464' }}
                          ></ErrorOutlineOutlinedIcon>
                        ) : (
                          ''
                        )}
                        {val.title === 'Plaintiffs' && pErr ? (
                          <div
                            style={{
                              color: '#cc6464',
                              fontWeight: '300',
                              fontSize: '15px'
                            }}
                          ></div>
                        ) : (
                          ''
                        )}

                        {val.title === 'Defendants' && dErr ? (
                          <ErrorOutlineOutlinedIcon
                            style={{ color: '#cc6464' }}
                          ></ErrorOutlineOutlinedIcon>
                        ) : (
                          ''
                        )}
                        {val.title === 'Defendants' && dErr ? (
                          <div
                            style={{
                              color: '#cc6464',
                              fontWeight: '300',
                              fontSize: '15px'
                            }}
                          ></div>
                        ) : (
                          ''
                        )}

                        {val.title === 'Firms' && fErr ? (
                          <ErrorOutlineOutlinedIcon
                            style={{ color: '#cc6464' }}
                          ></ErrorOutlineOutlinedIcon>
                        ) : (
                          ''
                        )}
                        {val.title === 'Firms' && fErr ? (
                          <div
                            style={{
                              color: '#cc6464',
                              fontWeight: '300',
                              fontSize: '15px'
                            }}
                          ></div>
                        ) : (
                          ''
                        )}

                        {val.title === 'Attorneys' && aErr ? (
                          <ErrorOutlineOutlinedIcon
                            style={{ color: '#cc6464' }}
                          ></ErrorOutlineOutlinedIcon>
                        ) : (
                          ''
                        )}
                        {val.title === 'Attorneys' && aErr ? (
                          <div
                            style={{
                              color: '#cc6464',
                              fontWeight: '300',
                              fontSize: '15px'
                            }}
                          ></div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
            {/* <li
                        className="row"
                        style={{display:"flex",
                                borderTopStyle:"solid", 
                                borderBottomStyle:"solid",
                                marginTop:"20px", 
                                borderWidth:'1px', 
                                borderColor:"lightgray"}}
                        onClick={() => navigate('/app/matters')}>
                            <div style={{"margin-left":"61px"}}>
                                <ArrowBackIosNewOutlinedIcon></ArrowBackIosNewOutlinedIcon>&nbsp;&nbsp;Exit Matter
                            </div>
                        </li> */}
          </ul>
        </div>
        <div className='PlaceHolder1'></div>
      </div>
    )
  } else if (isCollapsed) {
    return (
      <div>
        <div className='CSidebar'>
          <div className='Top2'>
            <div className='Expand'>
              <ChevronRightIcon
                onClick={() => expandSidebar()}
              ></ChevronRightIcon>
            </div>
          </div>
          <br></br>
          <ul className='CSidebarList'>
            {SidebarData.map((val, key) => {
              return (
                <li
                  className='row'
                  id={getCurrentLocation() === val.link ? 'active' : ''}
                  key={key}
                  onClick={() => navigate(val.link)}
                >
                  <div>{val.icon}</div>
                </li>
              )
            })}
          </ul>
        </div>
        <div className='PlaceHolder2'></div>
      </div>
    )
  }
}

export default Sidebar
