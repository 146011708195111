import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Form } from 'react-bootstrap'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const Q10_2 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q10_2, setQ10_2] = useState([])
  const [q10_2disability, setQ10_2disability] = useState('')
  const [q10_2hasDisabilities, setQ10_2hasDisabilities] = useState(false)

  async function saveQuestion() {
    if (q10_2.length === 0) {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q10_2: 'N/A'
        }
      )
    } else {
      await updateDoc(
        doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
        {
          q10_2: q10_2
        }
      )
    }
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    if (docSnap.data().q10_2) {
      if (docSnap.data().q10_2 !== 'N/A') {
        setQ10_2(docSnap.data().q10_2)
        setQ10_2hasDisabilities(true)
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  function addDisability() {
    if (q10_2disability !== '') {
      let currList = q10_2
      currList.push(q10_2disability)

      setQ10_2(currList)
      setQ10_2disability('')
    }
  }

  function remDisability(index) {
    let newList = []
    for (let i = 0; i < q10_2.length; i++) {
      if (i !== index) {
        newList.push(q10_2[i])
      }
    }
    setQ10_2(newList)
  }

  function noDisabilities() {
    setQ10_2hasDisabilities(false)
    setQ10_2([])
  }

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          Did you have any physical, mental, and/or emotional<br></br>
          disabilities immediately before the incident?
          <div style={{ marginTop: '15px' }}></div>
        </div>
        <div style={{ textAlign: 'center' }}>
          {['radio'].map(type => (
            <div key={`inline-${type}`} className='mb-3'>
              <Form.Check
                inline
                label='Yes'
                name='group1'
                type={type}
                checked={q10_2hasDisabilities}
                onClick={() => setQ10_2hasDisabilities(true)}
                id={`inline-${type}-1`}
              />
              <Form.Check
                inline
                label='No'
                name='group1'
                type={type}
                checked={!q10_2hasDisabilities}
                onClick={() => noDisabilities(false)}
                id={`inline-${type}-2`}
              />
            </div>
          ))}
        </div>
        <div style={{ marginTop: '15px' }}></div>
        {q10_2hasDisabilities ? (
          <div>
            <div style={{ textAlign: 'center', width: '400px' }}>
              Add each disability. You may omit mental or <br></br>
              emotional disabilites unless you attribute it <br></br>
              to the incident.
              <div style={{ marginTop: '15px' }}></div>
            </div>
            <div style={{ marginTop: '15px' }}></div>
            <div className='input-container'>
              <input
                type='text'
                value={q10_2disability}
                required
                onChange={e => setQ10_2disability(e.target.value)}
              />
              <label className={q10_2disability && 'filled'}>
                {'Disability'}
              </label>
            </div>

            <div style={{ marginTop: '15px' }}></div>
            <div style={{ display: 'flex', marginTop: '15px' }}>
              <div
                className='AddPerson'
                style={{ color: 'white', margin: 'auto' }}
                onClick={() => addDisability()}
              >
                <div>&nbsp;Add&nbsp;</div>
                <AddIcon style={{ color: 'white' }}></AddIcon>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {q10_2.length > 0 ? <hr></hr> : ''}
              </div>
              <div>
                {q10_2.map((key, i) => {
                  return (
                    <Accordion
                      style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 3px #cccccc',
                        marginBottom: '4px'
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {q10_2[i]}

                        <div style={{ marginLeft: 'auto' }}>
                          <div
                            className='RemButton'
                            onClick={() => remDisability(i)}
                          >
                            &nbsp;
                            <RemoveIcon></RemoveIcon>
                            Remove&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={{ maxWidth: '400px' }}>
                          <div style={{ display: 'flex', padding: '10px' }}>
                            Disability: &nbsp;
                            <div style={{ color: 'gray' }}>{q10_2[i]}</div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
})

export default Q10_2
