import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback
} from 'react'
import '../Intake.css'
import { useParams } from 'react-router'
import '../../../../Inputs/Inputs.css'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../../../firebase'
import { InputGroup } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input-fix'

export const Q8_4 = forwardRef((props, ref) => {
  //m_id is the matter id, p_id is the plaintiff id
  const { m_id, p_id } = useParams()

  const [q8_4income, setQ8_4income] = useState('')
  const [q8_4explanation, setQ8_4explanation] = useState('')

  async function saveQuestion() {
    await updateDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake'),
      {
        q8_4: { Income: q8_4income, Explanation: q8_4explanation }
      }
    )
  }
  useImperativeHandle(ref, () => ({
    saveData() {
      saveQuestion()
      return 'saved'
    }
  }))

  const getAndSetIntakeData = useCallback(async () => {
    const docSnap = await getDoc(
      doc(db, 'matters', m_id, 'plaintiffs', p_id, 'forms', 'intake')
    )
    console.log(docSnap.data())
    if (docSnap.data().q8_4) {
      if (docSnap.data().q8_4 !== 'N/A') {
        setQ8_4income(docSnap.data().q8_4['Income'])
        setQ8_4explanation(docSnap.data().q8_4['Explanation'])
      }
    }
  }, [m_id, p_id])

  useEffect(() => {
    getAndSetIntakeData()
  }, [getAndSetIntakeData])

  return (
    <div style={{ minWidth: '400px' }}>
      <div>
        <div style={{ textAlign: 'center', width: '400px' }}>
          State your monthly income at the time of the<br></br>
          incident and how the amount was calulated
          <div style={{ marginTop: '15px' }}></div>
        </div>

        <div style={{ marginTop: '15px' }}></div>
        <InputGroup className='mb-3'>
          <InputGroup.Text>$</InputGroup.Text>
          <div className='input-container'>
            <CurrencyInput
              id='input-example'
              name='input-name'
              value={q8_4income}
              decimalsLimit={2}
              style={{ width: '363px' }}
              onValueChange={value => setQ8_4income(value)}
            />
            <label className={q8_4income && 'filled'}>{'Monthly income'}</label>
          </div>
        </InputGroup>
        <div style={{ marginTop: '15px' }}></div>
        <div className='input-container' style={{ height: '100px' }}>
          <textarea
            type='text'
            value={q8_4explanation}
            style={{
              height: '100px',
              borderStyle: 'none',
              resize: 'none',
              paddingLeft: '15px',
              paddingRight: '15px',
              paddingTop: '10px',
              borderRadius: '5px'
            }}
            required
            onChange={e => setQ8_4explanation(e.target.value)}
          />
          <label className={q8_4explanation && 'filled'}>
            {'Explain how this number was calculated'}
          </label>
        </div>
      </div>
    </div>
  )
})

export default Q8_4
